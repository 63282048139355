/* eslint-disable react/no-unstable-nested-components */
import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";
import { SxProps } from "@mui/system";
import { useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTable, Cell, Column, useSortBy, usePagination } from "react-table";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ButtonVariant } from "components/library/Button";
import DoverLoadingOverlay from "components/loading-overlay";
import { useJobId } from "hooks/useJobIdFromUrl";
import { AddCandidateButton } from "sections/addcandidate";
import { useHandleAddCandidate } from "sections/addcandidate/components/AddCandidateButton";
import { PipelineCandidate } from "services/openapi";
import { colors } from "styles/theme";
import { isPreClientReview, isPreResponseStage } from "utils/isStage";
import { MatchLabel } from "views/candidates/ApplicationReview/components/ApplicantListPanel/MatchLabel";
import { addPageToStageAtom } from "views/candidates/CandidateNavigation/atoms";
import { ActionCell } from "views/candidates/CandidateTable/table/cells/ActionCell";
import { CurrentStageCell } from "views/candidates/CandidateTable/table/cells/CurrentStageCell";
import { FailureReasonCell } from "views/candidates/CandidateTable/table/cells/FailureReasonCell";
import { LastModifiedCell } from "views/candidates/CandidateTable/table/cells/LastModifiedCell";
import { NameCell } from "views/candidates/CandidateTable/table/cells/NameCell";
import { NextActionCell } from "views/candidates/CandidateTable/table/cells/NextActionCell";
import { OutboundNextUpCell } from "views/candidates/CandidateTable/table/cells/NextUp/OutboundNextUpCell";
import { Cell as StyledCell } from "views/candidates/CandidateTable/table/cells/styles";
import { NoCandidatesFound } from "views/candidates/CandidateTable/table/NoCandidatesFound";
import PaginatedTable from "views/candidates/CandidateTable/table/PaginatedTable";
import NoApplicantsEmptyState from "views/candidates/components/NoApplicantsEmptyState";
import {
  CANDIDATES_LIMIT,
  ColumnNameEnum,
  columnNameOrderingIds,
  MAX_TABLE_HEIGHT,
  MIN_TABLE_HEIGHT,
  TABLE_STYLES,
} from "views/candidates/constants";
import {
  getOrderingString,
  useIsApplicantsActive,
  useCandidates,
  useListCandidatesWithNextActions,
  useParams,
} from "views/candidates/hooks";

type CustomColumn<T extends object> = Column<T> & { sx?: SxProps };

export const CandidateTable = React.memo(
  (): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAppliedStageSelected = useIsApplicantsActive();
    const openAddCandidateDrawer = useHandleAddCandidate();

    const { data: candidatesData, isFetching } = useCandidates({ provideCandidateBucketLabels: true });
    const { results: candidates, count } = candidatesData || { results: [] as PipelineCandidate[], count: 0 };

    const addPageToStage = useSetAtom(addPageToStageAtom);
    const [{ page, hpsId }, setParams] = useParams();
    useEffect(() => {
      const stageId = hpsId ? hpsId[0] : undefined;
      if (candidatesData?.results && stageId) {
        addPageToStage({ stageId, page, candidates: candidatesData?.results });
      }
    }, [candidatesData, page, addPageToStage, hpsId, candidates]);

    const candidateIds = useMemo(() => candidates?.map((c: PipelineCandidate) => c.id!), [candidates]);
    const { data: candidatesWithNextActionsMap, isFetching: nextActionsFetching } = useListCandidatesWithNextActions(
      isAppliedStageSelected ? undefined : candidateIds // For applied stage we show the matching labels instead
    );

    const setPage = useCallback(
      (pageIndex: number) => {
        setParams({ page: pageIndex });
      },
      [setParams]
    );

    const totalNumPages = useMemo(() => Math.ceil(count / CANDIDATES_LIMIT), [count]);

    const columns = useMemo(
      (): CustomColumn<PipelineCandidate>[] => [
        {
          id: columnNameOrderingIds[ColumnNameEnum.Name],
          Header: ColumnNameEnum.Name,
          //  @ts-ignore
          accessor: "contact.fullName",
          // @ts-ignore
          Cell: ({ row }: Cell<PipelineCandidate>): React.ReactElement => (
            <NameCell candidate={row.original} socialLinkTypes={["LINKEDIN"]} />
          ),
          sx: { width: "350px" },
        },
        {
          id: columnNameOrderingIds[ColumnNameEnum.PipelineStage],
          Header: ColumnNameEnum.PipelineStage,
          // @ts-ignore
          accessor: "pipelineProcessingStage.value",
          // @ts-ignore
          Cell: ({ row }: Cell<PipelineCandidate>): React.ReactElement => <CurrentStageCell candidate={row.original} />,
        },
      ],
      []
    );

    //  @ts-ignore
    const columnsWithActionButtons: Column<PipelineCandidate>[] = useMemo(() => {
      const columnsWithActionButtonsTmp = [
        ...columns,
        {
          id: ColumnNameEnum.NextUp,
          Header: isAppliedStageSelected ? ColumnNameEnum.Match : ColumnNameEnum.NextUp,
          Cell: ({ row }: Cell<PipelineCandidate>): React.ReactElement => {
            // show pipeline failures for ayone before client review
            // this includes QUEUED (falsy currentStage) and any stage before CLIENT_REVIEW 400
            if (!row.original.candidatePipelineStage || isPreClientReview(row.original.candidatePipelineStage)) {
              if (row.original.pipelineFailedProcessing) {
                return <FailureReasonCell candidate={row.original} />;
              }
            }

            if (isAppliedStageSelected) {
              return (
                <StyledCell>
                  <MatchLabel label={row.original.candidateBucketLabel} />
                </StyledCell>
              );
            }

            // if they have not responded show outbound next up
            if (row.original.candidatePipelineStage && isPreResponseStage(row.original.candidatePipelineStage)) {
              return <OutboundNextUpCell candidate={row.original} />;
            }

            return nextActionsFetching ? (
              <Skeleton width="100px" height="60px" style={{ margin: "0 0 0 16px" }} />
            ) : (
              <NextActionCell
                candidate={row.original}
                nextAction={candidatesWithNextActionsMap && candidatesWithNextActionsMap[row.original.id!]}
              />
            );
          },
          accessor: ColumnNameEnum.NextUp,
          disableSortBy: true,
        },
        {
          Header: ColumnNameEnum.Actions,
          Cell: ({ row }: Cell<PipelineCandidate>): React.ReactElement => <ActionCell candidate={row.original} />,
          accessor: ColumnNameEnum.Actions as any,
          id: ColumnNameEnum.Actions,
          disableSortBy: true,
        },
        {
          Header: ColumnNameEnum.LastModified,
          accessor: "lastEventTs",
          Cell: ({ row }: Cell<PipelineCandidate>): React.ReactElement => <LastModifiedCell candidate={row.original} />,
          id: columnNameOrderingIds[ColumnNameEnum.LastModified],
        },
      ];
      return columnsWithActionButtonsTmp;
    }, [candidatesWithNextActionsMap, columns, isAppliedStageSelected, nextActionsFetching]);

    const table = useTable(
      {
        columns: columnsWithActionButtons,
        data: candidates,
        manualSortBy: true,
        autoResetSortBy: false,
        manualPagination: true,
        pageCount: totalNumPages,
      },
      useSortBy,
      usePagination
    );

    useEffect(() => {
      setParams({ ordering: getOrderingString(table.state.sortBy) });
    }, [setParams, table.state.sortBy]);

    // Information potentially needed for navigation on row click
    const [jobId] = useJobId();

    const handleRowClick = (rowData: PipelineCandidate): void => {
      if (jobId) {
        navigate(
          APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, rowData.id!, new URLSearchParams(location.search))
        );
      }
    };

    if ((!candidates || !candidates.length) && !isFetching) {
      if (isAppliedStageSelected) {
        return (
          <Stack justifyContent="center" minHeight={MIN_TABLE_HEIGHT} maxHeight={MAX_TABLE_HEIGHT}>
            <NoApplicantsEmptyState />
          </Stack>
        );
      }
      return (
        <Stack justifyContent="space-between">
          <Stack>
            <TableRow
              className="candidates-table-row"
              key={"add-candidate"}
              onClick={(): void => openAddCandidateDrawer()}
              sx={{
                backgroundColor: "#fff",
                "&:hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" },
                borderBottom: "1px",
                borderColor: colors.grayscale.gray200,
                borderStyle: "solid",
              }}
            >
              <TableCell align="left" padding="none" sx={{ padding: "4px 16px !important", border: "none" }}>
                <AddCandidateButton
                  buttonProps={{
                    variant: ButtonVariant.Ghost,
                  }}
                />
              </TableCell>
              <TableCell
                sx={{ borderBottom: "1px", borderColor: colors.grayscale.gray200 }}
                align="left"
                padding="none"
              />
              <TableCell padding="none" />
              <TableCell padding="none" />
              <TableCell padding="none" />
            </TableRow>
          </Stack>
          <Stack justifyContent="center" minHeight={MIN_TABLE_HEIGHT} maxHeight={MAX_TABLE_HEIGHT}>
            <NoCandidatesFound />
          </Stack>
        </Stack>
      );
    }

    return (
      <DoverLoadingOverlay active={isFetching} minHeight={MIN_TABLE_HEIGHT}>
        <PaginatedTable
          table={table}
          hiddenHeaders={[ColumnNameEnum.SocialLinks]}
          onRowClick={handleRowClick}
          sx={{ ...TABLE_STYLES, borderRadius: "0" }}
          isLoadingMore={isFetching}
          totalNumItems={count}
          numItemsPerPage={CANDIDATES_LIMIT}
          currentPageIndex={page}
          setCurrentPageIndex={setPage}
        />
      </DoverLoadingOverlay>
    );
  }
);
