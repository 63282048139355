import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { ReactComponent as CirclePlusSVG } from "assets/icons/circle-plus.svg";
import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import SearchesTable from "components/dover/SearchesTable/SearchesTable";
import { SourcingAutopilotCard } from "components/dover/sourcing/SourcingAutopilotCard";
import { SourcingExtensionCard } from "components/dover/sourcing/SourcingExtensionCard";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useJobId } from "hooks/useJobIdFromUrl";
import useEnableAutopilotV2 from "services/doverapi/cross-endpoint-hooks/useEnableAutopilotV2";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3SearchTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import SourcingPageCampaignSection from "views/job/JobSetup/steps/CampaignVariants/SourcingPageCampaignSection";

interface SearchesSectionProps {
  jobId?: string | null;
}

const SearchesSection = ({ jobId }: SearchesSectionProps): ReactElement => {
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);
  // Hook is misnamed; in this context we init search + sourcing boilerplate without enabling the feature
  const setupSourcingBoilerplate = useEnableAutopilotV2(job, false);

  // Hide the new search button if one already exists
  const { data: searches } = useListSearchesV3Query(
    jobId
      ? {
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );

  const hasSearch = searches?.length && searches.length > 0;

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Subtitle2>Search Criteria</Subtitle2>
        {!hasSearch && (
          <Tooltip title="Create a new search">
            <Stack
              alignItems="center"
              onClick={setupSourcingBoilerplate}
              spacing={0.5}
              direction="row"
              sx={{ cursor: "pointer" }}
            >
              <CirclePlusSVG style={{ cursor: "pointer" }} />
              <BodySmall color={colors.primary.dark}>Add</BodySmall>
            </Stack>
          </Tooltip>
        )}
      </Stack>
      <Box mt={2}>
        <SearchesTable inJobView />
      </Box>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * JobSourcingPage
 * -------------------------------------------------------------------------- */

const JobSourcingPage = (): React.ReactElement => {
  const advertiseChromeExtension = useFeatureFlag(FeatureFlag.AdvertiseSourcingExtension);

  const [jobId] = useJobId();

  const { isUninitialized } = useListSearchesV3Query(
    jobId
      ? {
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );

  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  const isAdmin = useHasRole(Role.ADMIN);

  const showSearchesSection = useLegacySourcingAutopilot || isAdmin;

  return (
    <Stack spacing={3} maxWidth="800px" margin="0 auto" pb={2}>
      {advertiseChromeExtension && <SourcingExtensionCard />}
      <SourcingPageCampaignSection />

      <Stack spacing={2}>
        <Subtitle2>Sourcing</Subtitle2>
        <JobFeatureToggleHandler>
          <SourcingAutopilotCard />
        </JobFeatureToggleHandler>
      </Stack>

      {showSearchesSection && !isUninitialized && <SearchesSection jobId={jobId} />}
    </Stack>
  );
};

export default JobSourcingPage;
