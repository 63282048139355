import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as NoEmailIcon } from "assets/icons/no-email.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning-outlined.svg";
import { SchedulingLinkBannerInfo, Warning } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";

export const WarningBanner: FC<React.PropsWithChildren<{ warning?: Warning }>> = ({ warning }) => {
  if (!warning) {
    return null;
  }

  return (
    <Banner
      variant={warning.useErrorColor ? BannerVariant.Critical : BannerVariant.Warning}
      overrideIcon={<NoEmailIcon width="28px" />}
    >
      <Stack>
        <BodySmall weight="500">{warning.title}</BodySmall>
        <BodySmall>{warning.body}</BodySmall>
      </Stack>
    </Banner>
  );
};

const schedulingHelpLink = "https://help.dover.com/en/articles/8155575-emailing-scheduling-candidates";

export const SchedulingLinkBanner: FC<React.PropsWithChildren<{ bannerInfo: SchedulingLinkBannerInfo }>> = ({
  bannerInfo,
}) => {
  // We show if there are no scheduling links or only a Calendly link
  // Just a findatime link is perfectly fine (we want to encourage using Dover Scheduling)
  // Calendly => warning, no links => info

  const noLinks = !(bannerInfo.hasFindatimeLink || bannerInfo.hasCalendlyLink);
  const onlyCalendly = bannerInfo.hasCalendlyLink && !bannerInfo.hasFindatimeLink;
  const show = noLinks || onlyCalendly;
  if (!show) {
    return null;
  }

  const variant = onlyCalendly ? BannerVariant.Warning : BannerVariant.Info;
  const overrideIcon = variant === BannerVariant.Warning ? <WarningIcon width="28px" /> : <InfoIcon width="28px" />;

  return (
    <Banner variant={variant} overrideIcon={overrideIcon}>
      <Stack>
        <BodySmall>
          We strongly recommend using Dover Scheduling.{" "}
          <a href={schedulingHelpLink} rel="noopener noreferrer" target="_blank">
            {"Here's why"}
          </a>
          .
        </BodySmall>
      </Stack>
    </Banner>
  );
};
