import { atom } from "jotai";

import { CandidateBio, PipelineCandidate } from "services/openapi";

/*
  This is a gross part of this implementation.  We fetch the candidate results lower in the tree in
  the Page components, but we need access to the candidate here for optimistic updates (and also in Column for the dnd render clones)
  So, we create this jotai map and copy the rtkq results into it so we can access the candidate data here.

  I have thoughts on better approaches, but would require a signficant change of direction that I don't currently have time for.
*/
export const candidateMapAtom = atom<Map<string, PipelineCandidate>>(new Map());

export const getCandidateFromMapAtom = atom(get => (id: string | undefined): PipelineCandidate | undefined =>
  id ? get(candidateMapAtom).get(id) : undefined
);

export const addCandidateToMapAtom = atom(null, (get, set, candidate: PipelineCandidate) => {
  const map = get(candidateMapAtom);
  map.set(candidate.id!, candidate);
});

/*
This is so we can access what is being dragged and dropped from the context of the card, since that doesn't have access to the
Droppable context.
*/

export const dndCandidateBioResponseAtom = atom<CandidateBio | undefined>(undefined);
export const candidateBeingUpdatedAtom = atom<string>();
