import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useState } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import FeedbackTemplateSelect, {
  FeedbackTemplateSelectorProps,
} from "components/dover/FeedbackTemplates/FeedbackTemplateSelect";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Checkbox } from "components/library/Checkbox";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import Modal from "components/Modal";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { interviewRubricResponseStateAtom } from "views/interview/CandidateInterview/atoms";

interface FeedbackTemplateSelectModalProps extends FeedbackTemplateSelectorProps {
  isOpen: boolean;
  close: () => void;
  interviewStageFeedbackTemplateId?: string;
  jobId?: string;
}

const FeedbackTemplateSelectModal: React.FC<React.PropsWithChildren<FeedbackTemplateSelectModalProps>> = ({
  isOpen,
  close,
  feedbackTemplateId,
  setFeedbackTemplateId,
  interviewStageFeedbackTemplateId,
  jobId,
}) => {
  const interviewRubricResponseState = useAtomValue(interviewRubricResponseStateAtom);

  const [selectedFeedbackTemplateId, setSelectedFeedbackTemplateId] = useState<string | undefined>(feedbackTemplateId);
  const [confirmDiscardInProgress, setConfirmDiscardInProgress] = useState(false);
  const selectedTemplateNotAssignedToStage = selectedFeedbackTemplateId !== interviewStageFeedbackTemplateId;
  const requireChangeConfirmation =
    interviewRubricResponseState !== undefined &&
    interviewRubricResponseState >= 200 &&
    selectedFeedbackTemplateId !== feedbackTemplateId;

  // Disable save if the feedback template is unchanged or if the user needs to confirm changes will be lost
  const disableSave =
    feedbackTemplateId === selectedFeedbackTemplateId || (requireChangeConfirmation && !confirmDiscardInProgress);

  return (
    <Modal
      open={isOpen}
      onClose={close}
      maxWidth="sm"
      title="Change feedback form"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button variant={ButtonVariant.Secondary} onClick={close}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              // update atom value on submission
              setFeedbackTemplateId(selectedFeedbackTemplateId);
              close();
            }}
            disabled={disableSave}
          >
            Change feedback form
          </Button>
        </Stack>
      }
    >
      <Stack spacing={1}>
        <FeedbackTemplateSelect
          // shouldn't modify the atom value directly but update internal state
          feedbackTemplateId={selectedFeedbackTemplateId}
          setFeedbackTemplateId={setSelectedFeedbackTemplateId}
        />
        {selectedTemplateNotAssignedToStage && jobId && (
          <BodyExtraSmall color={colors.grayscale.gray600}>
            Want to always use this form for this interview stage?{" "}
            <InternalLink to={APP_ROUTE_PATHS.job.interviewPlan(jobId)} target="_blank">
              Assign form to stage
            </InternalLink>
          </BodyExtraSmall>
        )}
        {requireChangeConfirmation && (
          <Stack spacing={1} pt={2}>
            <Banner variant={BannerVariant.Critical}>
              <Stack>
                <BodySmall>
                  {/* conditionally show items as bulleted list */}
                  {selectedTemplateNotAssignedToStage && <span>&bull; </span>}Changing feedback forms will overwrite
                  your existing answers.
                </BodySmall>
                {selectedTemplateNotAssignedToStage && (
                  <BodySmall>
                    <span>&bull;</span> This is not the form currently assigned to this stage.
                  </BodySmall>
                )}
              </Stack>
            </Banner>
            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="center">
              <Checkbox
                checked={confirmDiscardInProgress}
                onChange={(): void => setConfirmDiscardInProgress(!confirmDiscardInProgress)}
              />
              <BodySmall> I understand my notes will be lost</BodySmall>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default FeedbackTemplateSelectModal;
