import styled from "styled-components";

export const Divider = styled.hr`
  margin: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 90%;
`;
interface InvisibleLinkProps {
  disabled?: boolean;
}

export const InvisibleLink = styled.a<InvisibleLinkProps>`
  div:hover {
    background-color: ${({ disabled }): string | undefined => (disabled ? "transparent !important" : undefined)};
  }
  &:not([href]):not([tabindex]) {
    color: #888888;
    font-weight: 400;
    text-decoration: none;
    cursor: ${({ disabled }): string => (disabled ? "auto" : "pointer")};

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      color: "#888888"
      text-decoration: none;
    }
  }
`;

export const BackLink = styled(InvisibleLink)`
  &:not([href]):not([tabindex]) {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:focus,
    &:visited,
    &:link,
    &:hover {
      color: #666666;
    }
  }
`;
