import { Box } from "@mui/material";
import React, { useMemo } from "react";

import { Body, BodySmall } from "components/library/typography";
import Confirm from "components/Modal/ConfirmModal";
import {
  useDeleteJobPositionMutation,
  useDeleteJobPositionOpeningMutation,
  useListJobPositionsQuery,
} from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanTableRow } from "views/HiringPlan/types";

interface DeleteJobPositionProps {
  tableRow: HiringPlanTableRow;
}

const DeleteJobPositionMenuItem = ({ tableRow }: DeleteJobPositionProps): React.ReactElement => {
  const jobPosition = tableRow.jobPosition;
  const hasMultipleOpenings = jobPosition?.jobPositionOpenings && jobPosition?.jobPositionOpenings?.length > 1;
  const deleteHeadcountOnly = !tableRow.isFirstOpening || (tableRow.isFirstOpening && hasMultipleOpenings);

  const { isLoading: isJobPositionsLoading } = useListJobPositionsQuery();

  const [deleteJobPosition] = useDeleteJobPositionMutation();
  const [deleteJobPositionOpening] = useDeleteJobPositionOpeningMutation();

  const handleDelete = (): void => {
    if (deleteHeadcountOnly && tableRow.id) {
      deleteJobPositionOpening({ id: parseInt(tableRow.id), jobPositionId: tableRow.jobPositionId });
      return;
    } else if (tableRow.jobPositionId) {
      deleteJobPosition({ id: tableRow.jobPositionId });
    }
  };

  const confirmDeleteContent = useMemo((): React.ReactElement => {
    return (
      <Body>
        Are you sure you want to delete{" "}
        {deleteHeadcountOnly
          ? `${tableRow.title} Headcount ${tableRow.nthOpeningForPosition}?`
          : `${tableRow.title} position?`}
      </Body>
    );
  }, [deleteHeadcountOnly, tableRow.nthOpeningForPosition, tableRow.title]);

  return (
    <Confirm
      submitText={"Delete"}
      submitVariant="critical"
      content={confirmDeleteContent}
      disabled={isJobPositionsLoading}
    >
      {/* @ts-ignore FIX: Confirm type is miserable */}
      {(confirm: any): React.ReactNode => (
        /*
        // @ts-ignore */
        <Box onClick={confirm((): void => handleDelete())}>
          <BodySmall>{deleteHeadcountOnly ? "Delete Headcount" : "Delete Position"}</BodySmall>
        </Box>
      )}
    </Confirm>
  );
};

export default DeleteJobPositionMenuItem;
