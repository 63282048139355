/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { NextAction, PipelineCandidate } from "services/openapi";
import { getNextActionColors, getNextActionTitle } from "views/CandidateDetail/components/next-action/utils/helpers";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";

interface Props {
  nextAction: NextAction;
  candidate: PipelineCandidate;
  condensed?: boolean;
}

export const NextUpCell = ({ nextAction, candidate, condensed }: Props): React.ReactElement => {
  const colors = getNextActionColors(nextAction, candidate);
  const title = useMemo(() => {
    return getNextActionTitle(nextAction, candidate);
  }, [nextAction, candidate]);

  const NextActionBox = (): React.ReactElement => {
    return (
      <StyledNextActionBox colors={colors} condensed={condensed!}>
        <Overline color={colors.headerTextColor} inline>
          {title}
        </Overline>{" "}
      </StyledNextActionBox>
    );
  };

  const CellContent = (): React.ReactElement => {
    if (!title) {
      return <></>;
    }

    return <NextActionBox />;
  };

  return (
    <StyledNextUpCell condensed={condensed}>
      <CellContent />
    </StyledNextUpCell>
  );
};

interface NextActionBoxProps {
  colors: { backgroundColor: string; borderColor: string; headerTextColor: string };
  condensed: boolean;
}

const StyledNextActionBox = styled.span<NextActionBoxProps>`
  border-radius: 4px;
  padding-top: ${(props): any => (props.condensed ? "2px" : "10px")};
  padding-bottom: ${(props): any => (props.condensed ? "5px" : "10px")};
  padding-left: ${(props): any => (props.condensed ? "8px" : "10px")};
  padding-right: ${(props): any => (props.condensed ? "8px" : "10px")};
  background: ${({ colors }): string => colors.backgroundColor};
  color: ${({ colors }): string => colors.headerTextColor};
  white-space: nowrap;
`;

const StyledNextUpCell = styled(Cell)<{ condensed?: boolean }>`
  padding: ${(props): any => (props.condensed ? "0px" : "16px")};
`;
