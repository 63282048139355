import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { FC } from "react";
import { Navigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useCandidateId } from "hooks/useCandidateId";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";

export const CandidateViewRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const [jobIdFromUrl] = useJobId();
  const candidateId = useCandidateId();

  const {
    data: candidateBio,
    isLoading: isCandidateBioLoading,
    isUninitialized: isCandidateBioUninitialized,
  } = useGetCandidateBioQuery(candidateId ?? skipToken);

  const jobId = candidateBio?.job ?? jobIdFromUrl;

  if (!jobId) {
    // If we still don't have a job id, we can't redirect to a candidate page and should just go home
    if (!candidateId) {
      return <Navigate to={APP_ROUTE_PATHS.home()} />;
    }

    // If we have no job id we want to attempt to get it from the candidate bio first
    if (isCandidateBioUninitialized || isCandidateBioLoading) {
      return <DoverLoadingSpinner />;
    }

    // I don't think this should ever be reached
    return <Navigate to={APP_ROUTE_PATHS.home()} />;
  }

  if (!candidateId) {
    return <Navigate to={APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId)} />;
  }

  return <Navigate to={APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, candidateId)} />;
};
