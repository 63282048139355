import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, CenteredOverline, Overline } from "components/library/typography";
import { useGetCandidateBioQuery, useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { ArchiveReason, ArchiveReasonTypeEnum, UpdateCandidateBioBodyStatusEnum } from "services/openapi";
import { colors } from "styles/theme";
import { getFormattedTimestamp } from "utils/dates";
import { toastOptions } from "utils/showToast";
const hiredIcon = <span className="emoji">🎉</span>;

const reasonToTitle = (reason: ArchiveReason): string | React.ReactElement => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return "Rejected";
    case ArchiveReasonTypeEnum.Withdrew:
      return "Withdrew";
    case ArchiveReasonTypeEnum.Hired:
      return <>{hiredIcon} Hired</>;
    case ArchiveReasonTypeEnum.Snoozed:
      return "Snoozed";
    case ArchiveReasonTypeEnum.Other:
      return "Archived";
    default:
      return "Archived";
  }
};

const reasonToVariant = (reason: ArchiveReason): BannerVariant => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return BannerVariant.Critical;
    case ArchiveReasonTypeEnum.Withdrew:
      return BannerVariant.Critical;
    case ArchiveReasonTypeEnum.Hired:
      return BannerVariant.Success;
    case ArchiveReasonTypeEnum.Snoozed:
      return BannerVariant.Warning;
    case ArchiveReasonTypeEnum.Other:
      return BannerVariant.Info;
    default:
      return BannerVariant.Info;
  }
};

const reasonToColor = (reason: ArchiveReason): string => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return colors.critical.base;
    case ArchiveReasonTypeEnum.Withdrew:
      return colors.critical.base;
    case ArchiveReasonTypeEnum.Hired:
      return colors.success.base;
    case ArchiveReasonTypeEnum.Snoozed:
      return colors.warning.dark;
    case ArchiveReasonTypeEnum.Other:
      return colors.informational.base;
    default:
      return colors.informational.base;
  }
};

export const ArchiveBanner = ({
  candidateId,
  archiveReason,
}: {
  candidateId?: string;
  archiveReason?: ArchiveReason;
}): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const [updateBio] = useUpdateCandidateBioMutation();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  const clickEditReject = useCallback(() => {
    if (!candidateId) return; // Shouldn't be possible because button is disabled

    showCandidateActionModal({
      candidateId,
      modalType: CandidateActionModalType.Reject,
      initialRejectionReason: archiveReason,
    });
  }, [archiveReason, candidateId, showCandidateActionModal]);

  const clickEditSnoozed = useCallback(async () => {
    if (!candidateId || !candidateBio) return; // Shouldn't be possible because button is disabled
    const jobId = candidateBio.job;
    const args = {
      id: candidateId,
      jobId,
      hideToast: true,
      data: {
        snoozedUntil: null,
        status: UpdateCandidateBioBodyStatusEnum.Active,
      },
    };

    const submitDecisionPromise = updateBio(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Un-Snoozing...",
          success: "Un-Snoozed!",
          error: "Error Un-Snoozed Candidate!",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateId, candidateBio, updateBio]);

  if (!archiveReason) return <></>;
  const isSnoozed = archiveReason.type === ArchiveReasonTypeEnum.Snoozed;
  const isRejectedOrWithdrew =
    archiveReason.type === ArchiveReasonTypeEnum.Rejected || archiveReason.type === ArchiveReasonTypeEnum.Withdrew;

  const snoozedUntilTime: string = candidateBio?.snoozedUntil
    ? "until " + getFormattedTimestamp(candidateBio?.snoozedUntil)
    : "Indefinitely";
  const message: string | React.ReactElement = isSnoozed ? `Snoozed ${snoozedUntilTime}` : reasonToTitle(archiveReason);

  const OverlineClass = isSnoozed ? CenteredOverline : Overline;
  return (
    <Banner variant={reasonToVariant(archiveReason)} noIcon padding={isSnoozed ? "4px 6px" : "8px 4px"}>
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Stack alignItems={"center"}>
          <OverlineClass color={reasonToColor(archiveReason)}>{message}</OverlineClass>
          {isRejectedOrWithdrew && <BodySmall>{archiveReason.description}</BodySmall>}
        </Stack>
        {isRejectedOrWithdrew && (
          <Button variant={ButtonVariant.Ghost} onClick={clickEditReject}>
            <PencilIcon />
          </Button>
        )}
        {isSnoozed && (
          <Tooltip title="You can select this option to un-snooze a candidate and make them active again">
            <Button variant={ButtonVariant.Ghost} onClick={clickEditSnoozed} padding="0 4px">
              <UndoIcon height="16px" width="16px" color={colors.muiChipGray} />
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Banner>
  );
};
