import { Stack } from "@mui/material";
import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { colors } from "styles/theme";
import { useJobNavOptions } from "views/job/hooks";
import { JobNavOption } from "views/job/types";

interface JobViewNavOptionsProps {
  showLeftScrollArrow: (show: boolean) => void;
  showRightScrollArrow: (show: boolean) => void;
  onScrollableRefMount: (node: HTMLDivElement) => void;
}

const JobNavGroup = ({ jobId, navOptions }: { jobId: string; navOptions: JobNavOption[] }): React.ReactElement => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent={"center"}
      alignItems={"center"}
      p={"4px"}
      sx={{
        overflowX: "clip",
        borderRadius: "8px",
        backgroundColor: colors.grayscale.gray100,
        a: {
          boxSizing: "border-box",
          padding: "4px 8px",
          fontSize: "14px",
          fontFamily: "Inter",
          fontWeight: 400,
          color: colors.grayscale.gray500,
          textDecoration: "none !important",
          whiteSpace: "nowrap",

          ":hover": {
            color: colors.grayscale.gray700,
            backgroundColor: colors.white,
            borderRadius: "4px",
          },

          "&.job-nav-item-active": {
            fontWeight: 600,
            color: colors.success.base,
            backgroundColor: colors.white,
            borderRadius: "4px",

            "&:hover": {
              backgroundColor: colors.white,
            },
          },
        },
      }}
    >
      {navOptions.map(option => {
        return (
          <NavLink
            to={`/job/${jobId}/${option.path}`}
            className={({ isActive }): string | undefined => (isActive ? "job-nav-item-active" : undefined)}
          >
            {option.title}
          </NavLink>
        );
      })}
    </Stack>
  );
};

export const JobViewNavOptions = ({ onScrollableRefMount }: JobViewNavOptionsProps): React.ReactElement => {
  const navOptionGroups = useJobNavOptions();

  const { jobId } = useParams<{ jobId: string | undefined }>();

  if (!jobId) {
    return <></>;
  }

  return (
    <Stack
      ref={onScrollableRefMount}
      direction="row"
      alignItems="end"
      justifyContent="start"
      spacing={1}
      paddingLeft="25px"
      paddingRight="10px"
      position="relative"
      sx={{
        overflowX: "auto",
      }}
      marginBottom={"8px !important"}
    >
      {navOptionGroups.map(group => {
        return <JobNavGroup jobId={jobId} navOptions={group} />;
      })}
    </Stack>
  );
};
