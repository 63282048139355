/* -----------------------------------------------------------------------------
 * Duplicate FeedbackTemplate Form
 * -------------------------------------------------------------------------- */

import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue, useAtom } from "jotai";
import React, { useEffect } from "react";

import {
  feedbackTemplateDrawerConfigAtom,
  feedbackTemplateNameAtom,
  userDefinedQuestionsAtom,
} from "components/dover/FeedbackTemplates/atoms";
import { DrawerFooter } from "components/dover/FeedbackTemplates/DrawerHeaderFooter";
import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { useReset } from "components/dover/FeedbackTemplates/hooks";
import InnerForm from "components/dover/FeedbackTemplates/InnerForm";
import { FeedbackTemplateFormProps, FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { useModal } from "GlobalOverlays/atoms";
import {
  useGetFeedbackTemplateQuery,
  useCreateFeedbackTemplateMutation,
} from "services/doverapi/endpoints/feedbackTemplates";

/**
 * @description Form for duplicating an existing FeedbackTemplate
 *
 * Populates form state from an existing FeedbackTemplate and calls the regular create mutation.
 *
 */
const DuplicateFeedbackTemplateForm: React.FC<React.PropsWithChildren<FeedbackTemplateFormProps>> = ({
  validateOnClose,
}) => {
  const { close: onClose } = useModal(FeedbackTemplateDrawerAtom);

  const { existingTemplateId } = useAtomValue(feedbackTemplateDrawerConfigAtom);
  const resetAtomState = useReset();
  const [feedbackTemplateName, setFeedbackTemplateName] = useAtom(feedbackTemplateNameAtom);
  const [userDefinedQuestions, setUserDefinedQuestions] = useAtom(userDefinedQuestionsAtom);

  const { data: existingFeedbackTemplate, isFetching: isLoadingExistingTemplate } = useGetFeedbackTemplateQuery(
    existingTemplateId ?? skipToken
  );
  const [createFeedbackTemplate] = useCreateFeedbackTemplateMutation();

  useEffect(() => {
    if (existingFeedbackTemplate) {
      setFeedbackTemplateName(existingFeedbackTemplate.name + " (Copy)");
      setUserDefinedQuestions(existingFeedbackTemplate.userDefinedQuestions as FeedbackTemplateQuestion[]);
    }
  }, [existingFeedbackTemplate, setFeedbackTemplateName, setUserDefinedQuestions]);

  const onCreateFeedbackTemplate = async (): Promise<void> => {
    if (feedbackTemplateName && userDefinedQuestions) {
      const validQuestions = userDefinedQuestions.filter(q => q.label.trim() !== "");

      await createFeedbackTemplate({
        data: { name: feedbackTemplateName, questions: validQuestions },
      });
    }
    resetAtomState();
    onClose();
  };

  if (!existingTemplateId) {
    return null;
  }

  return (
    <Stack p={2} spacing={2}>
      {isLoadingExistingTemplate ? (
        <Skeleton variant="rectangular" height="50px" />
      ) : (
        <>
          <InnerForm />
          <DrawerFooter onClose={validateOnClose} onSave={onCreateFeedbackTemplate} />
        </>
      )}
    </Stack>
  );
};

export default DuplicateFeedbackTemplateForm;
