import React from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { Tooltip } from "components/library/Tooltip";
import { colors } from "styles/theme";

interface InfoIconProps {
  text: string | React.ReactElement;
  leaveDelay?: number | undefined;
}

export const InfoTip: React.FC<InfoIconProps> = ({ text, leaveDelay }) => {
  return (
    <Tooltip title={text} leaveDelay={leaveDelay}>
      <InfoIcon className="svg-color" color={colors.grayscale.gray500} />
    </Tooltip>
  );
};
