import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import throttle from "lodash/throttle";
import React, { useEffect, useState } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { getOpenApiClients } from "services/api";
import config from "services/auth_config";
import { useAuth0 } from "services/react-auth0-spa";
import { reAuth } from "services/reAuth";

const ImpersonatorPage = props => {
  const { user } = useAuth0();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    if (props.skipRefresh) {
      return;
    }

    reAuth();
    window.location.reload(false);
  };

  const setAlias = async () => {
    setLoading(true);
    const { apiApi } = await getOpenApiClients({});
    try {
      await apiApi.setAlias({ data: { aliasUserEmail: value.email } });
    } catch (err) {
      console.error(err);
    }
    refreshPage();
  };

  const clearAlias = async () => {
    setLoading(true);
    try {
      const { apiApi } = await getOpenApiClients({});
      await apiApi.clearAlias();
      refreshPage();
    } catch (err) {
      console.error(err);
    }
  };

  const throttledFetchUsers = React.useMemo(
    () =>
      throttle((search, callback) => {
        (async () => {
          try {
            const { apiApi } = await getOpenApiClients({});
            const usersResult = await apiApi.listUsers({ fullTextSearch: search });
            callback(usersResult);
          } catch (err) {
            console.error(err);
          }
        })();
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    throttledFetchUsers(inputValue, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results.results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, throttledFetchUsers]);

  return (
    <Container>
      {/* Main content */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p>Current User: {user.email}</p>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="google-map-demo"
            style={{ width: 300 }}
            getOptionLabel={option => (typeof option === "string" ? option : option.email)}
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText="no options -- try a different search"
            value={value}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={params => (
              <TextField {...params} label="Select a user to impersonate" variant="outlined" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={ButtonVariant.SecondaryCritical}
            disabled={!(user.impersonated || user[config.clientInfoUrl])}
            onClick={clearAlias}
          >
            Clear
          </Button>{" "}
          <Button variant={ButtonVariant.Primary} disabled={!value || loading} onClick={setAlias}>
            {loading ? "Loading..." : "Impersonate"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImpersonatorPage;
