import { Progress } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC } from "react";

import { BodySmall } from "components/library/typography";
import { HiringPipelineStage } from "services/openapi";
import { colors } from "styles/theme";

interface PendingStageRowProps {
  stage: HiringPipelineStage;
}

export const PendingStageRow: FC<React.PropsWithChildren<PendingStageRowProps>> = ({ stage }) => {
  return (
    <Stack direction="row" width="100%" alignItems="center">
      <Progress size="small" />
      <BodySmall color={colors.grayscale.gray400}>{`Creating ${stage.name}`}</BodySmall>
    </Stack>
  );
};
