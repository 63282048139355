import { differenceInDays } from "date-fns";
import React from "react";

import StyledBadge from "components/StyledBadge";
import { CampaignStats } from "services/openapi";
import { colors } from "styles/theme";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
export const StatusCell: React.FC<React.PropsWithChildren<{
  status: CampaignStatus | undefined;
  stats: CampaignStats | undefined;
}>> = ({ status, stats }) => {
  switch (status) {
    case CampaignStatus.Active: {
      let text: string = "Active";
      if (stats?.earliestFirstContactedOn) {
        const daysActive = differenceInDays(new Date(), stats.earliestFirstContactedOn);
        text += ` for ${daysActive} day${daysActive == 1 ? "" : "s"}`;
      }
      return <StyledBadge color={colors.primary.base} backgroundColor={"#ebf6f3"} borderColor={"none"} text={text} />;
    }
    case CampaignStatus.Draft:
      return <StyledBadge text={"Draft"} />;
    case CampaignStatus.Inactive:
      return <StyledBadge text={"Inactive"} />;
    case CampaignStatus.Processing:
      return <StyledBadge text={"Processing"} />;
    default:
      return <></>;
  }
};
