import { DeactivateJobDeactivationReasonEnum } from "services/openapi";
import { JobNavOption } from "views/job/types";

export enum RemotePolicies {
  InOffice = "InOffice",
  Remote = "Remote",
  Both = "Both",
}
export enum VisaSupport {
  Yes = "Yes",
  No = "No",
}

export const INTERNATIONAL_LOCATION_NAME = "International";

export const JOB_NAV_HEADER_Z_INDEX = 11;

const OVERVIEW_NAV_OPTION = {
  title: "Overview",
  path: "overview",
};

const JOB_DETAILS_NAV_OPTION = {
  title: "Job Posting",
  path: "job-posting",
};
const JOB_BOARDS_NAV_OPTION = {
  title: "Job Boards",
  path: "job-boards",
};
const CANDIDATES_NAV_OPTION = {
  title: "Candidates",
  path: "candidates",
};
export const APPLICANTS_NAV_OPTION = {
  title: "Applicants",
  path: "application-review-v2",
};
export const SOURCING_AUTOPILOT_NAV_OPTION = {
  title: "Sourcing Autopilot",
  path: "criteria",
};
const INTERVIEW_PLAN_NAV_OPTION = {
  title: "Interview Plan",
  path: "interview-plan",
};

const SOURCING_NAV_OPTION = {
  title: "Sourcing",
  path: "sourcing",
};

const REFERRALS_NAV_OPTION = {
  title: "Referrals",
  path: "referrals",
};

const AGENCIES_NAV_OPTION = {
  title: "Agencies",
  path: "agencies",
};

export const GROUP_2_WITH_LEGACY_SOURCING_AUTOPILOT: JobNavOption[] = [
  JOB_BOARDS_NAV_OPTION,
  SOURCING_NAV_OPTION,
  SOURCING_AUTOPILOT_NAV_OPTION,
  REFERRALS_NAV_OPTION,
  AGENCIES_NAV_OPTION,
];

export const jobNavOptions: JobNavOption[][] = [
  [OVERVIEW_NAV_OPTION, APPLICANTS_NAV_OPTION, CANDIDATES_NAV_OPTION],
  [JOB_BOARDS_NAV_OPTION, SOURCING_NAV_OPTION, REFERRALS_NAV_OPTION, AGENCIES_NAV_OPTION],
  [JOB_DETAILS_NAV_OPTION, INTERVIEW_PLAN_NAV_OPTION],
];

export type DeactivateOptions = Partial<
  {
    [key in DeactivateJobDeactivationReasonEnum]: Readonly<string>;
  }
>;
export const deactivateOptions: Readonly<DeactivateOptions> = {
  [DeactivateJobDeactivationReasonEnum.Hired]: "Hired someone for this job",
  [DeactivateJobDeactivationReasonEnum.DePrioritizedJobNoLongerHiring]: "We're no longer hiring someone for this job",
};

export const SCROLLABLE_CONTAINER_WIDTH = "900px";
