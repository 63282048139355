import { Box, InputAdornment, Skeleton, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning-outlined.svg";
import { ReactComponent as DoverDLogo } from "assets/logos/DoverD-small.svg";
import { useAreInterviewPreferencesCompleteForInterviewerIds } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/utils";
import { BodySmall } from "components/library/typography";
import InterviewerCreateModal from "components/NextInterviewer/InterviewerCreateModal";
import { useInterviewerOptions } from "components/NextInterviewer/useInterviewerOptions";
import { useNewInterviewer } from "components/NextInterviewer/useNewInterviewer";
import { NextInterviewerHiringPipelineStage } from "components/NextInterviewer/usePossibleInterviewers";
import { DOVER_INTERVIEWER_ID } from "components/NextInterviewer/usePossibleInterviewers";
import { colors } from "styles/theme";
import { useJob } from "views/candidates/hooks";

const DownArrowIcon = (
  <Box display="flex" m="auto">
    <DownCaretSVG width="30px" />
  </Box>
);

export interface Interviewer {
  label: string;
  value: string;
  fullName?: string;
  hasSchedulingLinkOverride: boolean;
  preferencesComplete: boolean;
}

interface InterviewerAutoCompleteProps {
  selectedInterviewer: Interviewer;
  setSelectedInterviewer: (interviewer: Interviewer) => void;
  excludeDoverInterviewer?: boolean;
  hideLabel?: boolean;
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
  pipelineStageId?: string;
  jobId?: string;
  disabled?: boolean;
  borderColor?: string;
}

export const InterviewerAutoComplete: React.FC<React.PropsWithChildren<InterviewerAutoCompleteProps>> = ({
  selectedInterviewer,
  setSelectedInterviewer,
  excludeDoverInterviewer = false,
  hideLabel = false,
  desiredHiringPipelineStage,
  disabled,
  borderColor,
}) => {
  const job = useJob();

  const {
    options: { data: interviewerOptions, isFetching: isFetchingInterviewers },
  } = useInterviewerOptions({
    desiredHiringPipelineStage: desiredHiringPipelineStage,
    excludeDoverInterviewer,
  });

  // Add interviewer pref to options
  const interviewerIds = interviewerOptions?.filter(o => o.value !== undefined).map(o => o.value!) ?? [];
  const { interviewerIdsToValidate } = useAreInterviewPreferencesCompleteForInterviewerIds({
    interviewerIds,
  });
  const interviewerOptionsWithPref = interviewerOptions?.map(o => {
    if (o.value === undefined)
      return {
        ...o,
        preferencesComplete: false,
      };

    const hasPref = !interviewerIdsToValidate[o.value];
    return {
      ...o,
      preferencesComplete: hasPref,
    };
  });

  const { showModal, setShowModal, createInterviewerFn } = useNewInterviewer({
    selectedInterviewer,
    setInterviewer: interviewer => {
      setSelectedInterviewer({
        label: interviewer.label,
        value: interviewer.value ?? "",
        fullName: interviewer.fullName,
        hasSchedulingLinkOverride: interviewer.hasSchedulingLinkOverride ?? false,
        preferencesComplete: interviewer.preferencesComplete,
      });
    },
  });

  if (isFetchingInterviewers || !interviewerOptionsWithPref) {
    return <Skeleton width="100%" height="60px" />;
  }

  return (
    <>
      <Autocomplete
        disableClearable
        disabled={disabled}
        id="application-review-select-interviewer"
        value={selectedInterviewer}
        options={interviewerOptionsWithPref}
        getOptionLabel={(interviewer): string => interviewer.label}
        popupIcon={DownArrowIcon}
        onChange={(_, newOption: any): void => {
          newOption = newOption ?? { label: "", value: "", hasSchedulingLinkOverride: false };
          setSelectedInterviewer(newOption);
        }}
        sx={{
          marginBottom: "-8px !important",
          fontSize: "14px",
          fontFamily: "Inter",
          backgroundColor: colors.white,
          ".MuiOutlinedInput-root ": { border: "none" },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor,
          },
          ".MuiAutocomplete-endAdornment": { top: "auto" },
        }}
        renderInput={(params: any): React.ReactElement => (
          <StyledTextField
            {...params}
            label={hideLabel ? undefined : "Interviewer"}
            variant="outlined"
            hideLabel={hideLabel}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment:
                params?.inputProps?.value === "Dover Interviewer" ? (
                  <InputAdornment position="start">
                    <DoverDLogo width="24px" height="24px" style={{ marginRight: "-8px" }} />
                  </InputAdornment>
                ) : (
                  undefined
                ),
            }}
          />
        )}
        renderOption={(props, option): React.ReactElement => (
          // @ts-ignore
          <Stack {...props} direction="row" spacing={1} width="100%">
            {option.value === DOVER_INTERVIEWER_ID && <DoverDLogo width="24px" height="24px" />}
            {!option.preferencesComplete && <WarningIcon />}
            <BodySmall style={{ flexGrow: 1 }}>{option.label}</BodySmall>
            {option.isDefaultInterviewer && <BodySmall color={colors.grayscale.gray500}>Default</BodySmall>}
          </Stack>
        )}
      />
      <InterviewerCreateModal
        showModal={showModal}
        onCloseModal={(): void => setShowModal(false)}
        createInterviewer={createInterviewerFn}
        interviewers={interviewerOptions}
        validateEmail={(): boolean => true}
        creating={showModal}
        hiringManagerEmail={job?.hiringManager?.email}
      />
    </>
  );
};

interface StyledTextFieldProps {
  hideLabel?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  .MuiOutlinedInput-root {
    height: 40px;
  }
  input {
    font-size: 14px;
    font-family: Inter;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grayscale.gray300};
    ${(props): any =>
      props.hideLabel &&
      css`
        height: 100%;
        top: 0;

        legend {
          display: none;
        }
      `}
  }
`;
