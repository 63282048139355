import { Button, Icon } from "@doverhq/dover-ui";
import { ReactComponent as ChevronDownIcon } from "@doverhq/dover-ui/icons/chevron-down.svg";
import { ReactComponent as ChevronRightIcon } from "@doverhq/dover-ui/icons/chevron-right.svg";
import { ReactComponent as InfoIcon } from "@doverhq/dover-ui/icons/info.svg";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Paper, Menu, MenuItem } from "@mui/material";
import Stack from "@mui/material/Stack";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Autocomplete } from "mui-rff";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { Form, useForm, useFormState } from "react-final-form";
import { useDispatch } from "react-redux";

import { getUpdatedJobSetupValues } from "components/AtsSettings/components/AtsJobSettings/helpers";
import { GREENHOUSE_ATS_SYNC_SETTING_OPTIONS, LEVER_ATS_SYNC_SETTING_OPTIONS } from "components/AtsSettings/constants";
import { useAtsJobs, useAtsUsers, useGetBehalfUsers } from "components/AtsSettings/hooks";
import { SelectOption } from "components/AtsSettings/types";
import Drawer from "components/library/Drawer";
import { TextField } from "components/library/TextField";
import Toggle from "components/library/Toggle";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall, Heading, Subtitle1, Subtitle2 } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { useElementDimensions } from "hooks/useElementDimensions";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { doverApi } from "services/doverapi/apiSlice";
import { useShouldSyncJobAtsStages } from "services/doverapi/cross-endpoint-hooks/useShouldSyncJobAtsStages";
import { useGetAshbyDataQuery } from "services/doverapi/endpoints/ashbyData";
import { useListCandidateSourcesQuery } from "services/doverapi/endpoints/candidateSource";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetGreenhouseDataQuery } from "services/doverapi/endpoints/greenhouseData";
import { jobEndpoints, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import { useGetSourceDisplaySectionData } from "services/doverapi/endpoints/job-source-settings/customHooks";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import { useGetLeverDataQuery } from "services/doverapi/endpoints/leverData";
import { ASHBY_DATA, GREENHOUSE_DATA, LEVER_DATA } from "services/doverapi/endpointTagsConstants";
import {
  CandidateSource,
  CandidateSourceDisplaySectionEnum,
  ClientOnboardingAtsTypeEnum,
  DashboardJob,
  DashboardJobAtsSyncSettingEnum,
  JobCandidateSourceSettingDesiredStateEnum,
  JobSetupAtsSyncSettingEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";
import { titleCase } from "utils/strings";
import { AtsSourceSettingsList } from "views/CompanySetup/components/AtsSettings/AtsSourceSettingsList";

/* -----------------------------------------------------------------------------
 * DrawerHeader
 * -------------------------------------------------------------------------- */

interface DrawerHeaderProps extends Pick<AtsJobConfigurationDrawerProps, "onRequestClose"> {}

const DrawerHeader = ({ onRequestClose }: DrawerHeaderProps): ReactElement => {
  return (
    <Box sx={{ position: "sticky", top: 0, height: "56px", zIndex: 1400 }}>
      <Paper elevation={1} sx={{ borderRadius: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%" px={3} py={1}>
          <Heading>Sync job</Heading>
          <Button variant="ghost" onPress={onRequestClose}>
            <Icon Icon={XIcon} />
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * AtsJobInboundConfigurationSection
 * -------------------------------------------------------------------------- */

interface AtsJobInboundConfigurationSectionProps {
  atsType?: ClientOnboardingAtsTypeEnum;
  jobId?: string;
  formValues?: AtsJobConfigurationFormValues;
  setValue?: <F extends keyof AtsJobConfigurationFormValues>(
    name: F,
    value?: AtsJobConfigurationFormValues[F] | undefined
  ) => void;
}

const AtsJobInboundConfigurationSection = ({
  atsType,
  jobId,
  formValues,
  setValue,
}: AtsJobInboundConfigurationSectionProps): ReactElement => {
  const sourceDisplaySectionData = useGetSourceDisplaySectionData({ jobId, includeAdminPreview: true });
  const enrichedCandidateSourceDetails = sourceDisplaySectionData?.data?.[CandidateSourceDisplaySectionEnum.InboundAts];

  const { data: candidateSources } = useListCandidateSourcesQuery(
    jobId
      ? {
          jobId: jobId,
        }
      : skipToken
  );

  const [isUpdatingInbound, setIsUpdatingInbound] = useState(false);
  const [isCandidateSourcesOpen, setIsCandidateSourcesOpen] = useState(false);

  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  const candidateSource = (Object.values(candidateSources?.entities ?? { atsType: false }) as CandidateSource[]).find(
    source => source?.atsType === atsType
  );
  const enrichedSourceDetails = enrichedCandidateSourceDetails?.find(d => d.candidateSource.id === candidateSource?.id);
  const sourceSettings = enrichedSourceDetails?.jobCandidateSourceSettings;

  const toggleIsCandidateSourcesOpen = (): void => {
    setIsCandidateSourcesOpen(!isCandidateSourcesOpen);
  };

  const handleToggleInboundSources = async (value: boolean): Promise<void> => {
    if (candidateSource?.id && jobId) {
      setIsUpdatingInbound(true);
      await submitJobCandidateSourceForm({
        jobId,
        candidateSourceId: candidateSource.id,
        desiredState: value
          ? JobCandidateSourceSettingDesiredStateEnum.Active
          : JobCandidateSourceSettingDesiredStateEnum.Inactive,
      }).unwrap();
      setIsUpdatingInbound(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={0.5}>
        <Subtitle2>Inbound</Subtitle2>
        <BodySmall color={colors.grayscale.gray600}>
          Configure Dover to automatically pull in the applications in your ATS and sort by best match
        </BodySmall>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Toggle
          checked={formValues?.atsInboundSync ?? false}
          onChange={(): void => {
            setValue?.("atsInboundSync", !formValues?.atsInboundSync);
            handleToggleInboundSources(!formValues?.atsInboundSync);
          }}
          label="atsInboundSync"
        />
        <BodySmall color={colors.grayscale.gray700}>Sync applications</BodySmall>
      </Stack>
      {formValues?.atsInboundSync &&
        [ClientOnboardingAtsTypeEnum.Greenhouse, ClientOnboardingAtsTypeEnum.Lever].includes(
          atsType as ClientOnboardingAtsTypeEnum
        ) && (
          <Stack spacing={2} p={2} border={`1px solid ${colors.grayscale.gray300}`} borderRadius={1}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Toggle
                  checked={formValues?.atsInboundRejectionEnabled ?? false}
                  onChange={(): void => {
                    setValue?.("atsInboundRejectionEnabled", !formValues?.atsInboundRejectionEnabled);
                  }}
                  label="atsInboundRejectionEnabled"
                />
                <BodySmall color={colors.grayscale.gray700}>Auto-reject candidates who do not pass criteria</BodySmall>
              </Stack>
              <Tooltip
                placement="top"
                title="If you have auto-rejection emails on for this role, applicants who do not pass your criteria will automatically receive rejection emails."
              >
                <Box>
                  <Icon Icon={InfoIcon} color="gray-400" />
                </Box>
              </Tooltip>
            </Stack>
            {!!candidateSource && !!sourceSettings && (
              <>
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  onClick={toggleIsCandidateSourcesOpen}
                  sx={{ cursor: "pointer" }}
                >
                  {isCandidateSourcesOpen ? (
                    <Icon Icon={ChevronDownIcon} color="gray-700" />
                  ) : (
                    <Icon Icon={ChevronRightIcon} color="gray-700" />
                  )}
                  <BodySmall color={colors.grayscale.gray700}>
                    Configure {titleCase(atsType!)} candidates sources
                  </BodySmall>
                </Stack>
                {isCandidateSourcesOpen && (
                  <AtsSourceSettingsList source={candidateSource} sourceSettings={sourceSettings} />
                )}
              </>
            )}
          </Stack>
        )}
      <DoverLoadingOverlay active={isUpdatingInbound} minHeight="auto" fullScreen={isUpdatingInbound} />
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * DrawerBody
 * -------------------------------------------------------------------------- */

interface DrawerBodyProps {
  job: DashboardJob;
  atsType?: ClientOnboardingAtsTypeEnum;
  isUpdating: boolean;
  onSubmit: () => Promise<any> | undefined;
}

const DrawerBody = ({ job, atsType, isUpdating, onSubmit }: DrawerBodyProps): ReactElement => {
  const drawerBodyRef = useRef<HTMLDivElement>();
  const atsJobs = useAtsJobs();
  const atsUsers = useAtsUsers();
  const getBehalfUsers = useGetBehalfUsers();
  const { width } = useElementDimensions(drawerBodyRef);

  const isEnterpriseCustomer = useFeatureFlag(FeatureFlag.HubspotDoverEE);

  const { isFetching: leverDataLoading } = useGetLeverDataQuery(
    atsType === ClientOnboardingAtsTypeEnum.Lever ? undefined : skipToken
  );
  const { isFetching: greenhouseDataLoading } = useGetGreenhouseDataQuery(
    atsType === ClientOnboardingAtsTypeEnum.Greenhouse ? {} : skipToken
  );
  const { isFetching: ashbyDataLoading } = useGetAshbyDataQuery(
    atsType === ClientOnboardingAtsTypeEnum.Ashby ? {} : skipToken
  );
  const atsJobsDataIsLoading = leverDataLoading || greenhouseDataLoading || ashbyDataLoading;

  const { change: setValue } = useForm<AtsJobConfigurationFormValues>();
  const { values: formValues, errors, submitFailed } = useFormState<AtsJobConfigurationFormValues>();
  const hasAtsSyncSettingError = submitFailed && !!errors?.atsSyncSetting;

  const selectedJobName = atsJobs?.find(option => option.value === job.atsJobId)?.label;
  const selectedUserName = atsUsers?.find(option => option.value === formValues.atsBehalfId)?.label;

  let atsSyncSettingOptions: Readonly<Array<SelectOption>> = [];
  if (atsType === ClientOnboardingAtsTypeEnum.Greenhouse) {
    atsSyncSettingOptions = GREENHOUSE_ATS_SYNC_SETTING_OPTIONS;
  } else if (atsType === ClientOnboardingAtsTypeEnum.Lever) {
    atsSyncSettingOptions = LEVER_ATS_SYNC_SETTING_OPTIONS;
  }

  const atsSyncSettingLabel = atsSyncSettingOptions.find(option => option.value === formValues.atsSyncSetting)?.label;
  const ashbyAtsSyncSettingLabel = "The candidate is approved for the Initial Call";

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [atsJobNameInput, setAtsJobNameInput] = useState(selectedJobName);
  const [employeeNameInput, setEmployeeNameInput] = useState(selectedUserName);

  const behalfUsers = useMemo(() => {
    return getBehalfUsers(formValues.atsJobId ?? "");
  }, [formValues.atsJobId, getBehalfUsers]);

  const handleCloseSyncSettingMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <Stack justifyContent="space-between" flex="1 1 auto" ref={drawerBodyRef}>
      <Stack p={2} spacing={2}>
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {atsType === ClientOnboardingAtsTypeEnum.Greenhouse && isEnterpriseCustomer ? (
              <>
                <Subtitle2>Input Greenhouse Job ID</Subtitle2>
                <Tooltip
                  leaveDelay={200}
                  title={
                    <>
                      More{" "}
                      <ExternalLink
                        display="inline"
                        href={
                          "https://support.greenhouse.io/hc/en-us/articles/360059668131-Find-template-job-IDs-for-a-custom-HRIS-integration#:~:text=using%20Harvest%20API-,Retrieve%20a%20job%20ID%20in%20Greenhouse%20Recruiting,numerical%20string%20on%20the%20URL"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </ExternalLink>{" "}
                      about finding your Greenhouse Job ID.
                    </>
                  }
                >
                  <span>
                    <Icon Icon={InfoIcon} color="gray-400" />
                  </span>
                </Tooltip>
              </>
            ) : (
              <Subtitle2>Select ATS Job</Subtitle2>
            )}
          </Stack>
          {atsType === ClientOnboardingAtsTypeEnum.Greenhouse && isEnterpriseCustomer ? (
            <TextField
              text={formValues.atsJobId}
              onTextUpdated={(text: string): void => setValue("atsJobId", text)}
              required
              error={!!errors?.atsJobId}
              errorMessage={errors?.atsJobId}
            />
          ) : (
            <Autocomplete
              name="atsJobId"
              label=""
              id="atsJobId"
              required
              textFieldProps={{ variant: "outlined" }}
              disabled={atsJobsDataIsLoading}
              options={atsJobs}
              getOptionValue={(option: SelectOption): any => option.value}
              getOptionLabel={(option: string | SelectOption): string =>
                typeof option === "string" ? option : option.label
              }
              inputValue={atsJobNameInput}
              value={atsJobs.find(o => o.value === formValues.atsJobId)}
              onInputChange={(_, newInputValue): void => {
                setValue("atsJobId", newInputValue);
                setAtsJobNameInput(newInputValue);
              }}
              onChange={(e: any, v: any): void => {
                if (v) {
                  setValue("atsJobId", v.value);
                  setAtsJobNameInput(v.label);
                }
              }}
            />
          )}
        </Stack>
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {atsType === ClientOnboardingAtsTypeEnum.Greenhouse && isEnterpriseCustomer ? (
              <>
                <Subtitle2>Input Greenhouse User ID to post on behalf of</Subtitle2>
                <Tooltip
                  leaveDelay={200}
                  title={
                    <>
                      More{" "}
                      <ExternalLink
                        display="inline"
                        href={
                          "https://support.greenhouse.io/hc/en-us/articles/10765948752027-Find-your-Greenhouse-Recruiting-user-ID#:~:text=the%20Harvest%20API-,Find%20a%20user%20ID%20in%20Greenhouse%20Recruiting,the%20user%20ID%20is%2012345%20"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </ExternalLink>{" "}
                      about finding your Greenhouse User ID.
                    </>
                  }
                >
                  <span>
                    <Icon Icon={InfoIcon} color="gray-400" />
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                <Subtitle2>Post on behalf of</Subtitle2>
                <Tooltip title="You must select someone who has full access to this job in your ATS. Changes made in Dover will be attributed to them.">
                  <span>
                    <Icon Icon={InfoIcon} color="gray-400" />
                  </span>
                </Tooltip>
              </>
            )}
          </Stack>
          {atsType === ClientOnboardingAtsTypeEnum.Greenhouse && isEnterpriseCustomer ? (
            <TextField
              text={formValues.atsBehalfId}
              onTextUpdated={(text: string): void => setValue("atsBehalfId", text)}
              required
              error={!!errors?.atsBehalfId}
              errorMessage={errors?.atsBehalfId}
            />
          ) : (
            <Autocomplete
              name="atsBehalfId"
              label=""
              id="atsBehalfId"
              required
              textFieldProps={{ variant: "outlined" }}
              disabled={atsJobsDataIsLoading}
              options={behalfUsers}
              getOptionValue={(option: SelectOption): any => option.value}
              getOptionLabel={(option: string | SelectOption): string =>
                typeof option === "string" ? option : option.label
              }
              inputValue={employeeNameInput}
              onInputChange={(_, newInputValue): void => {
                setValue("atsBehalfId", newInputValue);
                setEmployeeNameInput(newInputValue);
              }}
              value={behalfUsers.find(o => o.value === formValues.atsBehalfId)}
              onChange={(e: any, v: any): void => {
                if (v) {
                  setValue("atsBehalfId", v.value);
                  setEmployeeNameInput(v.label);
                }
              }}
            />
          )}
        </Stack>
        <Stack spacing={0.5}>
          <Subtitle2>Add sourced candidate to ATS when:</Subtitle2>
          <Stack spacing={1}>
            <Stack spacing={0.5}>
              <Stack
                border={hasAtsSyncSettingError ? `1px solid ${colors.critical.base}` : undefined}
                borderRadius="6px"
              >
                <Button
                  variant="outlined"
                  onPress={(e): void => {
                    setAnchorEl(e.target);
                  }}
                  isDisabled={atsType === ClientOnboardingAtsTypeEnum.Ashby}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Subtitle1 className="placeholder" style={{ textAlign: "left" }}>
                      {atsType === ClientOnboardingAtsTypeEnum.Ashby
                        ? ashbyAtsSyncSettingLabel
                        : atsSyncSettingLabel ?? "Select an option"}
                    </Subtitle1>
                    <KeyboardArrowDownIcon />
                  </Stack>
                </Button>
              </Stack>
              {hasAtsSyncSettingError && (
                <Box pl="14px">
                  <BodyExtraSmall color={colors.critical.base}>Required</BodyExtraSmall>
                </Box>
              )}
            </Stack>
            <Menu
              open={!!anchorEl}
              onClose={handleCloseSyncSettingMenu}
              onClick={handleCloseSyncSettingMenu}
              anchorEl={anchorEl}
            >
              {atsSyncSettingOptions.map(option => {
                return (
                  <MenuItem
                    key={option.value}
                    onClick={(): void => {
                      setValue("atsSyncSetting", option.value as DashboardJobAtsSyncSettingEnum);
                    }}
                    sx={{ "&.MuiButtonBase-root": { display: "flex", justifyContent: "start", padding: "8px" } }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Stack>
        </Stack>
        {atsType && [ClientOnboardingAtsTypeEnum.Greenhouse, ClientOnboardingAtsTypeEnum.Lever].includes(atsType) && (
          <AtsJobInboundConfigurationSection
            atsType={atsType}
            jobId={job?.id}
            formValues={formValues}
            setValue={setValue}
          />
        )}
      </Stack>
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        width={width ? width : "auto"}
        bgcolor="white"
        borderTop={1}
        borderColor={colors.grayscale.gray200}
        py={1.5}
        pr={1.5}
        sx={width ? { position: "fixed", bottom: 0 } : undefined}
      >
        <Button variant="primaryFilled" onPress={onSubmit} isLoading={isUpdating}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * AtsJobConfigurationDrawer
 * -------------------------------------------------------------------------- */

type FormErrors = "atsJobId" | "atsBehalfId" | "atsSyncSetting";

type ValidationResponse = { [key in FormErrors]?: string };

function isInteger(value?: string | null): boolean {
  if (!value) {
    return false;
  }

  return /^\d+$/.test(value);
}

const validateForm = (
  values: AtsJobConfigurationFormValues,
  atsType: ClientOnboardingAtsTypeEnum | undefined
): ValidationResponse => {
  const errors: ValidationResponse = {};
  // validation for Greenhouse, Lever, and Ashby
  if (
    atsType === ClientOnboardingAtsTypeEnum.Greenhouse ||
    atsType === ClientOnboardingAtsTypeEnum.Lever ||
    atsType === ClientOnboardingAtsTypeEnum.Ashby
  ) {
    if (!values.atsJobId) {
      errors.atsJobId = "Required";
    }

    if (!values.atsBehalfId) {
      errors.atsBehalfId = "Required";
    }
  }

  // GH specific
  if (atsType === ClientOnboardingAtsTypeEnum.Greenhouse) {
    if (!isInteger(values.atsJobId)) {
      errors.atsJobId = "Job ID must be a number (e.g. 1234 or 1234567)";
    }
    if (!isInteger(values.atsBehalfId)) {
      errors.atsBehalfId = "User ID must be a number (e.g. 1234 or 1234567)";
    }
  }

  // validation for Greenhouse and Lever
  if (atsType === ClientOnboardingAtsTypeEnum.Greenhouse || atsType === ClientOnboardingAtsTypeEnum.Lever) {
    if (!values.atsSyncSetting) {
      errors.atsSyncSetting = "Required";
    }
  }

  return errors;
};

export interface AtsJobConfigurationFormValues {
  atsJobId?: string | null;
  atsBehalfId?: string | null;
  atsSyncSetting?: DashboardJobAtsSyncSettingEnum;
  atsInboundSync?: boolean;
  atsInboundRejectionEnabled?: boolean;
}

interface AtsJobConfigurationDrawerProps {
  job?: DashboardJob;
  onRequestClose: () => void;
}

export const AtsJobConfigurationDrawer = ({ job, onRequestClose }: AtsJobConfigurationDrawerProps): ReactElement => {
  const dispatch = useDispatch();
  const atsJobs = useAtsJobs();
  const shouldSyncJobAtsStages = useShouldSyncJobAtsStages({ jobId: job?.id });
  const { data: client } = useGetClientOnboardingQuery();
  const { data: candidateSources } = useListCandidateSourcesQuery(
    job?.id
      ? {
          jobId: job.id,
        }
      : skipToken
  );
  const { data: jobCandidateSourceSettings } = useListJobCandidateSourceSettingsQuery(
    job?.id
      ? {
          job: job.id,
        }
      : skipToken
  );
  const candidateSource = (Object.values(candidateSources?.entities ?? { atsType: false }) as CandidateSource[]).find(
    source => source?.atsType === client?.atsType
  );
  const jobCandidateSourceSetting = Object.values(
    jobCandidateSourceSettings?.entities ?? { candidateSource: undefined }
  ).find(j => j?.candidateSource?.id === candidateSource?.id);

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateJobSetup] = useUpdateJobSetupMutation();
  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  const onSubmit = async (values: AtsJobConfigurationFormValues): Promise<void> => {
    if (!job?.id || !client?.id) {
      return;
    }
    setIsUpdating(true);
    showPendingToast("Updating job's ATS configuration...");

    const atsJobName = atsJobs.find(o => o.value === values.atsJobId)?.label;
    const { triggerResyncOverride, updateValues } = getUpdatedJobSetupValues(
      job.id,
      {
        atsJobTitle: atsJobName,
        atsJobId: values.atsJobId,
        atsBehalfId: values.atsBehalfId,
        atsSyncSetting: (values.atsSyncSetting as unknown) as JobSetupAtsSyncSettingEnum,
      },
      atsJobs,
      atsJobName,
      client?.atsType
    );

    try {
      await updateJobSetup({ ...updateValues, skipToast: true }).unwrap();

      if (shouldSyncJobAtsStages || triggerResyncOverride) {
        dispatch(jobEndpoints.endpoints.syncAndListJobAtsStages.initiate({ jobId: job.id, triggerResyncOverride }));
      }
      if (client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse) {
        dispatch(doverApi.util.invalidateTags([GREENHOUSE_DATA]));
      }
      if (client?.atsType === ClientOnboardingAtsTypeEnum.Lever) {
        dispatch(doverApi.util.invalidateTags([LEVER_DATA]));
      }
      if (client?.atsType === ClientOnboardingAtsTypeEnum.Ashby) {
        dispatch(doverApi.util.invalidateTags([ASHBY_DATA]));
      }

      if (candidateSource?.id && job?.id) {
        await submitJobCandidateSourceForm({
          candidateSourceId: candidateSource.id,
          jobId: job.id,
          desiredState: values.atsInboundSync
            ? JobCandidateSourceSettingDesiredStateEnum.Active
            : JobCandidateSourceSettingDesiredStateEnum.Inactive,
          atsInboundRejectionEnabled:
            values.atsInboundRejectionEnabled ?? job.atsInboundRejectionEnabled ? "true" : "false",
        }).unwrap();
      }

      showSuccessToast("Job's ATS configuration updated successfully!");
      onRequestClose();
    } catch (_) {
      showErrorToast("Failed to update job's ATS configuration. Please ensure job and behalf IDs are correct.");
    }

    setIsUpdating(false);
  };

  return (
    <Drawer
      open={!!job}
      anchor="right"
      onClose={onRequestClose}
      shouldToggleIntercom
      sx={{ position: "relative", overflow: "auto" }}
    >
      <DrawerHeader onRequestClose={onRequestClose} />
      <Form
        onSubmit={onSubmit}
        validate={(values: AtsJobConfigurationFormValues): ValidationResponse => validateForm(values, client?.atsType)}
        initialValues={{
          atsJobId: job?.atsJobId ?? undefined,
          atsBehalfId: job?.atsBehalfId ?? undefined,
          atsSyncSetting:
            client?.atsType === ClientOnboardingAtsTypeEnum.Ashby
              ? DashboardJobAtsSyncSettingEnum.CallScheduling
              : job?.atsSyncSetting,
          atsInboundSync: jobCandidateSourceSetting?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active,
          atsInboundRejectionEnabled: job?.atsInboundRejectionEnabled ?? false,
        }}
      >
        {({ handleSubmit }): React.ReactElement => {
          return job && client?.id ? (
            <DrawerBody job={job} atsType={client.atsType} isUpdating={isUpdating} onSubmit={handleSubmit} />
          ) : (
            <></>
          );
        }}
      </Form>
    </Drawer>
  );
};
