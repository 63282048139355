import { Stack } from "@mui/material";
import React from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CheckIcon } from "assets/icons/complete-check.svg";
import { ReactComponent as GreenCheck } from "assets/icons/simple-green-check.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Heading, BodySmall, TitleLarge } from "components/library/typography";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";
import { ReactComponent as Logos } from "views/RecruitingPartners/logos-small.svg";

export const NewJobUpsellModal: React.FC<React.PropsWithChildren<GlobalModalProps>> = ({ isOpen, close }) => {
  const [, setIsNewJob] = useQueryParam("isNewJob", BooleanParam);
  const rpPageUrl = `${appConfig.appUrl}${APP_ROUTE_PATHS.recruitingPartners()}`;

  setIsNewJob(false);

  return (
    <CustomModal open={isOpen} maxWidth="sm" onClose={close}>
      <Stack spacing={2} alignItems={"center"} justifyContent={"center"} px={4}>
        <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"}>
          <CheckIcon className="svg-color" color={colors.black} />
          <Heading weight="500">Job added!</Heading>
        </Stack>
        <Stack
          py={2}
          px={4}
          sx={{
            border: `1px solid ${colors.grayscale.gray200}`,
            borderRadius: "10px",
            background: colors.grayscale.gray100,
            textAlign: "center",
          }}
          minWidth={"max-content"}
          spacing={1.5}
          alignItems="center"
          justifyContent="center"
        >
          <TitleLarge weight="500">Do you need help with hiring?</TitleLarge>
          <BodySmall>Access elite recruiters with proven success at fast-growing startups.</BodySmall>
          <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="center">
            <Stack direction="row" spacing={0.5}>
              <GreenCheck />
              <BodySmall color={colors.grayscale.gray600}>No minimums</BodySmall>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <GreenCheck />
              <BodySmall color={colors.grayscale.gray600}>No commitments</BodySmall>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <GreenCheck />
              <BodySmall color={colors.grayscale.gray600}>Pause whenever you need</BodySmall>
            </Stack>
          </Stack>
          <Stack width="fit-content">
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                window.open(rpPageUrl, "_blank", "noopener noreferrer");
              }}
            >
              Learn more
            </Button>
          </Stack>
          <Stack pt={3} spacing={1} alignItems="center" justifyContent="center">
            <BodySmall color={colors.grayscale.gray500}>We&apos;ve helped hire at top companies</BodySmall>
            <Logos />
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export const newJobUpsellModalAtom = modalAtom(NewJobUpsellModal);
