import { Stack } from "@mui/material";
import React from "react";

import { ScheduleRejectButtons } from "components/ScheduleRejectButtons";
import { SaapReviewApplication } from "services/openapi";

interface ActionSectionProps {
  application: SaapReviewApplication;
}

export const ActionSection: React.FC<React.PropsWithChildren<ActionSectionProps>> = ({ application }) => {
  return (
    <>
      <Stack spacing={1}>
        <Stack direction="row" spacing={0.5} mr="auto">
          <ScheduleRejectButtons application={application} />
        </Stack>
      </Stack>
    </>
  );
};
