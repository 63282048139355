import { ArrowDropDown } from "@mui/icons-material";
import { Box, Menu, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState, useCallback, useEffect } from "react";

import { TextButton } from "components/Button";
import { timezones } from "views/interview/InterviewScheduler/utils";

const TimePicker = ({
  time,
  handleSetTime,
}: {
  time: string;
  handleSetTime: (time: string) => void;
}): React.ReactElement => {
  const menuItems = [];

  const increment = 15; // in minutes
  const iterations = 96;
  const initialTime = dayjs().startOf("day");

  for (let i = 0; i < iterations; i++) {
    const time = initialTime.add(increment * i, "m");
    menuItems.push({ text: time.format("h:mma"), value: time.format("HH:mm") });
  }

  return (
    <Select
      size="small"
      value={time}
      onChange={(e): void => {
        if (e.target.value) {
          handleSetTime(e.target.value as string);
        }
      }}
    >
      {menuItems.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

/* Helpers */

export enum ScheduledActionEnum {
  Immediately = "Immediately",
  InAbout24Hours = "InAbout24Hours",
  AtDateAndTime = "AtDateAndTime",
  Indefinitely = "Indefinitely",
}
export type Option = { value: ScheduledActionEnum; label: string };

const DefaultSchedululingOptionLabels: Option[] = [
  { value: ScheduledActionEnum.Immediately, label: "now" },
  { value: ScheduledActionEnum.InAbout24Hours, label: "in ~24 hours" },
  { value: ScheduledActionEnum.AtDateAndTime, label: "at specific time" },
];

type NullableDateSetter = (actionAt: Date | null) => void;
type NonNullableDateSetter = (actionAt: Date) => void;

interface ScheduledActionButtonProps {
  setActionAt: NullableDateSetter | NonNullableDateSetter;
  schedulingOptionLabels?: Option[];
  showTimzeone?: boolean;
}

export const ScheduledActionButton = ({
  setActionAt,
  schedulingOptionLabels = DefaultSchedululingOptionLabels,
  showTimzeone = true,
}: ScheduledActionButtonProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timezone, setTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [date, setDate] = useState<Dayjs>(dayjs().tz(timezone));
  const [time, setTime] = useState<string>("09:00");

  const options = schedulingOptionLabels;
  const [selectedOption, setSelectedOption] = useState<ScheduledActionEnum>(options[0].value);

  const getActionAtMoment = (date: Dayjs, hours: number, minutes: number, timezone: string): Date => {
    const computed = date
      .clone()
      .set("hour", hours)
      .set("minute", minutes)
      .tz(timezone, true);

    return computed.toDate();
  };

  const handleSetDate = (date: Dayjs): void => {
    setDate(date);
    const [hours, minutes] = time.split(":").map(num => parseInt(num));
    setActionAt(getActionAtMoment(date, hours, minutes, timezone));
  };

  const handleSetTime = (timeString: string): void => {
    setTime(timeString);
    const [hours, minutes] = timeString.split(":").map(num => parseInt(num));
    setActionAt(getActionAtMoment(date, hours, minutes, timezone));
  };

  const handleSetTimezone = (timezone: string): void => {
    const [hours, minutes] = time.split(":").map(num => parseInt(num));

    setTimezone(timezone);
    setActionAt(getActionAtMoment(date, hours, minutes, timezone));
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectedOption === ScheduledActionEnum.Immediately) {
      setActionAt(new Date());
    }

    if (selectedOption === ScheduledActionEnum.InAbout24Hours) {
      const hoursToAdd = 22 + Math.random() * 4; // Random time between 22 and 26 hours
      const actionAtDate = dayjs()
        .add(hoursToAdd, "hour")
        .toDate();
      setActionAt(actionAtDate);
    }

    if (selectedOption === ScheduledActionEnum.Indefinitely) {
      (setActionAt as NullableDateSetter)(null);
    }
  }, [setActionAt, selectedOption]);

  const handleMenuItemClick = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: ScheduledActionEnum): void => {
      setSelectedOption(value);
      setAnchorEl(null);
    },
    [setSelectedOption, setAnchorEl]
  );

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={1}>
      <TextButton padding="8px" onClick={handleOpenMenu} endIcon={<ArrowDropDown />}>
        {options.find(option => option.value === selectedOption)?.label || ""}
      </TextButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map(option => (
          <Tooltip
            key={option.value}
            title={
              option.value === ScheduledActionEnum.InAbout24Hours ? "Sometime between 22 and 26 hours from now" : ""
            }
            placement="right"
          >
            <MenuItem
              selected={option.value === selectedOption}
              onClick={(event): void => handleMenuItemClick(event, option.value as ScheduledActionEnum)}
            >
              {option.label}
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
      {selectedOption === ScheduledActionEnum.AtDateAndTime && (
        <>
          <Box
            sx={{
              width: "130px",
              "& .MuiInputBase-root": { height: "46px" },
              "& .MuiFormLabel-root": { top: "-4px" },
              "& .MuiInputLabel-shrink": { top: "0 !important" },
            }}
          >
            {/* @ts-ignore I don't know why this is having a type issue, but it does work */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                componentsProps={{
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
                inputFormat="MM/DD/YY"
                value={date}
                onChange={(newValue): void => {
                  const midnightWithTz = newValue?.startOf("day").tz(timezone, true);
                  if (midnightWithTz) {
                    handleSetDate(midnightWithTz);
                  }
                }}
                renderInput={(params): React.ReactElement => <TextField {...params} label="Date" />}
                disablePast={true}
              />
            </LocalizationProvider>
          </Box>
          <Stack sx={{ width: "90px", "& .MuiInputBase-root": { height: "46px" } }} direction="row" spacing={1}>
            <TimePicker time={time} handleSetTime={handleSetTime} />
            {showTimzeone && (
              <Select
                size="small"
                value={timezone}
                onChange={(e): void => {
                  handleSetTimezone(e.target.value);
                }}
              >
                {timezones.map(tz => (
                  <MenuItem key={tz.value} value={tz.value}>
                    {tz.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ScheduledActionButton;
