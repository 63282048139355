import { Stack } from "@mui/material";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import React, { useMemo } from "react";

import CheckmarkIcon from "assets/icons/circled-green-checkmark.svg";
import HelpSVG from "assets/icons/help-question-gray400.svg";
import HorizontalLineIcon from "assets/icons/horizontal-line.svg";
import MailBlueIcon from "assets/icons/mail-blue.svg";
import MailGreyIcon from "assets/icons/mail-grey.svg";
import RedXIcon from "assets/icons/red-x.svg";
import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { EnableOutreachTooltip } from "components/OutreachActionButtons";
import { useGetJobIsSnoozed } from "services/doverapi/endpoints/job/hooks";
import {
  SlimNextCampaignMessageRequestCampaignStateEnum,
  SlimNextCampaignMessageRequestEmailOutreachStepEnum,
  SlimNextCampaignMessageRequestStateEnum,
  PipelineCandidate,
  CandidateFilterSourcingContextEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { hasReceivedAllOutreach, isContactedStage, isQueuedStage, isRespondedStage } from "utils/isStage";
import { NextActionCell } from "views/candidates/CandidateTable/table/cells/NextActionCell";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { getNextOutreachSendLabel } from "views/candidates/CandidateTable/table/utils/getNextOutreachSendLabel";
import { useGetDisableCandidateActions, useParams } from "views/candidates/hooks";

dayjs.extend(calendar);

interface OutboundNextUpCellProps {
  candidate?: PipelineCandidate;
}

export const OutboundNextUpCell: React.FC<React.PropsWithChildren<OutboundNextUpCellProps>> = ({ candidate }) => {
  const [{ jobId, sourcingContext }] = useParams();

  const jobIsSnoozed = useGetJobIsSnoozed(jobId ?? "");

  const disableActions = useGetDisableCandidateActions();

  const outreachStep: SlimNextCampaignMessageRequestEmailOutreachStepEnum | undefined =
    candidate?.slimNextCampaignMessageRequest?.emailOutreachStep;
  const sendDate: string | undefined = candidate?.slimNextCampaignMessageRequest?.sendAt?.toString(); // This should be an ISO string
  const esrState = candidate?.slimNextCampaignMessageRequest?.state;
  // If candidate has received all three outreach messages, they are in stage 113
  const receivedAllOutreach =
    candidate?.candidatePipelineStage && hasReceivedAllOutreach(candidate?.candidatePipelineStage);

  const candidateHasResponded =
    candidate?.candidatePipelineStage && isRespondedStage(candidate?.candidatePipelineStage);

  const candidateNeedsDecision = useMemo(() => {
    const needsDecision =
      candidate?.candidatePipelineStage &&
      isQueuedStage(candidate?.candidatePipelineStage) &&
      // Show Needs Decision when the ESR is IN_REVIEW *or* Job is Paused and we require Manual Review
      (esrState === SlimNextCampaignMessageRequestStateEnum.InReview || jobIsSnoozed);

    return needsDecision;
  }, [candidate, esrState, jobIsSnoozed]);

  const campaignActive =
    candidate?.slimNextCampaignMessageRequest?.campaignState === SlimNextCampaignMessageRequestCampaignStateEnum.Active;

  const label = useMemo((): string => {
    return receivedAllOutreach ? "All outreach sent" : getNextOutreachSendLabel(dayjs(sendDate), outreachStep);
  }, [receivedAllOutreach, sendDate, outreachStep]);

  /** Not Available Cases:
   *  1. Job paused and candidate contacted but campaign is set to not allow followups
   *  2. Candidate responded
   *  3. Not enough information (No outreach step available and has not recieved all outreach)
   *  4. Not outbound case
   */
  const showNotAvailable = useMemo(() => {
    return (
      (jobIsSnoozed &&
        !candidate?.slimNextCampaignMessageRequest?.allowFollowUpsPostDeactivation &&
        candidate?.candidatePipelineStage &&
        isContactedStage(candidate.candidatePipelineStage)) ||
      candidateHasResponded ||
      (!outreachStep && !receivedAllOutreach) ||
      (sourcingContext.length && !sourcingContext?.includes(CandidateFilterSourcingContextEnum.Outbound))
    );
  }, [
    jobIsSnoozed,
    candidate?.slimNextCampaignMessageRequest?.allowFollowUpsPostDeactivation,
    candidate?.candidatePipelineStage,
    candidateHasResponded,
    outreachStep,
    receivedAllOutreach,
    sourcingContext,
  ]);

  // if the candidate's status has been set, we should display this instead of "Not available"
  if (candidate?.status) {
    return <NextActionCell candidate={candidate} />;
  }

  if (showNotAvailable) {
    return (
      <Cell>
        <BodySmall italic color={colors.grayscale.gray400}>
          Not available
        </BodySmall>
      </Cell>
    );
  }

  const failureReason = candidate?.slimNextCampaignMessageRequest?.debugInfo;
  const emailFailedToSend =
    candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Failed;

  /** Failure Case:
   * if Candidate hasn't responded and there exists a failure reason on the CMR and the
   * email failed to send
   */
  if (outreachStep && failureReason && emailFailedToSend && !candidateHasResponded) {
    return (
      <Cell>
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5}>
            {FailedIcons[outreachStep!].map((icon, idx, icons) => (
              <>
                <img src={icon} alt="mail icon" height="15px" />
                {idx < icons.length - 1 && <img src={HorizontalLineIcon} alt="line" />}
              </>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Chip variant="Warning" label="Failed to send email" />
            <Tooltip title={failureReason}>
              <img src={HelpSVG} alt="Help icon" />
            </Tooltip>
          </Stack>
        </Stack>
      </Cell>
    );
  }
  /**
   * Warning Chip Cases:
   * In order of precendence that would require user's action
   * 1. First check if ManagedOutbound is disabled
   * 2. Then check if Candidate's campaign is active
   * 3. Last, check if Candidate Needs Decision
   */
  if (disableActions) {
    return (
      <Cell>
        <Chip variant="Warning" label={"Enable Sourcing to queue email"} />
      </Cell>
    );
  }
  // If an outreach step exists but the campaign is not active
  if (outreachStep && !campaignActive) {
    return (
      <Tooltip title={<EnableOutreachTooltip />} enterDelay={200}>
        <Cell>
          <Chip variant="Warning" label={"Re-activate Campaign to send email"} />
        </Cell>
      </Tooltip>
    );
  }
  if (candidateNeedsDecision) {
    return (
      <Cell>
        <Chip variant="Warning" label={"Needs Decision"} />
      </Cell>
    );
  }

  return (
    <Cell>
      <Stack spacing={1}>
        <Stack direction="row" spacing={0.5}>
          {MailIcons[receivedAllOutreach ? "Complete" : outreachStep!].map((icon, idx, icons) => (
            <>
              <img src={icon} alt="mail icon" height="15px" />
              {idx < icons.length - 1 && <img src={HorizontalLineIcon} alt="line" />}
            </>
          ))}
        </Stack>
        <Chip variant={"Informational"} label={label} />
      </Stack>
    </Cell>
  );
};

const MailIcons = {
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.InitialOutreach]: [MailBlueIcon, MailGreyIcon, MailGreyIcon],
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup1]: [CheckmarkIcon, MailBlueIcon, MailGreyIcon],
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup2]: [CheckmarkIcon, CheckmarkIcon, MailBlueIcon],
  Complete: [CheckmarkIcon, CheckmarkIcon, CheckmarkIcon],
};

const FailedIcons = {
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.InitialOutreach]: [RedXIcon, RedXIcon, RedXIcon],
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup1]: [CheckmarkIcon, RedXIcon, RedXIcon],
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup2]: [CheckmarkIcon, CheckmarkIcon, RedXIcon],
};
