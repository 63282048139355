import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useDisableAtsControls } from "hooks/useDisableAtsControls";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { CandidateBio } from "services/openapi";
import { colors } from "styles/theme";

interface MoveJobButtonInterface {
  candidateBio: CandidateBio;
}

export const MoveJobButton: FC<React.PropsWithChildren<MoveJobButtonInterface>> = ({ candidateBio }) => {
  const { showMoveJobModal } = useTopLevelModalManagerOptions();

  const disableAtsControls = useDisableAtsControls(candidateBio?.id);

  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(candidateBio.job);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const disabled = invalidCandidateActionPerms || disableAtsControls;

  const clickMoveJob = (): void => {
    if (disabled || !candidateBio?.id) {
      return;
    }

    showMoveJobModal({ candidateId: candidateBio.id });
  };

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      onClick={clickMoveJob}
      sx={{ cursor: disabled ? "default" : "pointer" }}
    >
      <SortIcon />
      <Tooltip title={invalidCandidateActionPermsTooltip ?? ""}>
        <BodySmall color={disabled ? colors.grayscale.gray400 : "inherit"}>Move Job</BodySmall>
      </Tooltip>
    </Stack>
  );
};
