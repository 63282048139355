import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as WarningIcon } from "assets/icons/warning-outlined.svg";
import { ReactComponent as DoverDLogo } from "assets/logos/DoverD-small.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { AuthedUser, BaseJobSetupProUser, DoverUser } from "services/openapi";
import { colors, personaColors } from "styles/theme";

/************************************************************************************
 *                              Utils
 * ******************************************************************************** */

/** Chooses a persona color, staying consistent by ID */
function chooseRandomPersonaColorById(id?: string): string {
  // Map ID to a number between 0 and personaColors.length - 1
  const idNumber = id ? id.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) : 0;
  const index = idNumber % personaColors.length;
  return personaColors[index];
}

function getInitialsForFirstNameAndLastName(firstName?: string, lastName?: string): string {
  const firstInitial = firstName?.[0];
  const lastInitial = lastName?.[0];

  // If both initials are present, return them together
  if (firstInitial && lastInitial) {
    return `${firstInitial}${lastInitial}`;
  }

  // If only one just return the one
  if (firstInitial) {
    return firstInitial;
  }

  if (lastInitial) {
    return lastInitial;
  }

  // If no initials, return a placholder
  return "-";
}

/************************************************************************************
 *                              Base Component
 * ******************************************************************************** */

export interface PersonaCoinProps {
  id?: string;
  pictureUrl?: string;
  firstName?: string;
  lastName?: string;
  color?: string;
  size?: "small" | "medium" | "large";
  useDoverLogo?: boolean;
  tooltip?: string;
}

const coinDimensions = "32px";
const coinDimensionsLarge = "54px";
const coinDimensionsSmall = "24px";
const coinBorderRadius = "50%";

export const PersonaCoin = ({
  id,
  pictureUrl,
  firstName,
  lastName,
  size = "medium",
  color,
  useDoverLogo,
  tooltip,
}: PersonaCoinProps): React.ReactElement => {
  const fullName = `${firstName} ${lastName}`;
  const coinDimensionsBySize =
    size === "small" ? coinDimensionsSmall : size === "large" ? coinDimensionsLarge : coinDimensions;

  if (useDoverLogo) {
    return (
      <Tooltip title={tooltip ?? ""}>
        <Box sx={{ borderRadius: coinBorderRadius }}>
          <DoverDLogo width={coinDimensionsBySize} height={coinDimensionsBySize} />
        </Box>
      </Tooltip>
    );
  }

  if (pictureUrl) {
    return (
      <Tooltip title={tooltip ?? ""}>
        <Box
          width={coinDimensionsBySize}
          height={coinDimensionsBySize}
          sx={{
            borderRadius: coinBorderRadius,
            overflow: "hidden",
            img: {
              verticalAlign: "initial", // This is to override reboot css which is screwing stuff up here
            },
          }}
        >
          <img
            style={{ width: "100%" }}
            src={pictureUrl}
            alt={fullName ?? "Persona Coin"}
            referrerPolicy="no-referrer"
          />
        </Box>
      </Tooltip>
    );
  }

  const personaColor = color ?? chooseRandomPersonaColorById(id);
  const initials = getInitialsForFirstNameAndLastName(firstName, lastName);

  const TypographyComponent = size === "small" ? BodyExtraSmall : BodySmall;

  return (
    <Tooltip title={tooltip ?? ""}>
      <Box
        width={coinDimensionsBySize}
        height={coinDimensionsBySize}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: personaColor,
          borderRadius: coinBorderRadius,
        }}
      >
        <TypographyComponent centered={true} color={colors.white}>
          {initials}
        </TypographyComponent>
      </Box>
    </Tooltip>
  );
};

/************************************************************************************
 *                              Extended Components
 * ******************************************************************************** */

interface PersonaCoinWithWarningBadgeProps extends PersonaCoinProps {
  showWarningBadge: boolean;
}

export const PersonaCoinWithWarningBadge: React.FC<React.PropsWithChildren<
  PersonaCoinWithWarningBadgeProps
>> = props => {
  const iconCoinDimensionsBySize = props.size === "small" ? "12px" : "20px";
  const iconContainerDimensionsBySize = props.size === "small" ? "16px" : "24px";
  const iconOffset = props.size === "small" ? "14px" : "19px";

  return (
    <Box position="relative">
      <PersonaCoin {...props} />
      {props.showWarningBadge && (
        <Box
          width={iconContainerDimensionsBySize}
          height={iconContainerDimensionsBySize}
          sx={{
            borderRadius: coinBorderRadius,
            position: "absolute",
            bottom: iconOffset,
            left: iconOffset,
            backgroundColor: colors.warning.light,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WarningIcon width={iconCoinDimensionsBySize} height={iconCoinDimensionsBySize} />
        </Box>
      )}
    </Box>
  );
};

export interface GenericAvatarUser extends Omit<DoverUser, "fullName"> {
  name: string;
}

export const UserAvatarCoin = ({
  user,
  label,
}: {
  user: GenericAvatarUser | BaseJobSetupProUser | AuthedUser;
  label?: string;
}): React.ReactElement => {
  const coin = (
    <PersonaCoin
      firstName={user.firstName}
      lastName={user.lastName}
      pictureUrl={user.pictureUrl ?? undefined}
      size="small"
      color="gray"
    />
  );

  if (!label) {
    return coin;
  }

  return (
    <Tooltip
      title={
        <>
          <BodySmall weight="600" color="white">
            {user.name}
          </BodySmall>
          <BodySmall color="white">{label}</BodySmall>
        </>
      }
    >
      <div>{coin}</div>
    </Tooltip>
  );
};
