import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Divider, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import AlertIcon from "assets/icons/alert.svg";
import { ReactComponent as EditIconSVG } from "assets/icons/edit.svg";
import HelpIcon from "assets/icons/help-question.svg";
import MailOpenIcon from "assets/icons/mail-open.svg";
import ThumbsUpIcon from "assets/icons/thumbs-up.svg";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { UpdateOrCreateCampaignModal } from "components/dover/Campaigns/UpdateOrCreateCampaignModal";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import Confirm from "components/Modal/ConfirmModal";
import { CampaignsFormSchemaType } from "components/outreach-configuration/form/types";
import { Spacer } from "components/Spacer";
import { useGetCampaignStatsQuery } from "services/doverapi/endpoints/campaign";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import { CampaignStats, CreateCampaignEmailSenderOptionEnum, ListCampaign } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { B1_doNotUse, B3_doNotUse } from "styles/typography";
import { getEmailAliasName } from "utils/getEmailAliasName";
import { ConfirmUnsavedChangesModal } from "views/job/JobSetup/steps/CampaignVariants/modals/ConfirmUnsavedChangesModal";
import { StatusCell } from "views/job/JobSetup/steps/CampaignVariants/StatusCell";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import {
  convertFloatToPercentWithOneDecimal,
  getCampaignStatus,
} from "views/job/JobSetup/steps/CampaignVariants/utils";

interface CampaignCardContainerProps {
  isSelected: boolean;
  status: CampaignStatus;
}

const CampaignCardContainer = styled(Box)<CampaignCardContainerProps>`
  height: auto;
  width: 186px;
  border-radius: 10px;
  border: 2px solid ${({ isSelected }): string => (isSelected ? colors.primary.base : colors.grayscale.gray200)};
  padding: 16px;
  margin-right: 12px;
  background-color: ${({ status }): string => (status === CampaignStatus.Inactive ? "none" : `${backgrounds.white}`)};
  cursor: ${({ isSelected }): string => (isSelected ? "auto" : "pointer")};
  box-shadow: ${({ isSelected }): string => (isSelected ? "0px 4px 10px rgba(0, 0, 0, 0.05)" : "none")};

  #campaign-card__open-in-new-icon {
    display: none;
  }

  &:hover {
    #campaign-card__open-in-new-icon {
      display: block;

      &:hover {
        svg {
          color: ${colors.grayscale.gray600};
        }
      }
    }
  }
`;

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  padding-right: 8px;
  svg {
    margin-bottom: 4px;
    height: 16px;
    width: 16px;
  }
`;

const StyledThumbsUpSVG = styled(StyledReactSVG)`
  svg {
    path {
      stroke: black;
    }
  }
`;

const StyledSVG = styled(StyledReactSVG)<{ color: string }>`
  padding-right: 0px;
  padding-left: 4px;
  svg {
    path {
      color: ${({ color }: { color: string }): string => color};
    }
  }
`;

const CampaignAgeWarning = ({
  earliestFirstContactedOn,
}: {
  earliestFirstContactedOn: Date | null | undefined;
}): React.ReactElement => {
  if (!earliestFirstContactedOn) {
    return <></>;
  }

  const daysActive = differenceInDays(new Date(), earliestFirstContactedOn);

  let message: string;
  if (daysActive <= 14) {
    message = "This email campaign has not been running for long so data may be skewed.";
  } else if (daysActive > 90) {
    message =
      "This email campaign has been running for over 3 months, so comparing to a new campaign is not a true A/B test.";
  } else {
    return <></>;
  }

  return (
    <Tooltip title={message} placement="top">
      <span>
        <StyledSVG src={AlertIcon} color={colors.warning.base} />
      </span>
    </Tooltip>
  );
};

const EmptyStatValue = (): React.ReactElement => (
  <span>
    --
    <StyledSVG src={HelpIcon} color={colors.grayscale.gray400} />
  </span>
);

const CampaignStat = ({
  icon,
  label,
  statName,
  isPercentage,
  campaignStats,
}: {
  icon?: React.ReactNode;
  label: string;
  statName: "interestRate" | "openRate" | "numContacted";
  isPercentage: boolean;
  campaignStats: CampaignStats;
}): React.ReactElement => {
  const stat = campaignStats[statName];

  const statValue = useMemo(
    (): React.ReactElement =>
      _.isNil(stat) ? (
        <Tooltip
          title="No candidates have been contacted through this campaign yet. Check back in a few days!"
          placement="top"
        >
          <EmptyStatValue />
        </Tooltip>
      ) : (
        <>{isPercentage ? convertFloatToPercentWithOneDecimal(stat) : stat}</>
      ),
    [isPercentage, stat]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <BodySmall>
        {icon && icon}
        {label}:
      </BodySmall>
      <BodySmall>{statValue}</BodySmall>
    </Box>
  );
};

const CampaignCard = ({
  campaign,
  setSelectedCampaignId,
  isSelected,
}: {
  campaign: ListCampaign;
  setSelectedCampaignId: (selectedCampaign: string) => void;
  isSelected: boolean;
}): React.ReactElement => {
  const [editNameModal, setEditNameModal] = useState(false);
  const [selectedOtherUser, setSelectedOtherUser] = useState<number | undefined>();

  const { jobId } = useParams<{ jobId: string }>();

  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    (campaign.userDefinedSenderUser as unknown) as string
  );
  const { data: campaignStats, isFetching: isFetchingCampaignStats } = useGetCampaignStatsQuery(
    campaign?.id ? { campaignId: campaign.id! } : skipToken
  );

  const campaignStatus: CampaignStatus = getCampaignStatus(campaign.state!, campaign.setupState!);

  const jobHasServicesEnabled: boolean | undefined = jobFeatures?.hasServicesEnabled;

  const campaignsForm = useFormContext<CampaignsFormSchemaType>();
  const campaignsFormValues = useWatch({ control: campaignsForm.control });
  const showConfirmModal = !isSelected && campaignsFormValues.isDirty;

  const handleSwitchCampaigns = (): void => {
    setSelectedCampaignId(campaign.id!);
    campaignsForm.setValue("isDirty", false);
  };

  return (
    <>
      {editNameModal && (
        <UpdateOrCreateCampaignModal
          isNewCampaign={false}
          initialCampaignName={campaign.name}
          initialEmailSenderOption={(campaign.emailSenderOption as unknown) as CreateCampaignEmailSenderOptionEnum}
          selectedOtherUser={selectedOtherUser}
          setSelectedOtherUser={setSelectedOtherUser}
          campaignId={campaign.id}
          shouldShowModal={ModalKeyType.UpdateOrCreateCampaign}
          handleCloseModal={(): void => {
            setEditNameModal(false);
          }}
          handleOpenProUserModal={(): void => {}}
          modalKey={ModalKeyType.UpdateOrCreateCampaign}
          onlyNameEditable={true}
        />
      )}
      <Confirm
        title={<B1_doNotUse>You may have unpublished changes.</B1_doNotUse>}
        submitText={`Discard Changes`}
        content={<ConfirmUnsavedChangesModal />}
        useProcessing
        submitVariant="critical"
      >
        {/* @ts-ignore this typing is brutal */}
        {(confirm: any): React.ReactNode => (
          <CampaignCardContainer
            isSelected={isSelected}
            status={campaignStatus}
            onClick={
              // Show confirmation modal if clicking on different campaign and there might be edited campaign content
              showConfirmModal
                ? confirm((): void => {
                    handleSwitchCampaigns();
                  })
                : // Switch campaigns without modal if clicking on different campaign and there are no edits to campaign content
                !isSelected
                ? handleSwitchCampaigns
                : // Do nothing if clicking on the already selected campaign
                  (): void => {}
            }
          >
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ marginBottom: "12px" }}>
              <Stack direction="row" spacing={1}>
                <StatusCell status={campaignStatus} stats={campaignStats} />
                <span style={{ marginTop: "2px" }}>
                  <CampaignAgeWarning earliestFirstContactedOn={campaignStats?.earliestFirstContactedOn} />
                </span>
              </Stack>
              {!isSelected && (
                <Box id="campaign-card__open-in-new-icon">
                  <Link
                    to={`/job/${jobId}/outreach?campaign=${campaign?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tooltip title="Open in new tab" placement="top">
                      <OpenInNewIcon
                        sx={{ color: colors.grayscale.gray400, width: "20px", height: "20px" }}
                        onClick={(event: React.MouseEvent): void => {
                          // Stop propagation so that we don't trigger the confirmation
                          // modal that pops up if there are unsaved changes.
                          // This is safe because we're opening the link in a new tab.
                          event.stopPropagation();
                        }}
                      />
                    </Tooltip>
                  </Link>
                </Box>
              )}
            </Box>
            <Button
              variant={ButtonVariant.Ghost}
              onClick={(e): void => {
                setEditNameModal(true);
                e.stopPropagation();
              }}
              removePadding={true}
            >
              <Stack direction="row" spacing={1} textAlign="left">
                <Subtitle1>{campaign.name}</Subtitle1>
                <EditIconSVG />
              </Stack>
            </Button>
            <Spacer height="8px" />
            <B3_doNotUse $color={colors.grayscale.gray600} medium>
              {isFetchingProUser
                ? "Loading..."
                : getEmailAliasName(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias)}
            </B3_doNotUse>
            <Spacer height="12px" />
            {isFetchingCampaignStats || !campaignStats ? (
              <Box height="50px">
                <DoverLoadingSpinner spinnerSize={"40px"} minHeight={"50px"} />
              </Box>
            ) : (
              <>
                <CampaignStat
                  label="Total contacted"
                  statName="numContacted"
                  isPercentage={false}
                  campaignStats={campaignStats}
                />
                {// We only show open/interest rates for jobs with services enabled
                // because platform-only jobs send emails through work gmail accounts,
                // which we don't get open & reply tracking for (today)
                jobHasServicesEnabled && (
                  <>
                    <Spacer height="16px" />
                    <CampaignStat
                      label="Open"
                      statName="openRate"
                      icon={<StyledReactSVG src={MailOpenIcon} />}
                      isPercentage={true}
                      campaignStats={campaignStats}
                    />
                    <Divider sx={{ marginY: "8px" }} />
                    <CampaignStat
                      label="Interested"
                      statName="interestRate"
                      icon={<StyledThumbsUpSVG src={ThumbsUpIcon} />}
                      isPercentage={true}
                      campaignStats={campaignStats}
                    />
                  </>
                )}
              </>
            )}
          </CampaignCardContainer>
        )}
      </Confirm>
    </>
  );
};

export default CampaignCard;
