import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useCloneSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";

const ActionsCell: React.FC<React.PropsWithChildren<{ searchId: string | undefined }>> = ({ searchId }) => {
  const [cloneSearchV3, { isLoading: isCloningSearch }] = useCloneSearchV3Mutation();

  if (!searchId) return null;

  const handleCloneSearch = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    cloneSearchV3(searchId)
      .unwrap()
      .then(result => {
        window.open(`/sourcing/${result}/`, "_blank", "noopener noreferrer");
      });
  };

  return (
    <Button variant={ButtonVariant.Secondary} onClick={handleCloneSearch} loading={isCloningSearch}>
      <BodySmall>Clone search</BodySmall>
    </Button>
  );
};

export default ActionsCell;
