import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Divider, Popover, Stack, TableCell, TableRow } from "@mui/material";
import { get, sum } from "lodash";
import React, { useState } from "react";

import { BodySmall } from "components/library/typography";
import { DashboardJob, JobInterviewStageStats, JobInterviewSubStageStats } from "services/openapi";
import {
  ChildRow,
  ChildTableHeader,
  StyledLink,
  ExpandableTableHeader,
  FlexSpan,
  StyledDrilldown,
} from "views/Reporting/styles";
import { renderPassThroughRate } from "views/Reporting/utils";

const renderStat = (stat: string, stageStats: JobInterviewStageStats): string => {
  const statValue = get(stageStats, stat);
  return statValue === null ? "0" : statValue;
};

const renderLostListItem = (
  name: string,
  value: number,
  stageStats: JobInterviewStageStats,
  job: DashboardJob
): React.ReactElement => {
  const label = name.charAt(0).toUpperCase() + name.slice(1);
  if (value === 0) {
    return (
      <Box display="flex" alignItems="center" p={1} justifyContent="center">
        {label}
        {": "}
        {value}
      </Box>
    );
  }

  const toLink =
    stageStats.hpsId !== "HIRED"
      ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=${label.toUpperCase()}`
      : `/job/${job.id}/candidates?status=${label.toUpperCase()}`;

  return (
    <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
      <Box display="flex" alignItems="center" p={1} justifyContent="center">
        {label}
        {": "}
        {value}
      </Box>
    </StyledLink>
  );
};

export const StageRow: React.FC<React.PropsWithChildren<{
  allStats: JobInterviewStageStats[];
  stageStats: JobInterviewStageStats;
  job: DashboardJob;
  showPercentages?: boolean;
}>> = ({ allStats, stageStats, job, showPercentages }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!stageStats) {
    return <></>;
  }

  const isExpandable = stageStats?.subgroups.length > 0;

  const lostSubComponents = [stageStats?.rejected, stageStats?.withdrew, stageStats?.snoozed];
  let lost: number | null;
  if (lostSubComponents.every(subComponent => subComponent !== undefined && subComponent !== null)) {
    lost = sum(lostSubComponents);
  } else {
    lost = null;
  }

  const renderActiveCell = (value: string | number): React.ReactElement => {
    if (value === null) {
      return <>{"—"}</>;
    }
    if (value == "0" || value === 0) {
      return <>{value}</>;
    }

    const toLink =
      stageStats.hpsId !== "HIRED"
        ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=ACTIVE`
        : `/job/${job.id}/candidates?status=ACTIVE`;
    return (
      <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
        {value}
      </StyledLink>
    );
  };

  return (
    <>
      <TableRow key={stageStats.hpsId}>
        <ExpandableTableHeader onClick={(): void => setIsExpanded(!isExpanded)}>
          <FlexSpan>
            {stageStats.hpsName}
            {isExpandable && (
              <span>
                {!isExpanded ? (
                  <AddIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                ) : (
                  <RemoveIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                )}
              </span>
            )}
          </FlexSpan>
        </ExpandableTableHeader>
        <TableCell>
          {renderStat("total", stageStats)}
          {showPercentages && <BodySmall>{renderPassThroughRate(allStats, stageStats)}</BodySmall>}
        </TableCell>
        <TableCell>{renderActiveCell(renderStat("active", stageStats))}</TableCell>
        <TableCell>
          {lost !== null && lost > 0 ? <LostCell stageStats={stageStats} job={job} totalLost={lost} /> : "0"}
        </TableCell>
      </TableRow>
      {isExpandable && isExpanded && (
        <>
          {stageStats.subgroups.map(
            (subgroup: JobInterviewSubStageStats): React.ReactNode => {
              const { name, label, total, active, rejected, withdrew, snoozed } = subgroup;
              const totalLost = rejected + withdrew + snoozed;
              return (
                <ChildRow key={name}>
                  <ChildTableHeader scope="row">{label}</ChildTableHeader>
                  <TableCell>{total}</TableCell>
                  <TableCell>{renderActiveCell(active)}</TableCell>
                  <TableCell>
                    {totalLost !== null && totalLost > 0 ? (
                      <LostCell
                        stageStats={stageStats}
                        job={job}
                        totalLost={totalLost}
                        numRejected={rejected}
                        numWithdrew={withdrew}
                        numSnoozed={snoozed}
                      />
                    ) : (
                      "0"
                    )}
                  </TableCell>
                </ChildRow>
              );
            }
          )}
        </>
      )}
    </>
  );
};

const LostCell: React.FC<{
  stageStats: JobInterviewStageStats;
  job: DashboardJob;
  totalLost: number;
  numRejected?: number;
  numWithdrew?: number;
  numSnoozed?: number;
}> = ({ stageStats, job, totalLost, numRejected, numWithdrew, numSnoozed }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const rejected = numRejected !== undefined ? numRejected : parseInt(renderStat("rejected", stageStats));
  const withdrew = numWithdrew !== undefined ? numWithdrew : parseInt(renderStat("withdrew", stageStats));
  const snoozed = numSnoozed !== undefined ? numSnoozed : parseInt(renderStat("snoozed", stageStats));

  // need to put "id" in front because the id can't start with a number
  const triggerId = "id" + stageStats.hpsId.replace(/-/g, "");

  return (
    <span>
      <StyledDrilldown onClick={handlePopoverOpen} href="#" id={triggerId}>
        <span>{totalLost}</span>
        <ArrowRightIcon style={{ color: "#3F51B5", marginRight: "-8px" }} />
      </StyledDrilldown>
      <Popover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Stack divider={<Divider flexItem />}>
          {renderLostListItem("rejected", rejected, stageStats, job)}
          {renderLostListItem("withdrew", withdrew, stageStats, job)}
          {renderLostListItem("snoozed", snoozed, stageStats, job)}
        </Stack>
      </Popover>
    </span>
  );
};
