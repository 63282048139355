import { ReactComponent as PaperIcon } from "assets/icons/call-notes.svg";
import { ReactComponent as CareerbuilderIcon } from "assets/icons/careerbuilder.svg";
import { ReactComponent as CrunchbaseIcon } from "assets/icons/crunchbase.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as FlickrIcon } from "assets/icons/flickr.svg";
import { ReactComponent as GithubIcon } from "assets/icons/github-filled.svg";
import { ReactComponent as GravatarIcon } from "assets/icons/gravatar.svg";
import { ReactComponent as HealthgradesIcon } from "assets/icons/healthgrades.svg";
import { ReactComponent as IndeedIcon } from "assets/icons/indeed.svg";
import { ReactComponent as KaggleIcon } from "assets/icons/kaggle.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linked-in.svg";
import { ReactComponent as PinterestIcon } from "assets/icons/pinterest.svg";
import { ReactComponent as PortfolioIcon } from "assets/icons/portfolio.svg";
import { ReactComponent as RateMDIcon } from "assets/icons/rate-md.svg";
import { ReactComponent as StackOverflowIcon } from "assets/icons/stack-overflow.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/website.svg";
import { ReactComponent as WellfoundIcon } from "assets/icons/wellfound.svg";
import { SocialLinkType } from "services/openapi";

export const candidateBioSocialLinkToDisplayMapV2: Record<
  SocialLinkType,
  {
    name: string;
    icon: React.FunctionComponent<
      React.PropsWithChildren<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    >;
    width?: number;
  }
> = {
  [SocialLinkType.ABOUTME]: { name: "About.me", icon: WebsiteIcon }, // TODO: get icon
  [SocialLinkType.ANGELLIST]: { name: "Wellfound", icon: WellfoundIcon },
  [SocialLinkType.CAREERBUILDER]: { name: "CareerBuilder", icon: CareerbuilderIcon },
  [SocialLinkType.CRUNCHBASE]: { name: "Crunchbase", icon: CrunchbaseIcon },
  [SocialLinkType.FACEBOOK]: { name: "Facebook", icon: FacebookIcon },
  [SocialLinkType.FLICKR]: { name: "Flickr", icon: FlickrIcon },
  [SocialLinkType.GITHUB]: { name: "Github", icon: GithubIcon },
  [SocialLinkType.GRAVATAR]: { name: "Gravatar", icon: GravatarIcon },
  [SocialLinkType.HEALTHGRADES]: { name: "Healthgrades", icon: HealthgradesIcon },
  [SocialLinkType.INDEED]: { name: "Indeed", icon: IndeedIcon },
  [SocialLinkType.INSTAGRAM]: { name: "Instagram", icon: WebsiteIcon }, // TODO: get icon
  [SocialLinkType.KAGGLE]: { name: "Kaggle", icon: KaggleIcon },
  [SocialLinkType.LINKEDIN]: { name: "LinkedIn", icon: LinkedInIcon },
  [SocialLinkType.OTHER]: { name: "Other", icon: WebsiteIcon },
  [SocialLinkType.PINTEREST]: { name: "Pinterest", icon: PinterestIcon },
  [SocialLinkType.PORTFOLIO]: { name: "Portfolio", icon: PortfolioIcon },
  [SocialLinkType.RATEMD]: { name: "RateMD", icon: RateMDIcon },
  [SocialLinkType.RESUME]: { name: "Resume", icon: PaperIcon },
  [SocialLinkType.STACKOVERFLOW]: { name: "Stack Overflow", icon: StackOverflowIcon },
  [SocialLinkType.TWITTER]: { name: "Twitter", icon: TwitterIcon },
  [SocialLinkType.WEBSITE]: { name: "Website", icon: WebsiteIcon },
  [SocialLinkType.NONE]: { name: "Other", icon: WebsiteIcon },
};
