import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card/Card";
import { Heading } from "components/library/typography";

interface AIActionsBannerProps {
  onGenerateQuestions: (e: React.MouseEvent) => void;
  onAutofillNotes: (e: React.MouseEvent) => void;
  existingRoughNotesAvailable: boolean;
}

const AIActionsBanner: React.FC<React.PropsWithChildren<AIActionsBannerProps>> = ({
  onGenerateQuestions,
  onAutofillNotes,
  existingRoughNotesAvailable,
}) => {
  return (
    <Card>
      <Stack direction="row" alignItems="center" spacing={2}>
        <WandIcon height="24px" width="24px" />
        <Heading>Dover AI</Heading>
        <Stack direction="row" spacing={2} flexGrow={1} justifyContent="flex-end">
          <Button
            variant={ButtonVariant.Secondary}
            onClick={onGenerateQuestions}
            tooltip="Get suggested questions based on the candidate's specific background and experience."
          >
            Generate specific questions for candidate
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={onAutofillNotes}
            tooltip="Paste in your unstructured notes and we'll populate each interview question below."
          >
            {existingRoughNotesAvailable ? "View rough notes" : "Autofill from rough notes"}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AIActionsBanner;
