import { skipToken } from "@reduxjs/toolkit/query";
import { atom, useAtomValue, useSetAtom } from "jotai";

import {
  ListPipelineCandidatesArgs,
  useLazyListPipelineCandidatesQuery,
} from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { PipelineCandidate } from "services/openapi";
import { BOARD_LIST_CANDIDATES_LIMIT } from "views/candidates/CandidateTable/board/hooks";

// Atoms for our hooks
// We need to keep track of the candidates on each page of the pipeline so we can navigate between them
// We also need to keep track of the position of each candidate so we can navigate between them
const stageTolistArgsAtom = atom<Map<string, ListPipelineCandidatesArgs>>(new Map());
export const setListArgsAtom = atom(
  null,
  (get, set, { stageId, args }: { stageId?: string; args: ListPipelineCandidatesArgs | typeof skipToken }) => {
    if (!stageId || args === skipToken) {
      return;
    }
    const map = get(stageTolistArgsAtom);
    map.set(stageId, args);
  }
);

const stageToCandidatesAtom = atom<Map<string, Map<number, PipelineCandidate[]>>>(new Map());
const candidatePositionsAtom = atom<Map<string, { page: number; stageId: string }>>(new Map());

// determine if we already fetched a page
export const hasPageAtom = atom(get => (stageId: string, page: number): boolean => {
  const map = get(stageToCandidatesAtom);
  const stageMap = map.get(stageId);
  return stageMap?.has(page) ?? false;
});

// Setter atoms so when we call the list endpoints we can update the atoms
export const addPageToStageAtom = atom(
  null,
  (get, set, { stageId, page, candidates }: { stageId: string; page: number; candidates: PipelineCandidate[] }) => {
    const map = get(stageToCandidatesAtom);

    const stageMap = map.get(stageId) || new Map();
    if (!map.has(stageId)) {
      map.set(stageId, stageMap);
    }

    // we can always override here, so that when we switch from kanban to list to review, we can update the list
    // so that it is relevant to the view the user is looking at without needing to reset state.
    stageMap.set(page, candidates);
    const candidatesMap = get(candidatePositionsAtom);
    for (const c of candidates) {
      candidatesMap.set(c.id!, { page, stageId });
    }
  }
);

// Getter atoms for our hooks

const useGetCandidatesForPage = atom(get => (stageId: string, page: number): PipelineCandidate[] | null | undefined => {
  const map = get(stageToCandidatesAtom);
  const stageMap = map.get(stageId);
  return stageMap?.get(page);
});

const useGetCandidatePositionAtom = atom(get => (candidateId: string):
  | { page: number; stageId: string }
  | undefined => {
  return get(candidatePositionsAtom).get(candidateId);
});

const getListArgsAtom = atom(get => (stageId: string): ListPipelineCandidatesArgs | undefined => {
  const map = get(stageTolistArgsAtom);
  return map.get(stageId);
});

export const useFetchNextPage = (stageId?: string, page?: number): (() => void) | undefined => {
  const addPageToStage = useSetAtom(addPageToStageAtom);
  const hasPage = useAtomValue(hasPageAtom);

  const getListArgs = useAtomValue(getListArgsAtom);
  const [listCandidates] = useLazyListPipelineCandidatesQuery();

  if (page === undefined || !stageId) {
    return;
  }

  const nextPage = page + 1;
  if (hasPage(stageId, nextPage)) {
    console.log("already fetched page", nextPage);
    return;
  }

  return (): void => {
    const listArgs = getListArgs(stageId);
    console.log("fetching next page", nextPage, listArgs);
    if (listArgs) {
      const args: ListPipelineCandidatesArgs = {
        ...listArgs,
        args: {
          ...listArgs.args,
          offset: nextPage * BOARD_LIST_CANDIDATES_LIMIT,
        },
      };
      listCandidates(args).then(r => {
        addPageToStage({ stageId, page: nextPage, candidates: r.data?.results || [] });
      });
    }
  };
};

type NavigationCandidate = {
  id: string;
  stageId: string;
  page: number;
};

export const useGetNextCandidateId = (candidateId: string): NavigationCandidate | undefined => {
  const getCandidatePosition = useAtomValue(useGetCandidatePositionAtom);
  const candidatePosition = getCandidatePosition(candidateId);

  const getPage = useAtomValue(useGetCandidatesForPage);
  if (!candidatePosition) {
    return;
  }

  const { page, stageId } = candidatePosition;
  const candidates = getPage(stageId, page);

  if (!candidates) {
    return;
  }

  const index = candidates.findIndex(c => c.id === candidateId);
  if (index === -1) {
    return;
  }

  if (index === candidates.length - 1) {
    const nextPageCandidates = getPage(stageId, page + 1);
    if (!nextPageCandidates) {
      return;
    }
    return { id: nextPageCandidates[0].id!, page: page + 1, stageId };
  }

  return { id: candidates[index + 1].id!, page, stageId };
};

export const useGetPreviousCandidateId = (candidateId: string): NavigationCandidate | undefined => {
  const getCandidatePosition = useAtomValue(useGetCandidatePositionAtom);
  const candidatePosition = getCandidatePosition(candidateId);

  const getPage = useAtomValue(useGetCandidatesForPage);
  if (!candidatePosition) {
    return;
  }

  const { page, stageId } = candidatePosition;
  const candidates = getPage(stageId, page);

  if (!candidates) {
    return;
  }

  const index = candidates.findIndex(c => c.id === candidateId);
  if (index === -1) {
    return;
  }

  if (index === 0) {
    const previousPageCandidates = getPage(stageId, page - 1);
    if (!previousPageCandidates) {
      return;
    }
    return {
      id: previousPageCandidates[previousPageCandidates.length - 1].id!,
      page: page - 1,
      stageId: stageId,
    };
  }

  return { id: candidates[index - 1].id!, page: page, stageId: stageId };
};
