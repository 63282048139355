import { BulkRejectModal } from "components/dover/top-level-modal-manager/modals/BulkRejectModal";
import NeedsResponseModal from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/NeedsResponseModal";
import { RejectModal } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/RejectModal";
import { ReplyModal } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/ReplyModal";
import { ReschedulingModal } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/RescheduleModal";
import SchedulingModal from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/SchedulingModal";
import { SnoozeModal } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/modals/SnoozeModal";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { PromptForEmailAuthModal } from "components/dover/top-level-modal-manager/modals/PromptForEmailAuthModal";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { colors } from "styles/theme";

export const INCLUDE_EMAIL_BY_DEFAULT_STORAGE_NAME = "includeEmailByDefault";
export const CANDIDATE_ACTION_MODAL_TITLE_WEIGHT = "600";
export const CANDIDATE_ACTION_MODAL_MAX_WIDTH = "md";
export const CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES = {
  zIndex: 1200,
  ".MuiDialog-paper ": { maxHeight: "calc(100% - 96px)" },
};
export const CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES = {
  padding: "16px 24px",
  backgroundColor: colors.grayscale.gray100,
};
export const CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES = { justifyContent: "space-between" };

/** Map of modal type to modal that takes CandidateActionModalProps */
export const CANDIDATE_ACTION_MODAL_TYPE_TO_MODAL_COMPONENT: Record<
  CandidateActionModalType,
  React.FC<React.PropsWithChildren<CandidateActionModalProps>>
> = {
  [CandidateActionModalType.NeedsResponse]: NeedsResponseModal,
  [CandidateActionModalType.Schedule]: SchedulingModal,
  [CandidateActionModalType.Reschedule]: ReschedulingModal,
  [CandidateActionModalType.Reply]: ReplyModal,
  [CandidateActionModalType.Reject]: RejectModal,
  [CandidateActionModalType.BulkReject]: BulkRejectModal,
  [CandidateActionModalType.PromptForEmailAuth]: PromptForEmailAuthModal,
  [CandidateActionModalType.Snooze]: SnoozeModal,
};
