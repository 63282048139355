import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const spinnerColor = "#312f4a";

interface SizeableSpinnerProps {
  fontSize: string;
  height: string;
  width: string;
}

export const SizeableSpinner = styled(CircularProgress)<SizeableSpinnerProps>`
  font-size: ${({ fontSize }): string => fontSize};
  height: ${({ height }): string => height};
  width: ${({ height }): string => height};
`;

export const LoadingRelative = styled(CircularProgress)`
  width: 3rem;
  height: 3rem;
  color: ${spinnerColor};
`;

const SpinLoading = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3rem;
  height: 3rem;
  color: ${spinnerColor};
`;

export default SpinLoading;
