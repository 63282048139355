import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React from "react";

import { Body } from "components/library/typography";
import { useGetInDepthExplanationQuery } from "services/doverapi/endpoints/applicationReview";
import { saapParamsAtom } from "views/candidates/ApplicationReview/atoms/saapParams";
import { searchIdAtom } from "views/candidates/ApplicationReview/atoms/searchId";
import CandidateFiltersExpanded from "views/sourcing/Search/components/CandidateCard/CandidateFiltersExpanded";

interface ExplanationTabProps {
  applicationId?: string;
}
export const ExplanationTab: React.FC<React.PropsWithChildren<ExplanationTabProps>> = ({ applicationId }) => {
  const searchId = useAtomValue(searchIdAtom);
  const params = useAtomValue(saapParamsAtom);

  const { data: inDepthExplanation, isLoading } = useGetInDepthExplanationQuery(
    applicationId && searchId
      ? {
          inboundAppId: applicationId,
          searchId: searchId,
          params: params,
        }
      : skipToken
  );

  if (isLoading) {
    return (
      <Stack height="300px" width="100%" alignItems="center" justifyContent="center">
        <Progress text="Loading Explanation..." size="large" />
      </Stack>
    );
  }

  if (!inDepthExplanation) {
    return (
      <Stack height="300px" width="100%" alignItems="center" justifyContent="center">
        <Body>Failed to load explanation</Body>
      </Stack>
    );
  }

  return (
    <Box width="100%" padding="16px">
      <CandidateFiltersExpanded
        expanded={true}
        niceToHaveExplanation={inDepthExplanation.niceToHaveExplanation}
        mustHaveExplanation={inDepthExplanation.mustHaveExplanation}
      />
    </Box>
  );
};
