/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks*/

import { MenuItem, Select, Stack } from "@mui/material";
import { Mutator } from "final-form";
import React from "react";
import { Form } from "react-final-form";

import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { DoverInsights } from "components/dover/CreateJob/components/DoverInsights";
import ProUserSelector from "components/dover/inputs/pro-users/ProUserDropdownSelector";
import { EyeIcon, EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import { TextField } from "components/library/TextField";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { FormData } from "services/doverapi/endpoints/create-job/types";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

const setValue: Mutator<any> = ([name, newValue], state, { changeValue }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  changeValue(state, name, _ => newValue);
};

interface FormErrors {
  hiringManagerId?: string;
  role?: string;
  title?: string;
}

const validate = (values: FormData): FormErrors => {
  let errors = {};
  if (!values.hiringManagerId) {
    errors = { ...errors, hiringManagertype: "This field is required" };
  }
  if (!values.title) {
    errors = { ...errors, title: "This field is required" };
  }

  return errors;
};

const CreateJobForm: React.FC<React.PropsWithChildren<Props>> = ({
  showHiringManagerAndRecruiterFields,
  onSubmit,
}: Props): React.ReactElement => {
  // form values
  const { data: userInfo } = useGetAuthedUserInfoQuery();

  const [title, setTitle] = React.useState<string | undefined>();
  const [hiringManagerId, setHiringManagerId] = React.useState<number | undefined>(userInfo?.id);
  const [recruiterId, setRecruiterId] = React.useState<number | undefined>(userInfo?.id);
  const [isPrivate, setIsPrivate] = React.useState<boolean>(false);
  const [hiringTimelineDays, setHiringPipelineDays] = React.useState<number | undefined>();

  const hoursPerWeek = hiringTimelineDays === 30 ? 24 : hiringTimelineDays === 60 ? 12 : 8;
  const timelineText = hiringTimelineDays === 30 ? "ASAP" : hiringTimelineDays === 60 ? "1-2 months" : "3+ months";

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        title,
        hiringManagerId,
        recruiterId,
        isPrivate,
        hiringTimelineDays,
      }}
      validate={(v): FormErrors => validate(v)}
      mutators={{ setValue }}
      render={({ handleSubmit, submitting, hasValidationErrors, errors }): React.ReactNode => {
        const disabled = hasValidationErrors || !hiringTimelineDays;
        const nextButtonDisabled = submitting || disabled;

        return (
          <form onSubmit={handleSubmit} noValidate={true}>
            <Stack spacing={2.5} sx={{ pb: 2, marginTop: "0px !important" }}>
              <Stack spacing={1}>
                <Subtitle2>Job title</Subtitle2>
                <TextField
                  text={title ?? ""}
                  onTextUpdated={setTitle}
                  error={errors?.title && title !== undefined}
                  errorMessage="Required"
                  autoFocus
                />
              </Stack>
              <Stack spacing={1} width="40%">
                <Subtitle2>Hiring Manager</Subtitle2>
                <ProUserSelector
                  value={hiringManagerId ?? null}
                  onChange={(value): void => setHiringManagerId(value as number)}
                  required={showHiringManagerAndRecruiterFields}
                  size="small"
                  fullWidth={false}
                />
              </Stack>

              <Stack spacing={1} width="40%">
                <Subtitle2>Recruiter</Subtitle2>
                <ProUserSelector
                  value={recruiterId ?? null}
                  onChange={(value): void => setRecruiterId(value as number)}
                  size="small"
                  fullWidth={false}
                />
              </Stack>

              <Stack spacing={1} direction="row" alignItems={"center"}>
                <Subtitle2>Job Visibility</Subtitle2>
                <ExternalLink
                  display="inline"
                  href={"https://help.dover.com/en/articles/8677668-user-permissions"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <HelpIcon color={colors.link} className="svg-color" />
                  </Stack>
                </ExternalLink>
              </Stack>
              <Stack spacing={1} mt={"8px !important"} width="40%">
                <Select
                  size="small"
                  value={isPrivate}
                  onChange={(e): void => {
                    setIsPrivate(e.target.value === "true");
                  }}
                  renderValue={(value): React.ReactNode => {
                    return (
                      <Stack direction="row" spacing={1} alignItems="center" pt="1px !important">
                        {value ? <EyeCrossedOutIcon /> : <EyeIcon />}
                        <BodySmall>{value ? "Private" : "Public"}</BodySmall>
                      </Stack>
                    );
                  }}
                >
                  <MenuItem key={"public"} value={"false"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <EyeIcon />
                      <BodySmall>Public</BodySmall>
                    </Stack>
                  </MenuItem>
                  <MenuItem key={"private"} value={"true"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <EyeCrossedOutIcon />
                      <BodySmall>Private</BodySmall>
                    </Stack>
                  </MenuItem>
                </Select>
              </Stack>

              <Stack spacing={1}>
                <Subtitle2>When do you need to make your hire by?</Subtitle2>
                <Stack spacing={1} direction="row">
                  <ButtonRadio
                    active={hiringTimelineDays === 30}
                    onClick={(): void => setHiringPipelineDays(30)}
                    label="ASAP (1 month)"
                    variant="secondary"
                  />
                  <ButtonRadio
                    active={hiringTimelineDays === 60}
                    onClick={(): void => setHiringPipelineDays(60)}
                    label="1-2 Months"
                    variant="secondary"
                  />
                  <ButtonRadio
                    active={hiringTimelineDays === 90}
                    onClick={(): void => setHiringPipelineDays(90)}
                    label="3+ Months"
                    variant="secondary"
                  />
                </Stack>
              </Stack>
              {!!hiringTimelineDays && <DoverInsights timelineText={timelineText} hoursPerWeek={hoursPerWeek} />}

              <Stack direction="row" justifyContent="flex-end" alignItems="center">
                <Stack direction="row" spacing={1} justifyContent="end">
                  <Button
                    onClick={(): void => {
                      handleSubmit();
                    }}
                    variant={ButtonVariant.Primary}
                    disabled={nextButtonDisabled}
                    tooltip={nextButtonDisabled ? "Please fill out all fields" : undefined}
                  >
                    <BodySmall color={nextButtonDisabled ? colors.grayscale.gray600 : colors.white}>
                      Create Job
                    </BodySmall>
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        );
      }}
    />
  );
};

interface Props {
  creating: boolean;
  onSubmit: (values: FormData) => Promise<void> | void;
  initialTitle?: string;
  showHiringManagerAndRecruiterFields: boolean;
  showPersonaField?: boolean;
  iconOverride?: any;
  justifyHeaderCenter?: boolean;
  hideBackButton?: boolean;
}

export default CreateJobForm;
