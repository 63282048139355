import { DndContext, DragEndEvent, DragOverlay, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useState } from "react";

import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { Body } from "components/library/typography";
import { useCandidateCountMap, useCandidateCountMapArgs } from "hooks/useCandidateCountMap";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import {
  candidateBeingUpdatedAtom,
  getCandidateFromMapAtom,
  dndCandidateBioResponseAtom,
} from "views/candidates/CandidateTable/board/atoms";
import { CandidateCard as CandidateCard2 } from "views/candidates/CandidateTable/board/components/Card";
import Column from "views/candidates/CandidateTable/board/components/Column";
import { useCountFilters, useKanbanListArgsGetter } from "views/candidates/CandidateTable/board/hooks";
import { KanbanWrapper } from "views/candidates/CandidateTable/board/styles";
import { useBoardStagesV2 } from "views/candidates/hooks";
import { PipelineExpandOption } from "views/candidates/types";

/**
 * The Candidates board is a kanban style board that displays all candidates in a job across all stages.
 * Each stage will be rendered as a column with the candidates in that stage displayed as cards.
 *
 * Each stage is implemented as a Virtualized List with "infinite" scrolling.
 *
 * The virtualization strategy is to use each "Page" as an item in the virtualized list and each <Page /> component will
 * make it's paginated call to rtkq to fetch the candidate data it needs.
 * The natural mechanisms of virtualization will mount and unmount these <Page /> components as the user scrolls and they become
 * visble or invisible.
 *
 * This behaviour of mounting and unmounting is also what drives our infinite scrolling data fetching as well, since
 * each page component has a rtkq hook call that fires off when it is mounted (and only when it is mounted) it will fetch the data
 * it needs and when it is unmounted its cache entry will be flushed after 60 seconds, preventing any memory leaks.
 *
 * Each page component will also handle it's loading state, so you will see incremental results come in and loading skeletons as you scroll.
 * We use overscan in the virtualizer to "prefetch" some of the upcoming pages that aren't quite in view yet.
 */
const CandidatesBoardV2 = (): React.ReactElement => {
  const [updateCandidateBio] = useUpdateCandidateBioMutation();
  const getListArgs = useKanbanListArgsGetter();

  const jobId = useJobIdFromUrl();

  const [activeId, setActiveId] = useState<string | undefined>();

  const setCandidateBioAtom = useSetAtom(dndCandidateBioResponseAtom);
  const setUpdatingCandidateId = useSetAtom(candidateBeingUpdatedAtom);

  const getCandidate = useAtomValue(getCandidateFromMapAtom);
  const candidate = getCandidate(activeId);

  // We need to add this activation constraint to our mouse sensor so that the
  // candidate cards on click events can still trigger
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 2, // Only start drag after moving mouse 2px
    },
  });
  const sensors = useSensors(mouseSensor);

  // We will iterate through each stage and make a column for each
  const stages = useBoardStagesV2() ?? [];

  // We need to get all the counts for each stage in advance because
  // the virtualizers need to know the total items they will managing right from the get go
  const countFilters = useCountFilters(stages);
  const { counts, isFetching, isError } = useCandidateCountMap(countFilters);

  // We also need to grab the args for optimistic updates
  const candidateCountsArgs = useCandidateCountMapArgs(countFilters);

  if (isFetching) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (!counts || isError) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error while fetching candidate data.</Body>
      </Stack>
    );
  }

  const handleDragStart = (event: any): void => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const candidateId = event.active.id as string | undefined;
    const sourceStageId = candidate?.candidatePipelineStage?.id;
    const destinationStageId = event.over?.id as string | undefined;

    setActiveId(undefined);

    if (!sourceStageId || !destinationStageId || !jobId || !candidateId || !candidate) {
      console.error(
        "Missing source, destination, jobId, candidateId, or candidate",
        sourceStageId,
        destinationStageId,
        jobId,
        candidateId,
        candidate
      );
      return;
    }

    // Don't allow reording in same column (We don't store this anywhere)
    if (destinationStageId === sourceStageId) {
      return;
    }

    const destinationStage = stages.find(s => s.id === destinationStageId);

    if (!destinationStage) {
      return;
    }

    const page = 0; // We always just drop to top of list because we sort by last modified
    const prevListArgs = getListArgs({
      stageId: sourceStageId,
      expand:
        candidate?.candidatePipelineStage?.name === "New Lead"
          ? PipelineExpandOption.CampaignMessageRequest
          : undefined,
      isApplicationStage: candidate?.candidatePipelineStage?.name === "Applied",
      page,
    });

    const nextListArgs = getListArgs({
      stageId: destinationStageId,
      expand: destinationStage.name === "New Lead" ? PipelineExpandOption.CampaignMessageRequest : undefined,
      isApplicationStage: destinationStage?.name === "Applied",
      page,
    });

    const candidatePipelineStage = {
      id: destinationStage.id,
      name: destinationStage.name,
      stageType: destinationStage.stageType,
      milestone: destinationStage.milestone,
      orderIndex: destinationStage.orderIndex ?? undefined,
      substage: 0,
    };

    setUpdatingCandidateId(candidateId);
    updateCandidateBio({
      id: candidateId,
      jobId: jobId,
      data: { currentPipelineStage: destinationStageId, currentPipelineSubstage: 0 },
      hideToast: true,
      kanbanStageUpdate:
        candidateCountsArgs === skipToken || prevListArgs === skipToken || nextListArgs === skipToken
          ? undefined
          : {
              prevListArgs,
              nextListArgs,
              candidate: {
                ...candidate,
                candidatePipelineStage,
              },
              candidateCountsArgs,
              prevStageId: sourceStageId,
              nextStageId: destinationStageId,
            },
      candidatePipelineStage,
      // This prevents the candidate list from being invalidated which can cause flashes
      // when the user is making many moves quickly and early api routes are returning and
      // overwriting the optimistic update cache with stale data. Also prevents isFetching from turning true again.
      skipTagInvalidation: true,
    })
      .unwrap()
      .then(bio => {
        setCandidateBioAtom(bio);
      })
      .finally(() => {
        setUpdatingCandidateId(undefined);
      });
  };

  return (
    <KanbanWrapper>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {stages.map(s => (
          <Column stage={s} count={counts.get(s.id) ?? 0} />
        ))}
        <DragOverlay>{activeId && candidate ? <CandidateCard2 candidate={candidate} /> : null}</DragOverlay>
      </DndContext>
    </KanbanWrapper>
  );
};

export default CandidatesBoardV2;
