import { Stack } from "@mui/material";
import React, { FC } from "react";

import { PersonaCoin } from "components/PersonaCoin";

// We have a lot of different types that the backend returns for users
// So these are the fields we need on this component
// Any user type that supersets this is acceptable
interface UserCoinListUser {
  firstName: string;
  lastName: string;
  pictureUrl?: string;
  tooltip?: string;
}

interface UserCoinListProps {
  users: Array<UserCoinListUser>;
  overflowLimit?: number; // The number of users to show before the overflow icon
}

export const UserCoinList: FC<React.PropsWithChildren<UserCoinListProps>> = ({ users, overflowLimit }) => {
  // Split the array into visible and overflowed users
  const visibleUsers = users.slice(0, overflowLimit);
  const overflowedUsers = users.slice(overflowLimit);

  // Add on the overflow coin if there is any overflow
  if (overflowedUsers.length) {
    const tooltip = overflowedUsers.map(u => `${u.firstName} ${u.lastName}`).join(", ");

    visibleUsers.push({ firstName: "+", lastName: overflowedUsers.length.toString(), tooltip });
  }

  return (
    <Stack direction="row" spacing={0.5}>
      {visibleUsers.map(u => (
        <PersonaCoin
          firstName={u?.firstName}
          lastName={u?.lastName}
          pictureUrl={u?.pictureUrl}
          size="small"
          tooltip={u?.tooltip}
        />
      ))}
    </Stack>
  );
};
