import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

export const StyledHeader = styled.h5`
  font-size: 15px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const TableHeader = styled.th`
  font-weight: bold;
  font-size: 18px;
  width: 20%;
`;

export const BenchmarkTableHeader = styled.th`
  font-weight: bold;
  font-size: 18px;
  width: 20%;
  background-color: #e3e3e3;
`;

export const ExpandableTableHeader = styled.th`
  cursor: pointer;
  border-bottom: 1px solid #dee2e6 !important;
`;

export const FlexSpan = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const ChildRow = styled.tr`
  opacity: 0.75;
  background-color: rgba(242, 242, 242, 0.65) !important;
`;

export const ChildTableHeader = styled.th`
  padding-left: 36px !important;
`;

export const Title = styled.h2`
  text-align: left;
`;

export const ReportBlock = styled.div`
  margin-bottom: 35px;
`;

export const BenchmarkCell = styled.td`
  font-weight: bold;
  background-color: #f4f4f4 !important;
`;

export const StyledLink = styled(Link)`
  color: ${colors.link};
  font-weight: 600;
`;

export const StyledDrilldown = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 7px;
  background-color: #eceef8;
  border-radius: 3px;
  width: 50px;
  color: #3f51b5;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
`;

export const BenchmarkSectionText = styled(B2_doNotUse)`
  display: flex;
  margin-top: 4px;
  margin-right: 16px;
`;

export const BenchmarkSectionSpinnerBox = styled(Box)`
  margin-right: 16px;
`;

export const StyledSVG = styled(ReactSVG)`
  display: flex;
  position: relative;
  width: ${props => props.$width || "12px"};

  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: ${props => props.$color || `${colors.grayscale.gray600}`};
    }
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .MuiSelect-select {
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;
