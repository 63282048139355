import { Button } from "@doverhq/dover-ui";
import { Menu, Stack, useMediaQuery, useTheme } from "@mui/material";
import { PressEvent } from "@react-types/shared/src/";
import React from "react";

import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";
import { screenSizesNumbers } from "styles/theme";
import { QuickFilter } from "views/candidates/CandidateTable/filters/QuickFilterSection/QuickFilter";
import { quickFilterTitles } from "views/candidates/constants";
import { useCounts } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";

export const QuickFilterSection = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const { counts } = useCounts();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.laptop));

  const handleButtonClick = (event: PressEvent): void => {
    setAnchorEl(event.target);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const qfList = [QuickFilterEnum.Interviewing, QuickFilterEnum.Scheduled, QuickFilterEnum.Snoozed];

  if (isTablet) {
    return (
      <Stack direction="row" spacing={1}>
        <Button variant="ghost" icon={{ Icon: LightningIcon }} onPress={handleButtonClick}>
          Quick Filters
        </Button>
        <Menu open={Boolean(anchorEl) && isTablet} onClose={handleClose} onClick={handleClose} anchorEl={anchorEl}>
          {Object.values(QuickFilterEnum)
            .filter(filter => qfList.includes(filter))
            .map(filter => (
              <QuickFilter
                key={filter}
                label={quickFilterTitles[filter]}
                value={filter}
                count={counts && counts[filter]}
                isDropdownItem
              />
            ))}
        </Menu>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      {Object.values(QuickFilterEnum)
        .filter(filter => qfList.includes(filter))
        .map(filter => (
          <QuickFilter key={filter} label={quickFilterTitles[filter]} value={filter} count={counts && counts[filter]} />
        ))}
    </Stack>
  );
};
