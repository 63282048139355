import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect } from "react";

import { feedbackTemplateFormHasErrorAtom, feedbackTemplateNameAtom } from "components/dover/FeedbackTemplates/atoms";
import { useHasPermissionToEditTemplate } from "components/dover/FeedbackTemplates/hooks";
import { OverallRatingSection } from "components/dover/FeedbackTemplates/OverallRatingSection";
import { QuestionList } from "components/dover/FeedbackTemplates/QuestionList";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { useDebounceState } from "hooks/useDebounceState";
import { useListFeedbackTemplatesQuery } from "services/doverapi/endpoints/feedbackTemplates";

/* -----------------------------------------------------------------------------
 * Inner Form Shared between Create and Edit
 * -------------------------------------------------------------------------- */
interface InnerFormProps {
  existingNameToEdit?: string;
}

/**
 * @description Inner form for creating or editing a FeedbackTemplate
 * @param existingNameToEdit - The name of the existing template to edit
 */
const InnerForm: React.FC<React.PropsWithChildren<InnerFormProps>> = ({ existingNameToEdit }) => {
  const hasPermsToEdit = useHasPermissionToEditTemplate();

  const setFormHasError = useSetAtom(feedbackTemplateFormHasErrorAtom);
  const [feedbackTemplateName, setFeedbackTemplateName] = useAtom(feedbackTemplateNameAtom);

  // Only make API call if:
  // - the name input is not empty and more than one character
  // - if the name input is NOT the same as the existing name (i.e. in edit mode)
  const shouldValidateAgainstName = Boolean(
    feedbackTemplateName && feedbackTemplateName.length > 1 && feedbackTemplateName !== existingNameToEdit
  );
  const { data: response } = useListFeedbackTemplatesQuery(
    shouldValidateAgainstName ? { name: feedbackTemplateName, matchExact: true } : skipToken
  );

  const [debouncedNameInput, setNameValue, nameValue] = useDebounceState<string>(feedbackTemplateName ?? "", 750);

  // Debounce the name updates before it hits jotai so that we don't re-call the api too often
  useEffect(() => {
    setFeedbackTemplateName(debouncedNameInput);
  }, [debouncedNameInput, setFeedbackTemplateName]);

  // If there are any feedback templates with the same name, show an error
  const existingFeedbackTemplateWithName = response && response.count > 0;
  const errorMessage = existingFeedbackTemplateWithName
    ? "A template with this name already exists. Please use a unique name."
    : undefined;

  // Propogate error state to atom to block form submission
  useEffect(() => {
    if (existingFeedbackTemplateWithName) {
      setFormHasError(true);
    } else {
      setFormHasError(false);
    }
  }, [existingFeedbackTemplateWithName, setFormHasError]);

  return (
    <>
      <Stack spacing={1}>
        <BodySmall weight="600">
          Interview feedback template name
          <RequiredAsterisk />
        </BodySmall>
        {/* For some reason, need to default to empty string here to see a value update */}
        <TextField
          text={nameValue}
          onTextUpdated={setNameValue}
          error={existingFeedbackTemplateWithName}
          errorMessage={errorMessage}
          disabled={!hasPermsToEdit}
        />
      </Stack>
      <QuestionList />
      <OverallRatingSection />
    </>
  );
};

export default InnerForm;
