import { Button } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { useForm, FormProvider, useController, useFormContext, useFormState } from "react-hook-form";
import styled, { keyframes } from "styled-components";

import { ReactComponent as AISparklesSVG } from "assets/icons/ai-sparkles.svg";
import { ReactComponent as AIWandSVG } from "assets/icons/ai-wand-gradient.svg";
import { ReactComponent as LightbulbSVG } from "assets/icons/lightbulb.svg";
import { ReactComponent as ImportCareersPageSVG } from "assets/images/collect-careers-page-info.svg";
import {
  CompanyUrlInputAtom,
  GenerateCareersPageErroredAtom,
  SkipImportCareersPageAtom,
} from "components/dover/EditCareersPage/atoms";
import {
  GENERATE_CAREERS_PAGE_CACHE_KEY,
  ImportCareersPageSchema,
  ImportCareersPageSchemaFormType,
} from "components/dover/EditCareersPage/types";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { BodySmall, Heading } from "components/library/typography";
import { SpicyLoadingText } from "components/loading-overlay/SpicyLoader";
import {
  useGenerateCareersPageMutation,
  useListClientDomainsQuery,
} from "services/doverapi/endpoints/company-setup/endpoints";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * Global Variables
 * -------------------------------------------------------------------------- */
const GRADIENT_BLEED = 17;
const GENERATING_CAREERS_PAGE_LOADING_OPTIONS = [
  "Generating careers page...",
  "Harnessing the power of AI to create the perfect page...",
  "Letting our AI intern handle this one...",
  "Our robots are hard at work to make your dreams come true...",
  "Just teaching the AI what a career is...",
  "Our AI is learning what makes your company great...",
];

interface ImportCareersPageFormProps {
  onSubmit: () => Promise<void>;
  inOnboardingFlow: boolean;
}

/* -----------------------------------------------------------------------------
 * React Hook Form Setup
 * -------------------------------------------------------------------------- */

const initialFormValues: ImportCareersPageSchemaFormType = {
  companyUrl: "",
};

/* -----------------------------------------------------------------------------
 * Submit Button
 * -------------------------------------------------------------------------- */

const BuildMyPageButton = ({ onSubmit, inOnboardingFlow }: ImportCareersPageFormProps): React.ReactElement => {
  const setSkipImportCareersPage = useSetAtom(SkipImportCareersPageAtom);
  // RHF setup
  const { handleSubmit } = useFormContext<ImportCareersPageSchemaFormType>();

  const onSkip = (): void => {
    setSkipImportCareersPage(true);
  };

  return (
    <Stack alignItems="flex-end" spacing={1}>
      <Button
        variant="primaryFilled"
        // @ts-ignore
        onPress={handleSubmit(onSubmit)}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <AIWandSVG className="svg-fill" color="white" />
          <BodySmall color="white" weight="500">
            Continue
          </BodySmall>
        </Stack>
      </Button>
      {!inOnboardingFlow && (
        <Button variant="inline" onPress={onSkip}>
          <BodySmall color={colors.grayscale.gray500} weight="500">
            Enter info manually
          </BodySmall>
        </Button>
      )}
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * Form
 * -------------------------------------------------------------------------- */

const ImportCareersPageForm = ({ onSubmit, inOnboardingFlow }: ImportCareersPageFormProps): React.ReactElement => {
  const setCompanyUrlInputAtom = useSetAtom(CompanyUrlInputAtom);

  const { isFetching: isFetchingDomains } = useListClientDomainsQuery({});

  // RHF setup
  const { control, watch } = useFormContext<ImportCareersPageSchemaFormType>();

  const { field: companyUrlField } = useController({
    name: "companyUrl",
    control,
  });

  const companyUrlFieldValue = watch("companyUrl");

  useEffect(() => {
    if (companyUrlFieldValue) {
      setCompanyUrlInputAtom(companyUrlFieldValue);
    }
  }, [companyUrlFieldValue, setCompanyUrlInputAtom]);

  const { errors } = useFormState({ control });

  return (
    <Stack spacing={2} height="100%" justifyContent="center">
      <Stack spacing={1}>
        <BodySmall weight="600">
          {"We'll scan your website and use the details about your company to create a careers page for you."}
        </BodySmall>
        <BodySmall>{"Don't worry, you can edit it later."}</BodySmall>
        <TextField
          text={companyUrlField.value ?? undefined}
          placeholderText="https://yourcompany.com"
          onTextUpdated={companyUrlField.onChange}
          error={!!errors.companyUrl}
          errorMessage={errors.companyUrl?.message}
          disabled={isFetchingDomains}
        />
      </Stack>
      <BuildMyPageButton onSubmit={onSubmit} inOnboardingFlow={inOnboardingFlow} />
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * Main Component
 * -------------------------------------------------------------------------- */
export interface ImportCareersPageProps {
  onSaveAndNext?: () => Promise<void> | void;
  inOnboardingFlow: boolean;
}

const ImportCareersPage = ({ onSaveAndNext, inOnboardingFlow }: ImportCareersPageProps): React.ReactElement => {
  const setGenerateCareersPageError = useSetAtom(GenerateCareersPageErroredAtom);

  const rightPanelRef = useRef<HTMLDivElement>(null);
  const [rightPanelWidth, setRightPanelWidth] = useState<number>(0);
  const [loadingClassName, setLoadingClassName] = useState<string | undefined>(undefined);
  const [generateCareersPage] = useGenerateCareersPageMutation({ fixedCacheKey: GENERATE_CAREERS_PAGE_CACHE_KEY });

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { existingDomain } = useListClientDomainsQuery(
    {},
    {
      selectFromResult: ({ data: clientDomains }): { existingDomain?: string } => {
        const primaryDomain = clientDomains?.filter(domain => domain.isPrimaryDomain)[0]?.domain;
        const existingDomain = primaryDomain ? "https://" + primaryDomain : undefined;
        return { existingDomain };
      },
    }
  );

  const onSubmit = async (): Promise<void> => {
    const { companyUrl } = formMethods.getValues();
    setLoadingClassName("loading");
    const generateCareersPageResult = await generateCareersPage({ companyUrl });
    if (generateCareersPageResult && "error" in generateCareersPageResult) {
      setGenerateCareersPageError(true);
      setLoadingClassName(undefined);
    }
    onSaveAndNext?.();
  };

  useEffect(() => {
    // Set the width of the right panel only once (i.e. when it is first rendered)
    if (rightPanelRef.current && rightPanelWidth === 0) {
      setRightPanelWidth(rightPanelRef.current.offsetWidth);
    }
  }, [rightPanelRef, rightPanelWidth]);

  // RHF setup
  const formMethods = useForm<ImportCareersPageSchemaFormType>({
    resolver: zodResolver(ImportCareersPageSchema),
    defaultValues: initialFormValues,
  });

  useEffect(() => {
    if (existingDomain) {
      formMethods.setValue("companyUrl", existingDomain);
    }
  }, [existingDomain, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <Box
        mt={inOnboardingFlow ? "15vh" : undefined}
        p={1}
        overflow={inOnboardingFlow ? "hidden" : undefined}
        maxWidth="840px"
      >
        <Card>
          <Stack direction="row" height="450px" maxHeight="100%">
            <Stack spacing={2} p={2} pt={3} pr={4} justifyContent="center">
              <div>
                <Heading weight="600">Create a beautiful careers page in minutes</Heading>
                <ImportCareersPageForm onSubmit={onSubmit} inOnboardingFlow={inOnboardingFlow} />
              </div>
            </Stack>
            {!smallScreen && <GrowElement growWidth={rightPanelWidth} className={loadingClassName} />}
            <StyledGradientLoader
              ref={rightPanelRef}
              // Negative margin applies bleed to edge of card (to accomodate card padding)
              mr={`-${GRADIENT_BLEED}px`}
              my={`-${GRADIENT_BLEED}px`}
              p={2}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              growWidth={rightPanelWidth}
              className={loadingClassName}
            >
              <ImportCareersPageSVG />
              <Stack direction="row" spacing={1} alignItems="flex-start">
                <LightbulbSVG className="svg-fill" color={colors.white} width="24px" />
                <BodySmall color={colors.white}>Descriptive careers pages can attract 3X more applicants</BodySmall>
              </Stack>
              {loadingClassName === "loading" && (
                <SpicyLoadingText
                  color={colors.white}
                  options={GENERATING_CAREERS_PAGE_LOADING_OPTIONS}
                  icon={
                    <FadeInOut>
                      <AISparklesSVG />
                    </FadeInOut>
                  }
                />
              )}
            </StyledGradientLoader>
          </Stack>
        </Card>
      </Box>
    </FormProvider>
  );
};

// styled components v6 annoyingly stopped exporting the Keyframes type
const growWidthAnimation = (growWidth: number): any => keyframes`
  0% { width: ${growWidth}px; }
  100% { width: 100%; }
`;

const slideUpAnimation = keyframes`
  0% { top: calc(100% + ${GRADIENT_BLEED}px + 16px); }
  100% { top: ${GRADIENT_BLEED}px; }
`;

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeInOutAnimation = keyframes`
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
`;

interface GrowElementProps {
  growWidth: number;
}

const GrowElement = styled(Stack)<GrowElementProps>`
  height: 100%;
  width: 1px;
  display: inline-block;

  &.loading {
    width: ${({ growWidth }): number => growWidth}px;
  }
`;

// styled components v6 annoyingly stopped exporting the Keyframes type
const StyledGradientLoader = styled(Stack)<GrowElementProps>`
  background-image: linear-gradient(#4bd2b2, #1347af);
  background-clip: border-box;
  border-radius: 6px;
  transition: all 1.3s;

  &.loading {
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    top: ${GRADIENT_BLEED}px;
    right: ${GRADIENT_BLEED}px;
    animation-delay: 0.5s;
    animation: ${({ growWidth }): any => growWidthAnimation(growWidth)} 1.3s ease-in-out forwards;
  }

  @media (max-width: 600px) {
    position: absolute;
    top: calc(100% + ${GRADIENT_BLEED}px + 16px);
    width: 100%;
    height: 100%;
    display: flex;
    right: ${GRADIENT_BLEED}px

    > * {
      opacity: 0;
    }

    &.loading {
      animation-delay: 0.5s;
      animation: ${slideUpAnimation} 1.3s ease-in-out forwards;

      > * {
        animation-delay: 1.3s;
        animation: ${fadeInAnimation} 0.5s ease-in-out forwards;
      }
  }
`;

const FadeInOut = styled.div`
  animation: ${fadeInOutAnimation} 2.25s ease-in-out infinite;
`;

export default ImportCareersPage;
