import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as DragHandleIcon } from "@doverhq/dover-ui/icons/drag-handle.svg";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { Box } from "@mui/material";
import React, { FC } from "react";

import { BodySmall, Subtitle2 } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { UserCoinList } from "components/UserCoinList";
import { HiringPlanInterviewer } from "services/openapi";
import { colors } from "styles/theme";
import { secondsToMinutesOrHours } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/helpers";

interface StageDragHandleProps {
  dragHandleProps: DraggableProvidedDragHandleProps | null;
  disabled?: boolean;
}

export const StageDragHandle: FC<React.PropsWithChildren<StageDragHandleProps>> = ({ dragHandleProps, disabled }) => {
  // The visibility of this gets set to visible when hovering the stage row
  return (
    <Box
      {...dragHandleProps}
      className={disabled ? undefined : "hide-until-hover"}
      sx={{ visibility: "hidden" }}
      display="flex"
      justifyContent="center"
    >
      <Icon Icon={DragHandleIcon} color="gray-500" />
    </Box>
  );
};

interface StageNameProps {
  name: string;
}

export const StageName: FC<React.PropsWithChildren<StageNameProps>> = ({ name }) => {
  return <Subtitle2>{name}</Subtitle2>;
};

interface SubstageNameProps {
  name: string | undefined | null;
}

export const SubstageName: FC<React.PropsWithChildren<SubstageNameProps>> = ({ name }) => {
  if (!name) {
    return null;
  }

  return <BodySmall>{name}</BodySmall>;
};

interface StageInterviewersProps {
  interviewers: Array<HiringPlanInterviewer>;
  // note only 0 or 1 of the following 2 args could be true
  stageUsingDI?: boolean;
  isTakeHome?: boolean;
}

export const StageInterviewers: FC<React.PropsWithChildren<StageInterviewersProps>> = ({
  interviewers,
  stageUsingDI,
  isTakeHome,
}) => {
  if (isTakeHome) {
    return <></>;
  }

  if (stageUsingDI) {
    return (
      // give it an inline block display so it doesnt take up the whole row (and thus tooltip is not where it should be)
      <Box display="inline-block">
        <PersonaCoin size="small" useDoverLogo tooltip="Dover Interviewer" />
      </Box>
    );
  }

  if (interviewers.length === 0) {
    return <BodySmall color={colors.grayscale.gray400}>Select interviewers</BodySmall>;
  }

  const interviewersWTooltips = interviewers.map(i => {
    const tooltip = i.firstName && i.lastName ? `${i.firstName} ${i.lastName}` : i.firstName || undefined;
    return {
      firstName: i.firstName,
      lastName: i.lastName,
      pictureUrl: i.pictureUrl,
      tooltip: tooltip,
    };
  });

  return <UserCoinList users={interviewersWTooltips} overflowLimit={4} />;
};

interface StageDurationProps {
  duration: number | undefined;
}

export const StageDuration: FC<React.PropsWithChildren<StageDurationProps>> = ({ duration }) => {
  if (duration === undefined) {
    return <BodySmall color={colors.grayscale.gray400}>Set duration</BodySmall>;
  }

  return <BodySmall>{secondsToMinutesOrHours(duration)}</BodySmall>;
};
