import React from "react";

import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { HiringPipelineStage } from "services/openapi";
import { EditStageDrawer } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/EditStageDrawer";

export interface StageDataWrapper {
  stage: HiringPipelineStage;
  id: string | undefined;
  name: string;
  contentTypeName: string | undefined;
  atsStageId: string | null | undefined;
  emailTemplateEdited?: boolean;
  useDoverInterviewer?: boolean;
}

interface EditStageDrawerWrapperProps {
  open: boolean;
  onClose: () => void;
  stage: HiringPipelineStage | undefined;
}

/**
 * WARNING: Here be some of the most dog shit code you've ever seen.
 *
 * We should not be adding features or modifying this code.  If your PM insists on this
 * burn them at the stake, use this code as the kindling.  It needs to be rewritten.  The only
 * acceptable time you should be even considering looking at this code is if there's an actually
 * important bug that has to be fixed, which is likely to happen because of the teetering, ticking time bomb
 * that is this code. It needs to be rewritten. This is the 161 Maiden Lane of
 * our code base.
 */
export const EditStageDrawerWrapper: React.FC<React.PropsWithChildren<EditStageDrawerWrapperProps>> = ({
  open,
  onClose,
  stage,
}) => {
  const substages = stage?.multipartInterviewStage?.substages || [];
  const useDoverInterviewer = substages.length ? !!substages[0].useDoverInterviewer : false;

  const { data: client } = useGetClientOnboardingQuery();

  if (!stage) {
    return null;
  }

  const clientAtsSetupComplete =
    !!client?.ashbyCredsAreValid || !!client?.greenhouseCredsAreValid || !!client?.leverCredsAreValid;

  const stageData = {
    // Actual stage
    stage,
    // Hiring Pipeline Stage fields
    id: stage.id,
    name: stage.name,
    contentTypeName: stage.contentTypeName,
    atsStageId: stage.atsStageId,
    // State
    emailTemplateEdited: false, // TODO

    useDoverInterviewer: useDoverInterviewer,
  };

  return (
    <EditStageDrawer
      open={open}
      onClose={onClose}
      stageData={stageData}
      clientAtsSetupComplete={clientAtsSetupComplete}
    />
  );
};
