import { Divider, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { useInterviewer } from "components/dover/top-level-modal-manager/hooks/useInterviewer";
import { useSchedulingOwnership } from "components/dover/top-level-modal-manager/hooks/useSchedulingOwnership";
import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionEmailEditor } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor";
import { WarningBanner } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { InterviewerSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerSelect";
import { StageSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/StageSelect";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { createInterviewPanel } from "components/dover/top-level-modal-manager/utils/createInterviewPanel";
import { getModalWidth, isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { ScheduledActionButton } from "components/ScheduledActionButton";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { useRescheduleInterviewMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import {
  ApiApiRescheduleInterviewOperationRequest,
  BaseInterviewSubstage,
  CandidateActionEmailArgs,
  GetEmailTemplateRequestV2DecisionEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

export const ReschedulingModal = ({
  isOpen,
  closeModal,
  candidateId,
}: CandidateActionModalProps): React.ReactElement => {
  const [rescheduleInterview] = useRescheduleInterviewMutation();
  const candidateActionVersion = useActionVersion(candidateId);
  const schedulingOwnership = useSchedulingOwnership(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const [proceedWithoutLink, setProceedWithoutLink] = useState(false);
  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [substages, setSubstages] = useState<BaseInterviewSubstage[]>([]);

  const { stage, setStage, stages, substage, isTakeHome } = useStage({
    candidateId,
    forceUseCurrentPipelineStage: true,
  });

  const { interviewer, setInterviewer } = useInterviewer(candidateId);
  const interviewPanel = createInterviewPanel({
    interviewer,
    stage,
    substages,
    substageId: substage?.id,
    substageDuration: substage?.duration,
    schedulingOwnership,
    isTakeHome,
  });

  const {
    to,
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey,
    threadId,
    isFetching,
    isValidatingLink,
    invalidLink,
    completedInterviews,
  } = useEmailState({ candidateId, decision: GetEmailTemplateRequestV2DecisionEnum.Reschedule, stage });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate("Reschedule", {
    fetchingTemplateAndBioData: isFetching,
    subject,
    to,
    from,
    body,
    desiredHiringPipelineStage: stage,
    substage,
    schedulingOwnership,
    interviewer,
    interviewPanel,
    isTakeHome,
    proceedWithoutLink,
    isValidatingLink,
    invalidLink,
    completedInterviews,
  });

  // Reset proceed without link if the interviewer changes
  useEffect(() => {
    setProceedWithoutLink(false);
  }, [interviewer]);

  const usingMultipartEditor = isMultipartInterview(stage);
  const modalWidth = getModalWidth(stage);

  const onSubmit = useCallback(async () => {
    if (!stage || !stage.id || !jobId || !from || !substage?.id || !substage?.duration) {
      return;
    }

    const emailArgs: CandidateActionEmailArgs = {
      emailAlias: from.id,
      toEmails: [to.email],
      ccEmails: cc.map(o => o.email),
      bccEmails: [],
      subject,
      body,
      threadId,
      messageKey: messageKey,
      sendAt: sendAt,
    };

    const args: ApiApiRescheduleInterviewOperationRequest = {
      id: candidateId,
      data: {
        candidateActionVersion,
        desiredHiringPipelineStage: stage.id,
        emailArgs,
      },
    };

    const rescheduleInterviewPromise = rescheduleInterview(args).unwrap();

    try {
      await toast.promise(
        rescheduleInterviewPromise,
        {
          pending: "Updating...",
          success: "Updated!",
          error: "Error updating",
        },
        { ...toastOptions }
      );
      closeModal();
    } catch (e) {
      console.error(e);
      return;
    }
  }, [
    stage,
    jobId,
    from,
    substage?.id,
    substage?.duration,
    to.email,
    cc,
    subject,
    body,
    threadId,
    messageKey,
    sendAt,
    candidateId,
    candidateActionVersion,
    rescheduleInterview,
    closeModal,
  ]);

  const ActionButtons = useMemo(
    () => (
      <Stack width="100%">
        {!isFetching && <WarningBanner warning={warning} />}
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between" padding="12px">
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Body>Send </Body>
            <ScheduledActionButton setActionAt={setSendAt} />
          </Stack>
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={onSubmit}
            disabled={disabledMap.Send}
            tooltip={tooltip}
          >
            Send
          </Button>
        </Stack>
      </Stack>
    ),
    [isFetching, warning, setSendAt, onSubmit, disabledMap.Send, tooltip]
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Reschedule</Body>}
      maxWidth={modalWidth}
      customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
      customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
      dialogActions={ActionButtons}
      dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
    >
      <Stack spacing={2}>
        <StageSelect stageId={stage?.id} setStage={setStage} stages={stages} disabled />
        <InterviewerSelect
          candidateId={candidateId}
          desiredHiringPipelineStage={stage}
          interviewer={interviewer}
          setInterviewer={setInterviewer}
          setSubstages={setSubstages}
          proceedWithoutLink={proceedWithoutLink}
          isTakeHome={isTakeHome}
          disabled
        />
        <BodySmall color={colors.grayscale.gray600}>Your currently scheduled meeting will be cancelled</BodySmall>
        <Divider sx={{ margin: "8px -24px !important" }} />
        {!hideEditor && (
          <Stack spacing={1}>
            <BodySmall weight="500">Email</BodySmall>
            <CandidateActionEmailEditor
              candidateId={candidateId}
              interviewer={interviewer}
              desiredHiringPipelineStageId={stage?.isOneOffInterview ? undefined : stage?.id}
              hideSchedulingLink={proceedWithoutLink || usingMultipartEditor}
              body={initialBody}
              onBodyChanged={setBody}
              subject={initialSubject}
              onSubjectChanged={setSubject}
              from={from}
              onFromChanged={setFrom}
              to={to}
              cc={cc}
              onCcChanged={setCc}
              bcc={bcc}
              onBccChanged={setBcc}
              disableFrom={disabledMap.From}
              disableTo={disabledMap.To}
              disableCc={disabledMap.CC}
              disableBcc={disabledMap.BCC}
              disableSubject={disabledMap.Subject}
              disableBody={disabledMap.Body}
              loading={isFetching}
            />
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
};
