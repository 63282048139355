import { Button } from "@doverhq/dover-ui";
import { ReactComponent as TrashIcon } from "@doverhq/dover-ui/icons/trash.svg";
import { Box } from "@mui/material";
import React, { FC, ReactNode } from "react";

import { Tooltip } from "components/library/Tooltip";
import Confirm from "components/Modal/ConfirmModal";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useDisableHiringPipelineStageMutation } from "services/doverapi/endpoints/hiringPipelineStage";
import { CanDetail } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCanStage";

interface DisableStageProps {
  id: string | undefined;
  canDisable: CanDetail;
}

export const DisableStage: FC<React.PropsWithChildren<DisableStageProps>> = ({ id, canDisable }) => {
  const [jobId] = useJobId();

  const [disableStage] = useDisableHiringPipelineStageMutation();

  const click = (): void => {
    if (!jobId || !id) {
      return;
    }

    disableStage({ id, jobId });
  };

  return (
    <Box className="hide-until-hover" visibility="hidden">
      <Confirm
        title="Remove Stage"
        submitText="Remove Stage"
        content="Are you sure you want to remove this stage?"
        submitVariant="critical"
      >
        {/* @ts-ignore this typing is brutal */}
        {(show): ReactNode => (
          <Tooltip title={canDisable.reason} placement="top-start">
            <div>
              <Button
                variant="ghost"
                icon={{ Icon: TrashIcon, color: canDisable.can ? "critical-base" : "gray-400" }}
                onPress={(): void => show(click)()}
                // kinda confusing naming, but canDisable refers to the ability to disable the stage
                // so isDisabled={!canDisable} means that the button is disabled if the stage can't be disabled
                isDisabled={!canDisable.can}
              />
            </div>
          </Tooltip>
        )}
      </Confirm>
    </Box>
  );
};
