import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients, isSuperAPIAllowedForNodeEnv } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CANDIDATE_BIO,
  CANDIDATES_NEXT_ACTIONS,
  LIST_TAG,
  PIPELINE_CANDIDATE,
  PIPELINE_CANDIDATE_COUNTS,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiFetchCandidateCountsRequest,
  ApiApiGetCandidateV2Request,
  ApiApiListPipelineCandidatesRequest,
  ApiApiListValidEmailSendRequestSendTimesRequest,
  CandidateNextAction,
  CandidatePipelineCountResponse,
  CandidatePipelineListResponse,
  PipelineCandidate,
  RegenerateSampleCandidateResponse,
  RemoveFromOutboxResponse,
} from "services/openapi";
import { RegenerateSampleCandidateRequest } from "services/openapi/models/RegenerateSampleCandidateRequest";
import { RemoveFromOutboxRequest } from "services/openapi/models/RemoveFromOutboxRequest";
import { ValidEmailSendAtTime } from "services/openapi/models/ValidEmailSendAtTime";
import { showErrorToast } from "utils/showToast";

const listCandidatesAdapter = createEntityAdapter<PipelineCandidate>();
export const listCandidatesInitialState = listCandidatesAdapter.getInitialState();

export interface ListPipelineCandidatesArgs {
  args: ApiApiListPipelineCandidatesRequest;
  useSuperApi?: boolean;
}

export interface CandidateCountArgs {
  args: ApiApiFetchCandidateCountsRequest;
  useSuperApi?: boolean;
}

export const pipelineEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listPipelineCandidates: build.query<CandidatePipelineListResponse, ListPipelineCandidatesArgs>({
      queryFn: async ({ args, useSuperApi = false }) => {
        const { apiApi } = await getOpenApiClients({
          useSuperApi: useSuperApi && isSuperAPIAllowedForNodeEnv(),
        });

        try {
          const response = await apiApi.listPipelineCandidates(args);

          return { data: response };
        } catch (err) {
          return { error: { serializedError: err as SerializedError } };
        }
      },
      providesTags: data => {
        if (data) {
          const { results: candidates } = data;
          const candidateIds = candidates?.map(c => c.id!);

          return [
            ...candidateIds.map(id => ({ type: PIPELINE_CANDIDATE, id } as const)),
            { type: PIPELINE_CANDIDATE, id: LIST_TAG },
          ];
        }

        return [{ type: PIPELINE_CANDIDATE, id: LIST_TAG }];
      },
    }),
    getCandidateCounts: build.query<CandidatePipelineCountResponse[], CandidateCountArgs>({
      queryFn: async ({ args, useSuperApi = false }) => {
        const { apiApi } = await getOpenApiClients({
          useSuperApi: useSuperApi && isSuperAPIAllowedForNodeEnv(),
        });

        try {
          const response = await apiApi.fetchCandidateCounts(args);

          return { data: response };
        } catch (err) {
          return { error: { serializedError: err as SerializedError } };
        }
      },
      providesTags: () => {
        return [
          { type: PIPELINE_CANDIDATE, id: LIST_TAG },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
        ];
      },
    }),
    getCandidateV2: build.query<PipelineCandidate, ApiApiGetCandidateV2Request>({
      queryFn: async (params: ApiApiGetCandidateV2Request) => {
        const { id, expand } = params;

        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.getCandidateV2({
            id,
            expand,
          });

          return { data: response };
        } catch (err) {
          const errorToastMessage = "Failed to load candidate";
          showErrorToast(errorToastMessage);

          return { error: { serializedError: err as SerializedError, userFacingMessage: errorToastMessage } };
        }
      },
      providesTags: response => {
        return response
          ? [
              { type: CANDIDATE_BIO, id: response.id },
              { type: PIPELINE_CANDIDATE, id: response.id },
            ]
          : [];
      },
    }),
    getCandidatesNextActions: build.query<CandidateNextAction[], string[]>({
      queryFn: async candidateIds => {
        const { apiApi: client } = await getOpenApiClients({});
        const data: CandidateNextAction[] = await client.determineNextActionForCandidates({
          data: { candidateIds },
        });
        return { data };
      },
      providesTags: result => {
        return result
          ? [
              ...result.map(candidateNextAction => {
                return { type: CANDIDATES_NEXT_ACTIONS, id: candidateNextAction.id } as const;
              }),
              { type: CANDIDATES_NEXT_ACTIONS, id: LIST_TAG },
            ]
          : [{ type: CANDIDATES_NEXT_ACTIONS, id: LIST_TAG }];
      },
    }),
    removeFromOutbox: build.mutation<RemoveFromOutboxResponse, { data: RemoveFromOutboxRequest; jobId: string }>({
      queryFn: async ({ data }) => {
        const { apiApi: client } = await getOpenApiClients({
          useSuperApi: isSuperAPIAllowedForNodeEnv(),
        });

        let result;
        try {
          result = await client.removeFromOutbox({ data: data });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, { data }) => {
        if (result) {
          return [
            ...data.candidateIds.map(id => ({ type: PIPELINE_CANDIDATE, id } as const)),
            { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          ];
        }

        return [];
      },
    }),
    listValidEmailSendRequestSendTimes: build.query<
      ValidEmailSendAtTime[],
      ApiApiListValidEmailSendRequestSendTimesRequest
    >({
      queryFn: async params => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.listValidEmailSendRequestSendTimes(params);
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
    }),
    regenerateSampleCandidate: build.mutation<RegenerateSampleCandidateResponse, RegenerateSampleCandidateRequest>({
      queryFn: async ({ jobId }: { jobId: string }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let response;
        try {
          response = await client.regenerateSampleCandidate({ data: { jobId } });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
      invalidatesTags: response => {
        return response
          ? [
              { type: PIPELINE_CANDIDATE, id: LIST_TAG },
              { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
            ]
          : [];
      },
    }),
  }),
});

export const {
  useGetCandidateV2Query,
  useGetCandidatesNextActionsQuery,
  useRemoveFromOutboxMutation,
  useListValidEmailSendRequestSendTimesQuery,
  useRegenerateSampleCandidateMutation,
  useListPipelineCandidatesQuery,
  useLazyListPipelineCandidatesQuery,
  useGetCandidateCountsQuery,
  usePrefetch,
} = pipelineEndpoints;

export const { selectAll: selectAllCandidates, selectIds: selectCandidateIds } = listCandidatesAdapter.getSelectors();
