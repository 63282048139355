import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom, useSetAtom } from "jotai";
import React, { FC, ReactElement } from "react";
import { toast } from "react-toastify";

import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import ComboButton, { ComboButtonVariant } from "components/library/combo-button/ComboButton";
import { Tooltip } from "components/library/Tooltip";
import Confirm from "components/Modal/ConfirmModal";
import { useQueueBulkRejectionMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import {
  multiSelectApplicationsAtom,
  multiSelectEnabledAtom,
} from "views/candidates/ApplicationReview/atoms/multiSelect";

interface MultiSelectActionBarProps {
  jobId?: string;
}

export const MultiSelectActionBar: FC<React.PropsWithChildren<MultiSelectActionBarProps>> = ({ jobId }) => {
  const [queueBulkReject, { isLoading }] = useQueueBulkRejectionMutation();

  const [multiSelectApplications, setMultiSelectApplications] = useAtom(multiSelectApplicationsAtom);
  const setMultiSelectEnabled = useSetAtom(multiSelectEnabledAtom);

  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);

  const { showBulkRejectModal } = useTopLevelModalManagerOptions();

  const bulkReject = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    const candidates = multiSelectApplications
      .filter(a => a.candidateActionVersion !== undefined)
      .map(a => ({
        id: a.candidateId,
        actionVersion: a.candidateActionVersion!, // The filter above gurantees this to be defined, ts just doesn't know it
      }));

    const args = {
      data: {
        sync: false,
        candidates,
      },
    };

    const queueBulkRejectPromise = queueBulkReject(args).unwrap();

    try {
      await toast.promise(
        queueBulkRejectPromise,
        {
          pending: "Queueing...",
          success: "Queued Rejections!",
          error: "Error queueing rejections",
        },
        { ...toastOptions }
      );

      setMultiSelectApplications([]);
      setMultiSelectEnabled(false);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const numApplicantsSelected = multiSelectApplications.length;

  const disabledTooltipText = numApplicantsSelected === 0 ? "You must select at least one applicant." : undefined;

  const rejectButtonText = jobSetup?.shouldSendInboundRejectionEmails
    ? `Send Rejection to ${numApplicantsSelected} Applicant${numApplicantsSelected === 1 ? "" : "s"}`
    : `Reject ${numApplicantsSelected} Applicant${numApplicantsSelected === 1 ? "" : "s"}`;

  return (
    <Stack
      direction="row"
      width="100%"
      spacing={1}
      p={1}
      justifyContent="center"
      boxShadow={`0px 0px 4px 0px ${colors.grayscale.gray400}`}
      bgcolor={colors.grayscale.gray100}
    >
      <Confirm
        title="Bulk Reject"
        cancelText="Cancel"
        submitText="Reject"
        submitVariant="critical"
        content={`Are you sure you want to reject ${numApplicantsSelected} applicant${
          numApplicantsSelected === 1 ? "" : "s"
        }?`}
      >
        {/* @ts-ignore FIX: confirm sucks */}
        {(confirm): ReactElement => {
          const openBulkReject = (): void => showBulkRejectModal({});
          const openConfirm = confirm(bulkReject);

          return (
            <>
              {isJobE2eEnabled ? (
                <Tooltip title={disabledTooltipText ?? ""}>
                  <Box width="min-content" display="flex" justifyContent="center">
                    <ComboButton
                      label={rejectButtonText}
                      variant={ComboButtonVariant.Critical}
                      icon={
                        jobSetup?.shouldSendInboundRejectionEmails ? (
                          <Box width="26px" paddingBottom="3px">
                            <MailIcon height="16px" width="16px" color={colors.white} className="svg-color" />
                          </Box>
                        ) : (
                          undefined
                        )
                      }
                      onClick={openConfirm}
                      overflowActions={[
                        {
                          label: "Customize Rejection",
                          onClick: openBulkReject,
                        },
                      ]}
                      disabled={isLoading || numApplicantsSelected === 0}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Button
                  variant={ButtonVariant.Critical}
                  disabled={isLoading || numApplicantsSelected === 0}
                  onClick={openBulkReject}
                  tooltip={disabledTooltipText ?? ""}
                  width="100%"
                >
                  {rejectButtonText}
                </Button>
              )}
            </>
          );
        }}
      </Confirm>
    </Stack>
  );
};
