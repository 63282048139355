import { Button as DuiButton } from "@doverhq/dover-ui";
import { ReactComponent as FilterIcon } from "@doverhq/dover-ui/icons/filter.svg";
import { Box, Modal, Stack } from "@mui/material";
import { PressEvent } from "@react-types/shared/src/";
import { format, isAfter } from "date-fns";
import React, { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import { Banner, BannerVariant } from "components/library/Banner";
import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import { candidatesActions, candidatesReducer, candidatesReducerKey } from "domains/candidate/reducer";
import { useCandidateId } from "hooks/useCandidateId";
import { useLocalStorage } from "hooks/useLocalStorage";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { isValidDate } from "utils/dates";
import { useInjectReducer } from "utils/injectReducer";
import CandidateDetailView from "views/CandidateDetail";
import { OUTREACH_EMAIL_EDITOR_OPEN_PARAM } from "views/CandidateDetail/constants";
import CandidatesBoard from "views/candidates/CandidateTable/board/CandidatesBoard";
import { BoardTableViewToggle } from "views/candidates/CandidateTable/components/BoardTableViewToggle";
import { NoJobs } from "views/candidates/CandidateTable/components/NoJobs";
import { OpenAppReviewButton } from "views/candidates/CandidateTable/components/OpenAppReviewButton";
import { CandidatesRemovedFromQueueProvider } from "views/candidates/CandidateTable/context";
import { ContactedFilterDropdown } from "views/candidates/CandidateTable/filters/ContactedFilterDropdown";
import { FilterMenu } from "views/candidates/CandidateTable/filters/FilterMenu";
import { QuickFilterSection } from "views/candidates/CandidateTable/filters/QuickFilterSection";
import { CandidateTable } from "views/candidates/CandidateTable/table/CandidateTable";
import { TableHeader } from "views/candidates/CandidateTable/table/TableHeader";
import {
  useDefaultQueryParams,
  useIsContactedStageSelected,
  useIsApplicantsActive,
  useJobOrDefault,
  useParams,
  useResetPage,
} from "views/candidates/hooks";
import { CandidatesViewType } from "views/candidates/types";

// Wrapper around candidates view that handles loading and empty states
// And ensures that a job exists before showing the candidate view
const CandidatesViewData: FC<React.PropsWithChildren<unknown>> = () => {
  useInjectReducer({
    key: candidatesReducerKey,
    reducer: candidatesReducer,
  });

  const { job, isLoading } = useJobOrDefault();

  // Loading State
  if (isLoading) {
    return <DoverLoadingSpinner />;
  }

  // If there are no jobs, show a message to add a job
  if (!job) {
    return <NoJobs />;
  }

  return <CandidatesView job={job} />;
};

interface CandidatesViewProps {
  job: DashboardJob;
}

const CandidatesView: FC<CandidatesViewProps> = ({ job }) => {
  const jobId = job.id!; // Job's have to have an id
  // load in the default query params
  const defaultQueryParams = useDefaultQueryParams();

  // ------------------------------
  // Redux
  // ------------------------------
  useInjectReducer({
    key: candidatesReducerKey,
    reducer: candidatesReducer,
  });

  // ------------------------------
  // Library Hooks
  // ------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // ------------------------------
  // Local State
  // ------------------------------
  const [filterMenuAnchor, setFilterMenuAnchor] = React.useState<Element | null>(null);

  // ------------------------------
  // URL Data
  // ------------------------------
  const [{ status, hpsId }, setParams] = useParams();

  // ------------------------------
  // Custom Hooks / RTKQ
  // ------------------------------
  const candidateId = useCandidateId();

  // ------------------------------
  // Local Storage
  // ------------------------------
  const [view, setView] = useLocalStorage("candidatesView", CandidatesViewType.Board);

  // ------------------------------
  // Local Functions
  // ------------------------------
  const handleChangeCandidatesViewType = (event: React.MouseEvent<HTMLElement>, nextView: CandidatesViewType): void => {
    setView(nextView);
  };

  const onCandidateDetailViewClosed = useCallback((): void => {
    // reset outreachEmailEditorOpen
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(OUTREACH_EMAIL_EDITOR_OPEN_PARAM);

    navigate(APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId, new URLSearchParams(searchParams.toString())));
  }, [jobId, location.search, navigate]);

  const clickFilterMenuButton = (e: PressEvent): void => {
    setFilterMenuAnchor(e.target);
  };

  const closeFilterMenu = (): void => {
    setFilterMenuAnchor(null);
  };

  // ------------------------------
  // Effects
  // ------------------------------
  // Effect that resets the page when the any filter or sort changes
  useResetPage();

  // Set params for initial load
  useEffect(() => {
    if (!hpsId?.length) {
      setParams({ hpsId: defaultQueryParams.hpsId });
    }

    if (!status.length) {
      setParams({ status: defaultQueryParams.status });
    }

    // We intentionally only want to run this on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQueryParams]);

  useEffect(() => {
    dispatch(candidatesActions.setCurrentCandidate(candidateId));
  }, [candidateId, dispatch]);

  // ------------------------------
  // Derived Data
  // ------------------------------

  // Only show the contacted filter when the contacted stage tab is selected
  const showContactedFilter = useIsContactedStageSelected();
  const isAppliedStageSelected = useIsApplicantsActive();
  const showCandidatesBoardView = view === CandidatesViewType.Board;

  return (
    <JobFeatureToggleHandler>
      <PageHelmet title={job ? `${job.title} - Pipeline` : "Pipeline"} />
      <FilterMenu anchor={filterMenuAnchor} onClose={closeFilterMenu} />
      <Modal open={Boolean(candidateId)} sx={{ height: "100%", zIndex: 1200 }}>
        <CandidateDetailView close={onCandidateDetailViewClosed} />
      </Modal>
      <Stack px={2} py={1} spacing={1} height="100%" minHeight={0}>
        <JobPausedBanner snoozedUntil={job.snoozedUntil} />
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="start">
          <BoardTableViewToggle view={view} handleChangeCandidatesViewType={handleChangeCandidatesViewType} />
          <QuickFilterSection />
          <DuiButton variant="ghost" onPress={clickFilterMenuButton} icon={{ Icon: FilterIcon }}>
            Filter
          </DuiButton>
        </Stack>
        {showCandidatesBoardView ? (
          <CandidatesBoard />
        ) : (
          <Box
            border={`1px solid ${colors.grayscale.gray300}`}
            sx={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px 0px" }}
          >
            <TableHeader />
            {showContactedFilter && (
              <TableSubheader>
                <ContactedFilterDropdown />
              </TableSubheader>
            )}
            {isAppliedStageSelected && (
              <TableSubheader>
                <OpenAppReviewButton />
              </TableSubheader>
            )}
            <TableArea>
              <CandidatesRemovedFromQueueProvider>
                <CandidateTable />
              </CandidatesRemovedFromQueueProvider>
            </TableArea>
          </Box>
        )}
      </Stack>
    </JobFeatureToggleHandler>
  );
};

interface JobPausedBannerProps {
  snoozedUntil?: Date | null;
}

const JobPausedBanner: FC<React.PropsWithChildren<JobPausedBannerProps>> = ({ snoozedUntil }) => {
  if (!snoozedUntil) {
    return null;
  }

  const hasValidSnoozedUntilDateInFuture = isValidDate(snoozedUntil) && isAfter(snoozedUntil, new Date());

  if (!hasValidSnoozedUntilDateInFuture) {
    return null;
  }

  return (
    <Banner centeredText={true} centeredIcon={true} fullWidth={true} variant={BannerVariant.Info}>
      <Body centered>{`Sourcing Paused until ${format(snoozedUntil, "MMMM do, yyyy")}`}</Body>
    </Banner>
  );
};

const TableArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  backgroundcolor: colors.white;
  border-top: 1px solid ${colors.grayscale.gray300};
`;

const TableSubheader = styled.div`
  background-color: ${colors.white};
  padding: 0.5em;
  border-top: solid 1px ${colors.grayscale.gray300};
`;

export default CandidatesViewData;
