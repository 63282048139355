import { Box } from "@mui/material";
import { capitalize } from "lodash";
import React, { FC } from "react";

import { Tooltip } from "components/library/Tooltip";
import { Caption } from "components/library/typography";
import { CandidateBioAts } from "services/openapi";
import { colors } from "styles/theme";
import { convertPrettyDate } from "views/CandidateDetail/utils";

interface AtsInfoProps {
  ats: CandidateBioAts;
}

export const AtsInfo: FC<React.PropsWithChildren<AtsInfoProps>> = ({ ats }) => {
  const { url, type, stageName, lastSyncedAt } = ats;

  const atsContext = stageName && lastSyncedAt ? `${stageName} - Last Synced ${convertPrettyDate(lastSyncedAt)}` : "";

  return (
    <Tooltip title={atsContext} placement="bottom">
      <Box sx={{ cursor: url ? "pointer" : "" }}>
        <a href={url} rel="noopener noreferrer" target="_blank">
          <Caption color={colors.grayscale.gray500}>{capitalize(type)}</Caption>
        </a>
      </Box>
    </Tooltip>
  );
};
