import { Stack } from "@mui/material";
import React from "react";

import { InfoTip } from "components/InfoTip";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Subtitle2 } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useModal } from "GlobalOverlays/atoms";
import { addAgencyModalAtom } from "views/agencies/AddAgencyModal";
import { AgenciesTable } from "views/agencies/AgenciesTable";
import { RecruiterMarketplaceSplash } from "views/agencies/RecruiterMarketplaceSplash";

/* -----------------------------------------------------------------------------
 * PrimaryView
 * -------------------------------------------------------------------------- */

const JobAgenciesPage = (): React.ReactElement => {
  const { open } = useModal(addAgencyModalAtom);

  return (
    <Stack maxWidth="950px" margin="0 auto" spacing={1}>
      <PageHelmet title="Agencies" />
      {/* header */}
      <Stack paddingTop={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <Subtitle2>Agencies</Subtitle2>
          <InfoTip
            leaveDelay={200}
            text={
              <BodySmall color="white">
                Invite recruiters to submit candidates.{" "}
                <a href={"https://help.dover.com/en/articles/10087355-agency-portal"} target="_blank" rel="noreferrer">
                  Learn more
                </a>
                .
              </BodySmall>
            }
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              open({});
            }}
          >
            + Agency
          </Button>
        </Stack>
      </Stack>
      <RecruiterMarketplaceSplash />
      <AgenciesTable />
    </Stack>
  );
};

export default JobAgenciesPage;
