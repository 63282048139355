import isHotkey from "is-hotkey";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";

import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { showPendingToast } from "utils/showToast";
import { session } from "utils/storage";

type Action = () => void;

// Where we register hotkeys
// key is the hotkeys used to trigger the action
// value is the description of the hotkey
const hotkeysAtom = atom(new Map<string, string>());

const addHotkeyAtom = atom(null, (get, set, [key, description]: [string, string]) => {
  const hotkeyMenu = get(hotkeysAtom);

  if (!hotkeyMenu.has(key)) {
    hotkeyMenu.set(key, description);
    set(hotkeysAtom, new Map(hotkeyMenu));
  }
});

const removeHotkeyAtom = atom(null, (get, set, key: string) => {
  const hotkeyMenu = get(hotkeysAtom);
  hotkeyMenu.delete(key);
  set(hotkeysAtom, new Map(hotkeyMenu));
});

export const listHotkeysAtom = atom(get => {
  const hotkeys = get(hotkeysAtom);

  return Array.from(hotkeys.entries()).sort();
});

// Create a custom jotai storage entity that uses sessionStorage to store a Map
// Adds custom JSON serialization for the Map
const mapSessionStorage = createJSONStorage<Map<string, boolean>>(() => session, {
  reviver: (_, value) => {
    if (typeof value === "object" && value !== null) {
      // @ts-expect-error - It's fine to check this property
      if (value.dataType === "Map") {
        // @ts-expect-error - Same here
        return new Map(value.value);
      }
    }

    return value;
  },
  replacer: (_, value) => {
    if (value instanceof Map) {
      return {
        dataType: "Map",
        value: [...value],
      };
    } else {
      return value;
    }
  },
});

// Store if the reminder to use a hotkey in the wrapped function has been used yet
const actionRemindersAtom = atomWithStorage<Map<string, boolean>>(
  "actionReminders",
  new Map<string, boolean>(),
  mapSessionStorage
);

const setReminderSentAtom = atom(null, (get, set, key: string) => {
  const actionUsed = get(actionRemindersAtom);
  actionUsed.set(key, true);
  set(actionRemindersAtom, new Map(actionUsed));
});

/**
 * Hook used to add a hotkey. Will automatically be added to the hotkey menu.
 *
 * NOTE: You should add your "keys" string in `src/hotkeys/hotkeys.ts`.
 * NOTE: We don't do any conflict detection or resolution currently. So, make sure your hotkeys don't override other ones.
 *
 * @param keys The hotkeys used to trigger the action. Please add these strings to `src/hotkeys/hotkeys.ts`. We use the [is-hotkey](https://github.com/ianstormtaylor/is-hotkey#readme) library to parse this string with 1 important difference: for multiple hotkeys pass in a comma seperated string (e.g. "a,b,c") instead of an array. The reason for this difference is we use the keys in dependnecy arrays and map keys so a stable reference is needed.
 * @param description Description used to describe the hotkey to the user in the hotkey menu.
 * @param action The function to trigger when the hotkey is pressed. This is optional, but should always be passed in in normal situations.
 * An example where you wouldn't pass this is in is for a tool like command bar where we don't have access to the event listener used to trigger
 * opening command bar, but we still want that shortcut to show up in the hotkey menu.
 *
 * @returns The action wrapped in a function that will show a reminder toast notification once per session when the function is run to let the user know about the hotkey.
 */
export const useHotkey = (keys: string, description: string, action?: Action): Action => {
  const isHotkeysEnabled = useFeatureFlag(FeatureFlag.Hotkeys);

  const addHotkey = useSetAtom(addHotkeyAtom);
  const removeHotkey = useSetAtom(removeHotkeyAtom);
  const setActionUsed = useSetAtom(setReminderSentAtom);

  const isDuplicateHotkey = useIsDuplicateHotkey(keys, description);

  // Callback ref pattern to keep the action up to date without triggering rerenders
  const callbackRef = useRef(action);
  useLayoutEffect(() => {
    callbackRef.current = action;
  });

  // Event listener to trigger the action
  const handleKeyPress = useMemo(() => {
    const splitKeys = keys.split(",");
    const isCurrentHotkey = isHotkey(splitKeys);

    return (event: KeyboardEvent): void => {
      if (isCurrentHotkey(event) && shouldRunHotkeyAction(event)) {
        // Stop the event from bubbling up and inputting the key into any inputs that may become visible as a result of hotkey press
        event.stopPropagation();
        event.preventDefault();

        setActionUsed(keys);
        callbackRef.current?.();
      }
    };
  }, [keys, setActionUsed]);

  // Track active hotkeys to display in hotkey menu
  useEffect(() => {
    if (!isHotkeysEnabled) {
      return;
    }

    if (isDuplicateHotkey) {
      if (process.env.NODE_ENV === "development") {
        throw new Error(`Hotkey ${keys} is already in use. This hotkey will be ignored in production.`);
      } else {
        console.warn(`Hotkey ${keys} is already in use. This hotkey will be ignored.`);
      }

      return;
    }

    addHotkey([keys, description]);

    return (): void => {
      removeHotkey(keys);
    };

    // We don't want to retrigger on isDuplicateHotkey
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys, isHotkeysEnabled, description, addHotkey, removeHotkey]);

  // Add / clean up the event listener
  useEffect(() => {
    if (!isHotkeysEnabled || !action) {
      return;
    }

    // const fn = handleKeyPress.bind(null);
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return (): void => document.removeEventListener("keydown", handleKeyPress);
  }, [action, handleKeyPress, isHotkeysEnabled]);

  return useWrappedAction(keys, description, action);
};

const useIsDuplicateHotkey = (keys: string, description: string): boolean => {
  const hotkeys = useAtomValue(hotkeysAtom);

  return hotkeys.get(keys) === description;
};

const preventTagNames = ["INPUT", "TEXTAREA", "SELECT"];
const shouldRunHotkeyAction = (event: KeyboardEvent): boolean => {
  // Prevent hotkeys from running when the user is typing into input style elements
  const target = event.target as HTMLElement;
  const okayTagName = !preventTagNames.includes(target?.tagName);

  // Prevent hotkeys from running when the user is typing into contenteditable elements
  // This includes rich text editors like TipTap
  const isEditable = target?.isContentEditable;

  return okayTagName && !isEditable;
};

// Wraps the action in a function that will show a reminder toast notification once per session when the function is run to let the user know about the hotkey.
const useWrappedAction = (keys: string, description: string, action?: Action): Action => {
  const isHotkeysEnabled = useFeatureFlag(FeatureFlag.Hotkeys);

  const isActionUsed = useAtomValue(actionRemindersAtom);
  const setReminderSent = useSetAtom(setReminderSentAtom);

  return (): void => {
    if (isHotkeysEnabled && !isActionUsed.get(keys)) {
      showPendingToast(`Psssst! You can press ${keys} to ${description}. Press / to see all hotkeys`);
      setReminderSent(keys);
    }

    action?.();
  };
};
