/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { AutocompleteRenderGetTagProps, Checkbox, Chip, TextField } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import React, { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { useField, useForm } from "react-final-form";

import { Spacer } from "components/Spacer";
import { useGetUserInterviewerSchedulingInfoQuery } from "services/doverapi/endpoints/proUser";
import { Calendar } from "services/openapi/models/Calendar";
import { B1_doNotUse } from "styles/typography";
import { RequiredAsterisk, StyledAutocomplete } from "views/CompanySetup/components/InterviewPreferences/styles";

export const ProtectedCalendars = ({ setIsDirty }: { setIsDirty: (value: boolean) => void }): React.ReactElement => {
  const form = useForm();
  const protectedCalendarsField = useField<string[]>("busyGcalIds");

  const { data: schedulingInfo, isSuccess } = useGetUserInterviewerSchedulingInfoQuery();

  const [busyCalendars, setBusyCalendars] = useState<Calendar[]>([]);

  useEffect(() => {
    if (isSuccess && schedulingInfo) {
      const initialBusyCalendars =
        schedulingInfo?.allCalendars?.filter(calendar => {
          return schedulingInfo.busyGcalIds!.includes(calendar.id);
        }) || [];
      setBusyCalendars(initialBusyCalendars);
    }
  }, [isSuccess, schedulingInfo]);

  useEffect(() => {
    handleUpdateBusyCalendars();
  }, [busyCalendars]);

  const handleUpdateBusyCalendars = (): void => {
    form.change(
      protectedCalendarsField.input.name,
      busyCalendars.map(c => c.id)
    );
  };

  return (
    <div>
      <B1_doNotUse medium>
        Select which calendars Dover should check to avoid double booking. (Make sure to select your
        &quot;Holidays&quot; calendar!)
        <RequiredAsterisk />
      </B1_doNotUse>
      <Spacer height="16px" />
      <StyledAutocomplete
        multiple
        value={busyCalendars}
        disableCloseOnSelect
        options={schedulingInfo?.allCalendars || []}
        renderInput={(params: any): React.ReactElement => <TextField variant={"standard"} {...params} fullWidth />}
        getOptionLabel={(option: unknown): string => (option as Calendar).name}
        // @ts-ignore
        getOptionDisabled={(option: unknown): boolean => (option as Calendar).disableToggle}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: unknown): React.ReactElement => (
          <li {...props} key={(option as Calendar).name}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={busyCalendars.some(cal => cal.id == (option as Calendar).id)}
            />
            {(option as Calendar).name}
          </li>
        )}
        renderTags={(tagValue: unknown[], getTagProps: AutocompleteRenderGetTagProps): React.ReactElement =>
          // @ts-ignore
          tagValue.map((option, index) => (
            <Chip
              label={(option as Calendar).name}
              {...getTagProps({ index })}
              disabled={(option as Calendar).disableToggle}
            />
          ))
        }
        onChange={(e: SyntheticEvent<Element | Event>, value: unknown): void => {
          setIsDirty(true);
          // @ts-ignore
          setBusyCalendars(uniqBy(value, "id"));
        }}
        onBlur={(): void => {
          handleUpdateBusyCalendars();
        }}
      />
    </div>
  );
};
