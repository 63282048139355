export enum CandidateActionModalType {
  NeedsResponse = "NeedsResponse",
  Schedule = "Schedule",
  Reschedule = "Reschedule",
  Reply = "Reply",
  Reject = "Reject",
  BulkReject = "BulkReject",
  PromptForEmailAuth = "PromptForEmailAuth",
  Snooze = "Snooze",
}
