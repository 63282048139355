import { Skeleton, TableCell } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isAfter } from "date-fns";
import React from "react";

import { CandidateActionButtons } from "components/CandidateActionButtons/CandidateActionButtons";
import { BodySmall } from "components/library/typography";
import { OutreachActionButtons } from "components/OutreachActionButtons";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { PipelineCandidate, CandidateFilterSourcingContextEnum, NextActionSchedulingStateEnum } from "services/openapi";
import { colors } from "styles/theme";
import { hasReceivedAllOutreach, isContactedStage, isPreResponseStage } from "utils/isStage";
import { InterviewCompletedByDoverInterviewerButtons } from "views/CandidateDetail/components/next-action/states/InterviewCompletedByDoverInterviewer";
import { InterviewScheduledButtons } from "views/CandidateDetail/components/next-action/states/InterviewScheduled";
import { NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";
import { SampleCandidateResetButton } from "views/candidates/CandidateTable/components/SampleCandidateResetButton";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { useParams } from "views/candidates/hooks";

const getActionButtonsComponent = ({
  candidateBio,
}: NextActionStateComponentProps): {
  stopPropagation: boolean;
  component: React.ReactElement;
} => {
  const schedulingState = candidateBio?.nextAction?.schedulingState;
  const hasDoverInterviewer = candidateBio?.nextAction?.currentInterview?.hasDoverInterviewer;
  const hasNotesSubmitted = hasDoverInterviewer && candidateBio?.nextAction?.currentInterview?.hasCompletedNotes;
  const isLastInterview = candidateBio?.nextAction?.isLastInterview;

  const noActionAvailable = {
    stopPropagation: false,
    component: (
      <Cell>
        <BodySmall italic color={colors.grayscale.gray400} style={{ whiteSpace: "nowrap" }}>
          No action available
        </BodySmall>
      </Cell>
    ),
  };

  /** No Action Available Cases:
   *  1. Candidate is not Active
   */
  if (candidateBio?.status) {
    return noActionAvailable;
  }

  const fullName = candidateBio?.contact?.fullName;
  const isSampleCandidate = fullName == "Max Kolysh";
  if (isSampleCandidate) {
    return {
      stopPropagation: true,
      component: <SampleCandidateResetButton jobId={candidateBio?.job} />,
    };
  }

  switch (schedulingState) {
    case NextActionSchedulingStateEnum.InitialReview: {
      return {
        stopPropagation: true,
        component: <CandidateActionButtons candidateBio={candidateBio} inPipeline />,
      };
    }
    case NextActionSchedulingStateEnum.CallCompleted: {
      if (hasDoverInterviewer) {
        if (hasNotesSubmitted && candidateBio.nextAction?.currentInterview?.id) {
          return {
            stopPropagation: true,
            component: (
              <InterviewCompletedByDoverInterviewerButtons
                candidateBio={candidateBio}
                interviewId={candidateBio.nextAction.currentInterview.id!}
              />
            ),
          };
        }
        return noActionAvailable;
      }
      if (isLastInterview) {
        return noActionAvailable;
      }
      return {
        stopPropagation: true,
        component: <CandidateActionButtons candidateBio={candidateBio} inPipeline />,
      };
    }

    case NextActionSchedulingStateEnum.Scheduled:
      return {
        stopPropagation: true,
        component: <InterviewScheduledButtons candidateBio={candidateBio} inPipeline />,
      };
  }

  return noActionAvailable;
};

interface ActionCellProps {
  candidate?: PipelineCandidate;
}

export const ActionCell: React.FC<React.PropsWithChildren<ActionCellProps>> = ({ candidate }) => {
  const { data: bio, isLoading: bioLoading } = useGetCandidateBioQuery(candidate ? candidate.id! : skipToken);

  if (candidate?.candidatePipelineStage && isPreResponseStage(candidate.candidatePipelineStage)) {
    return <OutreachActionCell candidate={candidate} />;
  }

  if (bioLoading) {
    return <Skeleton width="300px" height="60px" style={{ margin: "0 0 0 16px" }} />;
  }

  if (bio) {
    const { stopPropagation, component: actionButtons } = getActionButtonsComponent({
      candidateBio: bio,
    });
    return (
      <TableCell
        onMouseOver={stopPropagation ? handleDisableMouseEventPropagation : undefined}
        onClick={stopPropagation ? handleDisableMouseEventPropagation : undefined}
        style={{ borderBottom: "none" }}
      >
        {actionButtons}
      </TableCell>
    );
  }

  return (
    <Cell>
      <BodySmall italic color={colors.grayscale.gray400}>
        No action available
      </BodySmall>
    </Cell>
  );
};

interface OutreachActionCellProps {
  candidate?: PipelineCandidate;
}

const OutreachActionCell: React.FC<React.PropsWithChildren<OutreachActionCellProps>> = ({ candidate }) => {
  const [{ jobId, sourcingContext }] = useParams();
  const { data: jobSetup } = useGetJobSetupQuery(jobId ? jobId : skipToken);

  const jobPaused = !!jobSetup?.active && !!jobSetup?.snoozedUntil && isAfter(jobSetup.snoozedUntil, new Date());

  if (
    jobPaused &&
    !candidate?.slimNextCampaignMessageRequest?.allowFollowUpsPostDeactivation &&
    candidate?.candidatePipelineStage &&
    isContactedStage(candidate.candidatePipelineStage)
  ) {
    return (
      <Cell>
        <BodySmall italic color={colors.grayscale.gray400}>
          No action available - Autopilot paused
        </BodySmall>
      </Cell>
    );
  }

  /**
   * Don't display Action Buttons if:
   * 1. All outreach messages have been sent
   * 2. No next Campaign Message Request exists for the candidate
   * 3. The candidate is not sourced through Outbound
   */
  if (
    (candidate?.candidatePipelineStage && hasReceivedAllOutreach(candidate?.candidatePipelineStage)) ||
    !candidate?.slimNextCampaignMessageRequest ||
    (sourcingContext.length && !sourcingContext?.includes(CandidateFilterSourcingContextEnum.Outbound))
  ) {
    return (
      <Cell>
        <BodySmall italic color={colors.grayscale.gray400}>
          No action available
        </BodySmall>
      </Cell>
    );
  }

  return (
    <TableCell
      onMouseOver={handleDisableMouseEventPropagation}
      onClick={handleDisableMouseEventPropagation}
      style={{ borderBottom: "none" }}
    >
      <OutreachActionButtons candidate={candidate} />
    </TableCell>
  );
};

const handleDisableMouseEventPropagation = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>): void => {
  e.stopPropagation();
};
