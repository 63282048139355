import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Box, Stack } from "@mui/material";
import React, { FC, PropsWithChildren, ReactNode } from "react";

import { HiringPipelineStage } from "services/openapi";
import { colors } from "styles/theme";
import { isStage } from "utils/isStage";
import { AddStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/AddStage";
import { DisableStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/DisableStage";
import { MultipartRow } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/Multipart";
import { OfferStageRow } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/OfferStageRow";
import { PendingStageRow } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/PendingStageRow";
import { SinglePartRow } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/SinglePart";
import { StageDragHandle } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";
import { useCanStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCanStage";

// Sets up the droppable context for all the stages to sit in
export const StageRowsContainer: FC<React.PropsWithChildren<PropsWithChildren<{}>>> = ({ children }) => {
  return (
    <Droppable droppableId="stage-rows">
      {({ droppableProps, placeholder, innerRef }): ReactNode => (
        <Stack sx={{ paddingTop: "0px" }} gap={1} py={2} ref={innerRef} {...droppableProps}>
          {children}
          {placeholder}
        </Stack>
      )}
    </Droppable>
  );
};

interface StageRowProps {
  stage: HiringPipelineStage;
  index: number;
  // The naming of this is hard, but unsure of how to make better reasonably
  // This means that any row is being dragged currently, not specifically this one
  anyRowIsDragging: boolean;
  onEdit: (stage: HiringPipelineStage) => void;
}

// Composes the whole stage row (multipart, singlepart, and offer) and adds the draggable context
// Includes the flourishes outside the stage card like the drag handle and delete buttons
export const StageRow: FC<React.PropsWithChildren<StageRowProps>> = ({ stage, index, anyRowIsDragging, onEdit }) => {
  const { isOffer } = isStage(stage);

  const { canEdit, canMove, canDisable, canAdd } = useCanStage(stage);
  const click = (): void => {
    if (canEdit.can) {
      onEdit(stage);
    }
  };

  return (
    <Draggable draggableId={stage.id!} index={index} key={stage.id!}>
      {({ draggableProps, dragHandleProps, innerRef }): ReactNode => (
        <Stack
          spacing={0.5}
          {...draggableProps}
          sx={{
            "&:hover": {
              "& .hide-until-hover": {
                visibility: "visible",
              },
            },
          }}
        >
          {/* We include add stage here as part of the draggable because otherwise it really screws
            with the calculations the drag n drop library tries to do.  Not the ideal solution though. */}
          <AddStage orderIndex={stage.orderIndex} isDragging={anyRowIsDragging} canAdd={canAdd} />
          <Stack key={stage.id!} direction="row" spacing={1} alignItems="center" ref={innerRef}>
            <Box flexBasis="32px">
              <StageDragHandle dragHandleProps={dragHandleProps} disabled={anyRowIsDragging || !canMove.can} />
            </Box>
            <Box flex={1}>
              <StageCard disabled={!canEdit.can || anyRowIsDragging} onClick={isOffer ? undefined : click}>
                <Stage stage={stage} />
              </StageCard>
            </Box>
            <Box flexBasis="32px">
              <DisableStage id={stage.id} canDisable={canDisable} />
            </Box>
          </Stack>
        </Stack>
      )}
    </Draggable>
  );
};

interface StageCardProps {
  disabled?: boolean;
  onClick?: () => void;
}

export const StageCard: FC<React.PropsWithChildren<PropsWithChildren<StageCardProps>>> = ({
  disabled,
  onClick,
  children,
}) => {
  return (
    <Stack
      onClick={onClick}
      direction="row"
      width="100%"
      padding={2}
      bgcolor={colors.white}
      border={`1px solid ${colors.grayscale.gray200}`}
      borderRadius={2}
      boxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.25)"
      sx={{
        cursor: disabled ? undefined : "pointer",
        ":hover": {
          backgroundColor: disabled ? undefined : colors.grayscale.gray100,
        },
      }}
    >
      {children}
    </Stack>
  );
};

interface StageProps {
  stage: HiringPipelineStage;
}

// Mostly just a wrapper to determine which type of stage it is and then display the correct component
const Stage: FC<React.PropsWithChildren<StageProps>> = ({ stage }) => {
  // If the stage id is an empty string then that means this stage was made as part of
  // an optimistic update and is still being created on the backend
  if (!stage.id) {
    return <PendingStageRow stage={stage} />;
  }

  const { isMultipart, isOffer } = isStage(stage);

  if (isMultipart) {
    return <MultipartRow stage={stage} />;
  }

  if (isOffer) {
    return <OfferStageRow stage={stage} />;
  }

  return <SinglePartRow stage={stage} />;
};
