/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCandidateBioBody
 */
export interface UpdateCandidateBioBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateBioBody
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateBioBody
     */
    currentPipelineStage?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCandidateBioBody
     */
    currentPipelineSubstage?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateBioBody
     */
    status?: UpdateCandidateBioBodyStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof UpdateCandidateBioBody
     */
    snoozedUntil?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateBioBody
     */
    schedulingOwnership?: UpdateCandidateBioBodySchedulingOwnershipEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateCandidateBioBodyStatusEnum {
    Active = 'ACTIVE',
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum UpdateCandidateBioBodySchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}

export function UpdateCandidateBioBodyFromJSON(json: any): UpdateCandidateBioBody {
    return UpdateCandidateBioBodyFromJSONTyped(json, false);
}

export function UpdateCandidateBioBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCandidateBioBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currentPipelineStage': !exists(json, 'current_pipeline_stage') ? undefined : json['current_pipeline_stage'],
        'currentPipelineSubstage': !exists(json, 'current_pipeline_substage') ? undefined : json['current_pipeline_substage'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'snoozedUntil': !exists(json, 'snoozed_until') ? undefined : (json['snoozed_until'] === null ? null : new Date(json['snoozed_until'])),
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
    };
}

export function UpdateCandidateBioBodyToJSON(value?: UpdateCandidateBioBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_pipeline_stage': value.currentPipelineStage,
        'current_pipeline_substage': value.currentPipelineSubstage,
        'status': value.status,
        'snoozed_until': value.snoozedUntil === undefined ? undefined : (value.snoozedUntil === null ? null : value.snoozedUntil.toISOString()),
        'scheduling_ownership': value.schedulingOwnership,
    };
}


