import Stack from "@mui/material/Stack";
import React, { useCallback } from "react";

import { ReactComponent as AlarmSnoozeIcon } from "assets/icons/alarm-snooze.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { colors } from "styles/theme";

export function SnoozeButton({
  candidateId,
  variant = ButtonVariant.Secondary,
  disabled = false,
  disabledTooltip,
}: {
  candidateId: string;
  variant?: ButtonVariant;
  disabled?: boolean;
  disabledTooltip?: string;
}): React.ReactElement {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const clickSnooze = useCallback(() => {
    showCandidateActionModal({
      candidateId: candidateId,
      modalType: CandidateActionModalType.Snooze,
    });
  }, [showCandidateActionModal, candidateId]);

  const tooltipMessage =
    disabled && disabledTooltip ? disabledTooltip : "You can select this option to snooze a candidate for a set time.";

  return (
    <Stack
      spacing={0}
      sx={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Button
        variant={variant}
        onClick={clickSnooze}
        disabled={disabled}
        tooltip={tooltipMessage}
        padding="0px 8px"
        height="42px"
      >
        <AlarmSnoozeIcon height="20px" width="20px" color={colors.muiChipGray} />
      </Button>
    </Stack>
  );
}
