/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { Progress } from "@doverhq/dover-ui";
import { Stack, Box, Alert } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { BodySmall } from "components/library/typography";
import { useListUserCredentialsQuery } from "services/doverapi/endpoints/userCredential";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { UserCredential } from "services/openapi";
import { colors } from "styles/theme";
import SourcesList from "views/CompanySetup/components/Credentials/components/SourcesList";
import { SUPPORTED_SOURCES } from "views/CompanySetup/components/Credentials/constants";
import { CredentialsContainer } from "views/CompanySetup/components/Credentials/Styles";
import { Source } from "views/CompanySetup/components/Credentials/types";
import WaaSPageWrapper from "views/CompanySetup/components/Credentials/WaaSPageWrapper";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const Credentials = (): React.ReactElement => {
  const [selectedSource, setSource] = useQueryParam("source", StringParam);

  const { userCredentialsBySource, isLoading: loadingUserCredentials, error: loadError } = useListUserCredentialsQuery(
    undefined,
    {
      selectFromResult: ({ isLoading, error, data }) => {
        const allUserCredentials = listAllEntities(data);
        const userCredentialsList: UserCredential[] = SUPPORTED_SOURCES.map((source: Source) => {
          return allUserCredentials.find((c: UserCredential) => c.source === source);
        }).filter((r): r is UserCredential => !!r);

        const userCredentialsBySource: { [key in Source]?: UserCredential } = userCredentialsList.reduce(function(
          map,
          obj
        ) {
          // @ts-ignore
          map[obj.source] = obj;
          return map;
        },
        {} as { [key in Source]: UserCredential });
        return {
          isLoading,
          error,
          userCredentialsBySource: userCredentialsBySource,
        };
      },
    }
  );

  useEffect(() => {
    // @ts-ignore
    if (selectedSource && !SUPPORTED_SOURCES.includes(selectedSource)) {
      setSource(undefined);
      return;
    }
  }, [selectedSource, setSource]);

  if (loadingUserCredentials) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (loadError) {
    return (
      <CredentialsContainer>
        <Alert severity="error">Failed to load data</Alert>
      </CredentialsContainer>
    );
  }

  if (!selectedSource) {
    const userCredentials = SUPPORTED_SOURCES.map(s => {
      return userCredentialsBySource[s] || { source: s, state: undefined, username: undefined };
    });

    return (
      <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.INTEGRATIONS]}>
        <Box style={{ paddingTop: "8px" }}>
          <BodySmall>
            Interested in building your own integration or in setting up Zapier?{" "}
            <Link to={`/settings/company/${CompanySetupSectionType.API_KEY}`}>Check out this section.</Link>
          </BodySmall>
        </Box>
        <Box style={{ paddingTop: "4px" }}>
          <a
            href={"https://help.dover.com/en/articles/6237073-job-board-manager"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <HelpIcon color={colors.link} className="svg-color" />
              <BodySmall color={colors.link}>Learn More</BodySmall>
            </Stack>
          </a>
          <CredentialsContainer>
            <SourcesList sources={userCredentials} onSourceClick={setSource} />
          </CredentialsContainer>
        </Box>
      </SectionWrapper>
    );
  }

  if (selectedSource === "YC_WAAS") {
    return <WaaSPageWrapper />;
  }
  return <div></div>;
};

export default Credentials;
