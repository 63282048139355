import { Box, Stack } from "@mui/material";
import React, { FC } from "react";

import { SideBarNavItem } from "App/components/Nav/SideBarNavItemV2";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Caption } from "components/library/typography";
import { StarCandidateButton } from "components/StarCandidateButton";
import { useListStarredCandidatesQuery } from "services/doverapi/endpoints/starredCandidate";
import { StarredCandidate } from "services/openapi";
import { colors } from "styles/theme";

interface StarredCandidateNavItemProps {
  candidate: StarredCandidate;
}

// This component assumes that the candidate you pass it is starred
const StarredCandidateNavItem: FC<React.PropsWithChildren<StarredCandidateNavItemProps>> = ({ candidate }) => {
  // This should never really happen, but open api types are not perfect
  if (!candidate.candidateId) {
    return null;
  }

  const candidatePath = APP_ROUTE_PATHS.job.candidates.candidateDetail(candidate.jobId, candidate.candidateId);

  const candidateName = candidate.fullName;

  return (
    <Box
      width="100%"
      // To hide the star button until hover
      sx={{
        "& .hover-star-candidate": {
          visibility: "hidden",
        },
        "&:hover .hover-star-candidate": {
          visibility: "visible",
        },
      }}
    >
      <SideBarNavItem
        to={candidatePath}
        label={candidateName}
        endAdornment={
          <StarCandidateButton
            removePadding
            hideTooltip
            size="small"
            candidate={{
              id: candidate.candidateId,
              jobId: candidate.jobId,
              fullName: candidate.fullName,
              isStarred: true,
            }}
          />
        }
      />
    </Box>
  );
};

/**
 * Shows list of starred candidates in the sidebar that when click link to the candidate detail modal
 */
export const StarredCandidatesNavList: FC<React.PropsWithChildren<unknown>> = () => {
  const { data: starredCandidates } = useListStarredCandidatesQuery({});

  if (!starredCandidates) {
    return null;
  }

  return (
    <Stack height="100%" spacing={0.5}>
      <Caption
        color={colors.black}
        style={{
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        Starred
      </Caption>
      <Stack height="100%" overflow="auto">
        {starredCandidates.map(candidate => (
          <StarredCandidateNavItem key={candidate.id} candidate={candidate} />
        ))}
      </Stack>
    </Stack>
  );
};
