import Alert from "@mui/material/Alert";
import styled, { css, RuleSet } from "styled-components";

import { colors } from "styles/theme";

interface StyledLogoProps {
  isPreviewThumbnail?: boolean;
}

const previewThumbnailCss = css`
  border-radius: 6px;
  width: 100%;
  border: 1px solid ${colors.grayscale.gray300};
`;

export const StyledLogo = styled.img<StyledLogoProps>`
  height: 100px;
  object-fit: contain;
  ${({ isPreviewThumbnail }: StyledLogoProps): RuleSet<object> | undefined =>
    isPreviewThumbnail ? previewThumbnailCss : undefined};
`;

export const StyledEditInput = styled.input`
  display: none;
`;

export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    border: 1px solid #ffa0a0;
    background-color: #ffe4e4;
    color: ${colors.black};
    font-family: Nunito Sans;
    font-size: 14px;
    margin-bottom: 8px;
  }
`;
