import React from "react";
import { DropzoneOptions } from "react-dropzone";
import { Field } from "react-final-form";

import { Dropzone } from "components/inputs/FileUpload/Dropzone";

interface FileUploadFieldProps {
  name: string;
  dropzoneOptions?: DropzoneOptions;
  placeholder?: string | React.ReactNode;
  placeholderWhenDragActive?: string | React.ReactNode;
  acceptFileTypes?: Array<string> | string;
  errorKey?: string;
}

const FileUploadField: React.FC<React.PropsWithChildren<FileUploadFieldProps>> = ({
  name,
  dropzoneOptions,
  placeholder,
  placeholderWhenDragActive,
  acceptFileTypes,
  errorKey,
}) => {
  return (
    <Field name={name}>
      {({ input }): React.ReactNode => (
        <div>
          <Dropzone
            input={input}
            options={dropzoneOptions}
            placeholder={placeholder}
            placeholderWhenDragActive={placeholderWhenDragActive}
            acceptFileTypes={acceptFileTypes}
            errorKey={errorKey}
          />
        </div>
      )}
    </Field>
  );
};

export default FileUploadField;
