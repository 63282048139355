import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as PencilIcon } from "@doverhq/dover-ui/icons/pencil.svg";
import React, { FC } from "react";

import { HiringPipelineStage } from "services/openapi";

interface EditStageProps {
  stage: HiringPipelineStage;
}

export const EditStage: FC<React.PropsWithChildren<EditStageProps>> = () => {
  return <Icon Icon={PencilIcon} color="gray-500" />;
};
