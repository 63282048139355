import { Button } from "@doverhq/dover-ui";
import { ReactComponent as ChevronDownIcon } from "@doverhq/dover-ui/icons/chevron-down.svg";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC } from "react";
import { useBoolean } from "react-use";

import { Subtitle2 } from "components/library/typography";
import { MoveJobButton } from "components/MoveJobButton";
import { SAMPLE_CANDS_DISABLED_TOOLTIP, ScheduleRejectButtons } from "components/ScheduleRejectButtons";
import {
  CandidateBio,
  CandidateBioSchedulingOwnershipEnum,
  CandidateBioStatusEnum,
  SaapReviewApplication,
} from "services/openapi";
import { NextActionCard } from "views/CandidateDetail/components/next-action/NextActionCard";
import { StageSelect } from "views/CandidateDetail/components/StageAndStatusCard/StageSelect";
import { StatusSelect } from "views/CandidateDetail/components/StageAndStatusCard/StatusSelect";
import { Timeline } from "views/CandidateDetail/components/Timeline";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

interface ActionSectionProps {
  application: SaapReviewApplication;
  candidateBio: CandidateBio;
}

export const ActionSection: FC<React.PropsWithChildren<ActionSectionProps>> = ({ application, candidateBio }) => {
  const dtnMode = useAtomValue(isDtnMode);
  const [isExpanded, toggleExpanded] = useBoolean(false);

  const doverHandlesScheduling =
    !dtnMode && candidateBio?.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling;

  const disabled = candidateBio.isDemoInboundCand;

  return (
    <>
      {doverHandlesScheduling ? (
        <NextActionCard application={application} candidateBio={candidateBio} showBookmarkButton />
      ) : (
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5} mr="auto">
            <ScheduleRejectButtons
              application={application}
              candidateId={candidateBio.id}
              showBookmarkButton={!dtnMode}
              disabledSchedule={disabled}
              disabledTooltip={SAMPLE_CANDS_DISABLED_TOOLTIP}
            />
            {!dtnMode && (
              <Button
                variant="ghost"
                p={1}
                icon={{ Icon: ChevronDownIcon, side: "right" }}
                isDisabled={disabled}
                onPress={toggleExpanded}
              >
                {isExpanded ? "Less" : "More"}
              </Button>
            )}
          </Stack>
          {isExpanded && <StageAndStatus candidateBio={candidateBio} />}
        </Stack>
      )}
    </>
  );
};

interface StageAndStatusProps {
  candidateBio: CandidateBio;
}

const StageAndStatus: React.FC<React.PropsWithChildren<StageAndStatusProps>> = ({ candidateBio }) => {
  return (
    <Stack spacing={1} maxWidth="300px">
      <MoveJobButton candidateBio={candidateBio} />
      <Stack spacing={0.5}>
        <Subtitle2>Stage</Subtitle2>
        {candidateBio.id && <StageSelect candidateId={candidateBio.id} />}
        {candidateBio.status !== CandidateBioStatusEnum.Hired && <Timeline candidateBio={candidateBio} />}
      </Stack>
      <Stack spacing={0.5}>
        <Subtitle2>Status</Subtitle2>
        <StatusSelect candidateBio={candidateBio} />
      </Stack>
    </Stack>
  );
};
