import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as AlertTriangleIcon } from "@doverhq/dover-ui/icons/alert-triangle.svg";
import { ReactComponent as RefreshIcon } from "@doverhq/dover-ui/icons/refresh.svg";
import { Stack, Skeleton, Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as PencilEditSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as AshbyLogo } from "assets/logos/ashby.svg";
import { ReactComponent as GreenhouseLogo } from "assets/logos/greenhouse.svg";
import { ReactComponent as LeverLogo } from "assets/logos/lever.svg";
import DuplicateJobButton from "components/DuplicateJobButton";
import GoalHireDateButton from "components/GoalHireDateButton";
import { TextField } from "components/library/TextField";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle1 } from "components/library/typography";
import { PersonaCoin, UserAvatarCoin } from "components/PersonaCoin";
import { Role, useContainsRole } from "components/RBAC";
import SocialShareButton from "components/SocialShareButton";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobSetupQuery, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job/endpoints";
import { ClientOnboardingAtsTypeEnum, JobSetup } from "services/openapi";
import { colors } from "styles/theme";
import { SampleChip } from "views/candidates/ApplicationReview/components/SampleChip";
import { ChangeJobRolesModal } from "views/job/components/ChangeJobRolesModal";
import { JobPrivateSelect } from "views/job/components/JobPrivateSelect";
import { JobStatusSelect } from "views/job/components/JobStatusSelect";
import { JobViewNavOptions } from "views/job/components/JobViewNavOptions";
import { ScrollArrow } from "views/job/components/ScrollArrow";
import { JOB_NAV_HEADER_Z_INDEX } from "views/job/constants";

/* -----------------------------------------------------------------------------
 * AtsSyncStatus
 * -------------------------------------------------------------------------- */

interface AtsSyncStatusProps {
  job: JobSetup;
}

const AtsSyncStatus = ({ job }: AtsSyncStatusProps): React.ReactElement => {
  const navigate = useNavigate();
  const { data: client, isLoading: isClientLoading } = useGetClientOnboardingQuery();

  if (
    isClientLoading ||
    ![
      ClientOnboardingAtsTypeEnum.Greenhouse,
      ClientOnboardingAtsTypeEnum.Lever,
      ClientOnboardingAtsTypeEnum.Ashby,
    ].includes(client?.atsType as ClientOnboardingAtsTypeEnum)
  ) {
    return <></>;
  }

  if (job.atsJobId) {
    return (
      <Tooltip title={job.atsJobTitle ? `Synced to ${job.atsJobTitle}` : "Synced to ATS"} placement="top">
        <Stack
          direction="row"
          spacing={0.5}
          p={1}
          alignItems="center"
          border={`1px solid ${colors.grayscale.gray200}`}
          borderRadius="6px"
          sx={{ ":hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" } }}
          onClick={(): void => {
            navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.ATS_SETTINGS));
          }}
        >
          <Icon Icon={RefreshIcon} color="primary-base" />
          <BodySmall color={colors.grayscale.gray600}>Synced</BodySmall>
          {client?.atsType === ClientOnboardingAtsTypeEnum.Ashby && <AshbyLogo />}
          {client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse && (
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={colors.primary.base}
              borderRadius="6px"
              width="22px"
              height="22px"
            >
              <GreenhouseLogo />
            </Stack>
          )}
          {client?.atsType === ClientOnboardingAtsTypeEnum.Lever && <LeverLogo />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
      p={1}
      alignItems="center"
      border={`1px solid ${colors.grayscale.gray200}`}
      borderRadius="6px"
      bgcolor={colors.warning.light}
      sx={{ cursor: "pointer" }}
      onClick={(): void => {
        navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.ATS_SETTINGS));
      }}
    >
      <Icon Icon={AlertTriangleIcon} color="warning-base" />
      <BodySmall color={colors.grayscale.gray600}>Not synced</BodySmall>
      {client?.atsType === ClientOnboardingAtsTypeEnum.Ashby && <AshbyLogo />}
      {client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse && (
        // Greenhouse logo on its own is just a white "g" and needs a background to be visible
        <Stack justifyContent="center" alignItems="center" bgcolor={colors.primary.base} borderRadius="6px" p={0.25}>
          <GreenhouseLogo />
        </Stack>
      )}
      {client?.atsType === ClientOnboardingAtsTypeEnum.Lever && <LeverLogo />}
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * JobViewHeader
 * -------------------------------------------------------------------------- */

export const JobViewHeader = (): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string | undefined }>();
  const { data: jobSetup, isFetching: isJobSetupFetching } = useGetJobSetupQuery(jobId ?? skipToken);
  const [updateJobSetup, { isLoading: jobSetupIsUpdating }] = useUpdateJobSetupMutation();

  const canEditHiringTeam = useContainsRole(
    [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER],
    jobId
  );

  const showDuplicateJobButton = useFeatureFlag(FeatureFlag.DuplicateJob);

  const [editingJobTitle, setEditingJobTitle] = React.useState<boolean>(false);
  const [modifiedJobTitle, setModifiedJobTitle] = React.useState<string | undefined>(jobSetup?.title || "");

  const [userModalOpen, setUserModalOpen] = React.useState(false);

  // Track the scrollable content by ref so we can perform operations on it
  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | null>(null);
  // When the scroll buttons are clicked, we perform a scroll operation on the scrollable content
  const scrollLeft = (): void => scrollableRef?.scrollBy({ left: -150, top: 0, behavior: "smooth" });
  const scrollRight = (): void => scrollableRef?.scrollBy({ left: 150, top: 0, behavior: "smooth" });

  // Track whether or not the scroll buttons should be visible
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // When the scrollable content ref is mounted, we'll set it in state so we can perform operations on it
  const onScrollableRefMount = useCallback((node: HTMLDivElement): void => {
    setScrollableRef(node);
  }, []);

  const onJobTitleTextChange = React.useCallback((text: string) => {
    setModifiedJobTitle(text);
  }, []);

  const handleStartEditing = React.useCallback(() => {
    setEditingJobTitle(true);
  }, []);

  const handleStopEditing = React.useCallback(() => {
    setEditingJobTitle(false);
    if (jobId && modifiedJobTitle && modifiedJobTitle !== jobSetup?.title) {
      updateJobSetup({ id: jobId, title: modifiedJobTitle });
    }
  }, [modifiedJobTitle, jobId, jobSetup?.title, updateJobSetup]);

  const editableJobName = React.useMemo(() => {
    if (editingJobTitle) {
      return (
        <Box width="300px">
          <TextField
            text={modifiedJobTitle || ""}
            autoFocus={true}
            onBlur={handleStopEditing}
            handleEnter={handleStopEditing}
            onTextUpdated={onJobTitleTextChange}
            fullWidth
            disabled={jobSetupIsUpdating}
          />
        </Box>
      );
    }

    return <Subtitle1>{modifiedJobTitle}</Subtitle1>;
  }, [modifiedJobTitle, editingJobTitle, handleStopEditing, jobSetupIsUpdating, onJobTitleTextChange]);

  const HiringManagerRecruiterSection = React.useMemo(() => {
    const hm = jobSetup?.hiringManager;
    const recruiter = jobSetup?.recruiter;
    const hiringTeamCount = jobSetup?.hiringTeam?.length || 0;

    if (isJobSetupFetching) {
      return <Skeleton width="250px" height="35px" />;
    } else if (hm || recruiter) {
      return (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
          onClick={(): void => setUserModalOpen(canEditHiringTeam ? true : false)}
          sx={{ cursor: canEditHiringTeam ? "pointer" : undefined }}
        >
          {hm && <UserAvatarCoin user={hm} label={"Hiring Manager"} />}
          {recruiter && <UserAvatarCoin user={recruiter} label={"Recruiter"} />}
          {!!hiringTeamCount && (
            <Tooltip
              title={
                <>
                  <BodySmall weight="600" color="white">
                    Hiring Team
                  </BodySmall>
                  {jobSetup?.hiringTeam?.map(user => (
                    <BodySmall color="white">{user.name}</BodySmall>
                  ))}
                </>
              }
            >
              <div>
                <PersonaCoin firstName={"+"} lastName={hiringTeamCount.toString()} size="small" color="gray" />
              </div>
            </Tooltip>
          )}
          {canEditHiringTeam && <PencilEditSVG id="hm-recruiter-edit-icon" height="14px" width="14px" />}
        </Stack>
      );
    }
    return <></>;
  }, [isJobSetupFetching, jobSetup?.hiringManager, jobSetup?.recruiter, jobSetup?.hiringTeam, canEditHiringTeam]);

  // If job setup or its key data is not defined, we'll handle showing a loading state at the top level of the view
  // So no need to do anything here, and we can instead render nothign
  if (!jobSetup || !jobSetup?.title) {
    return <></>;
  }

  // Social share vars
  const clientName = jobSetup.clientName;
  const jobTitle = jobSetup.title;
  const jobPostingUrl = `https://app.dover.com/apply/${clientName}/${jobId}`;
  const socialPostText = `🚀 We’re hiring! Come work with me at ${clientName}. Check out our ${jobTitle} role here: ${jobPostingUrl}`;
  const emailSubject = `🚀 Join us at ${clientName} as a ${jobTitle}!`;
  const emailBody = `Hi there,

I’m excited to share that we’re hiring for a ${jobTitle} at ${clientName}! This is a fantastic opportunity for someone with your skills and experience to join our team.

Interested? Check out the job description and apply here: ${jobPostingUrl}

Looking forward to hearing from you!`;

  return (
    <>
      <Stack
        position="sticky"
        top="0"
        zIndex={JOB_NAV_HEADER_Z_INDEX}
        spacing={1}
        borderBottom={`1px solid ${colors.grayscale.gray200}`}
        sx={{
          backgroundColor: colors.white,
          paddingTop: "12px",
        }}
      >
        <Stack paddingLeft="24px" paddingRight="16px">
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack direction="row" alignItems={"center"}>
              <Tooltip
                title={
                  jobSetup.isSample
                    ? "Editing title of the sample job is not permitted. Create a new job to start hiring in Dover."
                    : undefined
                }
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  onClick={jobSetup.isSample ? undefined : handleStartEditing}
                  sx={{
                    ":hover": { cursor: jobSetup.isSample ? "default" : "pointer" },
                    "#job-title-edit-icon": { visibility: "hidden" },
                    ":hover #job-title-edit-icon": {
                      visibility: jobSetup.isSample || editingJobTitle ? "hidden" : "visible",
                    },
                  }}
                  paddingRight="4px"
                >
                  {editableJobName}
                  <PencilEditSVG id="job-title-edit-icon" height="14px" width="14px" />
                </Stack>
              </Tooltip>

              <Stack direction="row" alignItems="center" spacing={1.5}>
                {jobSetup?.isSample && (
                  <SampleChip tooltipText="We've created a sample job so you can experiment with Dover" />
                )}
                <JobPrivateSelect />
                <AtsSyncStatus job={jobSetup} />
                <JobStatusSelect />
                {HiringManagerRecruiterSection}
              </Stack>
            </Stack>
            {jobId && (
              <Stack direction="row" spacing={1}>
                <GoalHireDateButton jobId={jobId} />
                {showDuplicateJobButton && <DuplicateJobButton jobId={jobId} />}
                {jobSetup?.clientName && (
                  <SocialShareButton
                    url={jobPostingUrl}
                    socialPostText={socialPostText}
                    menuLabel="Share Job Posting"
                    emailSubject={emailSubject}
                    emailBody={emailBody}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <JobViewNavOptions
          showLeftScrollArrow={setShowLeftArrow}
          showRightScrollArrow={setShowRightArrow}
          onScrollableRefMount={onScrollableRefMount}
        />
        <ScrollArrow direction="left" visible={showLeftArrow} onClick={scrollLeft} />
        <ScrollArrow direction="right" visible={showRightArrow} onClick={scrollRight} />
      </Stack>
      <ChangeJobRolesModal open={userModalOpen} onClose={(): void => setUserModalOpen(false)} job={jobSetup} />
    </>
  );
};
