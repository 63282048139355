import { Button } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React from "react";

import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";

export const NewFeedbackTemplateButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { open: openFeedbackTemplateDrawer } = useModal(FeedbackTemplateDrawerAtom);

  return (
    <>
      <div>
        <Button variant="primaryOutlined" onPress={openFeedbackTemplateDrawer}>
          <Box sx={{ textWrap: "nowrap" }}>
            <BodySmall>+ New</BodySmall>
          </Box>
        </Button>
      </div>
    </>
  );
};
