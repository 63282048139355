import { Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import capitalize from "lodash/capitalize";
import React from "react";
import { ReactSVG } from "react-svg";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { getIcon } from "components/dover/CandidateStageSelect/utils";
import { BodySmall, Overline } from "components/library/typography";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListPipelineCandidatesQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { CandidateFilterSourcingContextEnum, DashboardJob, JobStage, PipelineCandidate } from "services/openapi";
import { colors } from "styles/theme";
import { ReferralsTableWrapper } from "views/referralsV2/styles";

const NameCell = (params: GridRenderCellParams): React.ReactElement => {
  const candidate = params.row as PipelineCandidate;
  const fullName = `${candidate.contact.fullName}`;

  return (
    <Stack direction="row" spacing={3} alignItems="center" justifyContent={"flex-start"} width="100%">
      <BodySmall weight="600">{fullName}</BodySmall>
    </Stack>
  );
};

const StageDisplay = ({ pipelineStageId }: { pipelineStageId: string }): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const { activeJobs: unsortedJobs } = useGetJobsDerivedData({});
  const job: DashboardJob | undefined = unsortedJobs?.find(job => job.id === jobId);

  const stage = job?.hiringPipelineStages?.find((stage: JobStage) => stage.id === pipelineStageId);
  const icon = stage && getIcon(stage);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Stack alignItems={"center"} justifyContent={"center"} pb={"3px"}>
        {icon && <ReactSVG src={icon} />}
      </Stack>
      <BodySmall ellipsis>{stage?.name}</BodySmall>
    </Stack>
  );
};

const CurrentStageCell = (params: GridRenderCellParams): React.ReactElement => {
  const candidate = params.row as PipelineCandidate;

  return (
    <Stack direction="row" spacing={3} alignItems="center" justifyContent={"flex-start"} width="100%">
      {candidate.candidatePipelineStage?.id && <StageDisplay pipelineStageId={candidate.candidatePipelineStage?.id} />}
    </Stack>
  );
};

const StatusCell = (params: GridRenderCellParams): React.ReactElement => {
  const candidate = params.row as PipelineCandidate;

  return (
    <Stack direction="row" spacing={3} alignItems="center" justifyContent={"flex-start"} width="100%">
      <BodySmall>{capitalize(candidate.status ?? "Active")}</BodySmall>
    </Stack>
  );
};

const COLUMN_DEF: GridEnrichedColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <NameCell {...params} />,
  },
  {
    field: "currentStage",
    headerName: "Current Stage",
    flex: 1,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <CurrentStageCell {...params} />,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <StatusCell {...params} />,
  },
];

interface ReferredCandidatesTableProps {
  referrerId: string;
  isAgency?: boolean;
}

const ReferredCandidatesTable = ({ referrerId, isAgency }: ReferredCandidatesTableProps): React.ReactElement => {
  const [page, setPage] = React.useState(0);

  const jobId = useJobIdFromUrl();
  const { data, isFetching } = useListPipelineCandidatesQuery(
    jobId
      ? {
          args: {
            jobId,
            offset: page * 10,
            limit: 10,
            data: {
              filters: {
                referrer: referrerId,
                sourcingContext: [
                  isAgency ? CandidateFilterSourcingContextEnum.Agency : CandidateFilterSourcingContextEnum.Referral,
                ],
              },
            },
          },
        }
      : skipToken
  );

  const candidates: PipelineCandidate[] = data?.results || [];

  return (
    <ReferralsTableWrapper>
      <DataGrid
        loading={isFetching}
        autoHeight
        headerHeight={40}
        rowHeight={64}
        rows={candidates}
        rowCount={candidates.length}
        hideFooter={!!((candidates.length || 0) <= 10)}
        columns={COLUMN_DEF}
        pagination
        paginationMode="server"
        page={page}
        pageSize={10}
        onPageChange={setPage}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        localeText={{ noRowsLabel: "No candidates have been submitted" }}
        onRowClick={(params: GridRowParams): void => {
          const candidateDetailPath = APP_ROUTE_PATHS.candidates.candidateDetail(params.row.id as string);
          window.open(`${appConfig.appUrl}${candidateDetailPath}`, "_blank");
        }}
      />
    </ReferralsTableWrapper>
  );
};

type ReferredCandidatesModalProps = {
  displayValue: string;
  referrerId: string;
  isAgency?: boolean;
};

export const ReferredCandidatesModal: React.FC<React.PropsWithChildren<
  ReferredCandidatesModalProps & GlobalModalProps
>> = ({ displayValue, referrerId, isAgency, isOpen, close }) => {
  return (
    <CustomModal
      title={`Referrals submitted by ${displayValue}`}
      open={isOpen}
      onClose={close}
      maxWidth="md"
      omitDividers
      showTitleSpacer={false}
    >
      <ReferredCandidatesTable referrerId={referrerId} isAgency={isAgency} />
    </CustomModal>
  );
};

export const referredCandidatesModalAtom = modalAtom(ReferredCandidatesModal);
