import React from "react";

import { Subtitle2 } from "components/library/typography";

interface ProgressListHeaderProps {
  children: React.ReactNode;
}

export const ProgressListHeader: React.FC<React.PropsWithChildren<ProgressListHeaderProps>> = ({ children }) => {
  return <Subtitle2>{children}</Subtitle2>;
};
