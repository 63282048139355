import { Box, Select, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import React, { useMemo } from "react";

import { doesStageRequireInterviewer } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  Interviewer,
  InterviewerAutoComplete,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import MultipartInterviewSubstageEditor from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/MultipartInterviewSubstageEditor";
import { isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import { isInterviewerEmpty } from "components/dover/top-level-modal-manager/utils/isInterviewerEmpty";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { NextInterviewerHiringPipelineStage } from "components/NextInterviewer/usePossibleInterviewers";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { BaseInterviewSubstage, CandidateBioSchedulingOwnershipEnum, InterviewPanel } from "services/openapi";
import { colors } from "styles/theme";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

interface DisableInterviewerArgs {
  interviewer: Interviewer;
  interviewPanel?: InterviewPanel;
  proceedWithoutLink: boolean;
}

export const shouldDisableForInterviewerPreferences = ({
  interviewer,
  proceedWithoutLink,
}: DisableInterviewerArgs): boolean => !interviewer.preferencesComplete && !proceedWithoutLink;

export const InterviewerSelect: React.FC<React.PropsWithChildren<{
  candidateId: string;
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
  interviewer: Interviewer;
  setInterviewer: (interviewer: Interviewer) => void;
  setSubstages: (substages: BaseInterviewSubstage[]) => void;
  proceedWithoutLink: boolean;
  disabled?: boolean;
  isTakeHome: boolean;
}>> = ({
  candidateId,
  desiredHiringPipelineStage,
  interviewer,
  setInterviewer,
  setSubstages,
  proceedWithoutLink,
  disabled,
  isTakeHome,
}) => {
  const dtnMode = useAtomValue(isDtnMode);
  const { data: bio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const jobId = bio?.job;

  const customerManagedScheduling =
    bio?.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling;
  const useMultipartEditor = !dtnMode && isMultipartInterview(desiredHiringPipelineStage);
  const interviewerHeaderText = useMemo(() => (useMultipartEditor ? "Interviewers" : "Interviewer"), [
    useMultipartEditor,
  ]);
  const showPrefWarning =
    !isInterviewerEmpty(interviewer) && shouldDisableForInterviewerPreferences({ interviewer, proceedWithoutLink });

  const interviewerRequired = doesStageRequireInterviewer({
    schedulingOwnership: bio?.schedulingOwnership,
    desiredHiringPipelineStage,
    isTakeHome,
  });

  if (!interviewerRequired) {
    return (
      <Stack spacing={1} direction={"column"} width={"100%"}>
        <BodySmall weight="500">{interviewerHeaderText}</BodySmall>
        <Select
          size="small"
          disabled={true}
          displayEmpty
          sx={{
            backgroundColor: colors.white,
          }} // This is to fix a mui v4 issue
          renderValue={(_): React.ReactNode => {
            return (
              <Tooltip title={"Interviewer not applicable for Assessment / Non-interview stages"}>
                <Box display="flex" alignItems="center">
                  <BodySmall color={colors.grayscale.gray400}>N/A</BodySmall>
                </Box>
              </Tooltip>
            );
          }}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={1} width="100%">
      <BodySmall weight="500">{interviewerHeaderText}</BodySmall>
      {useMultipartEditor ? (
        <MultipartInterviewSubstageEditor
          desiredHiringPipelineStage={desiredHiringPipelineStage}
          job={jobId}
          setUpdatedSubstages={setSubstages}
          showHeaders
          // we want to disable multipart  interviewer select for customer managed scheduling
          // because we do not book calls for them
          disabled={customerManagedScheduling || dtnMode}
        />
      ) : (
        <>
          <InterviewerAutoComplete
            hideLabel
            selectedInterviewer={interviewer}
            setSelectedInterviewer={setInterviewer}
            desiredHiringPipelineStage={desiredHiringPipelineStage}
            jobId={jobId}
            disabled={disabled}
            borderColor={showPrefWarning ? `${colors.warning.dark} !important` : ""}
            excludeDoverInterviewer={customerManagedScheduling}
          />
        </>
      )}
    </Stack>
  );
};
