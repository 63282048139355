import { Progress } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { Body, Heading } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useGetCareersPageClientQuery } from "services/doverapi/endpoints/careersPageClient";
import { useListCareersPageJobQuery } from "services/doverapi/endpoints/careersPageJob";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CareersPageClientDoverPlanEnum, CareersPageJob, DashboardJob } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { colors } from "styles/theme";
import { generateRandomizedImageUrl } from "utils/image";
import { sortJobsByTitle } from "utils/job";
import { StyledLogo } from "views/inboundExternal/styles";
import { CandidateForm } from "views/ReferralForm/CandidateForm";
import { ReferrerForm } from "views/ReferralForm/ReferrerForm";
import { SuccessScreen } from "views/ReferralForm/SuccessScreen";
import { SubmitReferralFormSchemaType, submitReferralFormSchema } from "views/ReferralForm/types";
import Unauthorized from "views/Unauthorized";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${colors.grayscale.gray025}};
  padding-top: 24px;
  min-height: 1100px;
`;

export interface ReferralJob {
  id?: string;
  title: string | null;
  client: string;
  isSample: boolean;
}

const ReferralsForm = (): React.ReactElement => {
  const { clientId } = useParams<{
    clientId: string;
  }>();

  const [name] = useQueryParam("name", StringParam);
  const [email] = useQueryParam("email", StringParam);

  const formMethods = useForm<SubmitReferralFormSchemaType>({
    resolver: zodResolver(submitReferralFormSchema),
  });
  const { getValues } = formMethods;

  // load data
  const { isAuthenticated } = useAuth0();

  const { data: authedProUser, isLoading: userInfoLoading } = useGetAuthedUserInfoQuery(
    isAuthenticated ? undefined : skipToken
  );
  const { data: client, isFetching: fetchingPublicJobs, isError } = useGetCareersPageClientQuery(
    clientId ? { clientId } : skipToken
  );

  // for authed users, we only want to show jobs for the client they are associated with
  // otherwise still use public facing jobs for other clients
  const shouldUseAuthedJobs = !userInfoLoading && clientId && isAuthenticated && authedProUser?.client === clientId;

  const { data: unauthedJobList } = useListCareersPageJobQuery(
    clientId && !userInfoLoading && (!authedProUser || authedProUser?.client !== clientId) ? { clientId } : skipToken
  );
  const { activeJobs: authedJobList, jobsLoading } = useGetJobsDerivedData({
    sortFunc: sortJobsByTitle,
    skip: !shouldUseAuthedJobs,
  });

  const jobsToUse: Array<CareersPageJob | DashboardJob> | undefined = shouldUseAuthedJobs
    ? authedJobList
    : listAllEntities(unauthedJobList);

  const jobs: ReferralJob[] =
    jobsToUse?.map(j => ({
      id: j.id!,
      title: j.title!,
      client: clientId!,
      isSample: false,
    })) ?? [];

  // local state
  const [page, setPage] = useState(0);

  // error and loading states
  if (isError) {
    return <Navigate to="/NotFound" />;
  }

  if (jobsLoading || userInfoLoading || fetchingPublicJobs) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (client?.doverPlan === CareersPageClientDoverPlanEnum.NoAccess) {
    return <Unauthorized errorCodeOverride={CareersPageClientDoverPlanEnum.NoAccess} />;
  }

  // render
  return (
    <>
      <PageHelmet noIndex title={`Refer Candidates to ${client?.name}`} />

      <PageWrapper>
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <Stack mb={2} alignItems={"center"} justifyContent={"center"} spacing={1}>
            {client?.logo ? (
              <Stack
                sx={{
                  cursor: client?.careersPageLogoRedirectUrl ? "pointer" : undefined,
                }}
                onClick={(): void => {
                  if (client?.careersPageLogoRedirectUrl) {
                    window.open(client?.careersPageLogoRedirectUrl, "_blank");
                  }
                }}
              >
                <StyledLogo src={generateRandomizedImageUrl(client?.logo)} alt=" url" />
              </Stack>
            ) : (
              <Heading>{client?.name}</Heading>
            )}

            <Stack alignItems={"center"} justifyContent={"center"}>
              {page !== 0 && (
                <Body>
                  {`${getValues("referrerName")}`}&apos;s Referral to {client?.name}
                </Body>
              )}
            </Stack>
          </Stack>

          <FormProvider {...formMethods}>
            <Stack spacing={2} minWidth="430px">
              {page === 0 && (
                <ReferrerForm
                  name={authedProUser?.fullName ?? name ?? ""}
                  email={authedProUser?.email ?? email ?? ""}
                  onNext={(): void => {
                    setPage(1);
                  }}
                />
              )}
              {page === 1 && (
                <CandidateForm
                  jobs={jobs}
                  onNext={(): void => {
                    setPage(2);
                  }}
                />
              )}
              {page === 2 && (
                <SuccessScreen
                  addAnother={(): void => {
                    setPage(1);
                  }}
                />
              )}
            </Stack>
          </FormProvider>
        </Stack>
      </PageWrapper>
    </>
  );
};

export default ReferralsForm;
