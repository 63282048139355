import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetSteps } from "sections/addcandidate/components/steps";
import {
  ADD_CANDIDATE_DRAWER_QUERY_PARAM,
  FLOW_TYPE_QUERY_PARAM,
  OUTREACH_TYPE_QUERY_PARAM,
} from "sections/addcandidate/data/queryParams";
import { useAddCandidateImplementation } from "sections/addcandidate/services/api";
import {
  AddCandidateState,
  FlowState,
  FlowStateValue,
  FlowType,
  FlowTypeParam,
  FlowTypes,
  HandleAddCandidateResult,
  OutreachType,
  OutreachTypeParam,
  StepType,
  StepTypes,
} from "sections/addcandidate/types";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { CandidateBioSchedulingOwnershipEnum } from "services/openapi";

const initialFlowState: FlowState = {
  [StepTypes.ATS_CANDIDATE_INFO]: {
    addingAtsCandidate: false,
  },
  [StepTypes.JOB_SELECT]: {},
  [StepTypes.CANDIDATE_INFO]: {
    firstName: "",
    lastName: "",
    email: "",
    source: {
      value: "",
      label: "",
      internalName: "",
    },
  },
  [StepTypes.SELECT_CAMPAIGN]: {
    campaign: null,
    body: "",
    subject: "",
    ccEmails: [],
  },
  [StepTypes.INITIAL_OUTREACH_REVIEW]: {},
  [StepTypes.STAGE_SELECT]: {},
  [StepTypes.INTERVIEW_INFO]: {
    scheduleInterviewThroughDover: false,
  },
  [StepTypes.CUSTOMIZE_EMAIL]: {
    schedulingEmailBody: "",
    schedulingEmailSubject: "",
    schedulingEmailSenderEmail: "",
    schedulingOwnership: CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling,
    schedulingEmailCcEmails: [],
  },
};

const initialAddCandidateState: AddCandidateState = {
  // General
  modalOpen: false,
  discardChangesModalOpen: false,
  setDiscardChangesModalOpen: () => {},
  flowType: FlowTypes.INITIAL_OUTREACH,
  closeModal: () => {},
  loading: false,
  isSubmitting: false,
  handleAddCandidate: () => Promise.resolve({ success: false }),
  // Form
  formDirty: false,
  activeStep: 0,
  handleNextStep: () => {},
  handlePreviousStep: () => {},
  handleResetSteps: () => {},
  flowState: initialFlowState,
  setStepState: () => {},
  candidateId: undefined,
};

export const AddCandidateContext = createContext<AddCandidateState>(initialAddCandidateState);

export const AddCandidateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  //----------------------------------------
  // Query param state

  const [isModalOpenParam, setIsModalOpenParam] = useQueryParam(
    ADD_CANDIDATE_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );
  const [flowTypeParam, setFlowTypeParam] = useQueryParam(FLOW_TYPE_QUERY_PARAM, FlowTypeParam);
  const [, setOutreacTypeParam] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, OutreachTypeParam);
  // Do not set only use on initial load
  const jobIdFromUrl = useJobIdFromUrl();
  //----------------------------------------
  // General state

  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);

  const { jobsLoading } = useGetJobsDerivedData({});

  const loading = jobsLoading; // TODO: dervied from jobs and other things of interest
  const [isSubmitting, setIsSubmitting] = useState(false);

  //----------------------------------------
  // Form state

  const [activeStep, setActiveStep] = useState(0);
  const [flowType, setFlowType] = useState<FlowType>(FlowTypes.INTERVIEW_PIPELINE);
  const [flowState, setFlowState] = useState<FlowState>(initialFlowState);
  const [candidateId, setCandidateId] = useState<string | undefined>(undefined);
  const steps = useGetSteps(flowType);
  const finished = useMemo(() => activeStep === steps.length, [activeStep, steps.length]);
  const formDirty = activeStep !== 0 && !finished;
  const addCandidateServiceImp = useAddCandidateImplementation(flowType, flowState);

  //----------------------------------------
  // Handlers and callbacks

  const handleAddCandidate = useCallback(
    async (outreachType?: OutreachType | null): Promise<HandleAddCandidateResult> => {
      const addCandidateService = addCandidateServiceImp(outreachType);
      setIsSubmitting(true);
      const result = await addCandidateService.addCandidate(flowState);
      setIsSubmitting(false);
      setCandidateId(result.candidateId);
      return result;
    },
    [flowState, addCandidateServiceImp]
  );

  // Form
  const setStepState = useCallback(
    (stepType: StepType, stepState: FlowStateValue): void =>
      setFlowState(prevState => ({
        ...prevState,
        [stepType]: stepState,
      })),
    [setFlowState]
  );

  const clearState = useCallback((): void => {
    setFlowState({
      ...initialFlowState,
      [StepTypes.JOB_SELECT]: {
        ...flowState[StepTypes.JOB_SELECT],
      },
    });
  }, [flowState, setFlowState]);

  // Steps
  const handleNextStep = useCallback((): void => setActiveStep(prevActiveStep => prevActiveStep + 1), [setActiveStep]);
  const handlePreviousStep = useCallback((): void => setActiveStep(prevActiveStep => prevActiveStep - 1), [
    setActiveStep,
  ]);
  const handleResetSteps = useCallback((): void => {
    setActiveStep(0);
    clearState();
  }, [setActiveStep, clearState]);

  // Modal
  const closeModal = useCallback(
    (force: boolean): void => {
      if (force || !formDirty) {
        // use undefined to remove the query param from the URL
        setIsModalOpenParam(undefined);
        setFlowTypeParam(undefined);
        setOutreacTypeParam(undefined);

        handleResetSteps();
      } else {
        setDiscardChangesModalOpen(true);
      }
    },
    [
      formDirty,
      setIsModalOpenParam,
      handleResetSteps,
      setDiscardChangesModalOpen,
      setFlowTypeParam,
      setOutreacTypeParam,
    ]
  );

  //----------------------------------------
  // Effects

  // Set job id from query param on initial load
  useEffect(() => {
    if (isModalOpenParam) {
      setStepState(StepTypes.JOB_SELECT, { jobId: jobIdFromUrl });
    }
  }, [isModalOpenParam, setStepState, jobIdFromUrl]);

  // Set flow type from query param on initial load
  useEffect(() => {
    // check jobId defined
    if (isModalOpenParam) {
      setFlowType(flowTypeParam || FlowTypes.INITIAL_OUTREACH);
      setActiveStep(0);
    }
  }, [isModalOpenParam, setFlowType, flowTypeParam]);

  //----------------------------------------
  // Render

  return (
    <AddCandidateContext.Provider
      value={{
        modalOpen: isModalOpenParam,
        discardChangesModalOpen,
        setDiscardChangesModalOpen,
        closeModal,
        flowType,
        formDirty,
        activeStep,
        handleNextStep,
        handlePreviousStep,
        handleResetSteps,
        flowState,
        setStepState,
        loading,
        isSubmitting,
        handleAddCandidate,
        candidateId,
      }}
    >
      {children}
    </AddCandidateContext.Provider>
  );
};
