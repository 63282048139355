import { Icon } from "@doverhq/dover-ui";
import { Autocomplete, Box, Skeleton, Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as PencilSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DoverIcon } from "assets/logos/DoverD.svg";
import { feedbackTemplateDrawerConfigAtom } from "components/dover/FeedbackTemplates/atoms";
import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { FeedbackTemplateDrawerContextType } from "components/dover/FeedbackTemplates/types";
import { StyledTextField } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { InfoTip } from "components/InfoTip";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useListFeedbackTemplatesQuery } from "services/doverapi/endpoints/feedbackTemplates";
import { FeedbackTemplateDetail } from "services/openapi";
import { colors } from "styles/theme";
import { FEEDBACK_TEMPLATES_PAGE_LIMIT } from "views/CompanySetup/components/FeedbackTemplates/constants";

const ADD_NEW_OR_MANAGE = "ADD_NEW_OR_MANAGE";

interface FeedbackTemplateOption {
  label: string;
  value: string | null;
  onClick?: () => void;
  isDefault?: boolean;
}

export interface FeedbackTemplateSelectorProps {
  feedbackTemplateId?: string;
  setFeedbackTemplateId: (templateId?: string) => void;
  disabled?: boolean;
  allowEdits?: boolean;
  condensed?: boolean;
}

const FeedbackTemplateSelect: React.FC<React.PropsWithChildren<FeedbackTemplateSelectorProps>> = ({
  feedbackTemplateId,
  setFeedbackTemplateId,
  disabled,
  allowEdits,
  condensed,
}) => {
  const { open: openFeedbackTemplateDrawer } = useModal(FeedbackTemplateDrawerAtom);
  const setFeedbackTemplateDrawerConfig = useSetAtom(feedbackTemplateDrawerConfigAtom);

  const { data, isFetching } = useListFeedbackTemplatesQuery({
    offset: 0 * FEEDBACK_TEMPLATES_PAGE_LIMIT,
    limit: 300,
  });

  const getOptions = (): FeedbackTemplateOption[] => {
    const fetchedTemplates = data?.results ?? [];

    return [
      ...fetchedTemplates.map(template => ({
        value: template.id,
        label: template.name,
        isDefault: template.isDefault,
      })),
    ] as FeedbackTemplateOption[];
  };
  const options = getOptions();

  const NewOrManageTemplatesRow: FeedbackTemplateOption = {
    value: ADD_NEW_OR_MANAGE,
    label: "Add New or Manage",
    isDefault: false,
  };

  const initialOption = feedbackTemplateId
    ? options.find(option => option.value === feedbackTemplateId) // use the selected template if passed in
    : undefined;

  if (isFetching || !options) {
    return <Skeleton width="100%" height="40px" sx={{ maxWidth: "480px" }} />;
  }

  const handleUpdateNewOption = (newOption: FeedbackTemplateOption | null): void => {
    setFeedbackTemplateId(newOption?.value || undefined);
  };

  const onCreateFeedbackTemplate = (): void => {
    const selectNewFeedbackTemplate = (template?: FeedbackTemplateDetail): void => {
      if (!template) {
        console.error("New template is undefined");
        return;
      }
      const newOption = {
        value: template.id,
        label: template.name,
      };
      handleUpdateNewOption(newOption);
    };
    setFeedbackTemplateDrawerConfig({ onCreateCallback: selectNewFeedbackTemplate });
    openFeedbackTemplateDrawer({});
  };

  const onEditTemplate = (templateId: string): void => {
    setFeedbackTemplateDrawerConfig({
      existingTemplateId: templateId,
      context: FeedbackTemplateDrawerContextType.Edit,
    });
    openFeedbackTemplateDrawer({});
  };

  return (
    <Autocomplete
      disabled={disabled}
      id="feedback-template-selector"
      value={initialOption}
      options={[...options, NewOrManageTemplatesRow]}
      getOptionLabel={(template): string => template?.label ?? ""}
      onChange={(_, newOption: FeedbackTemplateOption | null): void => {
        // No action on special options -- they have their own onClick handlers
        if (newOption?.value === ADD_NEW_OR_MANAGE) {
          return;
        }

        if (newOption?.onClick) {
          newOption.onClick();
          return;
        }
        handleUpdateNewOption(newOption);
      }}
      filterOptions={(options, { inputValue }): FeedbackTemplateOption[] => {
        return options.filter(
          option => option.value === ADD_NEW_OR_MANAGE || option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
      sx={{
        maxWidth: "480px",
        fontSize: "14px",
        fontFamily: "Inter",
        backgroundColor: colors.white,
        ".MuiOutlinedInput-root ": { border: "none" },
        ".MuiAutocomplete-endAdornment": { top: "auto" },
      }}
      componentsProps={{
        // Allows passing styles to the list of options (paper element)
        paper: {
          sx: {
            // Ensures all options in the dropdown are visible by accounting for the height of the fixed
            // "Add New or Manage" row at the bottom of the list
            paddingBottom: condensed ? "72px" : "52px",
          },
        },
      }}
      renderInput={(params: any): React.ReactElement => {
        return (
          <StyledTextField
            {...params}
            placeholder={"Select a template"}
            hideLabel
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
      renderOption={(props, option): React.ReactElement => {
        // Special option for default template
        if (option.isDefault) {
          return (
            // @ts-ignore
            <OptionWrapper {...props} key={option.value}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={condensed ? 0.25 : 1}
                // to visually left align with other options
                ml={"-4px"}
              >
                <DoverIcon width="20px" height="20px" />
                <BodySmall>{option.label}</BodySmall>
                <InfoTip text="Dover's default form includes basic fields to track pros, cons, and set an overall rating." />
              </Stack>
              {allowEdits && option.value && (
                <Tooltip title="View and edit">
                  <Box width="14px">
                    <PencilSVG
                      width="14px"
                      height="14px"
                      onClick={(): void => {
                        handleUpdateNewOption(option);
                        onEditTemplate(option.value!);
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </OptionWrapper>
          );
        }
        // Special option for fixed "Add New or Manage" row at the bottom of list
        if (option.value === ADD_NEW_OR_MANAGE) {
          return (
            <Stack
              direction={condensed ? "column" : "row"}
              spacing={1}
              position="fixed"
              bottom="0"
              justifyContent="space-between"
              width="100%"
              px={1}
              py={2}
              bgcolor={colors.grayscale.gray100}
              color={colors.black}
              sx={{ zIndex: 1 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                onClick={onCreateFeedbackTemplate}
                sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
              >
                <Icon Icon={PlusIcon} />
                <BodySmall>New Feedback Form</BodySmall>
              </Stack>
              <Link
                to={APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.FEEDBACK_FORMS)}
                target="_blank"
                style={{ color: "inherit" }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SettingsIcon className="svg-fill" color={colors.black} />
                  <BodySmall>Manage</BodySmall>
                </Stack>
              </Link>
            </Stack>
          );
        }
        return (
          // @ts-ignore
          <OptionWrapper {...props} key={option.value}>
            <BodySmall>{option.label}</BodySmall>
            {allowEdits && option.value && (
              <Tooltip title="View and edit">
                <Box width="14px">
                  <PencilSVG
                    width="14px"
                    height="14px"
                    onClick={(): void => {
                      handleUpdateNewOption(option);
                      onEditTemplate(option.value!);
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </OptionWrapper>
        );
      }}
    />
  );
};

export default FeedbackTemplateSelect;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  // Ensures pencil edit icons are right-aligned
  &.MuiAutocomplete-option {
    display: flex;
    justify-content: space-between;
  },
`;
