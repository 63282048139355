import {
  PipelineCandidateCandidateBucketLabelEnum,
  SaapReviewApplicationCandidateBucketLabelEnum,
} from "services/openapi";
import { colors } from "styles/theme";

export const applicationIdQueryParam = "applicationId";
export const orderingEnumQueryParam = "ordering";

export interface MatchChipProps {
  label: SaapReviewApplicationCandidateBucketLabelEnum | PipelineCandidateCandidateBucketLabelEnum;
}

// Different colors and texts per bucket label
export const labelText = {
  [SaapReviewApplicationCandidateBucketLabelEnum.BestMatch]: "Best match",
  [SaapReviewApplicationCandidateBucketLabelEnum.CloseMatch]: "Close match",
  [SaapReviewApplicationCandidateBucketLabelEnum.Mismatch]: "Mismatch",
  [SaapReviewApplicationCandidateBucketLabelEnum.Scoring]: "Processing",
  [SaapReviewApplicationCandidateBucketLabelEnum.EnrichingProfile]: "Processing",
  [SaapReviewApplicationCandidateBucketLabelEnum.NoInfo]: "No score",
};

export const backgroundColors = {
  [SaapReviewApplicationCandidateBucketLabelEnum.BestMatch]: colors.primary.light,
  [SaapReviewApplicationCandidateBucketLabelEnum.CloseMatch]: colors.warning.light,
  [SaapReviewApplicationCandidateBucketLabelEnum.Mismatch]: colors.critical.light,
  [SaapReviewApplicationCandidateBucketLabelEnum.Scoring]: colors.informational.light,
  [SaapReviewApplicationCandidateBucketLabelEnum.EnrichingProfile]: colors.informational.light,
  [SaapReviewApplicationCandidateBucketLabelEnum.NoInfo]: colors.informational.light,
};

export const labelColors = {
  [SaapReviewApplicationCandidateBucketLabelEnum.BestMatch]: colors.primary.hover,
  [SaapReviewApplicationCandidateBucketLabelEnum.CloseMatch]: colors.warning.dark,
  [SaapReviewApplicationCandidateBucketLabelEnum.Mismatch]: colors.critical.dark,
  [SaapReviewApplicationCandidateBucketLabelEnum.Scoring]: colors.informational.dark,
  [SaapReviewApplicationCandidateBucketLabelEnum.EnrichingProfile]: colors.informational.dark,
  [SaapReviewApplicationCandidateBucketLabelEnum.NoInfo]: colors.informational.dark,
};
