import React from "react";

import { CandidateTableTabs } from "views/candidates/CandidateTable/table/CandidateTableTabs";
import { useParams } from "views/candidates/hooks";

export const TableHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [{ quickFilter }] = useParams();

  // If we have any of the quick filteres selected
  const quickFilterSelected = !!quickFilter;

  // Quick filters automatically set the stages they want to show so
  // We hide the stage tabs to avoid confusion
  if (quickFilterSelected) {
    return null;
  }

  return <CandidateTableTabs />;
};
