import { Stack } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React, { FC } from "react";
import { useBoolean } from "react-use";

import { SELECT_ALL_APPLICATIONS } from "App/hotkeys/hotkeys";
import { useHotkey } from "App/hotkeys/useHotkey";
import { ReactComponent as MultiSelectIcon } from "assets/icons/multi-select.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Checkbox } from "components/library/Checkbox";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { colors } from "styles/theme";
import {
  multiSelectApplicationsAtom,
  multiSelectEnabledAtom,
} from "views/candidates/ApplicationReview/atoms/multiSelect";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";
import { isApplicantEligibleForBulkReject } from "views/candidates/ApplicationReview/utils/isApplicantEligibleForBulkReject";

const SelectOrCancelButton = ({ onClick, text }: { onClick: () => void; text: string }): React.ReactElement => {
  return (
    <Button variant={ButtonVariant.Ghost} onClick={onClick} removePadding removeOutline>
      <BodySmall color={colors.grayscale.gray500} textDecoration="underline">
        {text}
      </BodySmall>
    </Button>
  );
};

const MultiSelectControls: FC<React.PropsWithChildren<unknown>> = () => {
  const setMultiSelectApplications = useSetAtom(multiSelectApplicationsAtom);
  const [multiSelectEnabled, setMultiSelectEnabled] = useAtom(multiSelectEnabledAtom);

  const [allSelected, setAllSelected] = useBoolean(false);

  const { applications } = useApplications();

  const clickMultiSelect = (): void => {
    setMultiSelectEnabled(!multiSelectEnabled);
  };
  const jobId = useJobIdFromUrl();

  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const toggleSelectAll = (): void => {
    if (allSelected) {
      clearAllSelected();
    } else {
      selectAllApplications();
    }
  };

  const clearAllSelected = (): void => {
    setAllSelected(false);
    setMultiSelectApplications([]);
  };

  const selectAllApplications = (): void => {
    setAllSelected(true);
    const selectedApplications = applications
      ?.filter(app => isApplicantEligibleForBulkReject(app, isJobE2eEnabled))
      .map(app => ({
        applicationId: app.inboundAppId,
        candidateId: app.candidateId!,
        candidateActionVersion: app.candidateActionVersion,
        schedulingOwnership: app.schedulingOwnership,
      }));

    setMultiSelectApplications(selectedApplications ?? []);
  };

  const toggleAllHotkey = (): void => {
    if (!multiSelectEnabled) {
      selectAllApplications();
      setMultiSelectEnabled(true);
    } else {
      toggleSelectAll();
    }
  };

  useHotkey(SELECT_ALL_APPLICATIONS, "Select all applications", toggleAllHotkey);

  if (multiSelectEnabled) {
    return (
      <Stack
        width="100%"
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        px={1}
        py={0.5}
        bgcolor={colors.grayscale.gray100}
        borderBottom={`1px solid ${colors.grayscale.gray200}`}
      >
        <Stack direction="row" alignItems="center">
          <Checkbox checked={allSelected} onChange={toggleSelectAll} />
          <SelectOrCancelButton onClick={toggleSelectAll} text="Select all" />
        </Stack>
        <SelectOrCancelButton onClick={clickMultiSelect} text="Cancel" />
      </Stack>
    );
  }

  return (
    <Stack px={1} py={0.5} bgcolor={colors.grayscale.gray100} borderBottom={`1px solid ${colors.grayscale.gray200}`}>
      <Button variant={ButtonVariant.Secondary} onClick={clickMultiSelect} removePadding width="fit-content">
        <Stack direction="row" spacing={1} alignItems="center" px="6px" py="4px">
          <MultiSelectIcon />
          <BodySmall color={colors.grayscale.gray600}>Multi-select</BodySmall>
        </Stack>
      </Button>
    </Stack>
  );
};

export default MultiSelectControls;
