import { Autocomplete, Box, Stack, styled, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { BodySmall } from "components/library/typography";
import { useGetArchiveReasonsQuery } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { ArchiveReason, ArchiveReasonTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

const DownArrowIcon = (
  <Box display="flex" m="auto">
    <DownCaretSVG width="30px" />
  </Box>
);

const rejectionTypeToHeader: { [ArchiveReasonTypeEnum.Rejected]: string; [ArchiveReasonTypeEnum.Withdrew]: string } = {
  [ArchiveReasonTypeEnum.Rejected]: "We're rejecting the candidate",
  [ArchiveReasonTypeEnum.Withdrew]: "The candidate is withdrawing",
};

/**
 *
 * @param reason Controlled state
 * @param setReason Setter for controlled state
 * @param archiveContext The type of archive, either rejected or withdrew. Will control the default reason to be set when reason is null.
 */
export const RejectReasonSelect: React.FC<React.PropsWithChildren<{
  reason: ArchiveReason | null;
  setReason: (reason: ArchiveReason | null) => void;
  archiveContext: ArchiveReasonTypeEnum;
}>> = ({ reason, setReason, archiveContext }) => {
  // I don't like this pattern, but mui changes the value to null whenever input value goes to empty string
  // So in light of this, this is the best way I could think to write the effect
  const [initialized, setInitialized] = useState<boolean>(!!reason ?? false);

  // Get the archive reasons and filter to only reject related reasons
  const { data, isLoading } = useGetArchiveReasonsQuery();
  const rejectReasons = useMemo(
    () =>
      data?.results
        .filter(
          // There are plenty of archive types like snoozed and ignored, we only want reject related ones
          (reason: ArchiveReason): boolean =>
            reason.type === ArchiveReasonTypeEnum.Rejected || reason.type === ArchiveReasonTypeEnum.Withdrew
        )
        // Filter out the a small list of specific reasons that we don't want to show in the dropdown
        .filter(
          (reason: ArchiveReason): boolean =>
            reason.description !== "Outreach undelivered" &&
            reason.description !== "Rejected in ATS" &&
            reason.description !== "Withdrew in ATS" &&
            reason.description !== "Interview no show" &&
            reason.description !== "Hired in ATS" && // This one doesn't actually exist in the above types, but keeping it here just in case
            reason.description !== "Moved to another job"
        )
        .sort((a: ArchiveReason, b: ArchiveReason): number => {
          if (a.type !== b.type) {
            // First we need to sort types together so that mui autocomplete can group them properly
            return a.type === ArchiveReasonTypeEnum.Rejected ? -1 : 1;
          } else if (a.type === b.type) {
            // Next put the other options at the bottom of each group
            return a.description === "Other" ? 1 : -1;
          }
          return 0;
        }) ?? [],
    [data]
  );

  // Set initial archive reason based on context
  useEffect(() => {
    if (!initialized && !reason && rejectReasons.length) {
      const defaultDescription =
        archiveContext === ArchiveReasonTypeEnum.Rejected ? "Lacking skills/qualifications" : "Timing not right";
      const defaultReason = rejectReasons.find(
        (reason: ArchiveReason): boolean => reason.description === defaultDescription
      );
      setReason(defaultReason ?? null);
      setInitialized(true);
    }
  }, [archiveContext, initialized, reason, rejectReasons, setReason]);

  return (
    <Stack spacing={1}>
      <BodySmall weight="500">Reason</BodySmall>
      <Autocomplete
        value={reason}
        options={rejectReasons}
        loading={isLoading}
        popupIcon={DownArrowIcon}
        onChange={(_, o): void => {
          setReason(o);
        }}
        sx={{
          fontSize: "14px",
          fontFamily: "Inter",
          backgroundColor: colors.white,
          ".MuiOutlinedInput-root ": { border: "none" },
        }}
        getOptionLabel={(option): string => option.description}
        renderInput={(params): React.ReactElement => (
          <StyledTextField {...params} placeholder="Select a reason" variant="outlined" size="small" />
        )}
        renderOption={(props, option): React.ReactElement => (
          <li key={option.id} {...props}>
            <BodySmall>{option.description}</BodySmall>
          </li>
        )}
        groupBy={(option): string =>
          option.type
            ? rejectionTypeToHeader[option.type as ArchiveReasonTypeEnum.Rejected | ArchiveReasonTypeEnum.Withdrew]
            : "Other Reasons"
        }
      />
    </Stack>
  );
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 40px;
  }
  input {
    font-size: 14px;
    font-family: Inter;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grayscale.gray300};
  }
`;
