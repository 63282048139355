import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import moment from "moment-timezone";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { RRule } from "rrule";
import { StringParam, useQueryParam } from "use-query-params";

import SpinLoading from "components/HotLoading";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { Spacer } from "components/Spacer";
import {
  multipartInterview,
  useGetCandidateQuery,
  useGetMultipartInterviewQuery,
  useCancelMultipartInterviewMutation,
  useSubmitMultipartInterviewSchedulingMutation,
} from "services/doverapi/endpoints/multipartScheduler";
import { MultipartSchedulingBaseInterviewSubstage } from "services/openapi";
import { colors } from "styles/theme";
import { PageTitle } from "styles/typography/headers";
import { useInjectReducer } from "utils/injectReducer";
import { showErrorToast } from "utils/showToast";
import { InterviewPlan } from "views/interview/InterviewScheduler/components/InterviewPlan";
import { LocationOverride } from "views/interview/InterviewScheduler/components/LocationOverride";
import { Pill } from "views/interview/InterviewScheduler/components/Pill";
import {
  CalendarStyle,
  CalendarStyleColor,
  candidateIdQueryParam,
  interviewStageIdQueryParam,
  interviewSchedulerKey,
} from "views/interview/InterviewScheduler/constants";
import reducer, { interviewSchedulerActions } from "views/interview/InterviewScheduler/reducers";
import {
  makeSelectInterviewerAvailability,
  makeSelectInterviewerCalendars,
  selectCanceledSubstageIds,
  selectExpectedNumberOfSubstages,
  selectDate,
  selectDayStartEnd,
  selectDeletedSubstageIds,
  selectInterviewRounds,
  selectLocationOverride,
  selectResources,
  selectTimezone,
} from "views/interview/InterviewScheduler/selectors";
import { CalendarEvent, CalendarResource } from "views/interview/InterviewScheduler/types";
import {
  dateToUnix,
  interpretDateAsLocal,
  interpretDateAsUTC,
  removeOffset,
  timezones,
} from "views/interview/InterviewScheduler/utils";

const InterviewScheduler = (): React.ReactElement => {
  useInjectReducer({ key: interviewSchedulerKey, reducer });

  const dispatch = useDispatch();

  const [showOverrideWarning, setShowOverrideWarning] = useState<boolean | undefined>(undefined);

  // eslint-disable-next-line react/no-unstable-nested-components
  const TimeGutter: FC<React.PropsWithChildren<unknown>> = () => (
    <Box display="flex" height="100%">
      <Box margin="auto">{moment.tz(timezone).zoneAbbr()}</Box>
    </Box>
  );

  const [interviewStageId] = useQueryParam(interviewStageIdQueryParam, StringParam);
  const [candidateId] = useQueryParam(candidateIdQueryParam, StringParam);

  const resources = useSelector(selectResources);
  const interviewRounds = useSelector(selectInterviewRounds);

  // IDs of deleted CMIS
  const deletedSubstageIds = useSelector(selectDeletedSubstageIds);

  const canceledSubstageIds = useSelector(selectCanceledSubstageIds);

  const date = useSelector(selectDate);
  const timezone = useSelector(selectTimezone);
  const expectedNumberOfSubstages = useSelector(selectExpectedNumberOfSubstages);
  const { start, end } = useSelector(selectDayStartEnd);

  const locationOverride = useSelector(selectLocationOverride);

  const resourceIds = useMemo(() => resources.map(resource => resource.id), [resources]);
  const interviewerCalendars = useSelector(makeSelectInterviewerCalendars(resourceIds));
  const interviewerAvailabilities = useSelector(makeSelectInterviewerAvailability(resourceIds));

  const {
    data: interviewPlan,
    isLoading: isInterviewPlanLoading,
    isError: isInterviewPlanError,
  } = useGetMultipartInterviewQuery(
    interviewStageId && candidateId ? { id: interviewStageId, candidateId: candidateId } : skipToken
  );

  const { data: candidate, isLoading: isCandidateLoading, isError: isCandidateError } = useGetCandidateQuery(
    candidateId || skipToken
  );

  const [confirmedScheduledEvents, setConfirmedScheduledEvents] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(true);

  // localInterviewSubstages tracks the changes in the form, interviewPlan.substages is the default before customizations
  const [localInterviewSubstages, setLocalInterviewSubstages] = useState<
    Array<{ entityId: string; substage: MultipartSchedulingBaseInterviewSubstage }>
  >([]);

  // Set localInterviewSubstages to interviewPlan.substages; need useEffect because they're asynchronous
  useEffect(() => {
    if (interviewPlan?.substages) {
      // set the expected number of stages so we can validate that the form is complete upon submitting
      dispatch(interviewSchedulerActions.setExpectedNumberOfSubstages(interviewPlan.substages.length));
      setLocalInterviewSubstages(
        interviewPlan.substages.map(s => ({
          entityId: getSubstageEntityId(s),
          substage: s,
        }))
      );

      const earliestConfirmedStartTime: number = Math.min(
        ...interviewPlan.substages.reduce((confirmedStartTimes: number[], substage) => {
          if (substage.confirmedStartTime) {
            confirmedStartTimes.push(moment(substage.confirmedStartTime).unix());
          }
          return confirmedStartTimes;
        }, [])
      ); // Infinity if there are no confirmed start times

      const confirmed = earliestConfirmedStartTime !== Infinity;

      setConfirmedScheduledEvents(confirmed);
      setEditable(!confirmed);
      if (confirmed) {
        dispatch(interviewSchedulerActions.setDate(moment.unix(earliestConfirmedStartTime).toDate()));
      }
    }

    if (!isInterviewPlanLoading && showOverrideWarning === undefined) {
      const substagesWithoutTemplate = interviewPlan?.substages?.filter(s => !s.multipartInterviewSubstageId);
      setShowOverrideWarning(substagesWithoutTemplate && !!substagesWithoutTemplate.length);
    }
  }, [dispatch, interviewPlan, isInterviewPlanLoading, showOverrideWarning]);

  const [submitInterviewSchedule, { isSuccess: isSubmitSuccess }] = useSubmitMultipartInterviewSchedulingMutation();
  const [cancelInterviewSchedule, { isSuccess: isCancelSuccess }] = useCancelMultipartInterviewMutation();

  useEffect(() => {
    // just hard reload for now instead of manually updating the state and
    // dealing with refetch latency
    if (isCancelSuccess || isSubmitSuccess) {
      window.location.reload();
    }
  }, [isCancelSuccess, isSubmitSuccess]);

  // This creates our subscriptions in RTK Query for each interviewer's schedules
  useEffect(() => {
    resourceIds.forEach(id => {
      dispatch(multipartInterview.endpoints.listClientInterviewerCalendarEvents.initiate({ id, start, end }));
      dispatch(multipartInterview.endpoints.listClientInterviewerAvailability.initiate({ id }));
    });
  }, [resourceIds, start, end, dispatch]);

  // Creates default interview when we want to add an ad-hoc interview
  const handleAddInterview = (): void => {
    const newInterview: MultipartSchedulingBaseInterviewSubstage = {
      name: "Ad-hoc Interview",
      possibleInterviewers: [],
      actualInterviewers: [],
      durationInMinutes: 30,
    };
    setLocalInterviewSubstages([
      ...localInterviewSubstages,
      { entityId: getSubstageEntityId(newInterview), substage: newInterview },
    ]);
    dispatch(interviewSchedulerActions.incrementExpectedNumberOfStages());
  };

  const handleAddDebrief = (): void => {
    const allPreselectedInterviewers = new Set<string>();
    interviewRounds.forEach(round => {
      round.interviewerIds.forEach(id => allPreselectedInterviewers.add(id));
    });

    const possibleInterviewers =
      interviewPlan?.allInterviewers?.filter(i => i.id && allPreselectedInterviewers.has(i.id)) || [];

    const newInterview: MultipartSchedulingBaseInterviewSubstage = {
      name: "Debrief",
      possibleInterviewers,
      actualInterviewers: [],
      durationInMinutes: interviewPlan?.debriefDurationInMinutes ?? 15,
    };
    setLocalInterviewSubstages([
      ...localInterviewSubstages,
      { entityId: getSubstageEntityId(newInterview), substage: newInterview },
    ]);
    dispatch(interviewSchedulerActions.incrementExpectedNumberOfStages());
  };

  const removeLocalInterviewSubstage = useCallback((entityId: string): void => {
    setLocalInterviewSubstages(prevSubstages => prevSubstages.filter(s => s.entityId !== entityId));
  }, []);

  const handleSubmit = useCallback(() => {
    const args = {
      interviewStageId: interviewStageId!, // We display an error state below if these IDs are undefined, can't do above because this is a hook
      candidateId: candidateId!,
      substages: interviewRounds.map(i => ({
        candidateMultipartInterviewSubstageId: i.candidateMultipartInterviewSubstageId,
        multipartInterviewSubstageId: i.multipartInterviewSubstageId,

        interviewerIds: i.interviewerIds,
        start: i.start ? dateToUnix(moment(i.start)) : null,
        title: i.title,
        duration: i.duration,
      })),
      deletedSubstageIds,
      canceledSubstageIds,
      locationOverride,
    };

    if (interviewRounds.length === 0 || interviewRounds.length !== expectedNumberOfSubstages) {
      showErrorToast("Please make sure all interview rounds have a name, duration, and interviewer");
      return;
    }

    submitInterviewSchedule(args);
  }, [
    interviewRounds,
    interviewStageId,
    candidateId,
    locationOverride,
    deletedSubstageIds,
    canceledSubstageIds,
    submitInterviewSchedule,
    expectedNumberOfSubstages,
  ]);

  const handleCancel = useCallback(() => {
    const args = {
      interviewStageId: interviewStageId!, // We display an error state below if these IDs are undefined, can't do above because this is a hook
      candidateId: candidateId!,
    };

    cancelInterviewSchedule(args);
  }, [interviewStageId, candidateId, cancelInterviewSchedule]);

  // The react-big-calendar doesn't include a localizer, so you have to provide one
  const localizer = useMemo(() => {
    moment.tz.setDefault(timezone);
    return momentLocalizer(moment);
  }, [timezone]);

  if (isInterviewPlanLoading || isCandidateLoading) {
    return <SpinLoading />;
  }

  if (!interviewStageId) {
    return <div>Missing interview stage id</div>;
  }

  if (!candidateId) {
    return <div>Missing candidate id</div>;
  }

  if (isInterviewPlanError) {
    return <div>Error loading interview plan</div>;
  }

  if (isCandidateError) {
    return <div>Error loading candidate</div>;
  }

  // Build up a single event array for interviewer's schedules
  const interviewerEvents: CalendarEvent[] = resources.reduce((events: CalendarEvent[], resource) => {
    // We need to enrich each event with the resource id (which is the interviewer's id)
    // This is how the calendar knows which column to put each event in
    const resourceId = resource.id;
    const calendar = interviewerCalendars[resourceId].data;

    if (!calendar) {
      return events;
    }

    const interviewerEvents = calendar.map(event => ({
      title: event.title,
      start: moment.unix(Math.max(event.start, start)).toDate(),
      end: moment.unix(Math.min(event.end, end)).toDate(),
      resourceId,
    }));

    return [...events, ...interviewerEvents];
  }, []);

  // Build up a single event array for interviewers' availability
  const interviewerAvailabilityEvents: CalendarEvent[] = resources.reduce((events: CalendarEvent[], resource) => {
    // We need to enrich each event with the resource id (which is the interviewer's id)
    // This is how the calendar knows which column to put each event in
    const resourceId = resource.id;
    const availability = interviewerAvailabilities[resourceId].data;

    if (!availability || !availability.availableTimes) {
      return events;
    }

    // Bounds for RRule.between should be obtained from
    //  1. the bounds for the day in the target timezone
    //  2. interpreted in local machine time
    //  3. with the local time UTC offset removed
    //
    // e.g., if local machine time is UTC-07:00 and the target timezone is UTC-10:00
    //  1. the bounds for the UTC-10:00 day are [10:00, 09:59] in UTC-00:00
    //  2. these bounds are equivalent to [03:00, 02:59] in UTC-07:00
    //  3. the actual arguments should be [03:00, 02:59] in UTC-00:00
    const rruleStart = interpretDateAsUTC(moment.unix(start).toDate());
    const rruleEnd = interpretDateAsUTC(moment.unix(end).toDate());

    // RRule dtstart should always be midnight with no offset, i.e. 00:00+00:00
    const dtStart = removeOffset(
      moment
        .unix(start)
        .tz(availability.timezone)
        .startOf("day")
    );

    // All Dates passed to RRule should have offsets removed
    const availabilityRRules = availability.availableTimes.map(availableTime => ({
      start: new RRule({
        dtstart: dtStart,
        freq: RRule.WEEKLY,
        tzid: availability.timezone,
        byweekday: availableTime.day,
        byhour: availableTime.startHour,
        byminute: availableTime.startMinute,
      }),
      end: new RRule({
        dtstart: dtStart,
        freq: RRule.WEEKLY,
        tzid: availability.timezone,
        byweekday: availableTime.day,
        byhour: availableTime.endHour,
        byminute: availableTime.endMinute,
      }),
    }));

    const availabilityEvents = availabilityRRules.reduce((events: CalendarEvent[], availabilityRRule) => {
      const startDates = availabilityRRule.start.between(rruleStart, rruleEnd, true);
      const endDates = availabilityRRule.end.between(rruleStart, rruleEnd, true);

      // Make sure boundary intervals have a matching start/end
      if (startDates.length < endDates.length) {
        startDates.unshift(rruleStart);
      } else if (endDates.length < startDates.length) {
        endDates.push(rruleEnd);
      }

      // RRule results are meant to be interpreted as local times
      for (let i = 0; i < startDates.length; i++) {
        events.push({
          title: "Availability provided to Dover",
          start: moment.unix(Math.max(dateToUnix(interpretDateAsLocal(startDates[i])), start)).toDate(),
          end: moment.unix(Math.min(dateToUnix(interpretDateAsLocal(endDates[i])), end)).toDate(),
          resourceId,
          style: CalendarStyle.Availability,
        });
      }

      return events;
    }, []);

    return [...events, ...availabilityEvents];
  }, []);

  // Build up a single event array for each interview round
  const interviewEvents: CalendarEvent[] = interviewRounds.reduce((events: CalendarEvent[], round) => {
    if (!round.start || !round.end) {
      // Skip adding the CalendarEvents for rounds that aren't being scheduled
      return events;
    }

    const roundEvents = round.interviewerIds.map(id => ({
      title: round.title,
      resourceId: id,
      start: round.start!,
      end: round.end!,
      style: round.interview ? CalendarStyle.Interview : CalendarStyle.Default,
    }));

    return [...events, ...roundEvents];
  }, []);

  const events: CalendarEvent[] = [...interviewerEvents, ...interviewEvents];

  const backgroundEvents: CalendarEvent[] = interviewerAvailabilityEvents;

  const resourceTitleAccessor = (resource: CalendarResource): string => {
    const title = resource.fullName || resource.email;
    const calendarQuery = interviewerCalendars[resource.id];

    const info = calendarQuery.isLoading ? " - Loading..." : calendarQuery.isError ? " - Error!!!" : "";

    return `${title}${info}`;
  };

  return (
    <Box marginY="16px">
      <Box marginLeft="8px">
        <PageTitle>Interview Scheduler</PageTitle>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        padding="24px"
        border={`1px solid ${colors.grayscale.gray200}`}
        bgcolor="white"
      >
        <Box marginBottom="8px" display="flex">
          <BodySmall>
            <b>Job:</b>
            <span> {interviewPlan?.jobName!}</span>
          </BodySmall>
          <Spacer width="16px" />
          <BodySmall>
            <b>Stage:</b>
            <span> {interviewPlan?.stageName!}</span>
          </BodySmall>
          <Spacer width="16px" />
          <BodySmall>
            <b>Candidate Name:</b>
            <span> {candidate?.fullName!}</span>
          </BodySmall>
        </Box>
        <Box marginY="8px" maxWidth={300}>
          <FormControl fullWidth>
            <InputLabel>Timezone</InputLabel>
            <Select
              label="Timezone"
              size="small"
              value={timezone}
              onChange={(e): void => {
                dispatch(interviewSchedulerActions.setTimezone(e.target.value));
              }}
            >
              {timezones.map(tz => (
                <MenuItem value={tz.value}>{tz.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box marginY="8px" height="800px">
          {/* @ts-ignore FIX: react 18 type incompatibility */}
          <Calendar
            events={events}
            backgroundEvents={backgroundEvents}
            date={date}
            onNavigate={(newDate): void => {
              dispatch(interviewSchedulerActions.setDate(newDate));
            }}
            localizer={localizer}
            defaultView={Views.DAY}
            views={["day"]}
            step={15}
            resources={resources}
            resourceTitleAccessor={resourceTitleAccessor}
            scrollToTime={new Date(1972, 0, 1, 8, 0, 0)}
            eventPropGetter={styler}
            components={{ timeGutterHeader: TimeGutter }}
          />
        </Box>
        <Box marginY="16px" display="flex" alignItems="center">
          <Box>See schedules for:</Box>
          <Spacer width="4px" />
          {resources.map(resource => (
            <Pill resource={resource} />
          ))}
        </Box>
        <Box marginY="8px" padding="8px" borderRadius="3px" width="90vw" bgcolor={colors.grayscale.gray100}>
          <b>Additional Info: </b>
          <pre>{JSON.stringify(interviewPlan?.interviewDetails)}</pre>
        </Box>
        {showOverrideWarning && (
          <Banner variant={BannerVariant.Warning}>
            <Box display="flex" flexDirection="column">
              <BodySmall>
                <b>Attention: </b>Customer has indicated that we schedule the interviews base on the override preference
                they provided.
              </BodySmall>
              <BodySmall>Their preferences are already changed pre-populated in the interview plan below.</BodySmall>
              <BodySmall>This includes interview name, duration, and preferred interviewers.</BodySmall>
            </Box>
          </Banner>
        )}
        <Spacer height="8px" />
        <Banner variant={BannerVariant.Info}>
          <Box display="flex" flexDirection="column">
            <Subtitle2>Interviewer Icon Legend:</Subtitle2>
            <BodySmall>⚠️ Interviewer not on interview plan nor selected in override preference</BodySmall>
          </Box>
        </Banner>
        <Box marginY="16px">
          <InterviewPlan
            interviewRounds={localInterviewSubstages}
            allClientInterviewers={interviewPlan?.allInterviewers! ?? []}
            interviewNameOptions={interviewPlan?.substages?.map(i => i.name) ?? []}
            removeLocalInterviewSubstage={removeLocalInterviewSubstage}
            disabled={!editable}
          />
        </Box>
        <Stack direction="row" spacing={1}>
          <Button variant={ButtonVariant.Primary} onClick={handleAddInterview} disabled={!editable}>
            + Add Interview
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={handleAddDebrief} disabled={!editable}>
            + Add Debrief
          </Button>
        </Stack>

        <Box marginY="16px">
          <LocationOverride disabled={!editable} />
        </Box>
        <Box marginLeft="auto" display="flex" justifyContent="flex-end">
          <Button variant={ButtonVariant.Critical} onClick={handleCancel} disabled={!confirmedScheduledEvents}>
            Cancel All Events
          </Button>
          <Spacer width="8px" />
          {editable ? (
            <Button variant={ButtonVariant.Primary} onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            <Button variant={ButtonVariant.Primary} onClick={(): void => setEditable(true)}>
              Edit Schedule
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

// Controls the css the calendar uses to display events
const styler = (event: CalendarEvent): any => {
  const backgroundColor = event.style ? CalendarStyleColor[event.style] : CalendarStyleColor[CalendarStyle.Default];

  return { style: { backgroundColor } };
};

// Make sure that we have unique, defined IDs for each substage so that they
// can be properly tracked by `interviewEventsAdapter`
const getSubstageEntityId = (substage: MultipartSchedulingBaseInterviewSubstage): string => {
  return (
    substage.candidateMultipartInterviewSubstageId || substage.multipartInterviewSubstageId || `new-${Math.random()}`
  );
};

export default InterviewScheduler;
