import { Role, useHasRole } from "components/RBAC";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { GROUP_2_WITH_LEGACY_SOURCING_AUTOPILOT, jobNavOptions } from "views/job/constants";
import { JobNavOption } from "views/job/types";

export const useJobNavOptions = (): JobNavOption[][] => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);
  const isAdmin = useHasRole(Role.ADMIN);

  const showLegacySourcingTab = useLegacySourcingAutopilot || isAdmin;

  const navOptions = jobNavOptions;

  if (showLegacySourcingTab) {
    // this is a dumb hard code but it works and is static
    navOptions[1] = GROUP_2_WITH_LEGACY_SOURCING_AUTOPILOT;
  }

  return navOptions;
};
