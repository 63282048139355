import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { CANDIDATE_ACTION_MODAL_TYPE_TO_MODAL_COMPONENT } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";

export const useCandidateActionModal = (props: CandidateActionModalProps): React.FC<CandidateActionModalProps> => {
  // Override the modal type if the feature flag is enabled and the modal type is Schedule
  // Later we can add more conditions to override the modal type (e.g. if other actions require email auth)
  const hasImprovedSchedulingUX = useFeatureFlag(FeatureFlag.ImprovedSchedulingUX);
  const shouldPotentiallyShowPromptForEmailAuth =
    hasImprovedSchedulingUX && props.modalType === CandidateActionModalType.Schedule;
  const { senderNeedsAuth } = useEmailState({
    candidateId: props.candidateId,
  });
  const showPromptForEmailAuth = shouldPotentiallyShowPromptForEmailAuth && senderNeedsAuth;

  // Return the requested modal type, or the prompt for email auth modal if needed
  const ModalComponent =
    CANDIDATE_ACTION_MODAL_TYPE_TO_MODAL_COMPONENT[
      showPromptForEmailAuth ? CandidateActionModalType.PromptForEmailAuth : props.modalType
    ];

  return ModalComponent;
};
