import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as TrashIconSVG } from "assets/icons/trash.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import Confirm from "components/Modal/ConfirmModal";
import { useDeleteCampaignMutation } from "services/doverapi/endpoints/campaign";
import { Campaign, ListCampaign } from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

const DeleteCampaignButton = ({
  campaign,
  onClose,
}: {
  campaign: ListCampaign | Campaign;
  onClose?: () => void;
}): React.ReactElement => {
  const [deleteCampaign, { isLoading }] = useDeleteCampaignMutation();

  const handleDeleteCampaign = async (): Promise<void> => {
    try {
      await deleteCampaign({
        id: campaign.id!,
        name: campaign.name!,
      })
        .unwrap()
        .then((): void => onClose?.());
      showSuccessToast(`Successfully deleted ${campaign.name ?? "campaign"}`);
    } catch (e) {
      console.error(e);
      showErrorToast(`Failed to delete ${campaign.name ?? "campaign"}. Please try again.`);
    }
  };

  return (
    <Confirm
      title="Delete campaign"
      content={<BodySmall>{`Are you sure you want to delete ${campaign.name ?? "this campaign"}?`}</BodySmall>}
      useProcessing
      processing={isLoading}
      submitText="Delete"
      submitVariant="critical"
    >
      {/* @ts-ignore this typing is brutal */}
      {(confirm: any): React.ReactNode => (
        <Button variant={ButtonVariant.Secondary} onClick={confirm((): Promise<void> => handleDeleteCampaign())}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TrashIconSVG />
            <BodySmall>Delete</BodySmall>
          </Stack>
        </Button>
      )}
    </Confirm>
  );
};

export default DeleteCampaignButton;
