import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Checkbox, Chip, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import BuildingIcon from "assets/icons/building-icon-grey.svg";
import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import { Autocomplete, ControlledAutocomplete } from "components/library/Autocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { StyledSwitch } from "components/library/Switch";
import { Body, BodySmall, Overline, Subtitle2 } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useOpenApiClients } from "hooks/openApiClients";
import { useGetDoversTargetCompanyLists } from "services/doverapi/endpoints/search-v3/customHooks";
import { useListCompanySizesQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import {
  Company,
  CompanyList,
  ListCompanySizeSerializer,
  ProfileSearchIndustrySerializer,
} from "services/openapi/models";
import { colors } from "styles/theme";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import RequiredToggle from "views/sourcing/Search/components/RequiredToggle";
import { EditCompanyListsModal, SaveNewCompanyListModal } from "views/sourcing/Search/components/SaveAsListModal";
import TitleWithRequiredLabel from "views/sourcing/Search/components/TitleWithRequiredLabel";
import {
  COMPANY_RANK_RANGE_NAME,
  COMPANY_SIZES_EXCLUSIONS_NAME,
  COMPANY_SIZES_NAME,
  FiltersContainerID,
  INDIVIDUAL_COMPANIES_ELEMENTS_NAME,
  INDIVIDUAL_EXCLUDED_COMPANIES_NAME,
  INDUSTRIES_ELEMENTS_NAME,
  EXCLUDED_INDUSTRIES_ELEMENTS_NAME,
  INDUSTRIES_MOST_RECENT_ONLY,
  INDUSTRIES_NAME,
  EXCLUDED_INDUSTRIES_NAME,
  previewStateMarginBottom,
  previewStateMarginRight,
  SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY,
  SELECTED_DOVER_COMPANY_LISTS_NAME,
  sliderPaddingRight,
  COMPANY_TIER_TO_PERCENTILE_EXPLAINER,
  CHIP_MAX_WIDTH,
  INDIVIDUAL_COMPANIES_NAME,
} from "views/sourcing/Search/constants";
import { useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";
import { COMPANY_TIER_MAX } from "views/sourcing/Search/utils";

const CompanySizeTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const companySizes = useWatch({ control, name: COMPANY_SIZES_NAME });

    const onChipDelete = React.useCallback(
      (value: ListCompanySizeSerializer) => {
        return setValue(COMPANY_SIZES_NAME, {
          value: companySizes.value.filter(companySize => companySize.name !== value.name),
          required: companySizes.required,
          mostRecentOnly: companySizes.mostRecentOnly,
        });
      },
      [companySizes, setValue]
    );

    if (companySizes === undefined || companySizes.value.length === 0 || expanded) {
      return <></>;
    }

    return (
      <Box>
        {companySizes.value.map(companySize => {
          return (
            <Chip
              label={<TextWithMaxWidth label={companySize.name} width={CHIP_MAX_WIDTH} />}
              key={companySize.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(companySize)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

const CompanySizeFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const companySizes = useWatch({ control, name: COMPANY_SIZES_NAME });
    const excludedCompanySizes = useWatch({ control, name: COMPANY_SIZES_EXCLUSIONS_NAME });

    const { data: companySizesOptions } = useListCompanySizesQuery();

    const getIsCompanySizeChecked = React.useCallback(
      (companySizeOption: ListCompanySizeSerializer) => {
        return !!companySizes.value.find(companySize => companySize.name === companySizeOption.name);
      },
      [companySizes]
    );

    const setCompanySizeChecked = React.useCallback(
      (companySizeOption: ListCompanySizeSerializer, isChecked: any) => {
        const newCompanySizes = companySizes.value.filter(companySize => companySize.name !== companySizeOption.name);

        if (isChecked) {
          newCompanySizes.push(companySizeOption);
        }

        return setValue(COMPANY_SIZES_NAME, {
          value: newCompanySizes,
          required: companySizes.required,
          mostRecentOnly: companySizes.mostRecentOnly,
        });
      },
      [companySizes, setValue]
    );

    const getIsExclusionChecked = React.useCallback(
      (companySizeOption: ListCompanySizeSerializer) => {
        return !!excludedCompanySizes.value.find(companySize => companySize.name === companySizeOption.name);
      },
      [excludedCompanySizes]
    );

    const setExclusionChecked = React.useCallback(
      (companySizeOption: ListCompanySizeSerializer, isChecked: any) => {
        const newCompanySizes = excludedCompanySizes.value.filter(
          companySize => companySize.name !== companySizeOption.name
        );

        if (isChecked) {
          newCompanySizes.push(companySizeOption);
        }

        return setValue(COMPANY_SIZES_EXCLUSIONS_NAME, {
          value: newCompanySizes,
          required: excludedCompanySizes.required,
          mostRecentOnly: excludedCompanySizes.mostRecentOnly,
        });
      },
      [excludedCompanySizes, setValue]
    );

    const companySizesRequiredToggle = React.useMemo(() => {
      return (
        <RequiredToggle
          required={companySizes.required}
          onChange={(required: boolean): void => {
            setValue(COMPANY_SIZES_NAME, {
              value: companySizes.value,
              required,
              mostRecentOnly: companySizes.mostRecentOnly,
            });
          }}
        />
      );
    }, [companySizes.required, companySizes.value, companySizes.mostRecentOnly, setValue]);

    const companySizesCheckboxes = React.useMemo(() => {
      if (!companySizesOptions) {
        return (
          <Stack direction="row">
            <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
            <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
          </Stack>
        );
      }

      return (
        <Stack spacing={1}>
          <Stack>
            {[...companySizesOptions].map(companySize => {
              return (
                <Stack direction="row" alignItems="center" key={companySize.minSize}>
                  <Checkbox
                    sx={{
                      pl: "0px",
                      color: colors.grayscale.gray400,
                      "&.Mui-checked": {
                        color: colors.primary.base,
                      },
                    }}
                    checked={getIsCompanySizeChecked(companySize)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setCompanySizeChecked(companySize, event.target.checked);
                    }}
                  />
                  <BodySmall color={colors.grayscale.gray700}>{companySize.name}</BodySmall>
                </Stack>
              );
            })}
          </Stack>
          {companySizesRequiredToggle}
        </Stack>
      );
    }, [companySizesOptions, companySizesRequiredToggle, getIsCompanySizeChecked, setCompanySizeChecked]);

    const excludedCompanySizesCheckboxes = React.useMemo(() => {
      if (!companySizesOptions) {
        return (
          <Stack direction="row">
            <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
            <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
          </Stack>
        );
      }

      return (
        <Stack spacing={1}>
          <FormControlLabel
            sx={{
              margin: "0px",
            }}
            checked={companySizes.mostRecentOnly}
            onChange={(e: any): void => {
              setValue(COMPANY_SIZES_NAME, {
                value: companySizes.value,
                required: companySizes.required,
                mostRecentOnly: e.target.checked,
              });
            }}
            control={<StyledSwitch />}
            label={<BodySmall color={colors.grayscale.gray700}>Current position only</BodySmall>}
          />
          <Subtitle2>Excluded</Subtitle2>
          <Stack>
            {[...companySizesOptions].map(companySize => {
              return (
                <Stack direction="row" alignItems="center" key={companySize.minSize}>
                  <Checkbox
                    sx={{
                      pl: "0px",
                      color: colors.grayscale.gray400,
                      "&.Mui-checked": {
                        color: colors.primary.base,
                      },
                    }}
                    checked={getIsExclusionChecked(companySize)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setExclusionChecked(companySize, event.target.checked);
                    }}
                  />
                  <BodySmall color={colors.grayscale.gray700}>{companySize.name}</BodySmall>
                </Stack>
              );
            })}
          </Stack>
          {/* {excludedCompanySizesRequiredToggle} */}
        </Stack>
      );
    }, [
      companySizes.mostRecentOnly,
      companySizes.required,
      companySizes.value,
      companySizesOptions,
      getIsExclusionChecked,
      setExclusionChecked,
      setValue,
    ]);

    return (
      <Stack spacing={2}>
        {companySizesCheckboxes}
        <AdvancedAccordion title="Advanced" expandedContent={excludedCompanySizesCheckboxes} />
      </Stack>
    );
  }
);

interface IndividualCompaniesFiltersContentProps {
  toggleCompanyListModalOn: () => void;
}

const SpecificTargetCompanies = React.memo(
  ({ toggleCompanyListModalOn }: IndividualCompaniesFiltersContentProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const individualCompaniesInControl = useWatch({ control, name: INDIVIDUAL_COMPANIES_ELEMENTS_NAME });

    const client = useOpenApiClients()?.apiApi;
    const fetchIndividualCompanies = async (request: string): Promise<Company[]> => {
      if (client === undefined) {
        return [];
      }

      const companies = await client.listSimilarCompanies({ limit: 50, fullTextSearch: request });
      return companies.results;
    };

    const search = useGetSearchFromUrl();

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1} alignItems="start">
        <ControlledAutocomplete
          title="Specific Companies"
          control={control}
          fontSize="small"
          placeholder={"Enter specific companies..."}
          noOptionsText={"Start typing to see companies"}
          fetch={fetchIndividualCompanies}
          filterSelectedOptions={true}
          name={INDIVIDUAL_COMPANIES_ELEMENTS_NAME}
          initialValues={individualCompaniesInControl ?? []}
          multiple={true}
          sortByField={"name"}
          getOptionLabel={(option: Company): string =>
            option.url ? `${option.name} (${option.url})` : `${option.name}`
          }
        />
        <Button variant={ButtonVariant.Secondary} removeOutline onClick={toggleCompanyListModalOn}>
          <Overline color={colors.grayscale.gray600}>{"Save as list"}</Overline>
        </Button>
      </Stack>
    );
  }
);

const SpecificExcludedCompanies = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const individualExcludedCompaniesInControl = useWatch({ control, name: INDIVIDUAL_EXCLUDED_COMPANIES_NAME });

    const client = useOpenApiClients()?.apiApi;
    const fetchIndividualCompanies = async (request: string): Promise<Company[]> => {
      if (client === undefined) {
        return [];
      }

      const companies = await client.listSimilarCompanies({ limit: 50, fullTextSearch: request });
      return companies.results;
    };

    const search = useGetSearchFromUrl();
    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <ControlledAutocomplete
        title="Excluded Companies"
        control={control}
        fontSize="small"
        placeholder={"Enter specific companies..."}
        noOptionsText={"Start typing to see companies"}
        filterSelectedOptions={true}
        fetch={fetchIndividualCompanies}
        name={INDIVIDUAL_EXCLUDED_COMPANIES_NAME}
        initialValues={individualExcludedCompaniesInControl ?? []}
        multiple={true}
        sortByField={"name"}
        getOptionLabel={(option: Company): string => (option.url ? `${option.name} (${option.url})` : `${option.name}`)}
      />
    );
  }
);

const ClientLevelExclusions = React.memo(
  (): React.ReactElement => {
    const search = useGetSearchFromUrl();
    const clientLevelExclusions = search?.clientLevelCompanyExclusions ?? [];

    if (!clientLevelExclusions.length) {
      return <></>;
    }

    return (
      <Autocomplete
        title="Organization-level exclusions"
        fontSize="small"
        readOnly={true}
        // @ts-ignore
        freeSolo={true}
        placeholder={""}
        tooltipText={
          <>
            {"Candidates from these companies will be excluded. To change these, please visit the company setup "}
            <Link to={`/setup/company/exclusions`} target="_blank">
              page
            </Link>
          </>
        }
        initialValues={clientLevelExclusions}
        multiple={true}
        sortByField={"name"}
      />
    );
  }
);

const CompanyListsMostRecentOnly = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const companyListsMostRecentOnly = useWatch({ control, name: SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY });

    return (
      <FormControlLabel
        sx={{
          margin: "0px",
        }}
        checked={companyListsMostRecentOnly}
        onChange={(e: any): void => {
          setValue(SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY, e.target.checked);
        }}
        control={<StyledSwitch />}
        label={<BodySmall color={colors.grayscale.gray700}>Current position only</BodySmall>}
      />
    );
  }
);

const CompanyListsFilterTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const individualCompaniesInControl = useWatch({ control, name: INDIVIDUAL_COMPANIES_ELEMENTS_NAME });
    const companyListsInControl = useWatch({ control, name: SELECTED_DOVER_COMPANY_LISTS_NAME });

    const onChipDelete = React.useCallback(
      (value: Company | CompanyList) => {
        setValue(
          INDIVIDUAL_COMPANIES_ELEMENTS_NAME,
          individualCompaniesInControl.filter(company => company.id !== value.id)
        );
        setValue(
          SELECTED_DOVER_COMPANY_LISTS_NAME,
          companyListsInControl.filter(companyList => companyList.id !== value.id)
        );
      },
      [companyListsInControl, individualCompaniesInControl, setValue]
    );

    if (
      expanded ||
      companyListsInControl === undefined ||
      (individualCompaniesInControl.length === 0 && companyListsInControl.length === 0)
    ) {
      return <></>;
    }

    return (
      <Box>
        {companyListsInControl?.map(companyList => {
          return (
            <Chip
              label={<TextWithMaxWidth label={companyList.name} width={CHIP_MAX_WIDTH} />}
              key={companyList.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(companyList)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
        {individualCompaniesInControl.map(company => {
          const companyName = company.url !== undefined ? `${company.name} (${company.url})` : `${company.name}`;
          return (
            <Chip
              label={<TextWithMaxWidth label={companyName} width={CHIP_MAX_WIDTH} />}
              key={company.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(company)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

interface CompanyListsProps {
  toggleEditCompanyListModalOn: () => void;
}

const CompanyLists = React.memo(
  ({ toggleEditCompanyListModalOn }: CompanyListsProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const companyListsInControl = useWatch({ control, name: SELECTED_DOVER_COMPANY_LISTS_NAME });
    const { data: doversTargetCompanyListOptions } = useGetDoversTargetCompanyLists();

    if (!doversTargetCompanyListOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1}>
        <ControlledAutocomplete
          title={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <BodySmall weight="500">Lists</BodySmall>
              <Stack
                sx={{
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <BodySmall weight="500" color={colors.link} onClick={toggleEditCompanyListModalOn}>
                  Edit
                </BodySmall>
              </Stack>
            </Stack>
          }
          control={control}
          fontSize="small"
          placeholder={"Enter a company list..."}
          staticOptions={doversTargetCompanyListOptions}
          filterSelectedOptions={true}
          name={SELECTED_DOVER_COMPANY_LISTS_NAME}
          getOptionLabel={(option: Company): string => option.name}
          initialValues={companyListsInControl ?? []}
          multiple={true}
          sortByField={"name"}
        />
      </Stack>
    );
  }
);

const CompanyListFiltersAdvancedContent = React.memo(
  (): React.ReactElement => {
    return (
      <Stack spacing={1}>
        {/* Stack to remove spacing here */}
        <Stack>
          <CompanyListsMostRecentOnly />
        </Stack>
        <SpecificExcludedCompanies />
        <ClientLevelExclusions />
      </Stack>
    );
  }
);

const CompanyListsFiltersContent = React.memo(
  (): React.ReactElement => {
    const [newCompanyListModalOpen, setNewCompanyListModalOpen] = React.useState<boolean>(false);
    const [editCompanyListModalOpen, setEditCompanyListModalOpen] = React.useState<boolean>(false);

    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const companiesInControl = useWatch({ control, name: INDIVIDUAL_COMPANIES_NAME });

    const toggleNewCompanyListModalOn = React.useCallback(() => {
      setNewCompanyListModalOpen(true);
    }, []);

    const toggleNewCompanyListModalOff = React.useCallback(() => {
      setNewCompanyListModalOpen(false);
    }, []);

    const toggleEditCompanyListModalOn = React.useCallback(() => {
      setEditCompanyListModalOpen(true);
    }, []);

    const toggleEditCompanyListModalOff = React.useCallback(() => {
      setEditCompanyListModalOpen(false);
    }, []);

    return (
      <>
        <Stack spacing={1}>
          <SpecificTargetCompanies toggleCompanyListModalOn={toggleNewCompanyListModalOn} />
          <CompanyLists toggleEditCompanyListModalOn={toggleEditCompanyListModalOn} />
          <RequiredToggle
            required={companiesInControl.required}
            onChange={(required: boolean): void => {
              setValue(INDIVIDUAL_COMPANIES_NAME, {
                individualCompanyElements: companiesInControl.individualCompanyElements,
                required,
              });
            }}
          />
          <AdvancedAccordion title="Advanced" expandedContent={<CompanyListFiltersAdvancedContent />} />
        </Stack>
        <SaveNewCompanyListModal isModalOpen={newCompanyListModalOpen} toggleModalOff={toggleNewCompanyListModalOff} />
        <EditCompanyListsModal
          isModalOpen={editCompanyListModalOpen}
          toggleModalOff={toggleEditCompanyListModalOff}
          toggleNewCompanyModalOn={toggleNewCompanyListModalOn}
        />
      </>
    );
  }
);

const IndustryFilterTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const industries = useWatch({ control, name: INDUSTRIES_NAME });

    const onChipDelete = React.useCallback(
      (value: ProfileSearchIndustrySerializer) => {
        setValue(INDUSTRIES_NAME, {
          industryElements: industries.industryElements.filter(industry => industry.id !== value.id),
          required: industries.required,
        });
      },
      [industries, setValue]
    );

    if (industries === undefined || industries.industryElements.length === 0 || expanded) {
      return <></>;
    }

    return (
      <Box>
        {industries.industryElements.map(industry => {
          return (
            <Chip
              label={<TextWithMaxWidth label={industry.friendlyName} width={CHIP_MAX_WIDTH} />}
              key={industry.id}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(industry)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

const IndustriesMostRecentOnly = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const industriesMostRecentOnly = useWatch({ control, name: INDUSTRIES_MOST_RECENT_ONLY });

    const IndustriesMostRecentOnlySwitch = React.useMemo(() => {
      return (
        <FormControlLabel
          sx={{
            margin: "0px",
          }}
          checked={industriesMostRecentOnly}
          onChange={(e: any): void => {
            setValue(INDUSTRIES_MOST_RECENT_ONLY, e.target.checked);
          }}
          control={<StyledSwitch />}
          label={<BodySmall color={colors.grayscale.gray700}>Current position only</BodySmall>}
        />
      );
    }, [industriesMostRecentOnly, setValue]);

    return IndustriesMostRecentOnlySwitch;
  }
);

interface IndustryAutocompleteProps {
  watchName: "industries" | "excludedIndustries";
  formFieldName: "industries.industryElements" | "excludedIndustries.industryElements";
  showRequiredToggle?: boolean;
}

export const IndustryAutocomplete = React.memo(
  ({ watchName, formFieldName, showRequiredToggle = true }: IndustryAutocompleteProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const [selectedChildren, setSelectedChildren] = useState<string[]>([]);
    const industries = useWatch({ control, name: watchName });

    const client = useOpenApiClients()?.apiApi;
    const fetchIndustries = async (request: string): Promise<ProfileSearchIndustrySerializer[]> => {
      if (client === undefined) {
        return [];
      }

      const industries = await client.listProfileSearchIndustries({ limit: 50, fullTextSearch: request });
      const results = industries.results;
      // want to separate industries that will be auto added from those that will not
      const industriesNotAutoAdded = results.filter(industry => !selectedChildren.includes(industry.canonicalKeyword));
      const industriesAlreadyAdded: ProfileSearchIndustrySerializer[] = selectedChildren.map(child => ({
        id: uuidv4(),
        canonicalKeyword: child,
        friendlyName: child,
        aliases: [],
      }));
      // now sort the industries by friendlyName
      const orderedIndustriesNotAutoAdded = sortBy(industriesNotAutoAdded, "friendlyName");
      const orderedIndustriesAlreadyAdded = sortBy(industriesAlreadyAdded, "friendlyName");
      return [...orderedIndustriesAlreadyAdded, ...orderedIndustriesNotAutoAdded];
    };

    const selectedCanonicalIndustries = React.useMemo(() => {
      return industries.industryElements.map(industry => industry.friendlyName);
    }, [industries]);

    // this useEffect keeps track of which industries's children will be auto selected in the backend
    useEffect(() => {
      if (client === undefined) {
        return;
      }
      const setChildrenUsingListBucketChildrenAndAliases = async (): Promise<void> => {
        const results = await client.listIndustriesChildrenAndAliases({
          data: { industries: selectedCanonicalIndustries },
        });
        setSelectedChildren(results.industriesChildrenAndAliases);
      };

      setChildrenUsingListBucketChildrenAndAliases();
    }, [client, industries, selectedCanonicalIndustries]);

    const search = useGetSearchFromUrl();

    const industriesRequiredToggle = React.useMemo(() => {
      if (watchName === EXCLUDED_INDUSTRIES_NAME) return <></>;

      return (
        <RequiredToggle
          required={industries.required}
          onChange={(required: boolean): void => {
            setValue(watchName, { industryElements: industries.industryElements, required });
          }}
        />
      );
    }, [watchName, industries.industryElements, industries.required, setValue]);

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <>
        <ControlledAutocomplete
          control={control}
          fontSize="small"
          placeholder={"Enter an industry..."}
          noOptionsText={"Start typing to see industries"}
          fetch={fetchIndustries}
          filterSelectedOptions={true}
          name={formFieldName}
          getOptionLabel={(option: ProfileSearchIndustrySerializer): string => option.friendlyName}
          initialValues={industries.industryElements ?? []}
          multiple={true}
          getOptionDisabled={(option: ProfileSearchIndustrySerializer): boolean =>
            selectedChildren.includes(option.canonicalKeyword)
          }
          renderOption={(props, option: ProfileSearchIndustrySerializer): React.ReactElement => {
            if (selectedCanonicalIndustries.includes(option.canonicalKeyword)) {
              return (
                // @ts-ignore
                <StyledListItem {...props} key={option.friendlyName}>
                  <Body>{`${option.canonicalKeyword} `}</Body>
                  <BodySmall>{", already added"}</BodySmall>
                </StyledListItem>
              );
            }
            if (selectedChildren.includes(option.canonicalKeyword)) {
              return (
                // @ts-ignore
                <StyledListItem {...props} key={option.friendlyName}>
                  <Body>{`${option.canonicalKeyword} `}</Body>
                  <BodySmall>{", will be auto added"}</BodySmall>
                </StyledListItem>
              );
            }

            return (
              // @ts-ignore
              <StyledListItem {...props} key={option.canonicalKeyword}>
                <Body>{option.canonicalKeyword}</Body>
              </StyledListItem>
            );
          }}
        />
        {showRequiredToggle && industriesRequiredToggle}
      </>
    );
  }
);

const IndustryFilterContent = React.memo(
  (): React.ReactElement => {
    const search = useGetSearchFromUrl();

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1}>
        <IndustryAutocomplete watchName={INDUSTRIES_NAME} formFieldName={INDUSTRIES_ELEMENTS_NAME} />
        <AdvancedAccordion
          title="Advanced"
          expandedContent={
            <Stack spacing={1}>
              <IndustriesMostRecentOnly />
              <Stack>
                <Subtitle2>Excluded Industries</Subtitle2>
                <IndustryAutocomplete
                  watchName={EXCLUDED_INDUSTRIES_NAME}
                  formFieldName={EXCLUDED_INDUSTRIES_ELEMENTS_NAME}
                />
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }
);

const CompanyPrestigeTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const companyRankRange = useWatch({ control, name: COMPANY_RANK_RANGE_NAME });

    const tierLabelText = React.useMemo(() => {
      let label = "";
      if (companyRankRange.min) {
        label = `Top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.min]}`;
        if (companyRankRange.max !== undefined && companyRankRange.max !== COMPANY_TIER_MAX) {
          label += ` (excluding top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.max]})`;
        }
      } else if (companyRankRange.max !== undefined && companyRankRange.max !== COMPANY_TIER_MAX) {
        label = `Excluding top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.max]}`;
      }
      return label;
    }, [companyRankRange.max, companyRankRange.min]);

    if (expanded || !tierLabelText.length) {
      return <></>;
    }

    return (
      <Box>
        <Chip
          label={<TextWithMaxWidth label={tierLabelText} width={CHIP_MAX_WIDTH} />}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      </Box>
    );
  }
);

const CompanyPrestigeFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const companyRankRange = useWatch({ control, name: COMPANY_RANK_RANGE_NAME });

    const handleSpecificYOESliderChange = React.useCallback(
      (value: YearsOfExperienceRange) => {
        setValue(COMPANY_RANK_RANGE_NAME, {
          min: value.min ?? 0,
          max: value.max ?? COMPANY_TIER_MAX,
        });
      },
      [setValue]
    );

    const tierLabel = React.useMemo(() => {
      let label = "No Thresholds Selected";
      if (companyRankRange.min !== undefined && companyRankRange.min !== 0) {
        label = `Top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.min]}`;
        if (companyRankRange.max !== undefined && companyRankRange.max !== COMPANY_TIER_MAX) {
          label += ` (excluding top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.max]})`;
        }
      } else if (companyRankRange.max !== undefined && companyRankRange.max !== COMPANY_TIER_MAX) {
        label = `Excluding top ${COMPANY_TIER_TO_PERCENTILE_EXPLAINER[companyRankRange.max]}`;
      }

      if (!label.length) {
        return <></>;
      }

      return (
        <Box padding="10px" width="fit-content" sx={{ backgroundColor: colors.grayscale.gray100 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <BodySmall>{label}</BodySmall>
          </Stack>
        </Box>
      );
    }, [companyRankRange.max, companyRankRange.min]);

    return (
      <Stack spacing={2} paddingRight={sliderPaddingRight}>
        <YOESlider
          values={companyRankRange}
          onChange={handleSpecificYOESliderChange}
          maximumValue={COMPANY_TIER_MAX}
          minimumRange={1}
          marks={true}
          shouldShowTooltip={false}
          scrollContainerId={FiltersContainerID}
        />
        {tierLabel}
      </Stack>
    );
  }
);

const CompaniesFilters = React.memo(
  (): React.ReactElement => {
    const [companySizeExpanded, setCompanySizeExpanded] = React.useState<boolean>(false);
    const [targetCosExpanded, setTargetCosExpanded] = React.useState<boolean>(false);
    const [industriesExpanded, setIndustriesExpanded] = React.useState<boolean>(false);

    return (
      <>
        <FilterSectionHeader title={"Companies"} icon={BuildingIcon} />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              requiredFieldName={COMPANY_SIZES_NAME}
              tooltipText="We estimate the company size at the time they worked there."
              mainTitle={"Size"}
              expanded={companySizeExpanded}
            />
          }
          TitleContent={CompanySizeTitleContent}
          expandedContent={<CompanySizeFiltersContent />}
          expandedCallback={setCompanySizeExpanded}
        />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              mainTitle={"Prestige"}
              tooltipText="Dover’s estimation of company prestige based on algorithmic signals"
            />
          }
          TitleContent={CompanyPrestigeTitleContent}
          expandedContent={<CompanyPrestigeFiltersContent />}
        />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              requiredFieldName={INDIVIDUAL_COMPANIES_NAME}
              mainTitle={"Target Companies"}
              expanded={targetCosExpanded}
            />
          }
          expandedCallback={setTargetCosExpanded}
          TitleContent={CompanyListsFilterTitleContent}
          expandedContent={<CompanyListsFiltersContent />}
        />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              requiredFieldName={INDUSTRIES_NAME}
              mainTitle={"Industries"}
              expanded={industriesExpanded}
            />
          }
          TitleContent={IndustryFilterTitleContent}
          expandedContent={<IndustryFilterContent />}
          expandedCallback={setIndustriesExpanded}
        />
      </>
    );
  }
);

export default CompaniesFilters;
