import { Checkbox, Stack } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAtomValue } from "jotai";
import React, { FC, ReactElement, useEffect, useRef } from "react";

import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle1 } from "components/library/typography";
import { useIsInViewport } from "hooks/useIsInViewport";
import { PassingAndFailingFilters, SaapReviewApplicationCandidateBucketLabelEnum } from "services/openapi";
import { colors } from "styles/theme";
import { multiSelectEnabledAtom } from "views/candidates/ApplicationReview/atoms/multiSelect";
import { MatchLabel } from "views/candidates/ApplicationReview/components/ApplicantListPanel/MatchLabel";
import { SampleChip } from "views/candidates/ApplicationReview/components/SampleChip";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

dayjs.extend(relativeTime);

interface ApplicantTypePerson {
  fullName?: string;
  latestTitleAtLatestCompany?: string;
  candidateLocation?: string;
  candidateBucketLabel?: SaapReviewApplicationCandidateBucketLabelEnum;
  explanation?: PassingAndFailingFilters;
  isDemoCandidate?: boolean;
  appliedAt?: Date;
}

interface ApplicantCardProps {
  application: ApplicantTypePerson;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
}

export const ApplicantCard: FC<React.PropsWithChildren<ApplicantCardProps>> = ({
  application,
  onClick,
  selected,
  disabled,
}): ReactElement => {
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);

  const candidateCardRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(candidateCardRef);

  useEffect(() => {
    if (selected && !isInViewport) {
      candidateCardRef.current?.scrollIntoView({ block: "center" });
    }

    // We specifically do not want to scroll into view as the user scrolls
    // So we aren't including isInViewport on purpose
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const appliedAtDate = application.appliedAt ? dayjs(application.appliedAt) : undefined;

  // check if applied over 90 days ago, if so, show date in red
  const ninetyDaysAgo = dayjs().subtract(90, "day");
  const appliedOver90DaysAgo = appliedAtDate ? appliedAtDate.isBefore(ninetyDaysAgo) : false;

  return (
    <Tooltip title={disabled ? "You are not able to bulk reject this candidate." : ""}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        p="0.5rem"
        mx="0.5rem"
        my="0.25rem"
        borderRadius="6px"
        bgcolor={selected ? colors.grayscale.gray100 : ""}
        sx={{ cursor: disabled ? "" : "pointer", "&:hover": { backgroundColor: colors.grayscale.gray200 } }}
        onClick={(): void => {
          disabled || onClick();
        }}
      >
        {multiSelectEnabled && (
          <Checkbox
            checked={!!selected}
            disabled={disabled}
            sx={{
              // checkbox-specific hack in order to maintain padding needed for the highlight bubble to appear uniform,
              // but visually shift the checkbox to the left
              ml: "-16px",
              "&:hover": { backgroundColor: "transparent" },
              "&.Mui-checked": {
                color: colors.primary.base,
              },
            }}
          />
        )}
        <Stack spacing={0.5} flex={5} justifyContent="start" height="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <TextWithMaxWidth TextElement={Subtitle1} label={application.fullName ?? ""} width="180px" />
            <Stack alignItems="flex-end" spacing={0.5}>
              {/* Only show the candidate label if job has scoring enabled */}
              {jobHasScoringEnabled && application.candidateBucketLabel && (
                <MatchLabel label={application.candidateBucketLabel} explanation={application.explanation} />
              )}
              {application.isDemoCandidate ? <SampleChip /> : <></>}
            </Stack>
          </Stack>
          {application.latestTitleAtLatestCompany && (
            <BodySmall color={colors.grayscale.gray500}>{application.latestTitleAtLatestCompany}</BodySmall>
          )}

          {application.candidateLocation && (
            <BodySmall italic color={colors.grayscale.gray500}>
              {application.candidateLocation}
            </BodySmall>
          )}
          {appliedAtDate && (
            <BodySmall color={appliedOver90DaysAgo ? colors.critical.base : colors.grayscale.gray500}>
              {appliedAtDate.fromNow(true)} ago
            </BodySmall>
          )}
        </Stack>
      </Stack>
    </Tooltip>
  );
};
