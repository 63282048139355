import { Box, Dialog, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC } from "react";

import { listHotkeysAtom } from "App/hotkeys/useHotkey";
import { Body } from "components/library/typography";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";

export const HotkeyMenu: FC<React.PropsWithChildren<GlobalModalProps>> = ({ isOpen, close }) => {
  const hotkeys = useAtomValue(listHotkeysAtom);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth
      maxWidth="sm"
      sx={{ ".MuiDialog-paper": { backgroundColor: colors.grayscale.gray600 } }}
    >
      <DialogTitle color={colors.white}>Keyboard Shortcuts</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={1.5} p={2}>
          {hotkeys.map(h => {
            // Hotkeys can be passed in as a comma separated string to indicate multiple hotkey options
            const keys = h[0].split(",");

            return (
              <Stack key={h[0]} direction="row" justifyContent="space-between" alignItems="center">
                <Body color={colors.white}>{h[1]}</Body>
                <Stack direction="row" spacing={0.5}>
                  {keys.map((k, idx) => (
                    <Stack key={idx} direction="row" spacing={0.5} alignItems="center">
                      <Box p={1} borderRadius={1} bgcolor={colors.grayscale.gray700}>
                        <Body color={colors.white}>{k}</Body>
                      </Box>
                      {idx !== keys.length - 1 && <Body color={colors.white}>or</Body>}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const HotkeyMenuAtom = modalAtom(HotkeyMenu);
