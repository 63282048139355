import {
  BooleanParam,
  createEnumArrayParam,
  createEnumParam,
  NumberParam,
  StringParam,
  ArrayParam,
  withDefault,
} from "use-query-params";

import {
  CandidateFilterSourcingContextEnum,
  CandidateFilterStatusEnum,
  CandidateNextActionSchedulingOwnershipEnum,
  NextAction,
} from "services/openapi";

// These enums are used in the pipeline list view under the next up column
export enum NextUpLabelEnum {
  Sourced = "Sourced",
  OutreachQueued = "Outreach Queued",
  Contacted = "Contacted",
  NeedsAction = "Needs Action",
  NeedsResponse = "Needs Response",
  NeedsReview = "Needs Review",
  DoverScheduling = "Dover Scheduling",
  YouAreScheduling = "You Are Scheduling",
  InterviewScheduled = "Interview Scheduled",
  InterviewCompletedWithDover = "Interview Completed With Dover",
  PendingCustomerResponse = "Needs Response",
}

// these enums are used in the review page
export enum ReviewFilterEnum {
  RespondedAsInterested = "RESPONDED_AS_INTERESTED",
  PendingCustomerResponse = "PENDING_CUSTOMER_RESPONSE",
  CompletedInterview = "COMPLETED_INTERVIEW",
  Mentions = "MENTIONS",
}

export enum QuickFilterEnum {
  // review page
  AllNeedsAction = "ALL_NEEDS_ACTION",
  Applicants = "Applicants",
  RespondedAsInterested = "RESPONDED_AS_INTERESTED",
  PendingCustomerResponse = "PENDING_CUSTOMER_RESPONSE",
  CompletedInterview = "COMPLETED_INTERVIEW",
  Mentions = "MENTIONS",

  // pipeline view
  Interviewing = "INTERVIEWING",
  Scheduled = "SCHEDULED",
  Rejected = "REJECTED",
  Snoozed = "SNOOZED",
}

type CandidateSourcingContextLabel =
  | "Referral"
  | "Manually Added"
  | "Dover Sourcing"
  | "Inbound Application"
  | "Agency";

export interface CandidateSourcingContext {
  label: CandidateSourcingContextLabel;
  value: CandidateFilterSourcingContextEnum;
}

export interface CandidateStatusLabel {
  label: string;
  value: CandidateFilterStatusEnum;
}

export interface EnrichedNextAction extends NextAction {
  label: string;
  schedulingOwnership?: CandidateNextActionSchedulingOwnershipEnum;
}

export interface CandidateNextActionMap {
  [candidateId: string]: EnrichedNextAction | undefined;
}

export enum CandidatesViewType {
  Table = "TABLE",
  Board = "BOARD",
}

export const PageParam = withDefault(NumberParam, 0);

export const OrderingParam = withDefault(StringParam, undefined);

export const HasMentionParam = withDefault(BooleanParam, false);

export const OutReachEmailEditorOpenParam = withDefault(BooleanParam, false);

// HPS Id filter
export const StageIdParam = withDefault(ArrayParam, undefined);
// substages filter for pipeline stages
export const SubstageParam = withDefault(ArrayParam, undefined);

export const StatusParam = withDefault<CandidateFilterStatusEnum[] | null | undefined, CandidateFilterStatusEnum[]>(
  createEnumArrayParam(Object.values(CandidateFilterStatusEnum)),
  []
);

export const SourcingContextParam = withDefault<
  CandidateFilterSourcingContextEnum[] | null | undefined,
  CandidateFilterSourcingContextEnum[]
>(createEnumArrayParam(Object.values(CandidateFilterSourcingContextEnum)), []);

export const QuickFilterParam = withDefault(createEnumParam(Object.values(QuickFilterEnum)), undefined);

export enum PipelineProcessingStage {
  Queued = "QUEUED",
  Contacted = "CONTACTED",
  Responded = "RESPONDED",
}

export interface PipelineProcessingStageMapping {
  name: string;
  processingStage: PipelineProcessingStage;
  stageIds: string[];
}

export enum PipelineExpandOption {
  CampaignMessageRequest = "CAMPAIGN_MESSAGE_REQUEST",
}
