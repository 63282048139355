import { MenuItem, Box, SelectChangeEvent, Select, ListSubheader, Stack } from "@mui/material";
import React, { ReactElement, useCallback } from "react";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { InfoTip } from "components/InfoTip";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

const genMenuItems = (stages: Stage[]): ReactElement[] => {
  const items: ReactElement[] = [];

  stages?.forEach((option: Stage, index: number) => {
    if (!option.id) {
      items.push(
        <ListSubheader sx={{ "& hr": { margin: 0.2 } }}>
          <hr />
        </ListSubheader>
      );
    }

    items.push(
      <MenuItem
        key={index}
        value={option.id}
        sx={{ "&.MuiButtonBase-root": { display: "flex", justifyContent: "start", paddingLeft: "8px" } }}
      >
        <Stack spacing={0.5} direction="row" alignItems={"center"}>
          <BodySmall>{option.name}</BodySmall>
          {!option.id && <InfoTip text={"Schedule an interview not related to a specific interview stage."} />}
        </Stack>
      </MenuItem>
    );
  });

  return items;
};

interface Stage {
  id: string;
  name: string;
}

interface StageSelectorProps {
  pipelineStageId?: string;
  setStage: (pipelineStageId: string) => void;
  stages?: Array<Stage>;
  borderColor?: string;
  disabled?: boolean;
}

// I really don't know how to find this type
// Will look harder another time
const DownArrowIcon = (props: any): ReactElement => (
  // We need to override the mui default top property to properly center the icon
  <Box {...props} top="auto !important">
    <DownCaretSVG width="30px" />
  </Box>
);

const StageSelector: React.FC<React.PropsWithChildren<StageSelectorProps>> = ({
  pipelineStageId,
  setStage,
  stages,
  borderColor,
  disabled,
}) => {
  const onChange = useCallback(
    (e: SelectChangeEvent<unknown>): void => {
      const value = e.target.value as string;
      setStage(value);
    },
    [setStage]
  );

  return (
    <Select
      size="small"
      // MUI selects doesn't like `undefined` values
      value={pipelineStageId ?? ""}
      disabled={disabled || !stages?.length}
      displayEmpty
      onChange={onChange}
      IconComponent={DownArrowIcon}
      sx={{
        border: "none",
        backgroundColor: colors.white,
        borderColor,
      }} // This is to fix a mui v4 issue
      renderValue={(value): React.ReactNode => {
        const option = stages?.find((stage: Stage) => stage.id === value);
        const optionLabel = option?.name;

        if (value === undefined || !optionLabel) {
          return (
            <Box display="flex" alignItems="center">
              <BodySmall color={colors.grayscale.gray400}>Select a stage</BodySmall>
            </Box>
          );
        }

        return (
          <Box display="flex" alignItems="center">
            <BodySmall>{optionLabel}</BodySmall>
          </Box>
        );
      }}
    >
      {genMenuItems(stages ?? [])}
    </Select>
  );
};

export default StageSelector;
