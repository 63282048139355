/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, ChangeEvent } from "react";
import { FieldRenderProps } from "react-final-form";
import Slider from "react-input-slider";
import styled from "styled-components";

type SliderFieldProps = FieldRenderProps<number, any>;

interface SliderOtherProps {
  xmin?: number;
  xmax?: number;
}

interface SliderProps extends SliderFieldProps, SliderOtherProps {}

const styles = {
  track: {
    maxWidth: 120,
  },
};

const Input = styled.input`
  max-width: 120px;
`;

export const XAxisSlider: React.FC<React.PropsWithChildren<SliderProps>> = ({
  input: { value, onChange },
  xmin,
  xmax,
}: SliderProps) => {
  const [state, setState] = useState({ x: 0 });
  const cleanValue = typeof value !== "number" ? parseFloat(value) : value;

  useEffect(() => {
    if (value) {
      setState({ x: parseFloat(cleanValue.toFixed(3)) });
    }
  }, []);

  return (
    <div>
      <div>
        <Input
          type="number"
          step="0.01"
          value={state.x}
          size={5}
          onChange={({ target }: ChangeEvent<HTMLInputElement>): void => {
            const xNumber = parseFloat(target.value);
            const xFloat = parseFloat(xNumber.toFixed(3));
            setState({ x: xFloat });
            onChange(xFloat);
          }}
        />
      </div>
      {/* @ts-ignore FIX: react 18 type incompatibility */}
      <Slider
        styles={styles}
        axis="x"
        xstep={0.01}
        xmin={xmin ?? 0}
        xmax={xmax ?? 1}
        x={state.x}
        onChange={({ x }): void => {
          const xFloat = parseFloat(x.toFixed(3));
          setState({ x: xFloat });
          onChange(xFloat);
        }}
      />
    </div>
  );
};
