import { Stack } from "@mui/material";
import React from "react";

import { InfoTip } from "components/InfoTip";
import { HiringPipelineStage, HiringPipelineStageType } from "services/openapi";
import { EditStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStage";
import { StageName } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";

interface InterestedStageRowProps {
  stage: HiringPipelineStage;
}

export const InterestedStageRow: React.FC<React.PropsWithChildren<InterestedStageRowProps>> = ({ stage }) => {
  const tooltipContent =
    stage.stageType === HiringPipelineStageType.RESPONDED
      ? "Candidates who have responded to a sourcing email"
      : undefined;

  return (
    <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <StageName name={stage.name} />
        {tooltipContent && <InfoTip text={tooltipContent} />}
      </Stack>
      <EditStage stage={stage} />
    </Stack>
  );
};
