import styled, { createGlobalStyle } from "styled-components";

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktopS: "2000px",
  desktop: "2560px",
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "${props => props.theme.fontFamily}",arial,sans-serif;
    background-color: ${props => props.theme.colors.grayscale.gray100};
  }

  h1 { font-size: 32px; font-weight: 300; }
  h2 { font-size: 22px; font-weight: 600; }
  h3 { font-size: 20px; font-weight: 400; }
  h4 { font-size: 18px; font-weight: 500; }
  h5 { font-size: 15px; font-weight: 600; }
  p { font-size: 18px; font-weight: 400; }

  #app {
    overflow-x: hidden;
  }

  .navbar-grid-item {
    padding-bottom: 0 !important;
  }
`;

export const TopLevelContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;

  @media ${device.laptop} {
    padding-left: ${props => props.theme.gutterSize};
    padding-right: ${props => props.theme.gutterSize};
  }
`;

export default GlobalStyles;
