import { Icon } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC, ReactElement } from "react";

import { candidateBioSocialLinkToDisplayMapV2 } from "components/CandidateHeader/SocialLinks/constants";
import { Tooltip } from "components/library/Tooltip";
import { SocialLinkV2 } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SocialLinks";

interface CommandBarSocialLinksProps {
  socialLinks: Array<SocialLinkV2>;
}

export const CommandBarSocialLinks: FC<React.PropsWithChildren<CommandBarSocialLinksProps>> = ({ socialLinks }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {socialLinks.map(
        (link): ReactElement => {
          const socialLinkDisplay = candidateBioSocialLinkToDisplayMapV2[link.linkType];

          return (
            <Tooltip title={socialLinkDisplay.name}>
              <a
                href={link.url}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon Icon={socialLinkDisplay.icon} color="white" size={socialLinkDisplay.width ?? 16} />
              </a>
            </Tooltip>
          );
        }
      )}
    </Stack>
  );
};
