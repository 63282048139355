import { Box, Select, Stack, MenuItem } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import NewTabIconSVG from "assets/icons/external-link.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import SendIcon from "assets/icons/send.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { EmailOwner } from "components/library/TipTap/types";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { UnsavedChangesModal } from "components/UnsavedChangesModal";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { selectFromListCampaignsQueryResult, useListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { useListValidEmailSendRequestSendTimesQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetCandidateV2Query } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import {
  useSendCampaignMessageRequestEmailMutation,
  useUpdateCampaignMessageRequestMutation,
} from "services/doverapi/endpoints/emailSending";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { CampaignMessageRequest } from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { StyledBodySmall } from "views/CandidateDetail/styles";
import { getFormattedSendAt } from "views/CandidateDetail/utils";
import { useGetDisableCandidateActions } from "views/candidates/hooks";
import { PipelineExpandOption } from "views/candidates/types";
import { StyledCheckbox } from "views/Referrals/ReviewReferrals/components/styles";

const RightOffsetStack: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ position: "relative", right: "12px" }}>
      {children}
    </Stack>
  );
};

const CASUAL_TONE = "casual";
const FORMAL_TONE = "formal";
const CUSTOM_DEFAULT_TONE = "";

export const CandidateDetailEmailEditor = ({
  candidateId,
  candidateEmail,
  candidateName,
  campaignMessageRequest,
  setBody,
  setSubject,
  selectedSendAt,
  setSelectedSendAt,
  setEmailEditorOpen,
  handleSaveToQueue,
}: {
  candidateId?: string;
  candidateEmail: string;
  candidateName: string;
  campaignMessageRequest?: CampaignMessageRequest;
  selectedSendAt: Date | undefined;
  setBody: (body: string) => void;
  setSubject: (subject: string) => void;
  setSelectedSendAt: (sendAt: Date) => void;
  setEmailEditorOpen: (open: boolean) => void;
  handleSaveToQueue: () => Promise<void>;
}): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);

  const disableActions = useGetDisableCandidateActions();
  const campaignHasEmailAlias = !!campaignMessageRequest?.emailAlias;

  // Derive whether Send Now should be shown
  const { data: clientOnboarding } = useGetClientOnboardingQuery();
  const { isFetching: jobSetupIsFetching } = useGetJobSetupQuery(jobId || skipToken);
  const { isLoading: loadingCandidate } = useGetCandidateV2Query(
    candidateId
      ? {
          id: candidateId,
          expand: PipelineExpandOption.CampaignMessageRequest,
        }
      : skipToken
  );

  // latest version of useLazyQuery has problem with referencing isLoading state, so need to do it ourselves
  const [toneForRegeneration, setToneForRegeneration] = useState<string>(CASUAL_TONE); // cant be null because it always has a default
  const [customOptionsForRegeneration, setCustomOptionsForRegeneration] = useState<string | null>(null);
  const [customRegenerationModalOpen, setCustomRegenerationModalOpen] = useState<boolean>(false);

  const { selectedCampaign } = useListCampaignsQuery(
    { jobId: jobId ?? "" },
    {
      selectFromResult: rtkResults =>
        selectFromListCampaignsQueryResult(rtkResults, { selectedCampaignId: campaignMessageRequest?.campaignId }),
    }
  );
  const showSendNowButton = !!selectedCampaign?.userDefinedSenderUser && !!clientOnboarding?.hasDoverManagedDomainSetUp;

  const initialBody = useMemo(() => {
    return campaignMessageRequest?.body || "";
  }, [campaignMessageRequest]);

  const initialSubject = useMemo(() => {
    return campaignMessageRequest?.subject || "";
  }, [campaignMessageRequest]);

  const initialSendAt = useMemo(() => {
    return campaignMessageRequest?.emailSendRequest?.sendAt;
  }, [campaignMessageRequest]);

  const { data: sendAtOptionsList, isFetching: fetchingSendAtOptions } = useListValidEmailSendRequestSendTimesQuery({
    data: { campaignMessageRequest: campaignMessageRequest?.id },
  });

  const [, { isLoading: updatingCampaignMessageRequest }] = useUpdateCampaignMessageRequestMutation();
  const [sendCampaignMessageRequestEmail, { isLoading: isSendingEmail }] = useSendCampaignMessageRequestEmailMutation();

  const sendAtOptions = useMemo(() => {
    if (sendAtOptionsList) {
      const options = sendAtOptionsList.map(option => option.value.toString());
      return [initialSendAt?.toString(), ...options];
    }
    return [];
  }, [initialSendAt, sendAtOptionsList]);

  const handleSend = async (): Promise<void> => {
    if (campaignMessageRequest?.id && candidateId) {
      await handleSaveToQueue();
      await sendCampaignMessageRequestEmail({
        campaignMessageRequestId: campaignMessageRequest.id!,
        candidateId,
      }).unwrap();
    }

    setEmailEditorOpen(false);
  };

  const handleCloseEmailEditor = (): void => {
    setEmailEditorOpen(false);
  };

  const toneIsCustom = React.useMemo(() => {
    return toneForRegeneration !== CASUAL_TONE && toneForRegeneration !== FORMAL_TONE;
  }, [toneForRegeneration]);

  const toneInputError = React.useMemo(() => {
    return toneIsCustom && (!toneForRegeneration || !toneForRegeneration.length);
  }, [toneForRegeneration, toneIsCustom]);

  ///////////////////////////////
  // React Memoized Components //
  ///////////////////////////////

  const editOutreachButton = React.useMemo(() => {
    return jobId ? (
      <InternalLink target="__blank" $variant="primary" to={APP_ROUTE_PATHS.job.outreach(jobId)}>
        <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="center">
          <BodySmall weight="500">Edit outreach template</BodySmall>
          <StyledIcon src={NewTabIconSVG} />
        </Stack>
      </InternalLink>
    ) : null;
  }, [jobId]);

  //////////////////////////////////////////
  // Regenerate Personalized Content Modal /
  //////////////////////////////////////////

  const toneOptionsSectionPCModal = React.useMemo(() => {
    return (
      <>
        <Heading>Tone</Heading>
        <Stack>
          <RightOffsetStack>
            <StyledCheckbox
              checked={toneForRegeneration === CASUAL_TONE}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                if (event.target.checked) {
                  setToneForRegeneration(CASUAL_TONE);
                } else {
                  setToneForRegeneration(CUSTOM_DEFAULT_TONE); // if we uncheck this, should select the custom option
                }
              }}
            />
            <BodySmall>Casual</BodySmall>
          </RightOffsetStack>
          <RightOffsetStack>
            <StyledCheckbox
              checked={toneForRegeneration === FORMAL_TONE}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                if (event.target.checked) {
                  setToneForRegeneration(FORMAL_TONE);
                } else {
                  setToneForRegeneration(CUSTOM_DEFAULT_TONE); // if we uncheck this, should select the custom option
                }
              }}
            />
            <BodySmall>Formal</BodySmall>
          </RightOffsetStack>
          <Box padding="0px 0px 15px 10px">
            <BodySmall color={colors.grayscale.gray400}>OR</BodySmall>
          </Box>
          <TextField
            fullWidth
            onFocus={(): void => {
              // only want to reset custom tone to its default if it wasnt already filled out
              if (!toneIsCustom) {
                setToneForRegeneration(CUSTOM_DEFAULT_TONE); // clear the tone if the user is typing in the custom tone field
              }
            }}
            text={toneIsCustom ? toneForRegeneration : CUSTOM_DEFAULT_TONE}
            onTextUpdated={(value: string): void => setToneForRegeneration(value)}
            placeholderText="Custom Tone"
            error={toneInputError}
            errorMessage={toneInputError ? "Please enter a valid tone" : undefined}
          />
        </Stack>
      </>
    );
  }, [toneForRegeneration, toneInputError, toneIsCustom]);

  const additionalInstructionsSectionPCModal = React.useMemo(() => {
    return (
      <>
        <Stack padding="10px 0px" direction="row" alignItems="center" spacing={1}>
          <Heading>Additional Instructions</Heading>
          <Tooltip title="For example, specify 'Only generate one sentence of personalized content.'">
            <HelpIcon className="svg-color" color={colors.grayscale.gray400} />
          </Tooltip>
        </Stack>
        <TextField
          text={customOptionsForRegeneration || ""}
          onTextUpdated={(value: string): void => setCustomOptionsForRegeneration(value)}
          placeholderText="Optional"
        />
        <BodySmall color={colors.grayscale.gray400}>Tell Dover AI to customize other things!</BodySmall>
      </>
    );
  }, [customOptionsForRegeneration]);

  const regeneratePersonalizedContentModal = React.useMemo(() => {
    return (
      <CustomModal
        open={customRegenerationModalOpen}
        onClose={(): void => setCustomRegenerationModalOpen(false)}
        maxWidth="xs"
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            {toneOptionsSectionPCModal}
            {additionalInstructionsSectionPCModal}
          </Stack>
        </Stack>
      </CustomModal>
    );
  }, [additionalInstructionsSectionPCModal, customRegenerationModalOpen, toneOptionsSectionPCModal]);

  //////////////////
  // Final Returns /
  //////////////////

  if (fetchingSendAtOptions) {
    return <DoverLoadingSpinner />;
  }

  return (
    <>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={(): void => {
          setShowUnsavedChangesModal(false);
        }}
        onDiscard={(): void => setEmailEditorOpen(false)}
        onSave={(): void => {
          setShowUnsavedChangesModal(false);
          handleSaveToQueue();
        }}
      />
      {regeneratePersonalizedContentModal}

      <Stack spacing={3}>
        <Box sx={{ "& .texteditor-emailbody > div": { height: "40vh" } }}>
          <EmailEditor
            from={{
              id: "",
              email: campaignMessageRequest?.emailAlias?.email || "",
              label: campaignMessageRequest?.emailAlias?.fullName || "",
              isAuthed: true,
              owner: EmailOwner.AuthedUser,
            }}
            to={{
              id: "",
              email: candidateEmail,
              label: candidateName,
            }}
            body={initialBody}
            subject={initialSubject}
            onBodyChanged={setBody}
            onSubjectChanged={setSubject}
            hideSaveTemplateButton
          />
        </Box>
        <Stack width="100%" justifyContent="space-between" alignItems="center" direction="row">
          <Stack width="100%" spacing={0.5}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              {editOutreachButton}
            </Stack>
            <Box display="flex" justifyContent="flex-start" paddingLeft="20px">
              <Button
                variant={ButtonVariant.Ghost}
                removePadding
                removeOutline
                onClick={(): void => setCustomRegenerationModalOpen(true)}
              >
                <BodySmall>+ Custom Options</BodySmall>
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <BodySmall>Send time:</BodySmall>
            <Select
              disabled={false}
              disableUnderline
              variant="standard"
              value={selectedSendAt?.toString()}
              defaultValue={initialSendAt?.toString()}
              onChange={(e): void => {
                const date = new Date(e.target.value as string);
                setSelectedSendAt(date);
              }}
              renderValue={(value: unknown): React.ReactNode => {
                return <StyledBodySmall weight="600">{getFormattedSendAt(value as string)}</StyledBodySmall>;
              }}
            >
              {sendAtOptions.map(sendAtOption => {
                return (
                  <MenuItem value={sendAtOption}>
                    <BodySmall>{getFormattedSendAt(sendAtOption ?? "")}</BodySmall>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Button variant={ButtonVariant.Secondary} onClick={handleCloseEmailEditor}>
            <BodySmall>Cancel</BodySmall>
          </Button>
          {showSendNowButton && (
            <Button
              variant={ButtonVariant.Secondary}
              onClick={handleSend}
              disabled={disableActions || !campaignHasEmailAlias}
              loading={isSendingEmail || updatingCampaignMessageRequest || jobSetupIsFetching || loadingCandidate}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ReactSVG src={SendIcon} />
                <BodySmall>Save and send</BodySmall>
              </Stack>
            </Button>
          )}
          <Button variant={ButtonVariant.Primary} onClick={handleSaveToQueue} loading={updatingCampaignMessageRequest}>
            <BodySmall color={colors.white}>Save</BodySmall>
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

const StyledIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    filter: ${({ theme }): string => theme.filters.filterGray700};
  }
`;
