import { Button, ButtonProps } from "@mui/material";
import Color from "color";
import React from "react";
import styled from "styled-components";

import { colors, theme } from "styles/theme";
type ButtonVariant = "filled" | "outlined" | "text";

interface CustomButtonProps {
  "data-active"?: boolean;
  padding?: string;
  $fontSize?: string;
}

interface ButtonFactoryParams {
  hexColor: string;
  variant?: ButtonVariant;
}

export function ButtonFactory({
  hexColor,
  variant = "text",
}: ButtonFactoryParams): React.FC<React.PropsWithChildren<ButtonProps & CustomButtonProps>> {
  return styled(Button)<ButtonProps & CustomButtonProps>`
    &.MuiButton-root {
      background-color: ${(props): string => getBackgroundColor({ active: props["data-active"], hexColor, variant })};
      border: ${(props): string => getBorderColor({ active: props["data-active"], hexColor, variant })};
      font-weight: ${(props): string => (props["data-active"] ? "700" : "")};

      color: ${getTextColor({ hexColor, variant })};
      font-family: "Inter", sans-serif;
      padding: ${(props): string => props.padding || "8px 20px"};
      text-transform: unset;

      font-size: ${({ $fontSize }): string => $fontSize || "16px"};

      &:hover {
        color: ${getTextColor({ hexColor, variant })};
        background-color: ${getHoverColor({ hexColor: hexColor, variant })};
        border: ${getHoverBorder({ hexColor: hexColor, variant })};
      }

      &:focus {
        outline: ${getFocus({ hexColor, variant })};
      }

      &:disabled {
        border: 1px solid ${colors.grayscale.gray300};
        background-color: ${colors.grayscale.gray200};
        color: ${colors.subTitle};
      }
    }
  `;
}

interface ColorFunctionParam {
  active?: boolean;
  hexColor: string;
  variant: ButtonVariant;
}

function getBackgroundColor({ active, hexColor, variant }: ColorFunctionParam): string {
  if (active) {
    return getActiveBackgroundColor({ hexColor, variant });
  }
  return getIdleBackgroundColor({ hexColor, variant });
}

function getActiveBackgroundColor({ hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "filled":
      return getHoverColor({ hexColor, variant });
    case "outlined":
      return colors.white;
    case "text":
    default:
      return Color(hexColor)
        .alpha(0.1)
        .toString();
  }
}

function getIdleBackgroundColor({ hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "filled":
      return hexColor;
    case "outlined":
    case "text":
    default:
      return "";
  }
}

function getBorderColor({ active, hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "outlined":
      if (active) {
        return `1px solid ${hexColor}`;
      }
      return `1px solid ${theme.colors.grayscale.gray200}`;
    case "filled":
    case "text":
    default:
      return "";
  }
}

function getHoverColor({ hexColor, variant }: ColorFunctionParam): string {
  const color = Color(hexColor);
  switch (variant) {
    case "filled":
      return color.value(color.value() * 0.72).toString();
    case "outlined":
      return color
        .saturationv(4)
        .value(100)
        .toString();
    case "text":
    default:
      return theme.backgrounds.darkBlue;
  }
}

function getHoverBorder({ hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "outlined":
      return `1px solid ${hexColor}`;
    case "filled":
    case "text":
    default:
      return "";
  }
}

function getTextColor({ hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "filled":
      return "white";
    case "outlined":
    case "text":
    default:
      return hexColor;
  }
}

function getFocus({ hexColor, variant }: ColorFunctionParam): string {
  switch (variant) {
    case "outlined":
      return `5px auto ${hexColor}`;
    case "filled":
    case "text":
    default:
      return "unset";
  }
}
