import { Box, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as SlackLogo } from "assets/icons/slack.svg";
import { ReactComponent as DoverLogo } from "assets/logos/doverCircle.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";

export const ConciergeProfile: React.FC<React.PropsWithChildren<{
  slackUrl: string | undefined;
  slackIconVariant?: SlackIconVariant;
}>> = ({ slackUrl, slackIconVariant }) => {
  const conciergeInfo = useConciergeInfo();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <ProfileLogo />
      <Stack>
        <BodySmall weight="500">{conciergeInfo?.name}</BodySmall>
        <BodySmall color={colors.grayscale.gray600}>Recruiting Partner</BodySmall>
      </Stack>
      {slackUrl && <SlackIcon url={slackUrl} variant={slackIconVariant} />}
    </Stack>
  );
};

const ProfileLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const conciergeInfo = useConciergeInfo();

  return (
    <Box position="relative">
      <PersonaCoin pictureUrl={conciergeInfo?.pictureUrl} />
      <Box
        width="40px"
        height="40px"
        sx={{
          borderRadius: "50%",
          position: "absolute",
          bottom: "-14px",
          left: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DoverLogo />
      </Box>
    </Box>
  );
};

export enum SlackIconVariant {
  Condensed = "condensed",
  FullWidth = "fullWidth",
}

const SlackIcon: React.FC<React.PropsWithChildren<{ url?: string; variant?: SlackIconVariant }>> = ({
  url,
  variant = SlackIconVariant.Condensed,
}) => {
  if (url) {
    if (variant === SlackIconVariant.Condensed) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          <SlackLogo height="16px" width="16px" />
        </a>
      );
    }
    return (
      <Button variant={ButtonVariant.Secondary}>
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SlackLogo height="16px" width="16px" />
            <BodySmall color={colors.grayscale.gray600}>Slack me</BodySmall>
          </Stack>
        </a>
      </Button>
    );
  }

  return <></>;
};
