import { Box } from "@mui/material";
import React, { FC } from "react";

import { Tooltip } from "components/library/Tooltip";
import { Caption } from "components/library/typography";
import { CandidateBioSource } from "services/openapi";
import { colors } from "styles/theme";
import { convertPrettyDate } from "views/CandidateDetail/utils";

interface SourceInfoProps {
  source: CandidateBioSource;
}

export const SourceInfo: FC<React.PropsWithChildren<SourceInfoProps>> = ({ source }) => {
  const { pipelineStartedDate, displayAction, displayCategory } = source;

  if (!pipelineStartedDate) {
    return <></>;
  }

  const displayDate = convertPrettyDate(pipelineStartedDate);
  const sourceContext = displayAction && displayCategory ? `${displayAction} - ${displayCategory}` : "";

  return (
    <Tooltip title={sourceContext} placement="bottom">
      <Box sx={{ whiteSpace: "nowrap" }}>
        <Caption color={colors.grayscale.gray500}>{displayDate}</Caption>
      </Box>
    </Tooltip>
  );
};
