import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as HelpIcon } from "@doverhq/dover-ui/icons/help.svg";
import { ReactComponent as PlusCircleIcon } from "@doverhq/dover-ui/icons/plus-circle.svg";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import React, { FC, useState } from "react";

import { ReactComponent as MultipartInterviewStageSVG } from "assets/icons/multipart-interview-stage.svg";
import { ReactComponent as PaperSVG } from "assets/icons/paper.svg";
import { ReactComponent as SinglepartInterviewStageSVG } from "assets/icons/singlepart-interview-stage.svg";
import { Tooltip } from "components/library/Tooltip";
import { InterviewPlanStageType } from "services/openapi";
import { colors } from "styles/theme";
import { CanDetail } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCanStage";
import { useCreateHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCreateHps";
interface AddStageProps {
  orderIndex: number | null | undefined;
  isDragging: boolean;
  canAdd: CanDetail;
}

export const AddStage: FC<React.PropsWithChildren<AddStageProps>> = ({ orderIndex, isDragging, canAdd }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const click = (event: React.MouseEvent<HTMLElement>): void => {
    if (!canAdd.can) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const close = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <AddStageMenu anchorEl={anchorEl} onClose={close} orderIndex={orderIndex} />
      <Stack
        direction="row"
        width="100%"
        p={1}
        gap={1}
        borderRadius={1}
        alignItems="center"
        onClick={click}
        sx={{
          visibility: isDragging ? "hidden" : "visible",
          cursor: !canAdd.can ? "default" : "pointer",
          "&:hover .interview-plan-line": {
            borderColor: !canAdd.can ? undefined : colors.primary.base,
          },
          "&:hover svg": {
            fill: !canAdd.can ? undefined : `${colors.primary.base} !important`,
          },
          svg: {
            fill: !canAdd.can ? `${colors.grayscale.gray400} !important` : undefined,
          },
        }}
      >
        <Line />
        <Tooltip title={canAdd.reason}>
          <div>
            <Icon Icon={PlusCircleIcon} />
          </div>
        </Tooltip>
        <Line />
      </Stack>
    </>
  );
};

const Line: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box className="interview-plan-line" height={0} borderTop={`1px solid ${colors.grayscale.gray200}`} flexGrow={1} />
  );
};

interface AddStageMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  orderIndex: number | null | undefined;
}

const AddStageMenu: FC<React.PropsWithChildren<AddStageMenuProps>> = ({ anchorEl, onClose, orderIndex }) => {
  const [createHps] = useCreateHps({ shared: true });

  const open = Boolean(anchorEl);

  const selectOption = (name: string, interviewStageType: InterviewPlanStageType): void => {
    if (orderIndex == undefined) {
      return;
    }

    createHps({ data: { name, orderIndex, interviewStageType } });

    onClose();
  };

  const constructMenuItem = ({
    interviewTypeIcon,
    label,
    tooltip,
    onClick,
  }: {
    interviewTypeIcon: React.ReactElement;
    label: string;
    tooltip: string;
    onClick: () => void;
  }): React.ReactElement => {
    return (
      <MenuItem onClick={onClick}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} width="100%">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {interviewTypeIcon}
            <div>{label}</div>
          </Stack>
          <Tooltip title={tooltip}>
            <span>
              <Icon Icon={HelpIcon} />
            </span>
          </Tooltip>
        </Stack>
      </MenuItem>
    );
  };

  const menuItems = [
    {
      interviewTypeIcon: <SinglepartInterviewStageSVG />,
      label: "Single-part Interview",
      tooltip: "For single-call interviews where you’d like candidates to book directly via a scheduling link.",
      onClick: (): void => selectOption("Single-part Interview", InterviewPlanStageType.SINGLEPART),
    },
    {
      interviewTypeIcon: <MultipartInterviewStageSVG />,
      label: "Multi-part Interview",
      tooltip:
        "For multi-part interviews or other interviews where you’d like to request availability from candidates first.",
      onClick: (): void => selectOption("Multi-part Interview", InterviewPlanStageType.MULTIPART),
    },
    {
      interviewTypeIcon: <PaperSVG />,
      label: "Assessment / Other",
      tooltip: "For take-home exercises and other interview stages (e.g. reference check, background check).",
      onClick: (): void => selectOption("Assessment", InterviewPlanStageType.TAKE_HOME),
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {menuItems.map(item => constructMenuItem(item))}
    </Menu>
  );
};
