import { useContext } from "react";

import { TopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/context";
import { TopLevelModalManagerOptionsContext } from "components/dover/top-level-modal-manager/context";

function noOp(): void {
  console.error("Could not call method: TopLevelModalManagerContext not found");
}

/**
 * This hook is used to access method related to top level modals in the app.
 * It is simply a convenience wrapper around the method expose on the context.
 * If the context is not found, it will return a no-op method that logs an error to the console for each callback on the TopLevelModalManagerOptions type
 */
export function useTopLevelModalManagerOptions(): TopLevelModalManagerOptions {
  const topLevelModalManagerOptions = useContext<TopLevelModalManagerOptions | undefined>(
    TopLevelModalManagerOptionsContext
  );

  return {
    closeModal: topLevelModalManagerOptions?.closeModal ?? noOp,
    showMoveJobModal: topLevelModalManagerOptions?.showMoveJobModal ?? noOp,
    showCandidateActionModal: topLevelModalManagerOptions?.showCandidateActionModal ?? noOp,
    showPromptForEmailAuthModal: topLevelModalManagerOptions?.showPromptForEmailAuthModal ?? noOp,
    showBulkRejectModal: topLevelModalManagerOptions?.showBulkRejectModal ?? noOp,
    showFilePreviewModal: topLevelModalManagerOptions?.showFilePreviewModal ?? noOp,
  };
}
