import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { ReactComponent as ExpertIcon } from "assets/icons/expert.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { GradientBackground } from "components/library/GradientBackground";
import { Subtitle1 } from "components/library/typography";
import { Body } from "components/library/typography";
import { colors, screenSizesNumbers } from "styles/theme";

const ExpertCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));
  const calendlyUrl = useGetCalendlyUrl("app", "home_page", "simplify_hiring_banner");

  return (
    <GradientBackground
      spacing={3}
      direction={"row"}
      justifyContent="flex-start"
      alignItems="center"
      p={4}
      style={{
        border: `1px solid ${colors.grayscale.gray200}`,
        borderRadius: "13px",
        padding: "16px",
      }}
      angle="180"
      firstColor={colors.primary.light}
      secondColor={colors.white}
    >
      {!isSmallScreen && <ExpertIcon height={"140px"} width={"140px"} />}
      <Stack spacing={1}>
        <Subtitle1>Recruit faster</Subtitle1>
        <Body color={colors.grayscale.gray600}>Learn how to get the most out of Dover from a recruiting expert</Body>
        <Button
          width={"225px"}
          variant={ButtonVariant.Primary}
          onClick={(): void => {
            window.open(calendlyUrl, "_blank", "noopener noreferrer");
          }}
        >
          Talk to a recruiting expert
        </Button>
      </Stack>
    </GradientBackground>
  );
};

export default ExpertCard;
