import { Divider, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BooleanParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CancelOutreachIcon } from "assets/icons/cancel.svg";
import MailGreyIcon from "assets/icons/mail-grey.svg";
import PencilEditSVG from "assets/icons/pencil-edit.svg";
import RedXIcon from "assets/icons/red-x.svg";
import SendIcon from "assets/icons/send.svg";
import RemoveFromQueueIcon from "assets/icons/x-red-circle.svg";
import Accordion from "components/accordion";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useRemoveFromOutboxMutation } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import {
  useGetCampaignMessageRequestQuery,
  useSendCampaignMessageRequestEmailMutation,
  useUpdateCampaignMessageRequestMutation,
} from "services/doverapi/endpoints/emailSending";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import {
  HiringPipelineStageType,
  PipelineCandidate,
  SlimNextCampaignMessageRequestCampaignStateEnum,
  SlimNextCampaignMessageRequestStateEnum,
  UpdateCampaignMessageRequestEmailSendRequestStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink, InternalLink } from "styles/typography";
import { toastOptions } from "utils/showToast";
import { OUTREACH_EMAIL_EDITOR_OPEN_PARAM } from "views/CandidateDetail/constants";
import { SampleCandidateResetButton } from "views/candidates/CandidateTable/components/SampleCandidateResetButton";
import {
  CandidatesRemovedFromQueueContext,
  CandidatesRemovedFromQueueState,
} from "views/candidates/CandidateTable/context";
import { useGetDisableCandidateActions } from "views/candidates/hooks";

interface OutreachActionButtonsProps {
  candidate?: PipelineCandidate;
}

export const EnableOutreachTooltip = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();
  return (
    <>
      {"Re-activate your campaign "}
      <InternalLink $variant="secondary" to={`/job/${jobId}/setup/outreach`} rel="noopener noreferrer" target="_blank">
        here
      </InternalLink>
      {" to send emails"}
    </>
  );
};

export const OutreachActionButtons: React.FC<React.PropsWithChildren<OutreachActionButtonsProps>> = ({ candidate }) => {
  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  const showSendNowButton =
    clientOnboarding?.hasDoverManagedDomainSetUp &&
    candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Queued;

  const jobId = useJobIdFromUrl();
  const [, setOutreachEmailEditorOpen] = useQueryParam(OUTREACH_EMAIL_EDITOR_OPEN_PARAM, BooleanParam);
  const { candidateId } = useParams<{ candidateId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const removeFromQueueContext = useContext<CandidatesRemovedFromQueueState | undefined>(
    CandidatesRemovedFromQueueContext
  );

  const campaignActive =
    candidate?.slimNextCampaignMessageRequest?.campaignState === SlimNextCampaignMessageRequestCampaignStateEnum.Active;

  const fullName = candidate?.contact?.fullName;
  const isSampleCandidate = fullName === "Max Kolysh";

  const [removeFromQueueModalOpen, setRemoveFromQueueModalOpen] = React.useState(false);
  const [cancelOutreachModalOpen, setCancelOutreachModalOpen] = React.useState(false);
  const [sendNowModalOpen, setSendNowModalOpen] = React.useState(false);
  const [onboardingCallModalOpen, setOnboardingCallModalOpen] = React.useState(false);

  const disableActions = useGetDisableCandidateActions();
  const [updateCampaignMessageRequestMutation] = useUpdateCampaignMessageRequestMutation();

  const { autoQueueOutreach, isFetchingConfigOption } = useGetDoverOutboundConfigurationQuery(jobId ?? skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        autoQueueOutreach: data?.autoQueueOutreach,
        isFetchingConfigOption: isFetching,
      };
    },
  });

  const { data: jobSetup, isFetching: jobSetupIsFetching } = useGetJobSetupQuery(jobId || skipToken);

  const cmrId = candidate?.slimNextCampaignMessageRequest?.campaignMessageRequestId;
  const { data: campaignMessageRequest } = useGetCampaignMessageRequestQuery(
    cmrId && jobId ? { cmrId, jobId } : skipToken
  );
  const campaignHasEmailAlias = !!campaignMessageRequest?.emailAlias;
  const isValidEmailAlias = campaignMessageRequest?.emailAlias?.isValidEmailAlias || false;

  const showUpdateToInReviewButton =
    candidate?.candidatePipelineStage?.stageType === HiringPipelineStageType.QUEUED &&
    candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Queued &&
    !autoQueueOutreach &&
    !isFetchingConfigOption;

  const showCancelOutreachButton =
    candidate?.candidatePipelineStage?.stageType === HiringPipelineStageType.CONTACTED &&
    candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Queued &&
    !isFetchingConfigOption;

  const handleOpenEmailEditor = useCallback((): void => {
    // If we pass through a candidate id and the corresponding candidate detail view isn't open, set it open
    if (candidate?.id && !candidateId) {
      const queryParams = location.search + `&${OUTREACH_EMAIL_EDITOR_OPEN_PARAM}=1`;
      navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidate.id, new URLSearchParams(queryParams)));
    } else if (candidateId) {
      // Otherwise, if the candidate detail view is already open, just open the email editor
      setOutreachEmailEditorOpen(true);
    }
  }, [candidate, candidateId, location.search, navigate, setOutreachEmailEditorOpen]);

  const setEsrToInReview = useCallback(() => {
    const setEsrToInReviewAsync = async (): Promise<void> => {
      if (cmrId) {
        const updateCampaignMessageRequestPromise = updateCampaignMessageRequestMutation({
          campaignMessageRequestId: cmrId,
          data: { emailSendRequestState: UpdateCampaignMessageRequestEmailSendRequestStateEnum.InReview },
        });
        await toast.promise(
          updateCampaignMessageRequestPromise,
          {
            pending: "Removing candidate from queue",
            success: "Candidate removed from queue",
            error: "Failed to remove candidate from queue",
          },
          { ...toastOptions }
        );

        removeFromQueueContext?.incrementNumCandidatesRemoved();
      }
    };

    setEsrToInReviewAsync();
  }, [cmrId, removeFromQueueContext, updateCampaignMessageRequestMutation]);

  const queueCampaignMessageRequest = async (): Promise<void> => {
    // Gate users from app that have not completed a required onboarding call
    if (jobSetup?.gatedFromApp) {
      setOnboardingCallModalOpen(true);
      return;
    }

    if (cmrId) {
      const updateCampaignMessageRequestPromise = updateCampaignMessageRequestMutation({
        campaignMessageRequestId: cmrId,
        data: {
          emailSendRequestState: UpdateCampaignMessageRequestEmailSendRequestStateEnum.Queued,
          emailSendRequestSendAt: new Date(),
        },
      });
      await toast.promise(
        updateCampaignMessageRequestPromise,
        {
          pending: "Adding candidate to queue",
          success: "Candidate added to queue",
          error: "Failed to add candidate to queue",
        },
        { ...toastOptions }
      );
    }
  };

  const disabledActionTooltip = useMemo((): React.ReactElement => {
    if (disableActions) {
      return (
        <>
          <InternalLink
            $variant="secondary"
            to={`/job/${jobId}/setup/overview`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Enable sourcing
          </InternalLink>
          {" to perform this action"}
        </>
      );
    } else if (!campaignActive) {
      return <EnableOutreachTooltip />;
    }
    return <>{"Dover is finishing setup for this job to enable this action. Please check back soon."}</>;
  }, [campaignActive, disableActions, jobId]);

  const removeButton = useMemo((): React.ReactElement => {
    if (showCancelOutreachButton) {
      return (
        <>
          <Tooltip title="Cancel email">
            <div>
              <Button
                variant={ButtonVariant.Secondary}
                onClick={(): void => {
                  setCancelOutreachModalOpen(true);
                }}
              >
                <CancelOutreachIcon className="svg-fill" color={colors.critical.base} />
              </Button>
            </div>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip title={showUpdateToInReviewButton ? "Remove from queue" : "Remove candidate"}>
          <div>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => {
                if (showUpdateToInReviewButton) {
                  setEsrToInReview();
                  return;
                }
                setRemoveFromQueueModalOpen(true);
              }}
            >
              <ReactSVG src={showUpdateToInReviewButton ? RedXIcon : RemoveFromQueueIcon} />
            </Button>
          </div>
        </Tooltip>
      </>
    );
  }, [setEsrToInReview, showCancelOutreachButton, showUpdateToInReviewButton]);

  const editMessageButton = useMemo((): React.ReactElement => {
    return (
      <Button variant={ButtonVariant.Secondary} onClick={handleOpenEmailEditor}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PencilReactSVG src={PencilEditSVG} />
          <BodySmall style={{ whiteSpace: "nowrap" }}>Edit</BodySmall>
        </Stack>
      </Button>
    );
  }, [handleOpenEmailEditor]);

  return (
    <>
      <RemoveFromQueueModal
        candidateId={candidate?.id}
        isOpen={removeFromQueueModalOpen}
        close={(): void => setRemoveFromQueueModalOpen(false)}
      />
      <CancelOutreachModal
        cmrId={cmrId}
        isOpen={cancelOutreachModalOpen}
        close={(): void => setCancelOutreachModalOpen(false)}
      />
      <OnboardingCallModal isOpen={onboardingCallModalOpen} close={(): void => setOnboardingCallModalOpen(false)} />{" "}
      <SendNowModal
        isOpen={sendNowModalOpen}
        close={(): void => setSendNowModalOpen(false)}
        campaignMessageRequestId={cmrId}
        candidateId={candidate?.id}
      />
      <Stack direction="row" spacing={0.5}>
        <Stack direction="row" spacing={0.5}>
          {showSendNowButton ? (
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => {
                setSendNowModalOpen(true);
              }}
              disabled={disableActions || !campaignHasEmailAlias || !campaignActive || !isValidEmailAlias}
              tooltip={disableActions || !campaignHasEmailAlias || !campaignActive ? disabledActionTooltip : ""}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <ReactSVG src={SendIcon} />
                <BodySmall style={{ whiteSpace: "nowrap" }}>Send now</BodySmall>
              </Stack>
            </Button>
          ) : (
            <Stack direction="row">
              <Button
                variant={ButtonVariant.SecondarySuccess}
                onClick={queueCampaignMessageRequest}
                disabled={disableActions || jobSetupIsFetching}
                tooltip={disableActions ? disabledActionTooltip : ""}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <StyledMailIcon src={MailGreyIcon} />
                  <BodySmall color={colors.primary.hover} style={{ whiteSpace: "nowrap" }}>
                    Queue to send
                  </BodySmall>
                </Stack>
              </Button>
            </Stack>
          )}
          {editMessageButton}
        </Stack>
        <Stack divider={<Divider orientation="vertical" flexItem />} direction="row" spacing={2} alignItems="center">
          {removeButton}
          {isSampleCandidate && (
            <>
              <SampleCandidateResetButton jobId={jobId ?? undefined} />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const PencilReactSVG = styled(ReactSVG)`
  svg {
    margin-bottom: 2px;
  }
`;

const RemoveFromQueueModal = ({
  candidateId,
  isOpen,
  close,
}: {
  candidateId: string | undefined;
  isOpen: boolean;
  close: Function;
}): React.ReactElement => {
  const [removeFromOutbox] = useRemoveFromOutboxMutation();
  const removeFromQueueContext = useContext<CandidatesRemovedFromQueueState | undefined>(
    CandidatesRemovedFromQueueContext
  );
  const jobId = useJobIdFromUrl();

  const removeFromQueue = async (): Promise<void> => {
    if (candidateId && jobId) {
      const removeFromOutboxPromise = removeFromOutbox({
        data: { candidateIds: [candidateId] },
        jobId,
      });
      await toast.promise(
        removeFromOutboxPromise,
        {
          pending: "Removing candidate from queue",
          success: "Candidate removed from queue",
          error: "Failed to remove from queue",
        },
        { ...toastOptions }
      );
      removeFromQueueContext?.incrementNumCandidatesRemoved();
    }
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" style={{ padding: "12px" }}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => close()}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Critical}
            onClick={(): void => {
              close();
              removeFromQueue();
            }}
          >
            Remove
          </Button>
        </Stack>
      }
    >
      <Body>Are you sure you want to remove this candidate from the outreach queue?</Body>
    </CustomModal>
  );
};

const CancelOutreachModal = ({
  cmrId,
  isOpen,
  close,
}: {
  cmrId: string | undefined;
  isOpen: boolean;
  close: Function;
}): React.ReactElement => {
  const [updateCampaignMessageRequestMutation] = useUpdateCampaignMessageRequestMutation();

  const setEsrToCanceled = useCallback(() => {
    const setEsrToCanceledAsync = async (): Promise<void> => {
      if (cmrId) {
        const updateCampaignMessageRequestPromise = updateCampaignMessageRequestMutation({
          campaignMessageRequestId: cmrId,
          data: { emailSendRequestState: UpdateCampaignMessageRequestEmailSendRequestStateEnum.Cancelled },
        });
        await toast.promise(
          updateCampaignMessageRequestPromise,
          {
            pending: "Stopping outreach sequence",
            success: "Outreach sequence stopped",
            error: "Failed to stop outreach sequence",
          },
          { ...toastOptions }
        );
      }
    };

    setEsrToCanceledAsync();
  }, [cmrId, updateCampaignMessageRequestMutation]);

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" style={{ padding: "12px" }}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => close()}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Critical}
            onClick={(): void => {
              close();
              setEsrToCanceled();
            }}
          >
            Stop sequence
          </Button>
        </Stack>
      }
    >
      <Body>
        Are you sure you want to stop the sequence for this candidate? This will cancel all remaining follow-ups in this
        sequence. If you want to delay the send time of the next follow-up, you can change the send time by clicking
        “Edit message”.
      </Body>
    </CustomModal>
  );
};

const SendNowModal = ({
  isOpen,
  close,
  campaignMessageRequestId,
  candidateId,
}: {
  isOpen: boolean;
  close: Function;
  campaignMessageRequestId?: string;
  candidateId?: string;
}): React.ReactElement => {
  const [sendCampaignMessageRequestEmail, { isLoading }] = useSendCampaignMessageRequestEmailMutation();

  const handleSend = async (): Promise<void> => {
    if (campaignMessageRequestId && candidateId) {
      await sendCampaignMessageRequestEmail({ campaignMessageRequestId, candidateId }).unwrap();
    }
    close();
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" style={{ padding: "12px" }}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => close()}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={handleSend} disabled={isLoading}>
            Send
          </Button>
        </Stack>
      }
    >
      <Body>Send outreach to this candidate now?</Body>
    </CustomModal>
  );
};
export const OnboardingCallModal = ({ isOpen, close }: { isOpen: boolean; close: Function }): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "candidate_outbox", "outbox_send_button");
  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      title={"Talk to us to finish setup"}
    >
      <Stack spacing={1}>
        <Body>You’re almost there! Just finish your call with our team to complete setup.</Body>
        <Body>
          Haven’t booked a call yet? You can do so{" "}
          <ExternalLink display="inline" href={calendlyUrl} target="_blank" rel="noopener noreferrer">
            here
          </ExternalLink>
          .
        </Body>
        <Accordion title="Why do I need to have a call?">
          <Body>
            {
              "Sourcing is a paid service. We’ve noticed that our customers are most successful when we have a chance to understand their needs and set them up correctly."
            }
          </Body>
        </Accordion>
      </Stack>
    </CustomModal>
  );
};

const StyledMailIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${colors.primary.hover};
    }
    line {
      stroke: ${colors.primary.hover};
    }
  }
`;
