import { Button } from "@doverhq/dover-ui";
import { Box, Divider, Stack } from "@mui/material";
import { isEqual } from "lodash";
import React, { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Body } from "components/library/typography";
import { usePartialUpdateSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3 } from "services/openapi";
import { colors } from "styles/theme";
import AdvancedFilters from "views/sourcing/Search/components/AdvancedFilters";
import CompaniesFilters from "views/sourcing/Search/components/CompaniesFilters";
import EducationFilters from "views/sourcing/Search/components/EducationFilters";
import LocationFilters from "views/sourcing/Search/components/LocationFilters";
import PersonasFilters from "views/sourcing/Search/components/PersonasFilters";
import SeniorityFilters from "views/sourcing/Search/components/SeniorityFilters";
import SkillsFilters from "views/sourcing/Search/components/SkillsFilters";
import YoeFilters from "views/sourcing/Search/components/YoeFilters";
import { FiltersContainerID } from "views/sourcing/Search/constants";
import { FilterToggleWrapper, FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useSetFormDirty } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

interface SaapSortersProps {
  search?: SearchV3;
}

export const SaapSorters: FC<React.PropsWithChildren<SaapSortersProps>> = ({ search }) => {
  // Load in initial form values
  const formLoadState = React.useContext(FormLoadStateContext);
  const setInitialFormValuesLoaded = formLoadState?.setFormValuesLoaded;
  const initialFormValuesLoaded = formLoadState?.loaded;

  const { control } = useFormContext<SearchV3FormSchemaType>();
  const values = useWatch({ control });

  const [formDirty, setFormDirty] = React.useState<boolean>(false);
  useSetFormDirty(formDirty, setFormDirty, !!initialFormValuesLoaded, setInitialFormValuesLoaded);

  const [partialUpdateSearch, { isLoading: isSearchUpdating }] = usePartialUpdateSearchV3Mutation();

  const onClickSaveSearch = React.useCallback(async () => {
    // Early return if the form state isn't legit
    // We'll handle errors at the top level of the Sourcing page
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }

    // If a search is not yet defined, we don't wish to proceed
    if (!search || !search.id) {
      return;
    }

    // After updates, we'll get a referentially different search object from RTKQ
    // It's important the we check deep equality against the new search so we don't perform an additional unnecessary update
    const newSearch = getSearchV3FromFormState(formParseResult.data, search);
    if (isEqual(search, newSearch)) {
      return;
    }

    await partialUpdateSearch({ id: search.id, data: { ...search, ...newSearch } });
    // createChangeLogEntry({ searchId: search.id });
  }, [values, search, partialUpdateSearch]);

  return (
    <FilterToggleWrapper>
      <Box padding="5px 8px">
        <Button variant="primaryFilled" w="full" p={1} onPress={onClickSaveSearch} isLoading={isSearchUpdating}>
          <Body color={colors.white}>Save</Body>
        </Button>
      </Box>
      <Stack
        id={FiltersContainerID}
        height="100%"
        divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray200 }} />}
        sx={{
          overflowY: "auto",
          borderBottom: `1px solid ${colors.grayscale.gray200}`,
          borderTop: `1px solid ${colors.grayscale.gray200}`,
        }}
      >
        <PersonasFilters />
        <LocationFilters />
        <YoeFilters />
        <SeniorityFilters />
        <CompaniesFilters />
        <EducationFilters />
        <SkillsFilters />
        <AdvancedFilters hideDiversity />
      </Stack>
    </FilterToggleWrapper>
  );
};
