/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseCandidatePipelineStage,
    BaseCandidatePipelineStageFromJSON,
    BaseCandidatePipelineStageFromJSONTyped,
    BaseCandidatePipelineStageToJSON,
    Contact,
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
    Mention,
    MentionFromJSON,
    MentionFromJSONTyped,
    MentionToJSON,
    PipelineFailureReason,
    PipelineFailureReasonFromJSON,
    PipelineFailureReasonFromJSONTyped,
    PipelineFailureReasonToJSON,
    SlimNextAction,
    SlimNextActionFromJSON,
    SlimNextActionFromJSONTyped,
    SlimNextActionToJSON,
    SlimNextCampaignMessageRequest,
    SlimNextCampaignMessageRequestFromJSON,
    SlimNextCampaignMessageRequestFromJSONTyped,
    SlimNextCampaignMessageRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface PipelineCandidate
 */
export interface PipelineCandidate {
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {BaseCandidatePipelineStage}
     * @memberof PipelineCandidate
     */
    candidatePipelineStage?: BaseCandidatePipelineStage;
    /**
     * 
     * @type {Date}
     * @memberof PipelineCandidate
     */
    lastEventTs: Date;
    /**
     * 
     * @type {Date}
     * @memberof PipelineCandidate
     */
    readonly lastStatusEventTs?: Date;
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    job: string;
    /**
     * 
     * @type {Date}
     * @memberof PipelineCandidate
     */
    readonly modified?: Date;
    /**
     * 
     * @type {SlimNextCampaignMessageRequest}
     * @memberof PipelineCandidate
     */
    slimNextCampaignMessageRequest: SlimNextCampaignMessageRequest | null;
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    status?: PipelineCandidateStatusEnum;
    /**
     * 
     * @type {Contact}
     * @memberof PipelineCandidate
     */
    contact: Contact;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineCandidate
     */
    pipelineFailedProcessing?: boolean;
    /**
     * 
     * @type {PipelineFailureReason}
     * @memberof PipelineCandidate
     */
    pipelineFailureReason?: PipelineFailureReason | null;
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    schedulingOwnership?: PipelineCandidateSchedulingOwnershipEnum;
    /**
     * 
     * @type {Date}
     * @memberof PipelineCandidate
     */
    replyDate: Date;
    /**
     * 
     * @type {SlimNextAction}
     * @memberof PipelineCandidate
     */
    nextAction?: SlimNextAction;
    /**
     * 
     * @type {Array<Mention>}
     * @memberof PipelineCandidate
     */
    readonly mentions?: Array<Mention>;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineCandidate
     */
    readonly isStarred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    readonly candidateBucketLabel?: PipelineCandidateCandidateBucketLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineCandidate
     */
    readonly referrerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof PipelineCandidate
     */
    readonly appliedAt?: Date;
}

/**
* @export
* @enum {string}
*/
export enum PipelineCandidateStatusEnum {
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum PipelineCandidateSchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}/**
* @export
* @enum {string}
*/
export enum PipelineCandidateCandidateBucketLabelEnum {
    BestMatch = 'BEST_MATCH',
    CloseMatch = 'CLOSE_MATCH',
    Mismatch = 'MISMATCH',
    Scoring = 'SCORING',
    EnrichingProfile = 'ENRICHING_PROFILE',
    NoInfo = 'NO_INFO'
}

export function PipelineCandidateFromJSON(json: any): PipelineCandidate {
    return PipelineCandidateFromJSONTyped(json, false);
}

export function PipelineCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'candidatePipelineStage': !exists(json, 'candidate_pipeline_stage') ? undefined : BaseCandidatePipelineStageFromJSON(json['candidate_pipeline_stage']),
        'lastEventTs': (new Date(json['last_event_ts'])),
        'lastStatusEventTs': !exists(json, 'last_status_event_ts') ? undefined : (new Date(json['last_status_event_ts'])),
        'job': json['job'],
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'slimNextCampaignMessageRequest': SlimNextCampaignMessageRequestFromJSON(json['slim_next_campaign_message_request']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'contact': ContactFromJSON(json['contact']),
        'pipelineFailedProcessing': !exists(json, 'pipeline_failed_processing') ? undefined : json['pipeline_failed_processing'],
        'pipelineFailureReason': !exists(json, 'pipeline_failure_reason') ? undefined : PipelineFailureReasonFromJSON(json['pipeline_failure_reason']),
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
        'replyDate': (new Date(json['reply_date'])),
        'nextAction': !exists(json, 'next_action') ? undefined : SlimNextActionFromJSON(json['next_action']),
        'mentions': !exists(json, 'mentions') ? undefined : ((json['mentions'] as Array<any>).map(MentionFromJSON)),
        'isStarred': !exists(json, 'is_starred') ? undefined : json['is_starred'],
        'candidateBucketLabel': !exists(json, 'candidate_bucket_label') ? undefined : json['candidate_bucket_label'],
        'referrerId': !exists(json, 'referrer_id') ? undefined : json['referrer_id'],
        'appliedAt': !exists(json, 'applied_at') ? undefined : (new Date(json['applied_at'])),
    };
}

export function PipelineCandidateToJSON(value?: PipelineCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_pipeline_stage': BaseCandidatePipelineStageToJSON(value.candidatePipelineStage),
        'last_event_ts': (value.lastEventTs.toISOString()),
        'job': value.job,
        'slim_next_campaign_message_request': SlimNextCampaignMessageRequestToJSON(value.slimNextCampaignMessageRequest),
        'status': value.status,
        'contact': ContactToJSON(value.contact),
        'pipeline_failed_processing': value.pipelineFailedProcessing,
        'pipeline_failure_reason': PipelineFailureReasonToJSON(value.pipelineFailureReason),
        'scheduling_ownership': value.schedulingOwnership,
        'reply_date': (value.replyDate.toISOString()),
        'next_action': SlimNextActionToJSON(value.nextAction),
    };
}


