import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import Logo from "views/CompanySetup/components/Credentials/components/Logo";
import { Divider, BackLink } from "views/CompanySetup/components/Credentials/components/Styles";
import { Source } from "views/CompanySetup/components/Credentials/types";

interface Props {
  source: Source;
  instructionsComponent?: React.ReactNode;
  stageComponent: React.ReactNode;
}

const SourcePage: React.FC<React.PropsWithChildren<Props>> = ({ source, instructionsComponent, stageComponent }) => {
  const navigate = useNavigate();

  return (
    <>
      <BackLink onClick={(): void => navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.INTEGRATIONS))}>
        <ArrowBackIosIcon />
        Back to sources
      </BackLink>
      <Divider />
      <Box display="flex" alignItems="center" mx={8}>
        <Logo source={source} />
      </Box>
      <Divider />
      {!!instructionsComponent && (
        <>
          {instructionsComponent}
          <Divider />
        </>
      )}
      {stageComponent}
    </>
  );
};

export default SourcePage;
