import { Stack } from "@mui/material";
import React from "react";

import Emoji from "components/Emoji";
import { Body } from "components/library/typography";
import { UserCredential } from "services/openapi";
import { Logo } from "views/CompanySetup/components/Credentials/components/Logo";
import { Source } from "views/CompanySetup/components/Credentials/types";
interface Props {
  cred: UserCredential;
  source: string;
}

const SourceRow: React.FC<React.PropsWithChildren<Props>> = ({ cred, source }) => (
  <Stack
    p={1}
    spacing={1}
    alignItems="center"
    sx={{
      borderRadius: 1,
      "&:hover": {
        backgroundColor: "#e0e0e2",
      },
    }}
  >
    <Logo source={source as Source} />
    <>
      {cred.state === "LOST_CONNECTION" && (
        <h1>
          <Emoji symbol="⚠️" label="Invalid Credentials" />
        </h1>
      )}
      <Body>{cred.state === "CONNECTED" && "Enabled"}</Body>
      <Body>{cred.state == null && source !== "YC_WAAS" && "Not set up"}</Body>
      <Body>{cred.state == null && source === "YC_WAAS" && "Show key"}</Body>
    </>
  </Stack>
);

export default SourceRow;
