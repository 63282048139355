import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import Papa from "papaparse";
import React, { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

import { ReactComponent as EyeIconSVG } from "assets/icons/eye.svg";
import { ReactComponent as UploadIconSVG } from "assets/icons/upload-cloud.svg";
import { bulkUploadCandidatesToCampaignAtom } from "components/dover/AddCandidate/atoms";
import { TWENTY_MEGA_BYTES } from "components/dover/AddCandidate/constants";
import FooterActions from "components/dover/AddCandidate/Footer";
import {
  BulkUploadCandidatesToCampaignSchemaType,
  bulkUploadCandidatesToCampaignSchema,
} from "components/dover/AddCandidate/types";
import UploadBox from "components/dover/AddCandidate/UploadBox";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall, Subtitle2 } from "components/library/typography";
import { OutreachBulkCandidateItem } from "services/openapi";
import { colors } from "styles/theme";

class MissingHeaderError extends Error {
  constructor(missingHeaders: string[]) {
    super(`Missing header(s): ${missingHeaders}`);
  }
}

// TODO: add unit test for this fxn (sc-197353)
const parseManualSourcingItemCSV = (file: File): Promise<OutreachBulkCandidateItem[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      worker: true,
      header: true,
      complete: ({
        data,
        meta,
      }: {
        data: { Email?: string; LinkedIn?: string }[];
        // NOTE: The `fields` property is only provided since we expect headers in the CSV.
        meta: { fields?: string[] };
      }) => {
        const missingHeaders = ["LinkedIn", "Email"].filter(header => !meta?.fields?.includes(header));
        if (missingHeaders) {
          reject(new MissingHeaderError(missingHeaders));
        }

        const manualSourcingItems = data
          .filter(item => {
            const url = item.LinkedIn;
            return url && url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/);
          })
          .map(item => {
            return {
              email: item.Email || "",
              url: item.LinkedIn || "",
            } as OutreachBulkCandidateItem;
          });
        resolve(manualSourcingItems);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
};

const CSVSummary = (): React.ReactElement | null => {
  const { control } = useFormContext();
  const manualSourcingItems = useWatch({
    control: control,
    name: "manualSourcingItems",
  });

  if (!manualSourcingItems || manualSourcingItems.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Subtitle2>CSV Summary</Subtitle2>
      <Stack direction="row" alignItems="center" spacing={1}>
        <BodySmall>{manualSourcingItems.length} total rows parsed</BodySmall>
        <Tooltip
          title={
            <Stack spacing={0.5}>
              {/* TODO: address this design and limit num shown (sc-197353) */}
              {manualSourcingItems.map((row: OutreachBulkCandidateItem, index: number) => (
                <div key={index}>
                  {row.url} ({row.email ? row.email : "No email"})
                </div>
              ))}
            </Stack>
          }
        >
          <div style={{ cursor: "pointer" }}>
            <EyeIconSVG />
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const BulkCandidatesUploadForm: FC<React.PropsWithChildren<{ handleNextStep: () => void }>> = ({ handleNextStep }) => {
  const [bulkUploadFormValues, setBulkCandidatesUploadAtom] = useAtom(bulkUploadCandidatesToCampaignAtom);

  const [isParsing, setIsParsing] = React.useState(false);
  const [parsingError, setParsingError] = React.useState<string | null>(null);

  const form = useForm<BulkUploadCandidatesToCampaignSchemaType>({
    resolver: zodResolver(bulkUploadCandidatesToCampaignSchema),
    // initialize form with values from jotai state to support navigation between steps
    defaultValues: bulkUploadFormValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  // Handle drop
  // Read the file and parse it
  const handleDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = acceptedFiles[0];

    if (file) {
      // @ts-ignore
      setValue("candidatesCsv", file, { shouldValidate: true });
    }

    setIsParsing(true);
    let rows: OutreachBulkCandidateItem[] = [];
    try {
      rows = await parseManualSourcingItemCSV(file);
    } catch (err) {
      if (err instanceof MissingHeaderError) {
        setParsingError(err.message);
      } else {
        setParsingError("Failed to parse CSV");
      }
      setIsParsing(false);
      return;
    }
    if (rows.length == 0) {
      setParsingError(
        "No rows found with valid data. Check your headers and make sure you're supplying LinkedIn profile URLs."
      );
    }
    // @ts-ignore
    setValue("manualSourcingItems", rows);
    setIsParsing(false);
  };

  const handleRemoveFile = (): void => {
    // @ts-ignore
    setValue("candidatesCsv", null);
    // @ts-ignore
    setValue("manualSourcingItems", null);
    setParsingError(null);
  };

  const handleNext = (values: BulkUploadCandidatesToCampaignSchemaType): void => {
    // Persist form values to jotai to be used in the next step
    setBulkCandidatesUploadAtom(values);
    handleNextStep();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleNext)}>
        <Stack spacing={2}>
          <div>
            <Subtitle2>Upload a CSV of candidates</Subtitle2>
            <BodySmall>
              The CSV should contain two columns named <em>LinkedIn</em> and <em>Email</em>
            </BodySmall>
          </div>
          <UploadBox
            name="candidatesCsv"
            placeholder={
              <Stack direction="column" justifySelf="center" alignItems="center">
                <UploadIconSVG />
                <BodySmall>Drag and drop a CSV here or click to upload</BodySmall>
              </Stack>
            }
            maxSize={TWENTY_MEGA_BYTES}
            onDrop={handleDrop}
            onDelete={handleRemoveFile}
            sx={{ width: "100%", height: "32px", padding: 5 }}
            accept=".csv"
            previewPrefix="CSV"
          />
          {parsingError && <BodyExtraSmall color={colors.critical.base}>{parsingError}</BodyExtraSmall>}
          {errors.candidatesCsv && <BodyExtraSmall color={colors.critical.base}>CSV required</BodyExtraSmall>}
          {!isParsing && !parsingError && <CSVSummary />}
          <FooterActions />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default BulkCandidatesUploadForm;
