import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useCallback } from "react";

import { REJECT_CANDIDATE, SCHEDULE_CANDIDATE } from "App/hotkeys/hotkeys";
import { useHotkey } from "App/hotkeys/useHotkey";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ThumbsDownIcon } from "assets/icons/thumbs-down.svg";
import { BookmarkButton } from "components/BookmarkButton";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  CreateDtnCandidateArgs,
  useCreateDtnCandidateMutation,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { ArchiveReasonTypeEnum, CreateDtnCandidateRequestDecisionEnum, SaapReviewApplication } from "services/openapi";
import { colors } from "styles/theme";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";
import { listTalentsOptimisticUpdateArgsAtom } from "views/candidates/DoverTalentNetwork/atoms/listTalentNetwork";

export const SAMPLE_CANDS_DISABLED_TOOLTIP = "You cannot schedule sample candidates because they are not real.";

export const ScheduleRejectButtons: React.FC<React.PropsWithChildren<{
  application?: SaapReviewApplication; // Only necessary if including the bookmark button
  candidateId?: string;
  showBookmarkButton?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  disabledSchedule?: boolean; // For sample candidates we want to be able to reject and not schedule
}>> = ({ application, candidateId, showBookmarkButton, disabled, disabledTooltip, disabledSchedule }) => {
  const dtnMode = useAtomValue(isDtnMode);
  const listTalentsOptimisticUpdateArgs = useAtomValue(listTalentsOptimisticUpdateArgsAtom);
  const jobId = useJobIdFromUrl();

  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const [createDtnCandidate, { isLoading: isCreatingDtnCandidate }] = useCreateDtnCandidateMutation();

  const disableButton = disabled || (!dtnMode && !candidateId) || isCreatingDtnCandidate;

  const clickSchedule = useCallback(async () => {
    if (disableButton) return;

    if (dtnMode) {
      if (!application || !jobId) return;

      const createDtnArgs: CreateDtnCandidateArgs = {
        args: {
          decision: CreateDtnCandidateRequestDecisionEnum.Approve,
          jobId,
          parentAppId: application.inboundAppId,
        },
        // don't need to optimistic update DTN here because they need to schedule through the modal
      };
      const res = await createDtnCandidate(createDtnArgs).unwrap();

      if (res.candidateId) {
        showCandidateActionModal({
          candidateId: res.candidateId,
          modalType: CandidateActionModalType.Schedule,
        });
        return;
      }
    }
    if (!candidateId) return; // Shouldn't be possible because button is disabled

    showCandidateActionModal({
      candidateId,
      modalType: CandidateActionModalType.Schedule,
    });
  }, [disableButton, dtnMode, candidateId, showCandidateActionModal, application, jobId, createDtnCandidate]);

  const clickReject = useCallback(async () => {
    if (disableButton) return;

    if (dtnMode && application && jobId) {
      const createDtnArgs: CreateDtnCandidateArgs = {
        args: {
          decision: CreateDtnCandidateRequestDecisionEnum.Reject,
          jobId,
          parentAppId: application.inboundAppId,
        },
        ...listTalentsOptimisticUpdateArgs,
      };
      await createDtnCandidate(createDtnArgs).unwrap();
      return;
    }

    if (!candidateId) return; // Shouldn't be possible because button is disabled

    showCandidateActionModal({
      candidateId,
      modalType: CandidateActionModalType.Reject,
      archiveContext: ArchiveReasonTypeEnum.Rejected,
    });
  }, [
    disableButton,
    dtnMode,
    application,
    jobId,
    candidateId,
    showCandidateActionModal,
    listTalentsOptimisticUpdateArgs,
    createDtnCandidate,
  ]);

  const wrappedClickSchedule = useHotkey(SCHEDULE_CANDIDATE, "Open Schedule Menu", clickSchedule);
  const wrappedClickReject = useHotkey(REJECT_CANDIDATE, "Open Reject Menu", clickReject);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        cursor: disableButton ? "not-allowed" : "pointer",
        opacity: disableButton ? 0.5 : 1,
      }}
    >
      <Button
        variant={ButtonVariant.Secondary}
        removePadding
        height="42px"
        onClick={wrappedClickSchedule}
        disabled={disableButton || disabledSchedule}
        tooltip={disableButton || disabledSchedule ? disabledTooltip : ""}
      >
        <Stack direction="row" spacing={1} alignItems="center" px="16px">
          <CalendarIcon className="svg-color" color={colors.primary.base} />
          <BodySmall>Schedule</BodySmall>
        </Stack>
      </Button>
      <Button
        variant={ButtonVariant.SecondaryCritical}
        removePadding
        height="42px"
        onClick={wrappedClickReject}
        disabled={disableButton}
        tooltip={disableButton ? disabledTooltip : ""}
      >
        <Stack direction="row" spacing={1} alignItems="center" px="16px">
          <ThumbsDownIcon />
          <BodySmall>{dtnMode ? "Pass" : "Reject"}</BodySmall>
        </Stack>
      </Button>
      {showBookmarkButton && <BookmarkButton application={application} compact />}
    </Stack>
  );
};
