import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC } from "react";

import { SaapReviewApplication } from "services/openapi";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";
import { ApplicantList } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantList";
import { ApplicantListFooter } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantListFooter";
import { ApplicantListTabs } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantListTabs";
import { ApplicantListToolbar } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantListToolbar";
import { NoApplicants } from "views/candidates/ApplicationReview/components/ApplicantListPanel/NoApplicants";
import { RemoveSampleApplicants } from "views/candidates/ApplicationReview/components/ApplicantListPanel/RemoveSampleApplicants";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";
import { useSelectedTabCount } from "views/candidates/ApplicationReview/hooks/useSelectedTabCount";

interface ApplicantListPanelProps {
  jobId?: string;
  selectedApplication: SaapReviewApplication | undefined;
  setSelectedApplicationId: (application: string | undefined) => void;
  numApplicants?: number;
  isFetchingApplications?: boolean;
  paramsAreBroken?: boolean;
}

export const ApplicantListPanel: FC<React.PropsWithChildren<ApplicantListPanelProps>> = ({
  jobId,
  selectedApplication,
  setSelectedApplicationId,
  numApplicants,
  isFetchingApplications,
  paramsAreBroken,
}) => {
  const { applications, isFetching } = useApplications();
  const count = useSelectedTabCount();

  const hasSampleApplicants = applications?.some((application: SaapReviewApplication) => application.isDemoCandidate);

  const selectedTab = useAtomValue(selectedApplicantListTabAtom);
  const isMatchingTabSelected = selectedTab === "Matching";

  return (
    <Stack height="100%">
      <ApplicantListToolbar />
      <ApplicantListTabs />
      {/* If there are some apps on the page but no best matches and no filters are applied, show the 'no best matches' card */}
      {/* Only show the no applicants card for tabs OTHER than the best matches only tab  */}
      {!isFetching && !count && !isMatchingTabSelected && <NoApplicants paramsAreBroken={paramsAreBroken} />}
      {jobId && hasSampleApplicants && <RemoveSampleApplicants jobId={jobId} />}
      <ApplicantList selectedApplication={selectedApplication} setSelectedApplicationId={setSelectedApplicationId} />
      <ApplicantListFooter
        jobId={jobId}
        numApplicants={numApplicants}
        isFetchingApplications={isFetchingApplications}
      />
    </Stack>
  );
};
