/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActorField,
    ActorFieldFromJSON,
    ActorFieldFromJSONTyped,
    ActorFieldToJSON,
    EmailEventField,
    EmailEventFieldFromJSON,
    EmailEventFieldFromJSONTyped,
    EmailEventFieldToJSON,
    InterviewEventField,
    InterviewEventFieldFromJSON,
    InterviewEventFieldFromJSONTyped,
    InterviewEventFieldToJSON,
    MovedJobEventField,
    MovedJobEventFieldFromJSON,
    MovedJobEventFieldFromJSONTyped,
    MovedJobEventFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivityFeedItem
 */
export interface ActivityFeedItem {
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedItem
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedItem
     */
    esrId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityFeedItem
     */
    timestamp: Date;
    /**
     * 
     * @type {ActorField}
     * @memberof ActivityFeedItem
     */
    actor: ActorField;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedItem
     */
    type: ActivityFeedItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedItem
     */
    subtype?: ActivityFeedItemSubtypeEnum;
    /**
     * 
     * @type {EmailEventField}
     * @memberof ActivityFeedItem
     */
    emailEventDetails?: EmailEventField;
    /**
     * 
     * @type {InterviewEventField}
     * @memberof ActivityFeedItem
     */
    interviewEventDetails?: InterviewEventField;
    /**
     * 
     * @type {MovedJobEventField}
     * @memberof ActivityFeedItem
     */
    movedJobEventDetails?: MovedJobEventField;
}

/**
* @export
* @enum {string}
*/
export enum ActivityFeedItemTypeEnum {
    Email = 'EMAIL',
    PhoneNumberAdded = 'PHONE_NUMBER_ADDED',
    InterviewFeedback = 'INTERVIEW_FEEDBACK',
    Calendar = 'CALENDAR',
    FinalStatusSet = 'FINAL_STATUS_SET',
    StageReachedUpdated = 'STAGE_REACHED_UPDATED',
    AtsStageUpdated = 'ATS_STAGE_UPDATED',
    ReplyTypeSet = 'REPLY_TYPE_SET',
    ApprovedByClient = 'APPROVED_BY_CLIENT',
    Approved = 'APPROVED',
    PassedAutomatedFitThreshold = 'PASSED_AUTOMATED_FIT_THRESHOLD',
    MovedToDifferentJob = 'MOVED_TO_DIFFERENT_JOB',
    InboundApplication = 'INBOUND_APPLICATION',
    InitialReplyTagsSet = 'INITIAL_REPLY_TAGS_SET'
}/**
* @export
* @enum {string}
*/
export enum ActivityFeedItemSubtypeEnum {
    InterviewScheduled = 'Interview scheduled',
    InterviewCanceled = 'Interview canceled',
    InterviewNoShow = 'Interview no-show',
    InterviewFeedback = 'Interview feedback',
    Hired = 'Hired',
    Snoozed = 'Snoozed',
    Rejected = 'Rejected',
    Withdraw = 'Withdraw',
    Active = 'Active',
    UnSnoozed = 'Un-Snoozed'
}

export function ActivityFeedItemFromJSON(json: any): ActivityFeedItem {
    return ActivityFeedItemFromJSONTyped(json, false);
}

export function ActivityFeedItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityFeedItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'event_id') ? undefined : json['event_id'],
        'esrId': !exists(json, 'esr_id') ? undefined : json['esr_id'],
        'timestamp': (new Date(json['timestamp'])),
        'actor': ActorFieldFromJSON(json['actor']),
        'type': json['type'],
        'subtype': !exists(json, 'subtype') ? undefined : json['subtype'],
        'emailEventDetails': !exists(json, 'email_event_details') ? undefined : EmailEventFieldFromJSON(json['email_event_details']),
        'interviewEventDetails': !exists(json, 'interview_event_details') ? undefined : InterviewEventFieldFromJSON(json['interview_event_details']),
        'movedJobEventDetails': !exists(json, 'moved_job_event_details') ? undefined : MovedJobEventFieldFromJSON(json['moved_job_event_details']),
    };
}

export function ActivityFeedItemToJSON(value?: ActivityFeedItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_id': value.eventId,
        'esr_id': value.esrId,
        'timestamp': (value.timestamp.toISOString()),
        'actor': ActorFieldToJSON(value.actor),
        'type': value.type,
        'subtype': value.subtype,
        'email_event_details': EmailEventFieldToJSON(value.emailEventDetails),
        'interview_event_details': InterviewEventFieldToJSON(value.interviewEventDetails),
        'moved_job_event_details': MovedJobEventFieldToJSON(value.movedJobEventDetails),
    };
}


