/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Paper } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import AddHiringManagerModal from "components/dover/AddHiringManagerModal";
import { usePartialUpdateJobPositionMutation } from "services/doverapi/endpoints/hiring-plan";
import { DoverUser, HiringPlanJobPosition } from "services/openapi/models";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";
import { HiringPlanTableRow } from "views/HiringPlan/types";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 100%;
    font-size: 14px;
    font-family: Inter;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

const AutocompletePaperComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Paper style={{ minWidth: "fit-content" }}>{children}</Paper>;
};

interface HiringManagerOption extends DoverUser {
  inputValue?: string;
}

const filter = createFilterOptions<HiringManagerOption>();

interface PassedProps {
  tableRow: HiringPlanTableRow;
  hiringManagers: DoverUser[];
}

const HiringManagerSelect = ({ hiringManagers, tableRow }: PassedProps): React.ReactElement => {
  const [value, setValue] = useState<HiringManagerOption | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect((): void => {
    const initialHiringManagerId = tableRow.jobHiringManager ?? tableRow.hiringManager;
    if (!value && initialHiringManagerId) {
      setValue(hiringManagers.find((hm: DoverUser): boolean => hm.pk === initialHiringManagerId) ?? null);
    }
  }, [hiringManagers]);

  const [partialUpdateJobPosition] = usePartialUpdateJobPositionMutation();

  const handleUpdateJobPosition = (newValue: HiringManagerOption | undefined): void => {
    const updatedHiringManager = newValue?.pk || null;
    const updatedJobPosition = {
      ...tableRow.jobPosition,
      hiringManager: updatedHiringManager,
    };
    partialUpdateJobPosition({ jobPosition: updatedJobPosition as HiringPlanJobPosition });
  };

  const handleCloseModal = (newHiringManager?: DoverUser): void => {
    setModalOpen(false);
    if (newHiringManager) {
      setValue(newHiringManager as HiringManagerOption);
      handleUpdateJobPosition(newHiringManager as HiringManagerOption);
    } else {
      setValue(null);
    }
  };

  return (
    <>
      <Autocomplete
        disabled={Boolean(tableRow.jobHiringManager)}
        value={value}
        onChange={(event, newValue): void => {
          if (
            typeof newValue === "string" ||
            (newValue && newValue.inputValue) ||
            newValue?.fullName === `Add a new user +`
          ) {
            setModalOpen(true);
          } else {
            setValue(newValue);
            handleUpdateJobPosition(newValue ?? undefined);
          }
        }}
        PaperComponent={AutocompletePaperComponent}
        selectOnFocus
        handleHomeEndKeys
        id={`hiring-manager-select-${tableRow.id}`}
        filterOptions={(options, params): any => {
          const filtered = filter(options, params);

          filtered.push({
            inputValue: params.inputValue,
            email: "",
            fullName: `Add a new user +`,
          } as HiringManagerOption);

          return filtered;
        }}
        options={(hiringManagers as HiringManagerOption[]) || []}
        getOptionLabel={(option): string => {
          option = option as HiringManagerOption;
          return option.fullName! || option.email!;
        }}
        renderOption={(props, option): React.ReactElement => (
          // @ts-ignore
          <li
            style={{
              color: option.fullName == "Add a new user +" || option.inputValue ? colors.informational.base : "inherit",
            }}
            {...props}
            key={option.pk ?? option.firstName! + option.lastName! + option.email}
          >
            <B2_doNotUse>{`${option.fullName || ""} ${option.email ? `(${option.email})` : " "}`}</B2_doNotUse>
          </li>
        )}
        sx={{ width: 150 }}
        freeSolo
        renderInput={(params): React.ReactElement => <StyledTextField variant="standard" {...params} />}
      />
      <AddHiringManagerModal isOpen={modalOpen} handleClose={handleCloseModal} />
    </>
  );
};

export default HiringManagerSelect;
