import { zodResolver } from "@hookform/resolvers/zod";
import { FormControlLabel, RadioGroup, Stack, TextFieldProps, ToggleButton } from "@mui/material";
import { useAtom } from "jotai";
import React, { ChangeEvent, FC, ReactElement, useState, MouseEvent } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { singleAddCandidateToCampaignAtom, uploadTypeAtom } from "components/dover/AddCandidate/atoms";
import BulkCandidatesUploadForm from "components/dover/AddCandidate/BulkUploadCandidatesForm";
import FooterActions from "components/dover/AddCandidate/Footer";
import {
  singleAddCandidateToCampaignSchema,
  SingleAddCandidateToCampaignSchemaType,
} from "components/dover/AddCandidate/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { Role, useContainsRole } from "components/RBAC";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { StyledRadio, StyledTextField, StyledToggleButtonGroup } from "components/StyledMuiComponents";
import { useJobId } from "hooks/useJobIdFromUrl";
import {
  useLazyCanSourceProfileQuery,
  useLazyFindEmailsFromLinkedInPublicUrlQuery,
} from "services/doverapi/endpoints/candidate";
import { CanSourceProfileResponse } from "services/openapi";
import { InternalLink } from "styles/typography";

interface ControlledTextFieldProps extends Pick<TextFieldProps, "placeholder" | "error" | "helperText"> {
  name: "firstName" | "lastName" | "email" | "linkedIn";
}

const ControlledTextField = ({ name, placeholder, error, helperText }: ControlledTextFieldProps): ReactElement => {
  const { control } = useFormContext<SingleAddCandidateToCampaignSchemaType>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): ReactElement => (
        <StyledTextField
          placeholder={placeholder}
          {...field}
          size="small"
          fullWidth
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

const SingleCandidateUploadForm: FC<React.PropsWithChildren<{ handleNextStep: () => void }>> = ({ handleNextStep }) => {
  const [singleCandidateFormValues, setSingleAddCandidateToCampaignToAtom] = useAtom(singleAddCandidateToCampaignAtom);

  const form = useForm<SingleAddCandidateToCampaignSchemaType>({
    resolver: zodResolver(singleAddCandidateToCampaignSchema),
    // initialize form with values from jotai state to support navigation between steps
    defaultValues: singleCandidateFormValues,
  });

  const {
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = form;

  const values = getValues();
  const email = values.email;

  const [jobId] = useJobId();

  const [hasCandidateEmail, setHasCandidateEmail] = useState<boolean | null>(email !== undefined ? !!email : null);
  const [failedToFindCandidateEmail, setFailedToFindCandidateEmail] = useState(false);

  const [manualSourcePrecheckResult, setManualSourcePrecheckResult] = useState<CanSourceProfileResponse | null>(null);

  const [findCandidateEmail, { isFetching: isFindingCandidateEmail }] = useLazyFindEmailsFromLinkedInPublicUrlQuery();

  const [
    canSourceProfile,
    { isFetching: isPresourcingCandidate, isError: failedToPresource },
  ] = useLazyCanSourceProfileQuery();

  const candidateAlreadyExists =
    manualSourcePrecheckResult?.canSource === false && manualSourcePrecheckResult?.candidateId && jobId;
  const showErrorBanner = (candidateAlreadyExists || failedToPresource) && !isPresourcingCandidate;

  const handleSetHasCandidateEmail = (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
    // Side effect: if the user toggles to "Yes", clear the LinkedIn field because it's no longer needed and
    // if it has any validation errors, it won't show on screen
    if (value === "true") {
      // ts thinks we're trying to assign a string to type "never" but this is valid based on our schema
      // @ts-ignore
      setValue("linkedIn", "");
      clearErrors("linkedIn");
      setHasCandidateEmail(true);
    } else {
      setHasCandidateEmail(false);
    }
  };

  const handleNext = async (values: SingleAddCandidateToCampaignSchemaType): Promise<void> => {
    if (!jobId) {
      console.error("No job id found to add candidate to campaign");
    }

    // First, if there is a linkedin URL and no email, attempt to find an email
    let foundEmail: string | undefined;
    if (!values.email && values.linkedIn) {
      const { linkedIn } = values;
      try {
        const candidateEmailResult = await findCandidateEmail({
          data: {
            linkedinPublicUrl: linkedIn,
            runFullEmailFinding: false,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        }).unwrap();

        if (candidateEmailResult.success && candidateEmailResult.emails && candidateEmailResult.emails.length > 0) {
          foundEmail = candidateEmailResult.emails[0].email;
        } else {
          setFailedToFindCandidateEmail(true);
          return;
        }
      } catch (e) {
        console.error("Failed to find email for candidate", e);
        setFailedToFindCandidateEmail(true);
        return;
      }
    }

    try {
      const canSourceProfileResult = await canSourceProfile({
        data: {
          email: values.email || foundEmail || undefined,
          jobId: jobId!,
          linkedinPublicUrl: values.linkedIn || undefined,
        },
      }).unwrap();

      if (canSourceProfileResult) {
        setManualSourcePrecheckResult(canSourceProfileResult);
        // If the candidate can be manually sourced, move to the next step
        if (canSourceProfileResult.canSource) {
          // Persist form state in jotai for access outside of this form
          setSingleAddCandidateToCampaignToAtom({
            ...values,
            // Replace email with found email (from linkedin url) if no email was provided
            email: values.email ?? foundEmail,
          });
          handleNextStep();
        }
      }
    } catch (e) {
      console.error("Failed to presource candidate", e);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleNext)}>
        <Stack spacing={2}>
          {/* First name field */}
          <div>
            <Stack direction="row" spacing={0.5}>
              <Subtitle2>First name</Subtitle2>
              <RequiredAsterisk />
            </Stack>
            <ControlledTextField
              name="firstName"
              placeholder="Enter first name"
              error={!!errors.firstName}
              helperText={errors.firstName?.message ?? ""}
            />
          </div>
          {/* Last name field */}
          <div>
            <Stack direction="row" spacing={0.5}>
              <Subtitle2>Last name</Subtitle2>
              <RequiredAsterisk />
            </Stack>
            <ControlledTextField
              name="lastName"
              placeholder="Enter last name"
              error={!!errors.lastName}
              helperText={errors.lastName?.message ?? ""}
            />
          </div>
          {/* Check if has candidate email */}
          <div>
            <Subtitle2>{"Do you have the candidate's email?"}</Subtitle2>
            <RadioGroup row value={hasCandidateEmail} onChange={handleSetHasCandidateEmail} name="hasCandidateEmail">
              <FormControlLabel value={true} control={<StyledRadio />} label={<BodySmall>Yes</BodySmall>} />
              <FormControlLabel
                value={false}
                control={<StyledRadio />}
                label={<BodySmall>No (find it for me)</BodySmall>}
              />
            </RadioGroup>
          </div>
          {/* Conditional email field */}
          {hasCandidateEmail && (
            <div>
              <Stack direction="row" spacing={0.5}>
                <Subtitle2>Email</Subtitle2>
                <RequiredAsterisk />
              </Stack>
              <ControlledTextField
                name="email"
                placeholder="Enter email"
                error={!!errors.email}
                helperText={errors.email?.message ?? ""}
              />
            </div>
          )}
          {/* Conditional LinkedIn field */}
          {hasCandidateEmail === false && (
            <div>
              <Stack direction="row" spacing={0.5}>
                <Subtitle2>{"Enter the candidate's LinkedIn"}</Subtitle2>
                <RequiredAsterisk />
              </Stack>
              <ControlledTextField
                name="linkedIn"
                placeholder="Enter URL"
                error={!!errors.linkedIn}
                helperText={errors.linkedIn?.message ?? ""}
              />
            </div>
          )}
          {showErrorBanner && (
            <Banner variant={BannerVariant.Critical}>
              {failedToPresource &&
                "There was an error adding this candidate. Please try again or reach out to support."}
              {manualSourcePrecheckResult?.candidateId && jobId && (
                <BodySmall>
                  This candidate has already been sourced. View them{" "}
                  <InternalLink
                    to={APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, manualSourcePrecheckResult.candidateId)}
                  >
                    here.
                  </InternalLink>
                </BodySmall>
              )}
            </Banner>
          )}
          {failedToFindCandidateEmail && (
            <Banner variant={BannerVariant.Critical}>
              Failed to find candidate email. Please enter the email manually or try again.
            </Banner>
          )}
          <FooterActions disableNext={isPresourcingCandidate || isFindingCandidateEmail} />
        </Stack>
      </form>
    </FormProvider>
  );
};

const CandidateDetailsForm: FC<React.PropsWithChildren<{ handleNextStep: () => void; handlePrevStep: () => void }>> = ({
  handleNextStep,
}) => {
  const [uploadType, setUploadType] = useAtom(uploadTypeAtom);

  // Disable bulk upload functionality for external users.
  if (!useContainsRole([Role.ADMIN, Role.RECRUITING_PARTNER, Role.INTERVIEWER, Role.COORDINATOR])) {
    setUploadType("single");
    return <SingleCandidateUploadForm handleNextStep={handleNextStep} />;
  }

  return (
    <Stack spacing={2}>
      <StyledToggleButtonGroup
        size="small"
        value={uploadType}
        exclusive
        onChange={(_: MouseEvent<HTMLElement | MouseEvent>, newValue: any): void => setUploadType(newValue)}
        aria-label="Bulk Upload Toggle"
        color="success"
      >
        <ToggleButton value="single" aria-label="single">
          Single candidate
        </ToggleButton>
        <ToggleButton value="bulk" aria-label="bulk">
          Multiple candidates
        </ToggleButton>
      </StyledToggleButtonGroup>

      {uploadType === "bulk" ? (
        <BulkCandidatesUploadForm handleNextStep={handleNextStep} />
      ) : (
        <SingleCandidateUploadForm handleNextStep={handleNextStep} />
      )}
    </Stack>
  );
};

export default CandidateDetailsForm;
