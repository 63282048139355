import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { colors } from "styles/theme";

const Container = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1202; // top nav + 1
  background-color: ${colors.grayscale.gray100};
  overflow: auto;
`;

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Layout;
