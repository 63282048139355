import { DataGrid, GridEnrichedColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { FC } from "react";

import { BodySmall, Overline } from "components/library/typography";
import { colors } from "styles/theme";

const calculationsTableColumns: GridEnrichedColDef[] = [
  {
    field: "task",
    headerName: "Task",
    flex: 1,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray500}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <BodySmall weight="600" color={colors.grayscale.gray700}>
        {params.row.task}
      </BodySmall>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray500}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <BodySmall color={colors.grayscale.gray600}>{params.row.type}</BodySmall>
    ),
  },
  {
    field: "candidates",
    headerName: "Candidates",
    sortable: false,
    flex: 1,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray500}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <BodySmall color={colors.grayscale.gray600}>{params.row.candidates}</BodySmall>
    ),
  },
  {
    field: "hours",
    headerName: "Hours",
    sortable: false,
    flex: 1,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray500}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <BodySmall color={colors.grayscale.gray600}>{params.row.hours}</BodySmall>
    ),
  },
];

const calculationsTableRows = [
  {
    id: 1,
    task: "Sourcing",
    type: "Top of funnel",
    candidates: "200",
    hours: "10",
  },
  { id: 2, task: "Resume Review", type: "Top of funnel", candidates: "600", hours: "10" },
  { id: 4, task: "Referrals", type: "Top of funnel", candidates: "8", hours: "2" },
  { id: 10, task: "Phone Screens", type: "Interviews", candidates: "30", hours: "20" },
  {
    id: 6,
    task: "Hiring manager screens",
    type: "Interviews",
    candidates: "15",
    hours: "18",
  },
  { id: 7, task: "Onsites", type: "Interviews", candidates: "4", hours: "20" },
  { id: 8, task: "Offer", type: "Interviews", candidates: "2", hours: "10" },
  { id: 5, task: "Scheduling", type: "Interviews", candidates: "51", hours: "6" },
  { id: 9, task: "Total", type: "", candidates: "", hours: "96" },
];

export const TimeToHireTable: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <DataGrid
      disableColumnMenu
      autoHeight
      rows={calculationsTableRows}
      columns={calculationsTableColumns}
      disableSelectionOnClick
      disableColumnFilter
      density="compact"
      hideFooter
    />
  );
};
