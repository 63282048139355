import { Stack, createFilterOptions } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Checkbox } from "components/library/Checkbox";
import { BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { useInviteReferrersMutation, useListReferrersQuery } from "views/referralsV2/endpoints";

const BASIC_EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const INVALID_EMAIL_OPTION = "Enter a valid email to continue";
type Referrer = {
  id?: string;
  email: string;
};

export const InviteReferrerModal: React.FC<React.PropsWithChildren<GlobalModalProps>> = ({ isOpen, close }) => {
  const filter = createFilterOptions<Referrer>();

  const [inviteReferrers] = useInviteReferrersMutation();
  const { data, isFetching } = useListReferrersQuery({ limit: 300, isAgencyRecruiter: false });
  const emails =
    data?.results.map(r => {
      return {
        id: r.id,
        email: r.email,
      };
    }) || [];

  const [selectedReferrers, setSelectedReferrers] = React.useState<Referrer[]>([]);

  const jobId = useJobIdFromUrl();
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);

  const handleSubmit = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    await inviteReferrers({
      jobId,
      data: {
        emails: selectedReferrers.map(r => r.email),
      },
    }).unwrap();

    close();
  };

  return (
    <CustomModal
      title="Invite referrers"
      loading={isFetching}
      open={isOpen}
      onClose={close}
      maxWidth="sm"
      omitDividers
      showTitleSpacer={false}
    >
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <BodySmall weight="400">
            Inviting someone will send them an email with instructions on how they can submit referral candidates
            {jobSetup?.clientName && ` to ${jobSetup.clientName}`}
          </BodySmall>
          <Autocomplete
            multiple
            // @ts-ignore
            freeSolo
            filterOptions={(options, params): any[] => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(option => inputValue === option.email);
              if (inputValue !== "" && !isExisting)
                if (inputValue.match(BASIC_EMAIL_REGEX)) {
                  filtered.push({
                    email: `Invite "${inputValue}"`,
                  });
                } else {
                  filtered.push({
                    email: INVALID_EMAIL_OPTION,
                  });
                }

              return filtered;
            }}
            autoHighlight
            handleHomeEndKeys
            selectOnFocus
            clearOnBlur
            placeholder="Email"
            initialValues={selectedReferrers}
            staticOptions={emails}
            disableCloseOnSelect
            onSelectedOptionsChange={(values): void => {
              const cleanedValues = values
                .filter(o => o && o.email && o.email !== INVALID_EMAIL_OPTION)
                .map(o => {
                  return {
                    ...o,
                    email: o.email.replace('Invite "', "").replace('"', ""),
                  };
                });
              setSelectedReferrers(cleanedValues);
            }}
            isOptionEqualToValue={(option: Referrer, value: Referrer): boolean => {
              return option.email === value.email;
            }}
            getOptionLabel={(option): string => option.email}
            renderOption={(props, option, { selected }): ReactElement => {
              const { ...optionProps } = props;
              return (
                <li key={option.email} {...optionProps}>
                  {option.id && <Checkbox checked={selected} />}
                  {option.email}
                </li>
              );
            }}
          />
        </Stack>

        <Stack direction="row" justifyContent={"flex-end"} width="100%">
          <Stack width="fit-content">
            <Button variant={ButtonVariant.Primary} onClick={handleSubmit}>
              Invite
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export const inviteReferrerModalAtom = modalAtom(InviteReferrerModal);
