import { useEffect, useState } from "react";

import { EXTENSION_ID } from "utils/constants";

export const useIsExtensionInstalled = (): boolean | undefined => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isExtensionInstalled !== undefined) {
      return;
    }

    (async (): Promise<void> => {
      if (chrome?.runtime?.sendMessage) {
        const resp = await chrome.runtime.sendMessage(EXTENSION_ID, { message: "is_installed" });

        setIsExtensionInstalled(!!resp?.is_installed);
      } else {
        setIsExtensionInstalled(false);
      }
    })();
  }, [isExtensionInstalled]);

  return isExtensionInstalled;
};
