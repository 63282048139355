/*eslint no-undef: "error"*/
/*eslint-env node*/

import { captureConsoleIntegration, httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { boot_ondemand_integration } from "ondemand-integration";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

import AllRoutes from "App/routing/allRoutes";
import Loading from "components/HotLoading";
import config from "services/auth_config";
import { Auth0Provider } from "services/react-auth0-spa";
import { store } from "store";
import history from "utils/history";

// Init sentry for error tracking
if (process.env.NODE_ENV === "production") {
  try {
    Sentry.init({
      dsn: "https://920cff409c175b008ab31f744a3bab65@o238576.ingest.us.sentry.io/4507053744128000",
      integrations: [
        Sentry.browserTracingIntegration(),
        captureConsoleIntegration({
          levels: ["error"],
        }),
        httpClientIntegration({
          failedRequestTargets: ["localhost", /^https:\/\/app\.dover\.io\/*/, /^https:\/\/app\.dover\.com\/*/],
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      beforeSend(event, hint) {
        // drop some
        const error = hint.originalException;
        // until we figure out the cause, we want to sample these errors at a lower rate to save sentry quota
        if (error && error.message && error.message.match(/Login required/i)) {
          let sampleRate = 0.25;
          if (Math.random() > 0.25) {
            // only send 25% of these errors, drop the rest
            return null;
          }
          event.extra = {
            ...event.extra,
            appliedCustomErrorSampleRate: sampleRate,
          };
        }
        return event;
      },
      // Performance Monitoring
      tracesSampleRate: 0.1, //  Capture 10% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/app\.dover\.io\/api/, /^https:\/\/app\.dover\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      sendDefaultPii: true, // for http error request tracking
    });
  } catch (error) {
    console.error("Sentry initialization failed:", error);
  }
}

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const container = document.getElementById("root");
const root = createRoot(container);

const render = () => {
  root.render(
    <Provider store={store}>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        audience={config.audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        // screen_hint redirects to our signup page if this query param exists, otherwise the login page
        screen_hint={window.location.search.includes("signup=welcome") ? "signup" : undefined}
        useRefreshTokens={true}
        cacheProvider="localstorage"
        cacheLocation="localstorage"
      >
        <React.Suspense fallback={<Loading />}>
          <AllRoutes />
        </React.Suspense>
      </Auth0Provider>
    </Provider>
  );
};

if (module.hot) {
  // Hot reloadable React components
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["."], () => {
    root.unmount(container);
    render();
  });
}

render();

if (process.env.NODE_ENV === "development") {
  boot_ondemand_integration();
}
