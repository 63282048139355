import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as CheckSVG } from "assets/icons/simple-green-check.svg";
import { ReactComponent as SourcingTargetIcon } from "assets/icons/sourcing-target.svg";
import { SOURCING_EXTENSION_STORE_URL, SOURCING_EXTENSION_HELP_ARTICLE_URL } from "components/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Overline } from "components/library/typography";
import { useIsExtensionInstalled } from "hooks/useIsExtensionInstalled";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { TemplateCard } from "views/job/JobSetup/steps/Overview/components/DoverSolutionsTemplateCard";
import { BaseBadge } from "views/job/JobSetup/steps/Overview/styles";

const SourcingExtensionStoreLink = ({ linkText }: { linkText: string }): React.ReactElement => {
  return (
    <ExternalLink href={SOURCING_EXTENSION_STORE_URL} target="_blank" rel="noreferrer">
      {linkText}
    </ExternalLink>
  );
};

const ShareOnLinkedInLink = ({
  shareUrl,
  shareMessage,
  linkText,
}: {
  shareUrl: string;
  shareMessage: string;
  linkText: string;
}): React.ReactElement => {
  return (
    <ExternalLink
      href={`https://www.linkedin.com/feed/?shareActive=true&shareUrl=${encodeURIComponent(
        shareUrl
      )}&text=${encodeURIComponent(shareMessage)}`}
      target="_blank"
      rel="noreferrer"
    >
      {linkText}
    </ExternalLink>
  );
};

const shareExtensionOnLinkedInMessage =
  "I've supercharged my recruiting efforts with Dover's FREE candidate sourcing extension. Check it out!";

const SourcingExtensionDescription = (): React.ReactElement => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      Source candidates directly on LinkedIn using our Chrome sourcing extension.
    </BodySmall>
  );
};

const SourcingExtensionDescriptionDownloaded = (): React.ReactElement => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      Start sourcing your candidates with the extension on LinkedIn.
      <BodySmall color={colors.grayscale.gray500} style={{ marginTop: "5px" }}>
        Like the extension? Review on the <SourcingExtensionStoreLink linkText="Chrome Store" /> or{" "}
        <ShareOnLinkedInLink
          shareUrl={SOURCING_EXTENSION_STORE_URL}
          shareMessage={shareExtensionOnLinkedInMessage}
          linkText="Share on LinkedIn"
        />
        .
      </BodySmall>
    </BodySmall>
  );
};

export const SourcingExtensionCard = (): React.ReactElement => {
  const isExtensionInstalled = useIsExtensionInstalled();

  if (isExtensionInstalled === undefined) {
    return <></>;
  }

  const DownloadExtensionButton = (
    <Button
      variant={ButtonVariant.SecondarySuccess}
      onClick={(): void => {
        window.open(SOURCING_EXTENSION_STORE_URL);
      }}
      width="100%"
    >
      Download
    </Button>
  );

  const StartSourcingButton = (
    <Button
      variant={ButtonVariant.SecondarySuccess}
      onClick={(): void => {
        window.open("https://www.linkedin.com");
      }}
      width="100%"
    >
      Start Sourcing
    </Button>
  );

  const Body = isExtensionInstalled ? <SourcingExtensionDescriptionDownloaded /> : <SourcingExtensionDescription />;
  const button = isExtensionInstalled ? StartSourcingButton : DownloadExtensionButton;
  const badge = isExtensionInstalled ? (
    <BaseBadge>
      <Stack direction="row" alignItems="center">
        <CheckSVG />
        &nbsp;<Overline color={colors.success.base}>INSTALLED</Overline>
      </Stack>
    </BaseBadge>
  ) : (
    <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
      <BaseBadge $backgroundColor={colors.grayscale.gray200}>
        <Overline>Not Installed</Overline>
      </BaseBadge>
      <BaseBadge>
        <Overline color={colors.success.base}>NEW!</Overline>
      </BaseBadge>
    </Stack>
  );
  return (
    <TemplateCard
      imageComponent={<SourcingTargetIcon height="100%" width="60px" style={{ paddingLeft: "8px" }} />}
      imageComponentMinWidth="60px"
      learnMoreLink={SOURCING_EXTENSION_HELP_ARTICLE_URL}
      learnMoreLinkOpenInNewTab
      title="Sourcing Extension"
      body={Body}
      actionButton={button}
      featureStateBadge={badge}
      forceAction
    />
  );
};
