import { CircularProgress, Stack } from "@mui/material";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import React, { ReactElement } from "react";

import { ReactComponent as PaperSVG } from "assets/icons/paper.svg";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { colors } from "styles/theme";

export const FileThumbnail = ({
  url,
  height = "50px",
  width = "50px",
}: {
  url?: string;
  height?: string;
  width?: string;
}): ReactElement => {
  if (!url) {
    return <NoFile />;
  }

  return (
    <Stack
      sx={{
        flexGrow: 0,
        marginLeft: "0.5em",
        height,
        width,
        "& .rpv-core__inner-pages": {
          overflow: "hidden !important",
          "div :not(:first-child)": {
            display: "none",
          },
        },
        "& .rpv-core__inner-page": {
          height: `${height} !important`,
          width: `${width} !important`,
        },
      }}
    >
      {url.includes(".pdf") ? (
        // Make sure `pdfjs-dist` package version is updated when we update this
        // @ts-ignore FIX: react 18 type incompatibility
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {/* @ts-ignore FIX: react 18 type incompatibility */}
          <Viewer
            fileUrl={url}
            defaultScale={SpecialZoomLevel.PageWidth}
            renderLoader={(): ReactElement => <CircularProgress size="25px" />}
            renderError={NoFile}
          />
        </Worker>
      ) : (
        <NoFile />
      )}
    </Stack>
  );
};

const NoFile = (): ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <PaperSVG className="svg-color" color={colors.grayscale.gray500} height="24px" width="24px" />
    </Stack>
  );
};
