import { Add, Remove } from "@mui/icons-material";
import { Box, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "react-use";
import styled from "styled-components";
import { StringParam, useQueryParams } from "use-query-params";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import { EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListJobsQuery, selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { DashboardJob } from "services/openapi";
import { CustomScrollBox } from "styles/layout";
import { colors } from "styles/theme";
import { FilterDivider, FilterSectionLabel } from "views/candidates/CandidateTable/filters/styles";

const JOB_FILTER_HEIGHT = "125px";

interface Props {
  activeJobId: string;
}

const View: React.FC<React.PropsWithChildren<Props>> = ({ activeJobId }) => {
  const [selectedHmId, setSelectedHmId] = useState<number | undefined>(undefined); // 0 represents "All" hms being selected
  const [showInactiveJobs, toggleInactiveJobs] = useBoolean(false);

  // Url Query Params
  const selectedJobId = useJobIdFromUrl();
  const [, setQuery] = useQueryParams({
    job: StringParam,
    stage: StringParam,
    status: StringParam,
  });

  const { activeJobs, inactiveJobs, isLoading: jobsLoading, activeHiringManagers } = useListJobsQuery(undefined, {
    selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { sortFunc }),
  });

  const { data: userInfo } = useGetAuthedUserInfoQuery();

  // Default hiring manager after data loads in
  useEffect(() => {
    if (selectedHmId === undefined && activeHiringManagers && userInfo) {
      const hmId = !selectedJobId && activeHiringManagers?.some(hm => hm.id === userInfo.id!) ? userInfo.id! : 0; // Default to all if user isn't an active hiring manager
      setSelectedHmId(hmId);
    }
  }, [activeHiringManagers, selectedHmId, selectedJobId, userInfo]);

  const changeJob = useCallback(
    (id: string): void => {
      setQuery({ job: id, stage: "", status: "ACTIVE" });
    },
    [setQuery]
  );

  const activeJobList = useMemo(
    () =>
      activeJobs?.map((job: DashboardJob) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <JobLabel
            key={job.id}
            $active={job.id === activeJobId}
            hidden={!!selectedHmId && job.hiringManager?.id !== selectedHmId}
            onClick={(): void => changeJob(job.id!)}
          >
            {job.title}
          </JobLabel>
          {job.isPrivate && <EyeCrossedOutIcon />}
        </Stack>
      )),
    [activeJobId, activeJobs, changeJob, selectedHmId]
  );

  const inactiveJobList = useMemo(
    () =>
      inactiveJobs?.map((job: DashboardJob) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <JobLabel
            key={job.id}
            $active={job.id === activeJobId}
            hidden={!!selectedHmId && job.hiringManager?.id !== selectedHmId}
            onClick={(): void => changeJob(job.id!)}
          >
            {job.title}
          </JobLabel>
          {job.isPrivate && <EyeCrossedOutIcon />}
        </Stack>
      )),
    [activeJobId, changeJob, inactiveJobs, selectedHmId]
  );

  // If there are no active jobs, but there are inactive jobs, toggle the list open
  useEffect(() => {
    if ((!activeJobList || activeJobList.length === 0) && inactiveJobList && inactiveJobList.length > 0) {
      toggleInactiveJobs(true);
    }
  }, [toggleInactiveJobs, activeJobList, inactiveJobList]);

  // Set default job
  useEffect(() => {
    // If nothing is selected, default to first active Job
    if (!selectedHmId && !selectedJobId) {
      if (activeJobs?.[0]) {
        changeJob(activeJobs[0].id!);
      }
    }
    // If a specific HM is selected, ensure selected Job belongs to them
    else if (selectedHmId && selectedJobId) {
      // Find their corresponding active and inactive jobs
      const activeJobIdsForHm = activeJobs?.filter(job => job.hiringManager?.id === selectedHmId).map(job => job.id!);
      const inactiveJobIdsForHm = inactiveJobs
        ?.filter(job => job.hiringManager?.id === selectedHmId)
        .map(job => job.id!);
      // And select the first active job if the current selectedJobId doesn't belong to that HM
      if (
        activeJobIdsForHm &&
        !activeJobIdsForHm?.includes(selectedJobId) &&
        !inactiveJobIdsForHm?.includes(selectedJobId)
      ) {
        changeJob(activeJobIdsForHm[0]);
      }
    }
  }, [activeJobs, changeJob, inactiveJobs, selectedHmId, selectedJobId]);

  if (jobsLoading) {
    return (
      <Box height={JOB_FILTER_HEIGHT} width="100%" position="relative">
        <Centered>
          <LoadingRelative />
        </Centered>
      </Box>
    );
  }

  return (
    <>
      <SectionHeader>
        <FilterSectionLabel>Hiring Manager</FilterSectionLabel>
      </SectionHeader>
      <Spacer height={6} />
      <StyledSelect
        value={selectedHmId ?? 0}
        onChange={(e: SelectChangeEvent<unknown>): void => {
          setSelectedHmId(Number(e.target.value));
        }}
      >
        {[
          <MenuItem value={0}>
            <BodySmall color={colors.grayscale.gray600}>All</BodySmall>
          </MenuItem>,
          ...(activeHiringManagers
            ? activeHiringManagers.map(hm => (
                <MenuItem key={hm.id} value={hm.id}>
                  <BodySmall color={colors.grayscale.gray600}>{hm.name}</BodySmall>
                </MenuItem>
              ))
            : []),
        ]}
      </StyledSelect>
      <Spacer height={16} />
      <FilterSectionLabel>Active Jobs</FilterSectionLabel>
      <Spacer height={6} />
      <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{activeJobList}</CustomScrollBox>

      <Spacer height={16} />
      <FilterDivider />
      <Spacer height={16} />

      <SectionHeader onClick={toggleInactiveJobs}>
        <FilterSectionLabel>Inactive Jobs</FilterSectionLabel>
        {showInactiveJobs ? <Remove /> : <Add />}
      </SectionHeader>
      {showInactiveJobs && (
        <>
          <Spacer height={6} />
          <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{inactiveJobList}</CustomScrollBox>
        </>
      )}
    </>
  );
};

const sortFunc = (jobs: DashboardJob[]): DashboardJob[] =>
  sortBy(jobs, [(job: DashboardJob): string => (job.title ? job.title.toLowerCase() : "")]);

const SectionHeader = styled.div`
  align-items: center;
  color: ${colors.grayscale.gray500};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

interface JobLabelProps {
  $active: boolean;
}

const JobLabel = styled(BodySmall)<JobLabelProps>`
  color: ${(props): string => (props.$active ? colors.black : colors.grayscale.gray500)};
  cursor: pointer;
  font-weight: ${(props): number => (props.$active ? 500 : 400)};
  padding: 3px 0px;
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &.MuiInputBase-root {
    border: none !important;
  }

  .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 10px;
    background: ${colors.white};
    border-color: ${colors.grayscale.gray300};
  }

  fieldset {
    border-color: ${colors.grayscale.gray300};
    top: 0px;

    legend {
      display: none;
    }
  }
`;

export default View;
