import { List } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface ProgressListProps {
  children: React.ReactNode;
  maxWidth?: number;
}

const StepList = styled(List)`
  &&& .MuiListItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .MuiButtonBase-root {
    display: flex;
    justify-content: flex-start;
    padding: 12px 16px;
  }
`;

export const ProgressList: React.FC<React.PropsWithChildren<ProgressListProps>> = ({ children, maxWidth }) => {
  return <StepList style={{ maxWidth: `${maxWidth}px` ?? "none" }}>{children}</StepList>;
};
