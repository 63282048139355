import { Box, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";

import HelpSVG from "assets/icons/help-question.svg";
import PipelineIcon from "assets/icons/pipeline-icon.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetJobFunnelStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { DashboardJob, JobInterviewStageStats } from "services/openapi";
import { colors } from "styles/theme";
import { DateDropdownSelector } from "views/Reporting/components/DateDropdownSelector";
import { StageRow } from "views/Reporting/components/StageRow";
import { DateOption } from "views/Reporting/constants";
import { ReportBlock, TableHeader } from "views/Reporting/styles";
import { StyledSVG } from "views/Reporting/styles";
import { DateRange } from "views/Reporting/types";

interface InterviewStatsTableProps {
  job: DashboardJob;
  dateRange: DateRange;
  dateRangeOption: DateOption;
  onDateRangeChange: (value: DateOption) => void;
}

export const InterviewStatsTable: React.FC<React.PropsWithChildren<InterviewStatsTableProps>> = ({
  job,
  dateRange,
  dateRangeOption,
  onDateRangeChange,
}) => {
  const [showPercentages, setShowPercentages] = useState<boolean>(false);

  const { data: interviewStats, isFetching } = useGetJobFunnelStatsQuery({
    id: job.id!,
    ...dateRange,
  });

  const PASS_THROUGH_RATE_TOOLTIP_TEXT = "Excludes active candidates in previous interview stages.";

  return (
    <ReportBlock>
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledSVG
              src={PipelineIcon}
              style={{
                marginRight: "8px",
                width: "16px",
                height: "16px",
                color: colors.grayscale.gray600,
              }}
            />
            <BodySmall weight="700">Interview Funnel</BodySmall>
            <Tooltip title={PASS_THROUGH_RATE_TOOLTIP_TEXT} placement="top">
              <span>
                <StyledSVG src={HelpSVG} style={{ marginLeft: "4px", marginRight: "4px" }} />
              </span>
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <DateDropdownSelector value={dateRangeOption} onChange={onDateRangeChange} />
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                disabled={isFetching}
                checked={showPercentages}
                onChange={(): void => setShowPercentages(!showPercentages)}
                size="small"
              />
              <BodySmall color={colors.grayscale.gray600}>Pass-through rates</BodySmall>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Spacer height={20} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader scope="col" />
              <TableHeader scope="col">Entered Stage</TableHeader>
              <TableHeader scope="col">Currently Active</TableHeader>
              <TableHeader scope="col">Lost</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={5} height="294px">
                  <DoverLoadingSpinner />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {interviewStats?.statsByStage.map((stats: JobInterviewStageStats, idx: number) => {
                  return (
                    <StageRow
                      key={idx}
                      allStats={interviewStats.statsByStage}
                      stageStats={stats}
                      job={job}
                      showPercentages={showPercentages}
                    />
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ReportBlock>
  );
};
