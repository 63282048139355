import { Box, Stack, FormControl, MenuItem, SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";

import { atsOptions } from "App/appConstants";
import { CompanySetupSectionType } from "App/routing/types";
import HelpIconSVG from "assets/icons/help-question.svg";
import { LoadingRelative } from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateClientOnboardingMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { useIsLeverAuthValid } from "services/doverapi/endpoints/leverData/hooks";
import {
  ClientOnboarding,
  ClientOnboardingAtsTypeEnum,
  ClientOnboardingSetupAtsStateEnum,
  UpdateClientBodySetupAtsStateEnum,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";
import { AshbyComponent } from "views/CompanySetup/components/AtsSettings/AshbyComponent";
import { GreenhouseComponent } from "views/CompanySetup/components/AtsSettings/GreenhouseComponent";
import { LeverOAuthComponent } from "views/CompanySetup/components/AtsSettings/LeverOAuthComponent";
import { NoAtsComponent } from "views/CompanySetup/components/AtsSettings/NoAtsComponent";
import { OtherAtsComponent } from "views/CompanySetup/components/AtsSettings/OtherAtsComponent";
import { AtsCard, StyledSelect } from "views/CompanySetup/components/AtsSettings/styles";
import { SyncAtsJobs } from "views/CompanySetup/components/AtsSettings/SyncAtsJobs";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";

const AtsSettingsComponent = ({ onSave }: { onSave?: () => void }): React.ReactElement => {
  const clientId = useGetClientId();
  const leverAuthValid = useIsLeverAuthValid();

  const { data: clientOnboarding, isLoading: isClientOnboardingLoading } = useGetClientOnboardingQuery();

  const [selectedAtsType, setSelectedAtsType] = React.useState<ClientOnboardingAtsTypeEnum>(
    clientOnboarding?.atsType ?? ClientOnboardingAtsTypeEnum.NoAts
  );
  // Greenhouse state
  const [greenhouseApiKeyInput, setGreenhouseApiKeyInput] = React.useState<string>(
    clientOnboarding?.greenhouseApiKey ?? ""
  );
  const [greenhouseSubdomainInput, setGreenhouseSubdomainInput] = React.useState<string>(
    clientOnboarding?.greenhouseSubdomain ?? ""
  );
  const [greenhouseEditMode, setGreenhouseEditMode] = React.useState<boolean>(false);

  // Ashby state
  const [ashbyApiKeyInput, setAshbyApiKeyInput] = React.useState<string>(clientOnboarding?.ashbyApiKey ?? "");
  const [ashbyEditMode, setAshbyEditMode] = React.useState<boolean>(false);

  const [
    updateClientOnboardingMutation,
    { isLoading: isUpdatingClientOnboarding },
  ] = useUpdateClientOnboardingMutation();

  const setupAtsState = clientOnboarding?.setupAtsState;
  const atsType = clientOnboarding?.atsType;

  const greenhouseCredsLastValidAt = React.useMemo(() => {
    return new Date(clientOnboarding?.greenhouseCredsLastValidatedAt!);
  }, [clientOnboarding?.greenhouseCredsLastValidatedAt]);

  const ashbyCredsLastValidAt = React.useMemo(() => {
    return new Date(clientOnboarding?.ashbyCredsLastValidatedAt!);
  }, [clientOnboarding?.ashbyCredsLastValidatedAt]);

  useEffect(() => {
    setGreenhouseEditMode(
      !(
        atsType === ClientOnboardingAtsTypeEnum.Greenhouse &&
        greenhouseCredsLastValidAt &&
        clientOnboarding?.greenhouseCredsAreValid
      )
    );
  }, [atsType, clientOnboarding?.greenhouseCredsAreValid, greenhouseCredsLastValidAt]);

  useEffect(() => {
    setAshbyEditMode(
      !(atsType === ClientOnboardingAtsTypeEnum.Ashby && ashbyCredsLastValidAt && clientOnboarding?.ashbyCredsAreValid)
    );
  }, [atsType, clientOnboarding?.ashbyCredsAreValid, ashbyCredsLastValidAt]);

  useEffect(() => {
    if (greenhouseApiKeyInput === "") {
      setGreenhouseApiKeyInput(clientOnboarding?.greenhouseApiKey ?? "");
    }

    if (greenhouseSubdomainInput === "") {
      setGreenhouseSubdomainInput(clientOnboarding?.greenhouseSubdomain ?? "");
    }
  }, [
    clientOnboarding?.atsType,
    clientOnboarding?.greenhouseApiKey,
    clientOnboarding?.greenhouseSubdomain,
    greenhouseApiKeyInput,
    greenhouseSubdomainInput,
    selectedAtsType,
  ]);

  useEffect(() => {
    if (ashbyApiKeyInput === "") {
      setAshbyApiKeyInput(clientOnboarding?.ashbyApiKey ?? "");
    }
  }, [clientOnboarding?.atsType, clientOnboarding?.ashbyApiKey, ashbyApiKeyInput, selectedAtsType]);

  const isSaveButtonDisabled = React.useMemo((): boolean => {
    if (
      (selectedAtsType === ClientOnboardingAtsTypeEnum.Greenhouse &&
        (greenhouseApiKeyInput === "" || greenhouseSubdomainInput === "")) ||
      (selectedAtsType === ClientOnboardingAtsTypeEnum.Ashby && ashbyApiKeyInput === "")
    ) {
      return true;
    }
    if (isUpdatingClientOnboarding) {
      return true;
    }

    if (
      setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete &&
      selectedAtsType === atsType &&
      selectedAtsType !== ClientOnboardingAtsTypeEnum.Ashby &&
      selectedAtsType !== ClientOnboardingAtsTypeEnum.Greenhouse
    ) {
      // We want to disable the save button when the state is Complete for all atsTypes
      // except Greenhouse in case they wish to switch their greenhouse creds.
      return true;
    }
    return false;
  }, [
    atsType,
    greenhouseApiKeyInput,
    greenhouseSubdomainInput,
    isUpdatingClientOnboarding,
    selectedAtsType,
    setupAtsState,
    ashbyApiKeyInput,
  ]);

  if (isClientOnboardingLoading || !clientOnboarding) {
    return (
      <Box marginTop="30px" display="flex" justifyContent="center">
        <LoadingRelative />
      </Box>
    );
  }
  const disableAtsChange =
    setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete &&
    (clientOnboarding?.atsType === ClientOnboardingAtsTypeEnum.Lever ||
      atsType === ClientOnboardingAtsTypeEnum.Greenhouse);

  const showSaveButton =
    selectedAtsType &&
    ((selectedAtsType === ClientOnboardingAtsTypeEnum.Greenhouse ? greenhouseEditMode : true) ||
      (selectedAtsType === ClientOnboardingAtsTypeEnum.Ashby ? ashbyEditMode : true));

  const showCancelButton =
    (selectedAtsType === ClientOnboardingAtsTypeEnum.Ashby && ashbyEditMode) ||
    (selectedAtsType === ClientOnboardingAtsTypeEnum.Greenhouse &&
      greenhouseEditMode &&
      clientOnboarding?.greenhouseCredsAreValid);
  const handleSave = async (): Promise<void> => {
    if (selectedAtsType === ClientOnboardingAtsTypeEnum.Lever && !leverAuthValid) {
      showErrorToast("You must connect your Lever account before saving.");
      return;
    }
    await updateClientOnboardingMutation({
      id: clientId!,
      data: {
        atsType: selectedAtsType,
        greenhouseApiKey: greenhouseApiKeyInput || undefined,
        greenhouseSubdomain: greenhouseSubdomainInput || undefined,
        ashbyApiKey: ashbyApiKeyInput || undefined,
        setupAtsState: [ClientOnboardingAtsTypeEnum.NoAts, ClientOnboardingAtsTypeEnum.Other].includes(
          selectedAtsType as ClientOnboardingAtsTypeEnum
        )
          ? UpdateClientBodySetupAtsStateEnum.Complete
          : undefined,
      } as ClientOnboarding,
      onboardingSection: OnboardingSection.ATS_SETTINGS,
    });
    if (onSave) onSave();
  };

  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.ATS_SETTINGS]}>
      <Box marginTop="30px">
        <AtsCard>
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Body> Which ATS do you use?</Body>
              {disableAtsChange && (
                <Tooltip
                  title={<BodySmall color="inherit">{"Contact Support to change your ATS."}</BodySmall>}
                  variant={TooltipVariant.Dark}
                  placement={"top"}
                  arrow={true}
                >
                  <span>
                    <StyledHelpIconSVG src={HelpIconSVG} justify-content="center" />
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Box>
          <Spacer height="8px" />
          <Box width="380px">
            <FormControl variant="outlined" size={"small"} fullWidth={true} disabled={disableAtsChange}>
              <StyledSelect
                value={selectedAtsType ?? ClientOnboardingAtsTypeEnum.NoAts}
                onChange={(value: SelectChangeEvent<unknown>): void => {
                  setSelectedAtsType(value.target.value as ClientOnboardingAtsTypeEnum);
                }}
                displayEmpty
              >
                <MenuItem key={ClientOnboardingAtsTypeEnum.NoAts} value={ClientOnboardingAtsTypeEnum.NoAts}>
                  {atsOptions[ClientOnboardingAtsTypeEnum.NoAts]}
                </MenuItem>
                {/* External ATS options -- filter out "Dover" / NoAts option */}
                {Object.keys(atsOptions)
                  .filter(
                    o =>
                      o !== ClientOnboardingAtsTypeEnum.NoAts &&
                      o !== ClientOnboardingAtsTypeEnum.GoogleHire &&
                      o !== ClientOnboardingAtsTypeEnum.NotRequired
                  )
                  .map(atsOptionKey => {
                    const mappedOption = atsOptions[atsOptionKey as ClientOnboardingAtsTypeEnum];
                    return (
                      <MenuItem key={atsOptionKey} value={atsOptionKey}>
                        {mappedOption}
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
            </FormControl>
            <Spacer height="24px" />
          </Box>
          {selectedAtsType === ClientOnboardingAtsTypeEnum.NoAts && (
            <NoAtsComponent setupAtsState={setupAtsState} atsType={clientOnboarding?.atsType} />
          )}
          {selectedAtsType === ClientOnboardingAtsTypeEnum.Lever && (
            <LeverOAuthComponent setupAtsState={setupAtsState} atsType={clientOnboarding?.atsType} />
          )}
          {selectedAtsType === ClientOnboardingAtsTypeEnum.Greenhouse && (
            <GreenhouseComponent
              setupAtsState={setupAtsState}
              greenhouseApiKeyInput={greenhouseApiKeyInput}
              greenhouseCredsValid={clientOnboarding?.greenhouseCredsAreValid}
              greenhouseCredsLastValidAt={greenhouseCredsLastValidAt}
              greenhouseSubdomainInput={greenhouseSubdomainInput}
              greenhouseEditMode={greenhouseEditMode}
              setGreenhouseEditMode={setGreenhouseEditMode}
              setGreenhouseSubdomainInput={setGreenhouseSubdomainInput}
              setGreenhouseApiKeyInput={setGreenhouseApiKeyInput}
            />
          )}
          {selectedAtsType === ClientOnboardingAtsTypeEnum.Other && (
            <OtherAtsComponent setupAtsState={setupAtsState} atsType={clientOnboarding?.atsType} />
          )}
          {selectedAtsType === ClientOnboardingAtsTypeEnum.Ashby && (
            <AshbyComponent
              setupAtsState={setupAtsState}
              ashbyApiKeyInput={ashbyApiKeyInput}
              ashbyCredsValid={clientOnboarding?.ashbyCredsAreValid}
              ashbyCredsLastValidAt={ashbyCredsLastValidAt}
              setAshbyApiKeyInput={setAshbyApiKeyInput}
              ashbyEditMode={ashbyEditMode}
              setAshbyEditMode={setAshbyEditMode}
            />
          )}
          {isUpdatingClientOnboarding && (
            <Box marginTop="30px" display="flex" justifyContent="center">
              <LoadingRelative />
            </Box>
          )}
          {showSaveButton && (
            <Stack marginTop="16px" spacing={1} direction="row" justifyContent="flex-end">
              {showCancelButton && (
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={(): void => {
                    setGreenhouseEditMode(false);
                    setAshbyEditMode(false);
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button variant={ButtonVariant.Primary} onClick={handleSave} disabled={isSaveButtonDisabled}>
                {getSaveButtonText(selectedAtsType)}
              </Button>
            </Stack>
          )}
        </AtsCard>
        <SyncAtsJobs />
      </Box>
    </SectionWrapper>
  );
};

function getSaveButtonText(selectedAtsType: ClientOnboardingAtsTypeEnum): string {
  switch (selectedAtsType) {
    case ClientOnboardingAtsTypeEnum.Greenhouse:
      return "Save and Validate";
    case ClientOnboardingAtsTypeEnum.Ashby:
      return "Save and Validate";
    default:
      return "Save";
  }
}

export default AtsSettingsComponent;
