import { Icon } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React, { FC, ReactElement } from "react";

import { Tooltip } from "components/library/Tooltip";
import { socialLinkTypeToDisplayMap, SocialLinkTypeEnum } from "components/SocialLinks/constants";

export interface SocialLink {
  linkType: SocialLinkTypeEnum;
  url: string;
}

interface SocialLinksProps {
  socialLinks: Array<SocialLink>;
}

export const SocialLinks: FC<React.PropsWithChildren<SocialLinksProps>> = ({ socialLinks }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {socialLinks.map((link): ReactElement | null => {
        const socialLinkDisplay = socialLinkTypeToDisplayMap[link.linkType as SocialLinkTypeEnum];
        if (!socialLinkDisplay) {
          return null;
        }
        return (
          <Tooltip title={socialLinkDisplay.name}>
            <a
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon Icon={socialLinkDisplay.icon} color="white" />
            </a>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
