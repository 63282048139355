import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

import { usePartialUpdateJobPositionMutation } from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanJobPosition } from "services/openapi/models";
import {
  JOB_POSITION_PRIORITY_OPTIONS,
  DEFAULT_PRIORITY_OPTION,
  getPriorityOptionByValue,
  JOB_POSITION_PRIORITY_ENUM,
} from "views/HiringPlan/constants";
import { HiringPlanTableRow } from "views/HiringPlan/types";

interface StyledSelectProps {
  $color?: string;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  position: relative;
  .MuiSelect-root {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: ${(props): any => props.$color || "inherit"};
  }

  &:before {
    border-color: ${(props): any => props.$color || "inherit"};
  }
  ,
  &:after {
    border-color: ${(props): any => props.$color || "inherit"};
  }
`;

interface Props {
  tableRow: HiringPlanTableRow;
}

const SingleSelectCellPriority = ({ tableRow }: Props): React.ReactElement => {
  const initialOption = tableRow.priority ? getPriorityOptionByValue(tableRow.priority) : DEFAULT_PRIORITY_OPTION;

  const [currOption, setCurrOption] = useState(initialOption);

  const [partialUpdateJobPosition] = usePartialUpdateJobPositionMutation();

  const handleUpdateJobPosition = async (currOptionValue: JOB_POSITION_PRIORITY_ENUM | null): Promise<void> => {
    if (currOptionValue === DEFAULT_PRIORITY_OPTION.value) currOptionValue = null;
    const updatedJobPosition = {
      ...tableRow.jobPosition,
      priority: currOptionValue,
    };
    partialUpdateJobPosition({ jobPosition: updatedJobPosition as HiringPlanJobPosition });
  };

  return (
    <StyledSelect
      variant="standard"
      $color={currOption?.color}
      onChange={async (e: SelectChangeEvent<unknown>): Promise<void> => {
        setCurrOption(getPriorityOptionByValue(e.target.value as JOB_POSITION_PRIORITY_ENUM));
        await handleUpdateJobPosition(
          getPriorityOptionByValue(e.target.value as JOB_POSITION_PRIORITY_ENUM)?.value ?? null
        );
      }}
      value={currOption?.value || DEFAULT_PRIORITY_OPTION}
    >
      {JOB_POSITION_PRIORITY_OPTIONS.map(option => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>;
      })}
    </StyledSelect>
  );
};

export default SingleSelectCellPriority;
