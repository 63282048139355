import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as UsersIcon } from "@doverhq/dover-ui/icons/users.svg";
import { Box, Stack } from "@mui/material";
import React, { ReactElement, useRef } from "react";
import ReactPlayer from "react-player";

import { Circle } from "components/library/Circle";
import { BodySmall } from "components/library/typography";
import { InterviewTranscriptResponse } from "services/openapi";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * helpers
 * -------------------------------------------------------------------------- */

const addLeadingZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

/* -----------------------------------------------------------------------------
 * SpeechSegment
 * -------------------------------------------------------------------------- */

interface SpeechSegmentProps {
  speakerColor: string;
  speakerName: string;
  start: string;
  content: string;
  onSeekTo: () => void;
}

const SpeechSegment = ({ speakerColor, speakerName, start, content, onSeekTo }: SpeechSegmentProps): ReactElement => {
  return (
    <Stack pb={2}>
      <Stack direction="row" spacing={1} pb={0.5}>
        <Circle size={24} bgcolor={speakerColor}>
          <Icon Icon={UsersIcon} size={12} color="gray-600" />
        </Circle>
        <BodySmall color={colors.grayscale.gray600}>{speakerName}</BodySmall>
        <Box onClick={onSeekTo} sx={{ cursor: "pointer" }}>
          <BodySmall color={colors.link}>{start}</BodySmall>
        </Box>
      </Stack>
      <Box bgcolor={colors.grayscale.gray100} borderRadius={1.5} p={1.5}>
        <BodySmall>{content}</BodySmall>
      </Box>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * TranscriptFeed
 * -------------------------------------------------------------------------- */

// NOTE: These colors are not part of our standard theme. They are only to be used within
// a conversational feed context to represent different speakers.
const speakerColors = ["#FDE9E7", "#E7EEFD", "#E7FAFD", "#F1FDE7"];

interface TranscriptFeedProps {
  transcript: InterviewTranscriptResponse;
}

export const TranscriptFeed = ({ transcript }: TranscriptFeedProps): ReactElement => {
  const playerRef = useRef<ReactPlayer>(null);

  const speakers = Array.from(new Set(transcript.speechSegments.map(segment => segment.speakerName)));
  const speakerColorMap = speakers.reduce((acc, speaker, index) => {
    acc[speaker] = speakerColors[index % speakerColors.length];
    return acc;
  }, {} as Record<string, string>);

  const handleSeekTo = (timestamp: number): void => {
    if (playerRef.current) {
      playerRef.current.seekTo(timestamp, "seconds");
    }
  };

  return (
    <Stack spacing={2} px={2}>
      {transcript.videoUrl && (
        <Box
          position="sticky"
          top="8px"
          sx={{ video: { borderRadius: "6px", overflow: "hidden", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)" } }}
        >
          {/* @ts-ignore FIX: react 18 type incompatibility */}
          <ReactPlayer ref={playerRef} url={transcript.videoUrl} controls width="100%" height="fit-content" />
        </Box>
      )}
      <Stack flex="1 1 auto">
        {transcript.speechSegments.map(segment => {
          const speakerName = segment.speakerName;
          const totalSeconds = Math.floor(segment.startTimestamp);
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = addLeadingZero(totalSeconds % 60);

          return (
            <SpeechSegment
              key={segment.startTimestamp}
              speakerColor={speakerColorMap[speakerName]}
              speakerName={speakerName}
              start={hours ? `${hours}:${addLeadingZero(minutes)}:${seconds}` : `${minutes}:${seconds}`}
              content={segment.content}
              onSeekTo={(): void => handleSeekTo(segment.startTimestamp)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
