import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import React from "react";
import { useField, useForm } from "react-final-form";

import { Spacer } from "components/Spacer";
import { B1_doNotUse } from "styles/typography";
import { StyledTimeSelect } from "views/CompanySetup/components/InterviewPreferences/styles";

export const MeetingBuffer = ({ setIsDirty }: { setIsDirty: (value: boolean) => void }): React.ReactElement => {
  const form = useForm();
  const meetingBufferField = useField("meetingBuffer");

  return (
    <Grid container direction="column">
      <Grid item>
        <B1_doNotUse medium>How much time do you want as a buffer between interviews?</B1_doNotUse>
        <Spacer height="16px" />
      </Grid>

      <Grid item>
        <StyledTimeSelect
          variant={"standard"}
          onChange={(e: SelectChangeEvent<unknown>): void => {
            setIsDirty(true);
            form.change(meetingBufferField.input.name, e.target.value);
          }}
          value={meetingBufferField.input.value || 0}
        >
          {BUFFER_OPTIONS.map(option => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
          })}
        </StyledTimeSelect>
      </Grid>
    </Grid>
  );
};

const BUFFER_OPTIONS = [
  { value: 0, label: "0 min" },
  { value: 5 * 60, label: "5 min" },
  { value: 10 * 60, label: "10 min" },
  { value: 15 * 60, label: "15 min" },
  { value: 20 * 60, label: "20 min" },
  { value: 25 * 60, label: "25 min" },
  { value: 30 * 60, label: "30 min" },
];
