import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEqual } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { LoginErrorCodes } from "App/appConstants";
import { GoogleAnalytics } from "App/routing/googleAnalytics";
import { Segment } from "App/routing/segment";
import { IndividualRouteConfig } from "App/routing/types";
import { isAdminOnlyPage } from "App/routing/utils";
import Loading from "components/HotLoading";
import { ROLES_REQUIRED_TO_ACCESS_APP, useContainsRole, useHasFlag } from "components/RBAC";
import { adminDataReducer, adminDataReducerKey, setRequiresSelectedClient } from "domains/admin/reducer";
import { FeatureFlag } from "hooks/useFeatureFlag";
import useIdentifyUser from "hooks/useIdentifyUser";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { Analytics } from "services/analytics";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useGetUserRolesAndPermissionsQuery } from "services/doverapi/endpoints/RBAC/endpoints";
import { ClientDoverPlanEnum } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { useInjectReducer } from "utils/injectReducer";
import Unauthorized from "views/Unauthorized";
import VerifyEmail from "views/VerifyEmail";

interface SuccessfullyAuthedComponentWrapperProps {
  isAdminOnlyPage: boolean;
}

/**
 * SuccessfullyAuthedComponentWrapper
 *
 * For pages that require authentication
 * This component is responsible for identifying the user in Segment
 */
const SuccessfullyAuthedComponentWrapper: React.FC<React.PropsWithChildren<
  SuccessfullyAuthedComponentWrapperProps
>> = ({ children, isAdminOnlyPage }) => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetAuthedUserInfoQuery(
    user && user.email_verified === false ? { checkEmailVerification: true } : undefined
  );

  const { data: clientData } = useGetUsersClientQuery();
  const jobId = useJobIdFromUrl();

  // Admin-only pages allow Dover employees to view info about any job, regardless of what the client picker
  // was previously set to. Because many of our apis are scoped to a single client, they'll fail on an admin-only
  // page where there is no client picker. For this reason, we must skip making client-scoped api calls on
  // these pages.
  const allowClientScopedApiCalls = !isAdminOnlyPage;

  const { data: jobFeatures } = useGetJobFeaturesQuery(
    allowClientScopedApiCalls && jobId ? { jobId, showToastOnError: false } : skipToken
  );

  const jobHasServicesEnabled = jobFeatures?.hasServicesEnabled;

  const additionalUserTraits = useMemo(() => {
    const roleTitleData = (userInfo?.roleTitleData as any) || {};

    return {
      // Client-level data, scoped to the authed user.
      clientId: clientData?.id,
      clientName: clientData?.name,
      doverPlan: clientData?.doverPlan,
      isTam: clientData?.isTam,
      isIcp: clientData?.isIcp,
      role: roleTitleData["calendlyRole"],
      isReferral: roleTitleData["isCustomerReferral"],
      referralSource: roleTitleData["referralSource"],
      roleTitle: roleTitleData["title"],
      // Job-level metadata. Scoped to the job id present in the page's url, if applicable.
      jobId,
      jobHasServicesEnabled,
    };
  }, [jobId, jobHasServicesEnabled, clientData, userInfo]);

  useIdentifyUser(additionalUserTraits);

  if (clientData?.doverPlan === ClientDoverPlanEnum.NoAccess) {
    return <Unauthorized errorCodeOverride={LoginErrorCodes.NoAccess} />;
  }

  if (user && user.email_verified === false && userInfo && !userInfo.emailVerified) {
    return (
      <>
        <Segment />
        <VerifyEmail />;
      </>
    );
  }

  return (
    <>
      <Segment />
      {children}
    </>
  );
};

interface RequiresAuthProps {
  allowedRoles?: string[];
  requiredFeatureFlag?: FeatureFlag;
}

/**
 * RequiresAuth
 *
 * This wrapper component will redirect the user to the login page if they are not authenticated.
 * If authentication fails, they will be redirected to the unauthorized page.
 */
const RequiresAuth: React.FC<React.PropsWithChildren<RequiresAuthProps>> = ({
  children,
  allowedRoles,
  requiredFeatureFlag,
}) => {
  const { loading, isAuthenticated, loginWithRedirect, failedToLogin, loginFailureErrorCode, user } = useAuth0();

  // This is needed here because we want to block on the fetching state of RBAC roles from our backend
  // otherwise we may render the page before we have the roles and permissions for the user
  // which leads to showing the unauthorized page when we shouldn't
  // The work to fix this is tracked in https://app.shortcut.com/dover/story/183964
  // TODO: Figure out what the intended changes are from above?
  const { isUninitialized: isRoleDataUninitialized, isFetching: fetchingRoleData } = useGetUserRolesAndPermissionsQuery(
    isAuthenticated ? undefined : skipToken
  );

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      loginWithRedirect?.({
        appState: { targetUrl: window.location },
      });
    }
  }, [loading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (failedToLogin) {
      console.log(`Login failed. Received error code: ${loginFailureErrorCode}`);
    }
  }, [failedToLogin, loginFailureErrorCode]);

  const hasAllowedRoles = useContainsRole(allowedRoles);
  const hasAllRequiredFeatureFlags = useHasFlag(requiredFeatureFlag);

  // Temporarily show the loading screen while we redirect to the login page.
  if (!isAuthenticated || !user || loading || fetchingRoleData || isRoleDataUninitialized) {
    return <Loading />;
  }

  // We should move everything below this line into a separate component so that:
  // 1. this component only checks for auth0 authentication
  // 2. the new component checks for roles and feature flags
  // this is tracked in https://app.shortcut.com/dover/story/183964

  if (failedToLogin || !(hasAllowedRoles && hasAllRequiredFeatureFlags)) {
    return <Navigate replace to="/unauthorized" />;
  }

  const isAdminOnlyPage = isEqual(allowedRoles, ["admin"]);

  return (
    <SuccessfullyAuthedComponentWrapper isAdminOnlyPage={isAdminOnlyPage}>
      {children}
    </SuccessfullyAuthedComponentWrapper>
  );
};

export const DoverRouteWrapper: React.FC<React.PropsWithChildren<
  Omit<IndividualRouteConfig, "children" | "isTopLevel" | "isMetaRoute" | "element">
>> = ({ children, authedRouteMetaData, path }): React.ReactElement => {
  useInjectReducer({ key: adminDataReducerKey, reducer: adminDataReducer });
  const dispatch = useDispatch();

  const { allowedRoles, requiresAuth, requiresSelectedClientOverride } = authedRouteMetaData;

  // Whenever the path has changed...
  // 1) Track the page navigation with segment; an
  // 2) Re-evaluate whether it's an admin only page.
  useEffect(() => {
    const analytics = new Analytics();
    analytics.page();

    // If the path is empty or "/", we don't want to set the requiresSelectedClient flag.
    // This is because we're at the root page, and child routes will determine if a selected client is required.
    if (["", "/"].includes(path)) {
      return;
    }

    const shouldRequireSelectedClient =
      requiresSelectedClientOverride === undefined
        ? !isAdminOnlyPage({ authedRouteMetaData })
        : requiresSelectedClientOverride;

    dispatch(setRequiresSelectedClient(shouldRequireSelectedClient));
  }, [allowedRoles, authedRouteMetaData, dispatch, path, requiresAuth, requiresSelectedClientOverride]);

  if (requiresAuth) {
    // if no explicit allowedRoles are provided, default to all (technically any) roles
    return <RequiresAuth allowedRoles={allowedRoles ?? ROLES_REQUIRED_TO_ACCESS_APP}>{children}</RequiresAuth>;
  }

  return (
    <>
      <GoogleAnalytics />
      {children}
    </>
  );
};
