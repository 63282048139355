import { Box, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as PlayIcon } from "assets/icons/arrow-filled.svg";
import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down-black.svg";
import { ReactComponent as PencilEditIcon } from "assets/icons/pencil-edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { SnoozeJobModal } from "components/dover/SnoozeJobModal";
import { GrayCircle, GreenCircle, YellowCircle } from "components/icons/RadioIcons";
import { BodySmall } from "components/library/typography";
import {
  useGetDeactivationStatsQuery,
  useGetJobSetupQuery,
  useReactivateJobMutation,
  useUnsnoozeJobMutation,
} from "services/doverapi/endpoints/job/endpoints";
import { useGetJobIsSnoozed } from "services/doverapi/endpoints/job/hooks";
import { DeactivateJobDeactivationReasonEnum } from "services/openapi";
import { colors } from "styles/theme";
import { isValidDate } from "utils/dates";
import { TurnOffCompletionModal } from "views/job/components/TurnOffCompletionModal";
import { DeactivateConfirmationModal } from "views/job/JobSetup/steps/Overview/components/DeactivateConfirmationModal";
import { DeactivateJobModal } from "views/job/JobSetup/steps/Overview/components/DeactivateJobModal";
import { UpdateSnoozeModal } from "views/job/JobSetup/steps/Overview/components/UpdateSnoozeModal";
import { ModalKeyType } from "views/job/types";

const DropdownButton: React.FC<React.PropsWithChildren<{
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}>> = ({ children, disabled, onClick }) => {
  return (
    <Box
      border={`1px solid ${colors.grayscale.gray200}`}
      borderRadius="6px"
      p={1}
      sx={{ cursor: !disabled ? "pointer" : "auto" }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export const JobStatusSelect = (): React.ReactElement => {
  // Fetch data
  const { jobId } = useParams<{ jobId: string | undefined }>();
  const { data: job, isLoading: jobLoading } = useGetJobSetupQuery(jobId ? jobId : skipToken);
  const { data: deactivationStats, isLoading: deactivationStatsLoading } = useGetDeactivationStatsQuery(
    jobId ?? skipToken
  );

  // Mutations
  const [reactivateJob] = useReactivateJobMutation();
  const [unsnoozeJob] = useUnsnoozeJobMutation();

  // Local state
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<ModalKeyType>(ModalKeyType.None);
  const [deactivationReason, setDeactivationReason] = useState<DeactivateJobDeactivationReasonEnum | undefined>();
  const [confirmationHeaderText, setConfirmationHeaderText] = useState<string>("");
  const [confirmationBodyText, setConfirmationBodyText] = useState<string>("");
  const [showCreateJobLink, setShowCreateJobLink] = useState<boolean>(false);
  const [snoozeReason, setSnoozeReason] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Derived data
  const hasSnoozedUntilDateInFuture = isValidDate(job?.snoozedUntil) && isAfter(job?.snoozedUntil!, new Date());
  const jobIsSnoozed = useGetJobIsSnoozed(jobId!);

  // Side effects
  useEffect(() => {
    // If the job has a snoozedUntil date in the future, set the snooze reason and date
    if (hasSnoozedUntilDateInFuture && job?.snoozedReason) {
      setSnoozeReason(job.snoozedReason);
    }
    if (hasSnoozedUntilDateInFuture && job?.snoozedUntil) {
      setSelectedDate(job.snoozedUntil);
    }
  }, [job, hasSnoozedUntilDateInFuture]);

  // Callbacks
  const openMenu = React.useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback((): void => {
    setMenuAnchorEl(null);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setOpenModal(ModalKeyType.None);
  }, []);

  const handleConfirmDeactivationModal = React.useCallback(() => {
    setOpenModal(ModalKeyType.DeactivateConfirmation);
  }, []);

  const handleSetDeactivationReason = React.useCallback((deactivationReason: DeactivateJobDeactivationReasonEnum) => {
    setDeactivationReason(deactivationReason);
  }, []);

  const openCompletionModal = React.useCallback(() => {
    if ([ModalKeyType.Deactivate, ModalKeyType.DeactivateConfirmation].includes(openModal)) {
      setConfirmationHeaderText("Dover is deactivating your job");
      setConfirmationBodyText("You'll receive a confirmation email once your search is completely deactivated.");
      setShowCreateJobLink(true);
    } else if (openModal === ModalKeyType.Snooze) {
      setConfirmationHeaderText("The job search is being paused");
    } else if (openModal === ModalKeyType.UpdateSnooze) {
      setConfirmationHeaderText("The job search is being updated and will still be paused");
    } else {
      setConfirmationHeaderText("The job search is being unpaused");
    }
    setOpenModal(ModalKeyType.Completion);
  }, [openModal]);

  const prevButtonDeactivationConfirmation = (): void => {
    setOpenModal(ModalKeyType.Deactivate);
  };

  // If data is not yet available, show job loading
  if (!job || jobLoading || !deactivationStats || deactivationStatsLoading) {
    return <Skeleton variant="rounded" width={"100px"} height={"35px"} />;
  }

  // If job is not active, tell users this but do not let them perform any actions
  if (!job.active) {
    return (
      <>
        <DropdownButton onClick={openMenu}>
          <Stack direction="row" spacing={1} alignItems="center">
            <GrayCircle sx={{ width: "10px", height: "10px" }} />
            <BodySmall color={colors.grayscale.gray500}>{"Inactive"}</BodySmall>
            <CaretDownIcon className="svg-fill" color={colors.grayscale.gray500} width="14px" height="14px" />
          </Stack>
        </DropdownButton>
        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={closeMenu}
          sx={{ minWidth: "150px" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={(): void => {
              reactivateJob(jobId!)
                .unwrap()
                .then(() => {
                  openCompletionModal;
                });
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <PlayIcon className="svg-fill" color={colors.primary.base} width="16px" height="16px" />
              <BodySmall color={colors.primary.base}>{"Reactivate job"}</BodySmall>
            </Stack>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <>
      <DropdownButton onClick={openMenu}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {jobIsSnoozed ? (
            <YellowCircle sx={{ width: "10px !important", height: "10px !important" }} />
          ) : (
            <GreenCircle sx={{ width: "10px !important", height: "10px !important" }} />
          )}
          <BodySmall color={colors.grayscale.gray500}>{`${jobIsSnoozed ? "Paused" : "Active"}`}</BodySmall>
          <CaretDownIcon className="svg-fill" color={colors.grayscale.gray500} width="14px" height="14px" />
        </Stack>
      </DropdownButton>
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        sx={{ minWidth: "150px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* If job is active, we always show the deactivate options */}
        <MenuItem
          onClick={(): void => {
            // if sample job go straight to confirmation screen
            if (job?.isSample) {
              setOpenModal(ModalKeyType.DeactivateConfirmation);
              return;
            }
            setOpenModal(ModalKeyType.Deactivate);
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <TrashIcon className="svg-color" color={colors.critical.base} width="16px" height="16px" />
            <BodySmall color={colors.critical.base}>{"Deactivate job"}</BodySmall>
          </Stack>
        </MenuItem>
        {/* If job is already paused, allow for unpause */}
        {hasSnoozedUntilDateInFuture && (
          <>
            <MenuItem
              onClick={(): void => {
                unsnoozeJob({ jobId: jobId! })
                  .unwrap()
                  .then(() => {
                    openCompletionModal;
                  });
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <PlayIcon className="svg-fill" color={colors.grayscale.gray700} width="16px" height="16px" />
                <BodySmall color={colors.grayscale.gray700}>{"Unpause"}</BodySmall>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                setOpenModal(ModalKeyType.UpdateSnooze);
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <PencilEditIcon className="svg-fill" color={colors.grayscale.gray700} width="16px" height="16px" />
                <BodySmall color={colors.grayscale.gray700}>{"Edit pause date"}</BodySmall>
              </Stack>
            </MenuItem>
          </>
        )}
      </Menu>
      <DeactivateJobModal
        shouldShowModal={openModal}
        handleCloseModal={handleCloseModal}
        handleNextModal={handleConfirmDeactivationModal}
        modalKey={ModalKeyType.Deactivate}
        jobName={job?.title || undefined}
        deactivationReason={deactivationReason}
        setDeactivationReason={handleSetDeactivationReason}
      />
      <DeactivateConfirmationModal
        shouldShowModal={openModal}
        handleCloseModal={handleCloseModal}
        // if its a sample job dont have a previous button the confirmation modal
        handlePrevModal={job.isSample ? undefined : prevButtonDeactivationConfirmation}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.DeactivateConfirmation}
        jobName={job?.title || undefined}
        selectedDeactivationReason={deactivationReason}
        isSampleJob={job?.isSample}
      />
      <SnoozeJobModal
        shouldShowModal={openModal}
        handleCloseModal={handleCloseModal}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.Snooze}
        jobName={job?.title || undefined}
        jobId={jobId}
        snoozeReason={snoozeReason}
        setSnoozeReason={setSnoozeReason}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <UpdateSnoozeModal
        shouldShowModal={openModal}
        handleCloseModal={handleCloseModal}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.UpdateSnooze}
        jobName={job?.title || undefined}
        jobId={jobId}
        snoozeReason={snoozeReason}
        setSnoozeReason={setSnoozeReason}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <TurnOffCompletionModal
        shouldShowModal={openModal}
        handleCloseModal={handleCloseModal}
        modalKey={ModalKeyType.Completion}
        headerText={confirmationHeaderText}
        bodyText={confirmationBodyText}
        shouldShowCreateJobLink={showCreateJobLink}
      />
    </>
  );
};
