import { Autocomplete, Box, ListItem, Stack, TextField as MUITextField } from "@mui/material";
import React from "react";

import { ReactComponent as TrashIconSVG } from "assets/icons/trash.svg";
import { isValidURL } from "components/CandidateHeader/SocialLinks/helpers";
import { CAREERS_PAGE_SOCIAL_LINK_EXCLUDED_LINK_TYPES } from "components/dover/EditCareersPage/constants";
import { InputErrorMessage } from "components/dover/EditCareersPage/InputErrorMessage";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { SocialLinkTypeEnum, socialLinkTypeToDisplayMap } from "components/SocialLinks/constants";
import { ClientSocialLink, ClientSocialLinkLinkTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

interface EditSocialLinkProps extends Pick<EditSocialLinksListProps, "onUpdateSocialLink" | "onRemoveSocialLink"> {
  socialLink?: ClientSocialLink;
  index: number;
}

const EditSocialLink = ({
  socialLink,
  index,
  onUpdateSocialLink,
  onRemoveSocialLink,
}: EditSocialLinkProps): React.ReactElement => {
  const linkTypeOptions = Object.values(ClientSocialLinkLinkTypeEnum).filter(
    // TODO: Remove type assertion when the backend is updated to use the ReferenceChoiceField
    linkType => !CAREERS_PAGE_SOCIAL_LINK_EXCLUDED_LINK_TYPES.includes((linkType as unknown) as SocialLinkTypeEnum)
  );
  const [urlErrorMessage, setUrlErrorMessage] = React.useState<string>("");

  const validateUrlOnBlur = (): void => {
    if (socialLink?.url && !isValidURL(socialLink.url)) {
      setUrlErrorMessage("Please enter a valid URL");
    } else setUrlErrorMessage("");
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%" pb={urlErrorMessage ? 2 : 0}>
      <Stack spacing={0.5} width="100%">
        <BodySmall weight="600">Type</BodySmall>
        <Autocomplete
          size="small"
          fullWidth
          value={socialLink?.linkType || undefined}
          options={linkTypeOptions}
          getOptionLabel={(option: ClientSocialLinkLinkTypeEnum): string => socialLinkTypeToDisplayMap[option].name}
          onChange={(_, newOption: ClientSocialLinkLinkTypeEnum | null): void => {
            if (newOption) {
              onUpdateSocialLink({ index, key: "linkType", newValue: newOption });
            }
          }}
          renderInput={(params: any): React.ReactElement => (
            <MUITextField {...params} fullWidth label=" " InputLabelProps={{ shrink: false }} />
          )}
          renderOption={(props, option: ClientSocialLinkLinkTypeEnum): React.ReactElement => {
            return (
              <ListItem {...props}>
                <BodySmall>{socialLinkTypeToDisplayMap[option].name}</BodySmall>
              </ListItem>
            );
          }}
        />
      </Stack>
      <Stack spacing={0.5} width="100%">
        <BodySmall weight="600">URL</BodySmall>
        <Box position="relative">
          <TextField
            text={socialLink?.url || ""}
            onTextUpdated={(text): void => onUpdateSocialLink({ index, key: "url", newValue: text })}
            error={!!urlErrorMessage}
            onBlur={validateUrlOnBlur}
          />
          <InputErrorMessage message={urlErrorMessage} show={!!urlErrorMessage} />
        </Box>
      </Stack>

      <div>
        <Box mt={2}>
          <Button
            variant={ButtonVariant.Ghost}
            onClick={(): void => onRemoveSocialLink(index)}
            removePadding
            removeOutline
          >
            <TrashIconSVG color={colors.grayscale.gray600} className="svg-color" />
          </Button>
        </Box>
      </div>
    </Stack>
  );
};

interface UpdateSocialLinkArgs {
  index: number;
  key: "linkType" | "url";
  newValue: string;
}

interface EditSocialLinksListProps {
  socialLinks: Array<ClientSocialLink>;
  onUpdateSocialLink: (args: UpdateSocialLinkArgs) => void;
  onAddSocialLink: () => void;
  onRemoveSocialLink: (index: number) => void;
}

const EditSocialLinksList = ({
  socialLinks,
  onUpdateSocialLink,
  onAddSocialLink,
  onRemoveSocialLink,
}: EditSocialLinksListProps): React.ReactElement => {
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <BodySmall weight="600">Links</BodySmall>
      {socialLinks.map((socialLink, index) => (
        <EditSocialLink
          key={index}
          index={index}
          socialLink={socialLink}
          onUpdateSocialLink={onUpdateSocialLink}
          onRemoveSocialLink={onRemoveSocialLink}
        />
      ))}
      <BodySmall>
        <Button variant={ButtonVariant.Link} onClick={onAddSocialLink} removePadding removeOutline>
          + Add link
        </Button>
      </BodySmall>
    </Stack>
  );
};

export default EditSocialLinksList;
