/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import { TimePicker } from "components/inputs/TimePicker";
import { colors } from "styles/theme";
import { B1_doNotUse } from "styles/typography";
import { StyledCheckbox } from "views/CompanySetup/components/InterviewPreferences/styles";
import {
  InterviewPreferencesFormValues,
  TimeRange,
  DayKeys,
} from "views/CompanySetup/components/InterviewPreferences/types";

interface Props {
  dayOfWeek: string;
  label: string;
  setIsDirty: (value: boolean) => void;
}
export const TimeRangePicker = ({ dayOfWeek, label, setIsDirty }: Props): React.ReactElement => {
  const form = useForm();
  const { values: formValues } = useFormState<InterviewPreferencesFormValues>();
  const availableTimesField = useField<TimeRange>("availableTimes." + dayOfWeek);

  const values = formValues.availableTimes ? formValues.availableTimes[dayOfWeek as DayKeys] : null;

  const [excluded, setExcluded] = useState<boolean>(!values?.start && !values?.end);
  const [from, setFrom] = useState<string>(get12HourTime(availableTimesField.input.value.start || ""));
  const [to, setTo] = useState<string>(get12HourTime(availableTimesField.input.value.end || ""));

  useEffect(() => {
    handleChange({ from: from, to: to });
  }, [from, to]);

  const handleExcludedChanged = (shouldExclude: boolean): void => {
    if (shouldExclude) {
      setExcluded(true);
      setTo("");
      setFrom("");
      handleChange({ from: "", to: "" });
    } else {
      setExcluded(false);
      setFrom(get12HourTime("10:00"));
      setTo(get12HourTime("17:00"));
      handleChange({ from: "10:00", to: "17:00" }); // ensure form is updated with default values when day is reselected
    }
  };

  const handleChange = ({ from, to }: { from: string; to: string }): void => {
    if (isTimeRangeValid({ from, to }) || !(from || to)) {
      const newTimeRange = { start: get24HourTime(from), end: get24HourTime(to) };
      form.change(availableTimesField.input.name, newTimeRange.start && newTimeRange.end ? newTimeRange : undefined);
    }
  };

  const getErrorText = (): string => {
    if (from && to && !isTimeRangeValid({ from, to })) {
      return "Invalid end time.";
    }
    return "";
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box display="flex" alignItems="center" marginLeft="-12px">
        <StyledCheckbox
          checked={!excluded}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setIsDirty(true);
            handleExcludedChanged(!e.target.checked);
          }}
          color="primary"
        />
      </Box>
      <Box display="flex" alignItems="center" width="72px" boxSizing="border-box" marginLeft="8px">
        <strong>{label}</strong>
      </Box>
      {excluded && (
        <Box display="flex" alignItems="center" color={colors.subTitle}>
          <B1_doNotUse>Unavailable</B1_doNotUse>
        </Box>
      )}
      {!excluded && (
        <Box display="flex" alignItems="center">
          <TimePicker
            value={from}
            onChange={(newValue): void => {
              setFrom(newValue);
              setIsDirty(true);
              handleChange({
                from: newValue,
                to: to,
              });
            }}
            disabled={excluded}
          />
          <Box margin="0 12px">{"-"}</Box>
          <TimePicker
            value={to}
            errorText={getErrorText()}
            onChange={(newValue): void => {
              setTo(newValue);
              setIsDirty(true);
              handleChange({
                from: from,
                to: newValue,
              });
            }}
            disabled={excluded}
          />
        </Box>
      )}
    </Box>
  );
};

function get24HourTime(timeString: string): string {
  if (!timeString) return "";

  return moment(timeString, ["h:mm A"]).format("HH:mm");
}

function get12HourTime(timeString: string): string {
  if (!timeString) return "";

  return moment(timeString, ["HH:mm"]).format("h:mm a");
}

function isTimeRangeValid({ from, to }: { from: string; to: string }): boolean {
  return moment(from, ["h:mm A"]).isBefore(moment(to, ["h:mm A"]));
}
