import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";
import { toast } from "react-toastify";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { applicationReviewEndpoints } from "services/doverapi/endpoints/applicationReview";
import { CandidateCountArgs, pipelineEndpoints } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { talentNetworkEndpoints } from "services/doverapi/endpoints/talentNetwork";
import {
  CANDIDATE_BIO,
  CANDIDATE_EMAIL_EVENT,
  CANDIDATE_INTERVIEW_EVENT,
  CANDIDATE_INTERVIEW_RUBRIC_RESPONSE,
  CANDIDATE_JOB_APPLICATION_EVENT,
  CANDIDATE_MOVED_JOB_EVENT,
  CANDIDATE_NOTE,
  CANDIDATE_STAGE_CHANGE_EVENT,
  DOVER_INTERVIEWER_CANDIDATES,
  LIST_TAG,
  PIPELINE_CANDIDATE,
  PIPELINE_CANDIDATE_COUNTS,
  CANDIDATE_FILE_LIST,
  SAAP_REVIEW_SCORED_APPLICATION_LIST,
  GET_EMAIL_TEMPLATE_V2,
} from "services/doverapi/endpointTagsConstants";
import {
  ActivityFeedItem,
  ApiApiCancelEmailOperationRequest,
  ApiApiCreateCandidateFileRequest,
  ApiApiDestroyCandidateFileRequest,
  ApiApiGetBulkRejectionTemplateOperationRequest,
  ApiApiListApplicationsViaAIRequest,
  ApiApiListCandidateFilesRequest,
  ApiApiListPipelineCandidatesRequest,
  ApiApiMoveJobOperationRequest,
  ApiApiQueueBulkRejectionRequest,
  ApiApiQueueEmailRequest,
  ApiApiRemoveSampleCandidatesOperationRequest,
  ApiApiRescheduleInterviewOperationRequest,
  ApiApiSubmitDecisionOperationRequest,
  ApiApiValidateSchedulingLinkForCandidateOperationRequest,
  BaseCandidateActionResponse,
  BaseCandidatePipelineStage,
  BulkActionEmailTemplate,
  CandidateActivityNote,
  CandidateBio,
  CandidateFiles,
  ClientInterviewer,
  CreateDtnCandidateRequest,
  EmailArgs,
  EmailTemplateV2,
  GetEmailTemplateRequestV2,
  GetSchedulingLinkRequest,
  Interview,
  InterviewSubstageEnum,
  ListArchiveReasonsResponse,
  ListSaapReviewApplicationRequest,
  NextActionSchedulingStateEnum,
  PipelineCandidate,
  RemoveSampleCandidatesResponse,
  SchedulingLink,
  TalentNetworkRequest,
  UpdateCandidateBioBody,
} from "services/openapi";
import { ValidateSchedulingLinkForCandidateResponse } from "services/openapi/models/ValidateSchedulingLinkForCandidateResponse";
import { isInterviewStage } from "utils/isStage";
import { showErrorToast, showPendingToast, showSuccessToast, toastOptions } from "utils/showToast";

// we allow providing a saaPReviewRequest so that we can do optimistic updates for saap review
// the optimstic update requires us to provide the params for listSaapReviewApplications for the optimstic update API call.
export interface SubmitDecisionArgs {
  args: ApiApiSubmitDecisionOperationRequest;
  jobId?: string; // Used for tag invalidation
  applicationId?: string; // Used for DTN optimistic update
  toNextApplication?: () => void; // Used to move to next application in app review
  listSaapReviewApplicationArgs?: ListSaapReviewApplicationRequest; // Used for optimistic update of list app routes for app review
  listApplicationsViaAiArgs?: ApiApiListApplicationsViaAIRequest; // Used for optimistic update of list app routes via ai for app review
  listDoverTalentsArgs?: TalentNetworkRequest; // Used for optimistic update of list dtn route
}

export interface CreateDtnCandidateArgs {
  args: CreateDtnCandidateRequest;
  toNextApplication?: () => void;
  listDoverTalentsArgs?: TalentNetworkRequest;
}

export interface InterviewAndCandidateIdData {
  candidateId: string;
  interview: Interview;
}

const candidateNoteAdapter = createEntityAdapter<CandidateActivityNote>();

export const candidateEndpointsTemp = doverApi.injectEndpoints({
  endpoints: build => ({
    getCandidateBio: build.query<CandidateBio, string>({
      queryFn: async candidateId => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data: CandidateBio = await client.getCandidateBio({ id: candidateId });
          return { data };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CANDIDATE_BIO, id: result.id } as const, { type: CANDIDATE_BIO } as const] : [];
      },
    }),
    getEmailTemplateV2: build.query<EmailTemplateV2, GetEmailTemplateRequestV2>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result: EmailTemplateV2 = await client.getEmailTemplateV2({ data });
        return { data: result };
      },
      providesTags: (result, error, args) => {
        const tags = [{ type: GET_EMAIL_TEMPLATE_V2, id: args.candidateId } as const];
        if (args?.clientEmailTemplateId) {
          tags.push({ type: GET_EMAIL_TEMPLATE_V2, id: args.clientEmailTemplateId } as const);
        }
        return tags;
      },
    }),
    getBulkRejectEmailTemplate: build.query<BulkActionEmailTemplate, ApiApiGetBulkRejectionTemplateOperationRequest>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.getBulkRejectionTemplate(data);
        return { data: result };
      },
    }),
    queueBulkRejection: build.mutation<BaseCandidateActionResponse, ApiApiQueueBulkRejectionRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.queueBulkRejection(args);
        return { data };
      },
      invalidatesTags: (result, error, args) => {
        const tags = args.data.candidates.map(candidate => [
          { type: CANDIDATE_BIO, id: candidate.id } as const,
          { type: PIPELINE_CANDIDATE, id: candidate.id } as const,
          { type: CANDIDATE_EMAIL_EVENT, id: candidate.id } as const,
          { type: CANDIDATE_INTERVIEW_EVENT, id: candidate.id } as const,
          { type: CANDIDATE_JOB_APPLICATION_EVENT, id: candidate.id } as const,
          { type: CANDIDATE_STAGE_CHANGE_EVENT, id: candidate.id } as const,
          { type: CANDIDATE_MOVED_JOB_EVENT, id: candidate.id } as const,
          { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: candidate.id } as const,
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG } as const,
          { type: PIPELINE_CANDIDATE, id: LIST_TAG } as const,
        ]);
        return [{ type: SAAP_REVIEW_SCORED_APPLICATION_LIST }, ...tags.flat()];
      },
    }),
    removeSampleCandidates: build.mutation<
      RemoveSampleCandidatesResponse,
      ApiApiRemoveSampleCandidatesOperationRequest
    >({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.removeSampleCandidates(args);
        return { data };
      },
      invalidatesTags: (result, _error) => {
        if (result) {
          const tags = result?.candidateIds.map(id => [
            { type: CANDIDATE_BIO, id } as const,
            { type: PIPELINE_CANDIDATE, id } as const,
            { type: CANDIDATE_EMAIL_EVENT, id } as const,
            { type: CANDIDATE_INTERVIEW_EVENT, id } as const,
            { type: CANDIDATE_JOB_APPLICATION_EVENT, id } as const,
            { type: CANDIDATE_STAGE_CHANGE_EVENT, id } as const,
            { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG } as const,
            { type: PIPELINE_CANDIDATE, id: LIST_TAG } as const,
          ]);
          return [{ type: SAAP_REVIEW_SCORED_APPLICATION_LIST }, ...tags.flat()];
        }
        return [];
      },
    }),
    getSchedulingLink: build.query<SchedulingLink, GetSchedulingLinkRequest>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        const result: SchedulingLink = await client.getSchedulingLink({ data });
        return { data: result };
      },
    }),
    validateSchedulingLinkForCandidate: build.query<
      ValidateSchedulingLinkForCandidateResponse,
      ApiApiValidateSchedulingLinkForCandidateOperationRequest
    >({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.validateSchedulingLinkForCandidate(args);
        return { data: result };
      },
    }),
    createDtnCandidate: build.mutation<BaseCandidateActionResponse, CreateDtnCandidateArgs>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.createDtnCandidate({ data: args.args });
        return { data };
      },
      invalidatesTags: (result, error, { args }) => {
        return [
          { type: PIPELINE_CANDIDATE_COUNTS, id: args.jobId },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
        ];
      },
      async onQueryStarted({ args, toNextApplication, listDoverTalentsArgs }, { dispatch, queryFulfilled }) {
        // First go to next application if provided
        toNextApplication?.();

        // Run the optimistic update for the listDoverTalentsQuery route if input is provided
        let patchListDoverTalents;
        if (listDoverTalentsArgs) {
          patchListDoverTalents = dispatch(
            talentNetworkEndpoints.util.updateQueryData("listDoverTalents", listDoverTalentsArgs, draft => {
              // Look for the app in the list
              const index = draft?.networkMembers.findIndex(app => app.inboundAppId === args.parentAppId);

              // If app was found, update the cache
              if (index > -1) {
                // Remove the actioned app
                draft.networkMembers.splice(index, 1);
              }
            })
          );
        }

        try {
          // Attempt to submit application review decision
          await queryFulfilled;
        } catch {
          // If the call failed, undo the optimistic updates
          patchListDoverTalents?.undo();
        }
      },
    }),
    submitDecision: build.mutation<BaseCandidateActionResponse, SubmitDecisionArgs>({
      queryFn: async ({ args }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.submitDecision(args);
        return { data };
      },
      invalidatesTags: (result, error, { args, jobId }) => {
        return [
          { type: CANDIDATE_BIO, id: args.id },
          { type: PIPELINE_CANDIDATE, id: args.id },
          { type: PIPELINE_CANDIDATE_COUNTS, id: jobId },
          { type: CANDIDATE_EMAIL_EVENT, id: args.id },
          { type: CANDIDATE_INTERVIEW_EVENT, id: args.id },
          { type: CANDIDATE_JOB_APPLICATION_EVENT, id: args.id },
          { type: CANDIDATE_STAGE_CHANGE_EVENT, id: args.id },
          { type: CANDIDATE_MOVED_JOB_EVENT, id: args.id },
          { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: args.id },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
        ];
      },
      async onQueryStarted(
        {
          args,
          applicationId,
          toNextApplication,
          listSaapReviewApplicationArgs,
          listApplicationsViaAiArgs,
          listDoverTalentsArgs,
        },
        { dispatch, queryFulfilled }
      ) {
        // First go to next application if provided
        toNextApplication?.();

        // Run the optimistic update for the listSaapReviewApplications route if input is provided
        let patchListSaapReviewApplications;
        if (listSaapReviewApplicationArgs) {
          patchListSaapReviewApplications = dispatch(
            applicationReviewEndpoints.util.updateQueryData(
              "listSaapReviewApplications",
              listSaapReviewApplicationArgs,
              draft => {
                // Look for the app in the list
                const index = draft?.applications.findIndex(app => app.candidateId === args.id);

                // If app was found, update the cache
                if (index > -1) {
                  // Remove the actioned app, and decrement the total count
                  draft.applications.splice(index, 1);
                  draft.totalCount--;
                }
              }
            )
          );
        }

        // Run the optimistic update for the listApplicationsViaAI route if input is provided
        let patchListAppsViaAi;
        if (listApplicationsViaAiArgs) {
          patchListAppsViaAi = dispatch(
            applicationReviewEndpoints.util.updateQueryData(
              "listApplicationsViaAI",
              listApplicationsViaAiArgs,
              draft => {
                // Look for the app in the list
                const index = draft?.results.applications.findIndex(app => app.candidateId === args.id);

                // If app was found, update the cache
                if (index > -1) {
                  // Remove the actioned app, and decrement the total count
                  draft.results.applications.splice(index, 1);
                  draft.results.totalCount--;
                }
              }
            )
          );
        }

        // Run the optimistic update for the listDoverTalentsQuery route if input is provided
        let patchListDoverTalents;
        if (listDoverTalentsArgs) {
          patchListDoverTalents = dispatch(
            talentNetworkEndpoints.util.updateQueryData("listDoverTalents", listDoverTalentsArgs, draft => {
              // Look for the app in the list
              const index = draft?.networkMembers.findIndex(app => app.inboundAppId === applicationId);

              // If app was found, update the cache
              if (index > -1) {
                // Remove the actioned app
                draft.networkMembers.splice(index, 1);
              }
            })
          );
        }

        try {
          // Attempt to submit application review decision
          await queryFulfilled;
        } catch (e) {
          console.error(e);
          // If the call failed, undo the optimistic updates
          patchListSaapReviewApplications?.undo();
          patchListAppsViaAi?.undo();
          patchListDoverTalents?.undo();
        }
      },
    }),
    rescheduleInterview: build.mutation<BaseCandidateActionResponse, ApiApiRescheduleInterviewOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.rescheduleInterview(args);
        return { data };
      },
      invalidatesTags: (result, error, args) => {
        return [
          { type: CANDIDATE_BIO, id: args.id },
          { type: PIPELINE_CANDIDATE, id: args.id },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: CANDIDATE_EMAIL_EVENT, id: args.id },
          { type: CANDIDATE_INTERVIEW_EVENT, id: args.id },
          { type: CANDIDATE_JOB_APPLICATION_EVENT, id: args.id },
          { type: CANDIDATE_STAGE_CHANGE_EVENT, id: args.id },
          { type: CANDIDATE_MOVED_JOB_EVENT, id: args.id },
          { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: args.id },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST },
        ];
      },
    }),
    listCandidateFiles: build.query<CandidateFiles[], ApiApiListCandidateFilesRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.listCandidateFiles(args);
        return { data: result.results };
      },
      providesTags: result => {
        return result ? [{ type: CANDIDATE_FILE_LIST } as const] : [];
      },
    }),
    uploadCandidateFile: build.mutation<CandidateFiles, ApiApiCreateCandidateFileRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.createCandidateFile(args);
        return { data };
      },
      invalidatesTags: () => {
        return [{ type: CANDIDATE_FILE_LIST }];
      },
    }),
    deleteCandidateFile: build.mutation<boolean, ApiApiDestroyCandidateFileRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        await client.destroyCandidateFile(args);
        return { data: true };
      },
      invalidatesTags: () => {
        return [{ type: CANDIDATE_FILE_LIST }];
      },
    }),
    queueEmail: build.mutation<BaseCandidateActionResponse, ApiApiQueueEmailRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.queueEmail(args);
        return { data };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: CANDIDATE_EMAIL_EVENT, id: args.id }];
      },
    }),
    cancelEmail: build.mutation<BaseCandidateActionResponse, ApiApiCancelEmailOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.cancelEmail(args);
        return { data };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: CANDIDATE_EMAIL_EVENT, id: args.id }];
      },
    }),
    getArchiveReasons: build.query<ListArchiveReasonsResponse, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.listArchiveReasons({});
        return { data };
      },
    }),
    listCandidateEmailEvents: build.query<ActivityFeedItem[], string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        return { data: await apiApi.listCandidateEmailEventsV2({ id: candidateId }) };
      },
      providesTags: (result, error, candidateId) => [{ type: CANDIDATE_EMAIL_EVENT, id: candidateId }],
    }),
    listStageSpecificInterviewers: build.query<ClientInterviewer[], { hiringPipelineStageId: string }>({
      queryFn: async ({ hiringPipelineStageId }: { hiringPipelineStageId: string }) => {
        const { apiApi } = await getOpenApiClients({});

        const resp = await apiApi.listInterviewers({
          hiringPipelineStageId,
        });

        return { data: resp.results };
      },
    }),
    listCandidateInterviewEvents: build.query<ActivityFeedItem[], string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        const events = await apiApi.listCandidateInterviewEvents({ id: candidateId });
        return { data: events };
      },
      providesTags: (result, error, candidateId) => [{ type: CANDIDATE_INTERVIEW_EVENT, id: candidateId }],
    }),
    listCandidateJobApplicationEvents: build.query<ActivityFeedItem[], string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        return { data: await apiApi.getJobApplicationEvent({ id: candidateId }) };
      },
      providesTags: (result, error, candidateId) => [{ type: CANDIDATE_JOB_APPLICATION_EVENT, id: candidateId }],
    }),
    listCandidateStageChangeEvents: build.query<ActivityFeedItem[], string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        return { data: await apiApi.listCandidateStageChangeEvents({ id: candidateId }) };
      },
      providesTags: (result, error, candidateId) => [{ type: CANDIDATE_STAGE_CHANGE_EVENT, id: candidateId }],
    }),
    listCandidateNotes: build.query<EntityState<CandidateActivityNote>, string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        const response = await apiApi.listCandidateActivityNotes({ candidate: candidateId });
        return { data: candidateNoteAdapter.addMany(candidateNoteAdapter.getInitialState(), response.results) };
      },
      providesTags: results => {
        // is result available?
        return results
          ? // successful query
            [...results.ids.map(id => ({ type: CANDIDATE_NOTE, id } as const)), { type: CANDIDATE_NOTE, id: LIST_TAG }]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: CANDIDATE_NOTE, id: LIST_TAG }];
      },
    }),
    listCandidateMovedJobEvents: build.query<ActivityFeedItem[], string>({
      queryFn: async candidateId => {
        const { apiApi } = await getOpenApiClients({});
        return { data: await apiApi.listCandidateMovedJobEvents({ id: candidateId }) };
      },
      providesTags: (result, error, candidateId) => [{ type: CANDIDATE_MOVED_JOB_EVENT, id: candidateId }],
    }),
    changeInterviewer: build.mutation<
      boolean,
      {
        candidateId: string;
        interviewerId: string;
        hiringPipelineStageId: string;
        interviewerFullName: string;
        hideToast?: boolean;
        skipInvalidateTags?: boolean;
      }
    >({
      queryFn: async ({
        candidateId,
        interviewerId,
        hiringPipelineStageId,
        hideToast = false,
      }: {
        candidateId: string;
        interviewerId: string;
        hiringPipelineStageId: string;
        interviewerFullName: string;
        hideToast?: boolean;
      }) => {
        const { apiApi } = await getOpenApiClients({});

        let toastId;
        if (!hideToast) {
          toastId = toast("Updating interviewer...", {
            ...toastOptions,
            type: toast.TYPE.INFO,
          });
        }

        try {
          await apiApi.changeInterviewer({
            data: {
              candidateId,
              interviewerId,
              hiringPipelineStageId,
            },
          });

          if (toastId) {
            toast.update(toastId, {
              render: "Successfully submitted interview schedule",
              type: toast.TYPE.SUCCESS,
            });
          }
        } catch (err) {
          const userFacingMessage = "Failed to update interviewer";

          if (toastId) {
            toast.update(toastId, {
              render: userFacingMessage,
              type: toast.TYPE.ERROR,
            });
          }

          return {
            error: {
              serializedError: err as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data: true };
      },
      async onQueryStarted({ candidateId, skipInvalidateTags }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } finally {
          if (!skipInvalidateTags) dispatch(doverApi.util.invalidateTags([{ type: CANDIDATE_BIO, id: candidateId }]));
        }
      },
    }),
    getInitialCallInterview: build.query<InterviewAndCandidateIdData, string>({
      queryFn: async candidateId => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getInitialCallInterview({ id: candidateId });
          return { data: { interview: data, candidateId } };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CANDIDATE_INTERVIEW_EVENT, id: result.candidateId } as const] : [];
      },
    }),
    createCandidateNote: build.mutation<
      CandidateActivityNote,
      {
        candidate: string;
        content: string;
        author?: string;
      }
    >({
      queryFn: async ({ candidate, content, author }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const toastId = toast("Creating note...", {
          ...toastOptions,
          type: toast.TYPE.INFO,
        });
        try {
          const response = await client.createCandidateActivityNote({
            data: { candidate, content, author },
          });
          toast.update(toastId, {
            render: "Successfully created note",
            type: toast.TYPE.SUCCESS,
          });
          return { data: response };
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to create note",
            type: toast.TYPE.ERROR,
          });
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return [
          // Invalidate our list so that it includes our newly created CandidateNote
          { type: CANDIDATE_NOTE, id: LIST_TAG } as const,
          // Nothing should depend on the created CandidateNote yet, but just in case, invalidate based on its id too
          ...(result ? [{ type: CANDIDATE_NOTE, id: result.id } as const] : []),
          { type: PIPELINE_CANDIDATE, id: LIST_TAG },
        ];
      },
    }),
    partialUpdateCandidateActivityNote: build.mutation<
      CandidateActivityNote,
      { candidateAcivtiyNoteId: string; updatedNote: CandidateActivityNote; candidateId: string }
    >({
      queryFn: async ({
        candidateAcivtiyNoteId,
        updatedNote,
      }: {
        candidateAcivtiyNoteId: string;
        updatedNote: CandidateActivityNote;
      }) => {
        let result: CandidateActivityNote;
        const { apiApi } = await getOpenApiClients({});
        try {
          result = await apiApi.apiV1CandidateNotePartialUpdate({ id: candidateAcivtiyNoteId, data: updatedNote });
        } catch (error) {
          showErrorToast("Failed to update note. Please refresh and try again.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: () => {
        return [
          // Invalidate our list so that it includes our newly updated CandidateNote
          { type: CANDIDATE_NOTE, id: LIST_TAG } as const,
        ];
      },
      onQueryStarted: async ({ candidateAcivtiyNoteId, updatedNote, candidateId }, { dispatch }) => {
        if (!candidateId) {
          return;
        }
        const patch = dispatch(
          candidateEndpoints.util.updateQueryData("listCandidateNotes", candidateId, draft => {
            candidateNoteAdapter.updateOne(draft, { id: candidateAcivtiyNoteId, changes: updatedNote });
          })
        );
        try {
          await patch;
        } catch (error) {
          console.error("Failed to update cache with updated note", error);
          patch?.undo();
        }
      },
    }),
    deleteCandidateActivityNote: build.mutation<boolean, { candidateAcivtiyNoteId: string }>({
      queryFn: async ({ candidateAcivtiyNoteId }: { candidateAcivtiyNoteId: string }) => {
        const { apiApi } = await getOpenApiClients({});

        showPendingToast("Deleting note.");
        try {
          await apiApi.apiV1CandidateNoteDelete({ id: candidateAcivtiyNoteId });
          showSuccessToast("Successfully deleted note.");

          return { data: true };
        } catch (err) {
          showErrorToast("Failed to delete note.");
          return { error: { serializedError: err as SerializedError } };
        }
      },
      invalidatesTags: () => {
        return [
          // Invalidate our list so that it removes our deleted CandidateNote
          { type: CANDIDATE_NOTE, id: LIST_TAG } as const,
        ];
      },
    }),
    // This is deprecated
    submitRescheduleInterview: build.mutation<
      boolean,
      {
        candidateId: string;
        emailArgs: EmailArgs;
        hiringPipelineStageId: string;
        overrideEmailAlias?: string;
      }
    >({
      queryFn: async ({
        emailArgs,
        candidateId,
        hiringPipelineStageId,
        overrideEmailAlias,
      }: {
        emailArgs: EmailArgs;
        candidateId: string;
        hiringPipelineStageId: string;
        overrideEmailAlias?: string;
      }) => {
        const { apiApi } = await getOpenApiClients({});

        const toastId = toast("Rescheduling interview", {
          ...toastOptions,
          type: toast.TYPE.INFO,
        });

        try {
          await apiApi.scheduleCandidateInterview({
            data: { candidateId, emailArgs, hiringPipelineStageId, cancelExistingInterview: true, overrideEmailAlias },
          });
          toast.update(toastId, {
            render: "Successfully submitted interview reschedule",
            type: toast.TYPE.SUCCESS,
          });
          return { data: true };
        } catch (err) {
          let errorBody;

          try {
            errorBody = (await err.json())?.error;
            // eslint-disable-next-line no-empty
          } catch (e) {}

          const errorToastMessage = errorBody || "Error rescheduling";
          toast.update(toastId, {
            render: errorToastMessage,
            type: toast.TYPE.ERROR,
          });
          return { error: { serializedError: err as SerializedError, userFacingMessage: errorToastMessage } };
        }
      },
      invalidatesTags: (result, error, { candidateId }) => {
        return [
          { type: CANDIDATE_BIO, id: candidateId },
          { type: CANDIDATE_STAGE_CHANGE_EVENT, id: candidateId },
          { type: CANDIDATE_EMAIL_EVENT, id: candidateId },
          { type: CANDIDATE_INTERVIEW_EVENT, id: candidateId },
          { type: DOVER_INTERVIEWER_CANDIDATES, id: LIST_TAG },
        ];
      },
    }),
    moveJob: build.mutation<BaseCandidateActionResponse, ApiApiMoveJobOperationRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.moveJob(args);

          return { data: response };
        } catch (err) {
          let errorBody;

          try {
            errorBody = (await (err as any).json())?.message;
            // eslint-disable-next-line no-empty
          } catch (e) {}

          return {
            error: {
              serializedError: err as SerializedError,
              userFacingMessage: errorBody || "Error moving candidate to different job",
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [
          { type: CANDIDATE_BIO, id: args.id },
          { type: CANDIDATE_MOVED_JOB_EVENT, id: args.id },
          { type: CANDIDATE_STAGE_CHANGE_EVENT, id: args.id },
          { type: CANDIDATE_EMAIL_EVENT, id: args.id },
          { type: PIPELINE_CANDIDATE, id: args.id },
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: args.id },
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST },
        ];
      },
    }),
  }),
});

// Split up our endpoint injections so that mutation endpoints can reference previously injected endpoints
// for usage by optimistic updates.
export const candidateEndpoints = candidateEndpointsTemp.injectEndpoints({
  endpoints: build => ({
    updateCandidateBio: build.mutation<
      CandidateBio,
      {
        id: string;
        data: UpdateCandidateBioBody;
        hideToast?: boolean;
        jobId?: string;
        skipTagInvalidation?: boolean;
        listPipelineCandidatesArgs?: { args: ApiApiListPipelineCandidatesRequest; useSuperApi?: boolean };
        // Used for optimistic updates when moving candidate from one stage to the next in kanban
        kanbanStageUpdate?: {
          prevListArgs: { args: ApiApiListPipelineCandidatesRequest; useSuperApi?: boolean }; // Args for the list pipeline candidates call from the stage the candidate is coming from
          nextListArgs: { args: ApiApiListPipelineCandidatesRequest; useSuperApi?: boolean }; // Args for the list pipeline candidates call from the stage the candidate is going to
          candidate: PipelineCandidate;
          candidateCountsArgs: CandidateCountArgs;
          prevStageId: string;
          nextStageId: string;
        };
        candidatePipelineStage?: BaseCandidatePipelineStage; // Used for optimstic updates
      }
    >({
      queryFn: async ({ id, data, hideToast = false, skipTagInvalidation = false }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.partialUpdateCandidateBio({ id, data });
          if (!hideToast) {
            showSuccessToast("Successfully updated candidate");
          }
        } catch (error) {
          // Only hide the Error toast in the case that hideToast is true but skipTagInvalidation is false
          // i.e. ensure we show an error toast when we skip tag invalidation to provide feedback to the user
          if (!hideToast || skipTagInvalidation) {
            showErrorToast("Failed to update candidate. Please refresh and try again.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      async onQueryStarted(
        { id, data, listPipelineCandidatesArgs, candidatePipelineStage, kanbanStageUpdate },
        { dispatch, queryFulfilled }
      ) {
        const isUpdatingStage = data.currentPipelineStage && data.currentPipelineSubstage !== undefined;

        // Perform an optimistic update, immediately updating the cache of our getCandidateBio endpoint
        // so that we don't have to wait for it to be invalidated and re-fetched.
        const bioPatchResult = dispatch(
          candidateEndpointsTemp.util.updateQueryData("getCandidateBio", id, draft => {
            if (isUpdatingStage) {
              draft.candidatePipelineStage = candidatePipelineStage;
            }

            // We can infer the next action type if the stage is updated to one of the interview scheduling stages
            if (
              draft.nextAction &&
              candidatePipelineStage &&
              isInterviewStage(candidatePipelineStage) &&
              // TODO: Extract this to a util if needed anymore else.
              // The notion of scheduling here seems specific to next action...
              [
                InterviewSubstageEnum.BASE,
                InterviewSubstageEnum.SCHEDULING_1,
                InterviewSubstageEnum.SCHEDULING_2,
                InterviewSubstageEnum.SCHEDULING_3,
                InterviewSubstageEnum.SCHEDULING_4,
              ].some(subStage => subStage === candidatePipelineStage.substage)
            ) {
              draft.nextAction.schedulingState = NextActionSchedulingStateEnum.Scheduling;
            }
          })
        );

        // Perform an optimistic update for list candidates route
        const listPatchResult =
          listPipelineCandidatesArgs &&
          dispatch(
            pipelineEndpoints.util.updateQueryData("listPipelineCandidates", listPipelineCandidatesArgs, draft => {
              if (isUpdatingStage) {
                const idx = draft.results.findIndex(candidate => candidate.id === id);

                draft.results[idx] = {
                  ...draft.results[idx],
                  candidatePipelineStage,
                };
              }
            })
          );

        // Remove candidate from previous stage in a kanban move
        const kanbanRemoveResult =
          kanbanStageUpdate &&
          dispatch(
            pipelineEndpoints.util.updateQueryData("listPipelineCandidates", kanbanStageUpdate.prevListArgs, draft => {
              if (isUpdatingStage) {
                const idx = draft.results.findIndex(candidate => candidate.id === id);
                if (idx !== -1) {
                  draft.results.splice(idx, 1);
                }
              }
            })
          );

        // Add candidate to the next stage in a kanban move
        const kanbanAddResult =
          kanbanStageUpdate &&
          dispatch(
            pipelineEndpoints.util.updateQueryData("listPipelineCandidates", kanbanStageUpdate.nextListArgs, draft => {
              if (isUpdatingStage) {
                draft.results.unshift(kanbanStageUpdate.candidate);
              }
            })
          );

        // If the cache entry for that specific stage and page doesn't exist yet, create it
        if (kanbanStageUpdate && isUpdatingStage && kanbanAddResult?.patches.length === 0) {
          dispatch(
            pipelineEndpoints.util.upsertQueryData("listPipelineCandidates", kanbanStageUpdate.nextListArgs, {
              count: 1,
              next: null,
              previous: null,
              results: [kanbanStageUpdate.candidate],
            })
          );
        }

        // Update the stage counts if the stage is updated
        const kanbanCountResult =
          kanbanStageUpdate &&
          dispatch(
            pipelineEndpoints.util.updateQueryData(
              "getCandidateCounts",
              kanbanStageUpdate.candidateCountsArgs,
              draft => {
                if (isUpdatingStage) {
                  const prevStageIdx = draft.findIndex(c => c.name === kanbanStageUpdate.prevStageId);
                  const nextStageIdx = draft.findIndex(c => c.name === kanbanStageUpdate.nextStageId);

                  draft[prevStageIdx].count--;
                  draft[nextStageIdx].count++;
                }
              }
            )
          );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side.
          bioPatchResult.undo();
          listPatchResult?.undo();
          kanbanRemoveResult?.undo();
          kanbanAddResult?.undo();
          kanbanCountResult?.undo();
        }
      },
      invalidatesTags: (result, error, args) => {
        if (result) {
          const cacheTagsToInvalidate: Array<{
            type:
              | "candidateBio"
              | "pipelineCandidate"
              | "pipelineCandidateCounts"
              | "saapReviewScoredApplicationList"
              | "candidateStageChangeEvent";
            id?: string;
          }> = [{ type: SAAP_REVIEW_SCORED_APPLICATION_LIST }];

          if (!args.skipTagInvalidation) {
            cacheTagsToInvalidate.push({ type: PIPELINE_CANDIDATE_COUNTS, id: args.jobId });
            cacheTagsToInvalidate.push({ type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG });
            cacheTagsToInvalidate.push({ type: CANDIDATE_BIO, id: args.id });
            cacheTagsToInvalidate.push({ type: PIPELINE_CANDIDATE, id: args.id });
            cacheTagsToInvalidate.push({ type: PIPELINE_CANDIDATE, id: LIST_TAG });
          }

          // always invalidate activity feed
          cacheTagsToInvalidate.push({ type: CANDIDATE_STAGE_CHANGE_EVENT, id: args.id });
          return cacheTagsToInvalidate;
        }
        return [];
      },
    }),
  }),
});

export const {
  useGetCandidateBioQuery,
  useGetInitialCallInterviewQuery,
  useLazyGetInitialCallInterviewQuery,
  useLazyGetCandidateBioQuery,
  useUpdateCandidateBioMutation,
  useGetEmailTemplateV2Query,
  useChangeInterviewerMutation,
  useListCandidateEmailEventsQuery,
  useListCandidateNotesQuery,
  useListCandidateInterviewEventsQuery,
  useListStageSpecificInterviewersQuery,
  useListCandidateJobApplicationEventsQuery,
  useListCandidateMovedJobEventsQuery,
  useListCandidateStageChangeEventsQuery,
  useCreateCandidateNoteMutation,
  usePartialUpdateCandidateActivityNoteMutation,
  useDeleteCandidateActivityNoteMutation,
  useSubmitRescheduleInterviewMutation,
  useLazyValidateSchedulingLinkForCandidateQuery,
  useValidateSchedulingLinkForCandidateQuery,
  useMoveJobMutation,
  useSubmitDecisionMutation,
  useRescheduleInterviewMutation,
  useGetSchedulingLinkQuery,
  useGetArchiveReasonsQuery,
  useQueueEmailMutation,
  useCancelEmailMutation,
  useListCandidateFilesQuery,
  useUploadCandidateFileMutation,
  useDeleteCandidateFileMutation,
  usePrefetch,
  useGetBulkRejectEmailTemplateQuery,
  useQueueBulkRejectionMutation,
  useRemoveSampleCandidatesMutation,
  useCreateDtnCandidateMutation,
} = candidateEndpoints;
