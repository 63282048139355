import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider, Stack } from "@mui/material";
import { isEqual } from "lodash";
import React, { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { ReactComponent as EllipsisSVG } from "assets/icons/ellipsis.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as ScoringSVG } from "assets/icons/scoring.svg";
import { Overline, BodyExtraSmall } from "components/library/typography";
import { usePartialUpdateSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3 } from "services/openapi";
import { colors } from "styles/theme";
import { JobScoringToggle } from "views/candidates/ApplicationReview/components/CriteriaPanel/JobScoringToggle";
import { StickyBottomButton } from "views/candidates/ApplicationReview/components/StickyBottomButton";
import { StickyTopButton } from "views/candidates/ApplicationReview/components/StickyTopButton";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import AdvancedFilters from "views/sourcing/Search/components/AdvancedFilters";
import CompaniesFilters from "views/sourcing/Search/components/CompaniesFilters";
import EducationFilters from "views/sourcing/Search/components/EducationFilters";
import LocationFilters from "views/sourcing/Search/components/LocationFilters";
import PersonasFilters from "views/sourcing/Search/components/PersonasFilters";
import SeniorityFilters from "views/sourcing/Search/components/SeniorityFilters";
import SkillsFilters from "views/sourcing/Search/components/SkillsFilters";
import YoeFilters from "views/sourcing/Search/components/YoeFilters";
import { FiltersContainerID } from "views/sourcing/Search/constants";
import { FilterToggleWrapper, FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useSetFormDirty } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

interface SaapSortersProps {
  search: SearchV3 | undefined;
  jobId: string | undefined;
  onClose: () => void;
}

const MoreOrLessFilters = ({
  showAdvancedFilters,
  onClick,
}: {
  showAdvancedFilters: boolean;
  onClick: () => void;
}): React.ReactElement => {
  const jobHasScoringEnabled = useJobHasScoringEnabled().jobHasScoringEnabled;
  return (
    <Stack
      direction="row"
      width="100%"
      padding="8px 16px"
      // if showAdvancedFilters dont have a border, because the filters/dividers will do it
      borderBottom={showAdvancedFilters ? undefined : `1px solid ${colors.grayscale.gray200}`}
      alignItems="center"
      justifyContent="space-between"
      sx={{ cursor: jobHasScoringEnabled ? "pointer" : "auto", opacity: jobHasScoringEnabled ? 1 : 0.5 }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <EllipsisSVG className="svg-fill" color={colors.grayscale.gray500} style={{ transform: "rotate(90deg)" }} />
        <Overline color={colors.grayscale.gray500}>{showAdvancedFilters ? "Less filters" : "More filters"}</Overline>
      </Stack>
      {showAdvancedFilters ? (
        <KeyboardArrowUpIcon sx={{ color: colors.grayscale.gray500 }} />
      ) : (
        <ChevronRightIcon sx={{ color: colors.grayscale.gray500 }} />
      )}
    </Stack>
  );
};

export const SimpleSorters: FC<React.PropsWithChildren<SaapSortersProps>> = ({ jobId, search, onClose }) => {
  // Load in initial form values
  const formLoadState = React.useContext(FormLoadStateContext);
  const setInitialFormValuesLoaded = formLoadState?.setFormValuesLoaded;
  const initialFormValuesLoaded = formLoadState?.loaded;

  const [showAdvancedFilters, setShowAdvancedFilters] = React.useState<boolean>(false);

  const { control } = useFormContext<SearchV3FormSchemaType>();
  const values = useWatch({ control });

  const [formDirty, setFormDirty] = React.useState<boolean>(false);
  useSetFormDirty(formDirty, setFormDirty, !!initialFormValuesLoaded, setInitialFormValuesLoaded);

  const [partialUpdateSearch, { isLoading: isSearchUpdating }] = usePartialUpdateSearchV3Mutation();
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  const onClickSaveSearch = async (): Promise<void> => {
    // Early return if the form state isn't legit
    // We'll handle errors at the top level of the Sourcing page
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }

    // If a search is not yet defined, we don't wish to proceed
    if (!search || !search.id) {
      return;
    }

    // After updates, we'll get a referentially different search object from RTKQ
    // It's important the we check deep equality against the new search so we don't perform an additional unnecessary update
    const newSearch = getSearchV3FromFormState(formParseResult.data, search);
    if (isEqual(search, newSearch)) {
      return;
    }

    await partialUpdateSearch({ id: search.id, data: { ...search, ...newSearch } });
  };

  const BottomSaveButton = (
    <StickyBottomButton
      text="Save"
      onClick={onClickSaveSearch}
      isLoading={isSearchUpdating}
      disabled={!jobHasScoringEnabled}
    />
  );

  const TopButtonTitle = (
    <StickyTopButton
      onClose={onClose}
      text="Applicant scoring"
      icon={<ScoringSVG className="svg-color" color={colors.primary.base} />}
      toggle={<JobScoringToggle jobId={jobId} />}
    />
  );

  return (
    <FilterToggleWrapper>
      {TopButtonTitle}
      {!jobHasScoringEnabled && (
        <Stack
          direction="row"
          width="100%"
          bgcolor={colors.grayscale.gray100}
          padding="4px 8px"
          alignItems="center"
          spacing={1}
        >
          <InfoIcon className="svg-color" color={colors.grayscale.gray500} />
          <BodyExtraSmall color={colors.grayscale.gray500}>Activate scoring to edit your criteria</BodyExtraSmall>
        </Stack>
      )}
      <Stack
        id={FiltersContainerID}
        height="100%"
        divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray200 }} />}
        sx={{
          overflowY: "auto",
          borderBottom: `1px solid ${colors.grayscale.gray200}`,
          borderTop: `1px solid ${colors.grayscale.gray200}`,
          pointerEvents: jobHasScoringEnabled ? "auto" : "none",
          cursor: jobHasScoringEnabled ? "auto" : "not-allowed",
          opacity: jobHasScoringEnabled ? 1 : 0.5,
        }}
      >
        <PersonasFilters />
        <LocationFilters />
        <YoeFilters />
        <SkillsFilters />
        <MoreOrLessFilters
          showAdvancedFilters={showAdvancedFilters}
          onClick={(): void => setShowAdvancedFilters(prev => !prev)}
        />
        {/* Do these checks one at a time so its all inside the stack above, instead of inside a <> which breaks some stack behaviour */}
        {showAdvancedFilters && <SeniorityFilters />}
        {showAdvancedFilters && <CompaniesFilters />}
        {showAdvancedFilters && <EducationFilters />}
        {showAdvancedFilters && <AdvancedFilters hideDiversity />}
      </Stack>
      {BottomSaveButton}
    </FilterToggleWrapper>
  );
};
