import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import DoverEmailIcon from "assets/icons/dover-email.svg";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_MAX_WIDTH,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { PromptForEmailAuthModalProps } from "components/dover/top-level-modal-manager/types";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";

const StyledReactSVG = styled(ReactSVG)`
  svg {
    width: 80px;
    height: 80px;
  }
`;

export const PromptForEmailAuthModal = ({
  isOpen,
  closeModal,
  title,
}: PromptForEmailAuthModalProps): React.ReactElement => {
  const click = (): void => {
    const emailConnectionPath = APP_ROUTE_PATHS.userSettings.emailConnection();
    // Open in new tab
    window.open(`${appConfig.appUrl}${emailConnectionPath}`, "_blank");
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>{title || "Authenticate Your Email"}</Body>}
      maxWidth={CANDIDATE_ACTION_MODAL_MAX_WIDTH}
      customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
      customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: "60vh" }}>
        <StyledReactSVG src={DoverEmailIcon} />
        <Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Authenticate Your Email</Body>
        <BodySmall>Link Dover to your Gmail account to send emails to candidates.</BodySmall>
        <Button variant="primaryFilled" onPress={click}>
          Authenticate
        </Button>
      </Stack>
    </CustomModal>
  );
};
