import classnames from "classnames";
import React from "react";

import { CustomLoadingOverlay } from "components/loading-overlay/styles";

/* TODO: (davin) pull out separate loaders into separate files and export them all here */
/* refactor to be more flexible (e.g. with height and width)
/* https://app.clubhouse.io/dover/story/41109/refactor-components-loading-overlay */

interface Props {
  active: boolean;
  children?: React.ReactNode;
  fullScreen?: boolean;
  overlay?: boolean; // defaults to true
  absolute?: boolean;
  minHeight?: string;
  height?: string;
  width?: string;
  text?: string;
  backgroundColor?: string;
}

const DoverLoadingOverlay: React.FC<React.PropsWithChildren<Props>> = ({
  active,
  children,
  fullScreen,
  overlay = true,
  absolute,
  minHeight = "50vh",
  height,
  width,
  text,
  backgroundColor,
}) => {
  return (
    <CustomLoadingOverlay
      active={active}
      spinner
      classNamePrefix="custom_"
      minHeight={minHeight}
      height={height}
      width={width}
      className={classnames({ fullScreen: fullScreen, overlay: overlay, absolute: absolute })}
      text={text}
      backgroundColor={backgroundColor}
    >
      {children}
    </CustomLoadingOverlay>
  );
};

interface LoadingSpinnerProps {
  active?: boolean;
  spinnerSize?: string;
  minHeight?: string;
  height?: string;
  width?: string;
  filter?: string;
}

export const DoverLoadingSpinner = ({
  active = true,
  spinnerSize,
  minHeight = "100px",
  height,
  width,
  filter,
}: LoadingSpinnerProps): React.ReactElement => {
  return (
    <CustomLoadingOverlay
      active={active}
      spinner
      minHeight={minHeight}
      height={height}
      width={width}
      spinnerSize={spinnerSize}
      classNamePrefix="custom_"
      className={classnames({ fullScreen: false, overlay: false })}
      filter={filter}
    />
  );
};

export default DoverLoadingOverlay;
