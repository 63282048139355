import { FormControlLabel } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

export const FilterDivider = styled.div`
  border-top: 1px solid ${colors.grayscale.gray300};
`;

export const FilterSectionLabel: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <BodySmall color={colors.grayscale.gray500} weight="600">
    {children}
  </BodySmall>
);

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;

  .MuiFormControlLabel-label {
    font-family: Inter;
    font-size: 14px;
  }
`;
