import { Box, Stack } from "@mui/material";
import { omit } from "lodash";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import SubstageRow from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/SubstageRow";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import {
  BaseInterviewSubstage,
  BaseInterviewSubstageInterviewersRequiredEnum,
} from "services/openapi/models/BaseInterviewSubstage";
import { colors } from "styles/theme";

const getMaxOrderIndex = (substages: Array<BaseInterviewSubstage>): number => {
  if (substages.length === 0) {
    return -1;
  }
  return Math.max(...substages.map(substage => (substage.orderIndex ? substage.orderIndex : 0)), 0);
};

const getNextOrderIndex = (substages: Array<BaseInterviewSubstage>): number => {
  return getMaxOrderIndex(substages) + 1;
};

interface DraggableSubstage extends BaseInterviewSubstage {
  draggableId: string;
}

const getDraggableId = (idx?: number): string => {
  const dateString = new Date().getTime().toString();
  return idx ? `${idx}-${dateString}` : dateString;
};

const getDraggableSubstages = (substages: Array<BaseInterviewSubstage>): DraggableSubstage[] => {
  return substages.map((substage: BaseInterviewSubstage, i: number) => ({
    ...substage,
    draggableId: substage.id || getDraggableId(i),
  }));
};

interface SubstagesInputProps {
  initialSubstages: Array<BaseInterviewSubstage>;
  setUpdatedSubstages: (updatedSubstages: Array<BaseInterviewSubstage>) => void;
  showHeaders?: boolean;
  disabled?: boolean;
}

const SubstagesInput = ({
  initialSubstages,
  setUpdatedSubstages,
  showHeaders,
  disabled,
}: SubstagesInputProps): React.ReactElement => {
  const [substages, setSubstages] = useState<DraggableSubstage[]>(
    initialSubstages ? getDraggableSubstages(initialSubstages) : []
  );

  useEffect(() => {
    // if there are no substages, initialize an empty one
    if (substages.length === 0) {
      setSubstages([
        ...substages,
        {
          name: "",
          duration: 60 * 30,
          possibleInterviewers: new Set(),
          interviewersRequired: BaseInterviewSubstageInterviewersRequiredEnum.Any,
          orderIndex: 0,
          orderRequired: false,
          draggableId: getDraggableId(),
          // TODO: set default feedback template to client default
        } as DraggableSubstage,
      ]);
    }
  }, [substages]);

  const addSubstage = useCallback((): void => {
    setSubstages([
      ...substages,
      {
        name: "",
        duration: 60 * 30,
        possibleInterviewers: new Set(),
        interviewersRequired: BaseInterviewSubstageInterviewersRequiredEnum.Any,
        orderIndex: getNextOrderIndex(substages),
        orderRequired: false,
        draggableId: getDraggableId(),
        // TODO: set default feedback template to client default
      },
    ]);
  }, [substages]);

  const addSubstageButton = useMemo((): React.ReactElement => {
    return (
      <Box width="150px">
        <BodySmall onClick={(): void => addSubstage()} style={{ color: colors.link, cursor: "pointer" }}>
          + Add Interview
        </BodySmall>
      </Box>
    );
  }, [addSubstage]);

  const handleSubstageChange = ({ idx, newSubstage }: { idx: number; newSubstage: any }): void => {
    setSubstages(
      substages.map(
        (substage: DraggableSubstage, i: number): DraggableSubstage => {
          if (i === idx) {
            // if a substage has been updated, strip out the id from the newSubstage
            // object to ensure that we create a new instance on the BE
            return omit(newSubstage, "id") as DraggableSubstage;
          }
          return substage;
        }
      )
    );
  };

  const handleDeleteSubstage = (idx: number): void => {
    const filteredSubstages = substages
      .filter((substage: DraggableSubstage, i: number) => {
        return i !== idx;
      })
      .map((substage: DraggableSubstage, i: number) => ({ ...substage, orderIndex: i })); // re-assign orderIndex

    setSubstages(filteredSubstages);
  };

  useEffect(() => {
    // strip draggableId's before update
    const strippedSubstages = substages.map(({ draggableId, ...rest }) => {
      console.log(draggableId);
      return rest;
    });
    setUpdatedSubstages(strippedSubstages);
  }, [setUpdatedSubstages, substages]);

  const reorder = (list: Array<DraggableSubstage>, startIndex: number, endIndex: number): DraggableSubstage[] => {
    const result = Array.from(list);
    // remove item dragged
    const [removed] = result.splice(startIndex, 1);
    // re-insert into list
    result.splice(endIndex, 0, removed);

    // re-assign orderIndices based on the new order
    const remappedOrderIndex = result.map((substage: DraggableSubstage, i: number) => ({ ...substage, orderIndex: i }));
    return remappedOrderIndex;
  };

  function onDragEnd(result: any): void {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedSubstages = reorder(substages, result.source.index, result.destination.index);

    setSubstages(reorderedSubstages);
  }

  const getHeightOfDraggingItem = (draggableId: string): number => {
    const draggingItem = substages.find(substage => substage.draggableId === draggableId);
    // if this substage has more than one selected possible interviewer, a conditional checkbox
    // will be rendered beneath the substage, increasing its total height
    const hasExtraHeight =
      draggingItem?.possibleInterviewers && Array.from(draggingItem.possibleInterviewers).length > 1;
    if (hasExtraHeight) {
      // note: this was derived from inspecting the element's styling and is subject to change
      // Derivation: Total height of substage row (76px) including Checkbox below and space between substages (8px)
      return 84;
    }
    return 48;
  };

  // FIX: temporary workaround while we can't fix our build system
  const DragDropContextTemp: any = DragDropContext;

  return (
    <DragDropContextTemp onDragEnd={onDragEnd}>
      <Stack spacing={0.5}>
        {showHeaders && (
          <Stack direction="row" spacing={1} width="100%">
            <Box flex="0.5 1 0" />
            <Box flex="1.8 1 0" display="flex" alignItems="flex-end" justifyContent="flex-start">
              {/* <SubstageHeader>Order</SubstageHeader> */}
              <BodyExtraSmall color={colors.grayscale.gray600}>ORDER</BodyExtraSmall>
            </Box>
            <Box flex="4 1 0">
              <Stack direction="row" spacing={1}>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
                  <BodyExtraSmall color={colors.grayscale.gray600}>STAGE NAME</BodyExtraSmall>
                </Box>
                <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
                  <Tooltip title={"e.g. Technical interview or Culture fit"} placement="top">
                    <InfoIcon className="svg-color" color={colors.grayscale.gray600} />
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
            <Box flex="2 1 0" display="flex" alignItems="flex-end" justifyContent="flex-start">
              <BodyExtraSmall color={colors.grayscale.gray600}>DURATION</BodyExtraSmall>
            </Box>
            <Box flex="8 1 0" display="flex" alignItems="flex-end" justifyContent="flex-start">
              <BodyExtraSmall color={colors.grayscale.gray600}>INTERVIEWER</BodyExtraSmall>
            </Box>
            <Box flex="0.5 1 0" />
          </Stack>
        )}
        {/* @ts-ignore FIX: react 18 type incompatibility */}
        <Droppable droppableId="onsite-substages" isDropDisabled={disabled}>
          {(provided: any, snapshot: any): any => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: "100%" }}>
              <Stack spacing={1}>
                {substages &&
                  substages.map((substage: any, idx: any) => {
                    return (
                      // @ts-ignore FIX: react 18 type incompatibility
                      <Draggable
                        key={substage.draggableId}
                        draggableId={substage.draggableId}
                        index={idx}
                        isDragDisabled={disabled}
                      >
                        {(provided: any): any => (
                          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <SubstageRow
                              value={substage}
                              onDelete={(): void => handleDeleteSubstage(idx)}
                              onChange={(newSubstage: any): void => handleSubstageChange({ idx, newSubstage })}
                              showDelete={substages.length > 1}
                              disabled={disabled}
                            />
                            {provided.placeholder}
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
              </Stack>
              {snapshot.isDraggingOver && (
                <div style={{ width: "100%", height: `${getHeightOfDraggingItem(snapshot.draggingOverWith)}px` }} />
              )}
            </div>
          )}
        </Droppable>
        {substages.length < 10 && !disabled && addSubstageButton}
      </Stack>
    </DragDropContextTemp>
  );
};

export default SubstagesInput;
