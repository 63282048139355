import { Box } from "@mui/material";
import React from "react";

interface SpacerProps {
  height?: string | number;
  width?: string | number;
}
export const Spacer: React.FC<React.PropsWithChildren<SpacerProps>> = ({ height, width }) => {
  return <Box height={height} width={width} />;
};
