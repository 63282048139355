import React from "react";

import ActivateDoverInterviewerModal from "components/dover/feature-toggle-handler/ActivateDoverInterviewerModal";
import ActivateEndToEndSchedulingModal from "components/dover/feature-toggle-handler/ActivateEndToEndSchedulingModal";
import ActivateTargetedSourcingModal from "components/dover/feature-toggle-handler/ActivateTargetedSourcingModal";
import DeactivateDoverInterviewerModal from "components/dover/feature-toggle-handler/DeactivateDoverInterviewerModal";
import DeactivateEndToEndSchedulingModal from "components/dover/feature-toggle-handler/DeactivateEndToEndSchedulingModal";
import DeactivateTargetedSourcingModal from "components/dover/feature-toggle-handler/DeactivateTargetedSourcingModal";
import { ServiceFeatureModalProps } from "components/dover/job-feature-card/types";
import { UpsertJobFeatureSettingFeatureNameEnum } from "services/openapi";

export const FEATURE_NAME_TO_DEACTIVATION_MODAL_MAP: Readonly<
  {
    [key in UpsertJobFeatureSettingFeatureNameEnum]?: React.ComponentType<
      React.PropsWithChildren<ServiceFeatureModalProps>
    >;
  }
> = {
  [UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer]: DeactivateDoverInterviewerModal,
  [UpsertJobFeatureSettingFeatureNameEnum.E2EScheduling]: DeactivateEndToEndSchedulingModal,
  [UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound]: DeactivateTargetedSourcingModal,
};

export const FEATURE_NAME_TO_ACTIVATION_MODAL_MAP: Readonly<
  {
    [key in UpsertJobFeatureSettingFeatureNameEnum]?: React.ComponentType<
      React.PropsWithChildren<ServiceFeatureModalProps>
    >;
  }
> = {
  [UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer]: ActivateDoverInterviewerModal,
  [UpsertJobFeatureSettingFeatureNameEnum.E2EScheduling]: ActivateEndToEndSchedulingModal,
  [UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound]: ActivateTargetedSourcingModal,
};

export const OPEN_SNOOZE_JOB_PARAM = "snoozeJob";
