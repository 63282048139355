import { Button } from "@doverhq/dover-ui";
import { PressEvent } from "@react-types/shared/src/";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { navigateToLink } from "App/routing/utils";
import { useJobId } from "hooks/useJobIdFromUrl";

interface OpenAppReviewButtonProps {
  fullWidth?: boolean;
}

export const OpenAppReviewButton: FC<React.PropsWithChildren<OpenAppReviewButtonProps>> = ({ fullWidth }) => {
  const navigate = useNavigate();
  const [jobId] = useJobId();
  const open = (event: PressEvent): void => {
    if (!jobId) {
      return;
    }
    navigateToLink(event, navigate, APP_ROUTE_PATHS.job.applicationReviewV2(jobId));
  };

  return (
    <Button w={fullWidth ? "full" : undefined} variant="primaryFilled" onPress={open} isLoading={!jobId}>
      Application Review
    </Button>
  );
};
