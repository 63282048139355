import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { Role, useContainsRole, useHasElevatedPermissions } from "components/RBAC";

export const useDisableCandidateActionsTooltipText = (jobId?: string): string | undefined => {
  const hasValidCandidateActionPerms = useHasElevatedPermissions(jobId);

  if (!hasValidCandidateActionPerms) {
    return INVALID_PERMS_TOOLTIP_TEXT;
  }

  return undefined;
};

export const useDisableFeedbackFormsTooltipText = (jobId?: string): string | undefined => {
  const hasValidCandidateActionPerms = useContainsRole(
    [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER],
    jobId
  );

  if (!hasValidCandidateActionPerms) {
    return "Only the hiring manager and recruiter on this job can view other interviewers' feedback forms.";
  }

  return undefined;
};
