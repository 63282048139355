import { Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { ScheduledActionButton, ScheduledActionEnum, Option } from "components/ScheduledActionButton";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { UpdateCandidateBioBodyStatusEnum } from "services/openapi";
import { toastOptions } from "utils/showToast";

const SnoozeSchedululingOptionLabels: Option[] = [
  { value: ScheduledActionEnum.Indefinitely, label: "indefinitely" },
  { value: ScheduledActionEnum.InAbout24Hours, label: "for ~24 hours" },
  { value: ScheduledActionEnum.AtDateAndTime, label: "until..." },
];

export const SnoozeModal = ({ isOpen, closeModal, candidateId }: CandidateActionModalProps): React.ReactElement => {
  const candidateActionVersion = useActionVersion(candidateId);
  const jobId = useCandidateJobId(candidateId);
  const [updateBio] = useUpdateCandidateBioMutation();
  const [snoozeAt, setSnoozedAt] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const clickSnooze = useCallback(async () => {
    if (!candidateId || !jobId) {
      console.warn("No candidateId or jobId when trying to snooze candidate");
      return;
    }

    const args = {
      candidateActionVersion,
      id: candidateId,
      jobId,
      hideToast: true,
      data: {
        snoozedUntil: snoozeAt,
        status: UpdateCandidateBioBodyStatusEnum.Snoozed,
      },
    };

    const submitDecisionPromise = updateBio(args).unwrap();

    try {
      setLoading(true);
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Snoozing zzZZZZz...",
          success: "Snoozed!",
          error: "Error Snoozing Candidate!",
        },
        { ...toastOptions }
      );
      setLoading(false);
      closeModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      return;
    }
  }, [candidateId, jobId, updateBio, snoozeAt, setLoading, closeModal, candidateActionVersion]);

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Snooze</Body>}
        maxWidth="sm"
        customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
        customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
        dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
      >
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between">
          <>
            <Stack spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
              <ScheduledActionButton
                setActionAt={setSnoozedAt}
                schedulingOptionLabels={SnoozeSchedululingOptionLabels}
                showTimzeone={false}
              />
            </Stack>
            <Button variant={ButtonVariant.SecondarySuccess} onClick={clickSnooze} loading={loading}>
              Snooze
            </Button>
          </>
        </Stack>
      </CustomModal>
    </>
  );
};
