export const OPEN_SEARCH = "cmd+k";
export const CREATE_JOB = "j";
export const ADD_CANDIDATE = "c";
export const INVITE_USER = "t";
export const SLACK_RECRUITING_PARTNER = "m";
export const TOGGLE_HOTKEY_MENU = "/";
export const BOOKMARK_CANDIDATE = "b";
export const SCHEDULE_CANDIDATE = "s";
export const REJECT_CANDIDATE = "r";
export const SELECT_ALL_APPLICATIONS = "a";
export const NEXT_APPLICATION = "j,down";
export const PREV_APPLICATION = "k,up";
export const PREV_TAB = "left,p";
export const NEXT_TAB = "right,n";
export const ENTER = "enter";
