import { CircularProgress, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { addCandidateToCampaignModalAtom } from "components/dover/AddCandidate/AddCandidateToCampaignModal";
import {
  bulkUploadCandidatesToCampaignAtom,
  campaignMessageBodyAtom,
  campaignMessageCcEmailsAtom,
  campaignMessageSubjectAtom,
  campaignMessageValidAtom,
  singleAddCandidateToCampaignAtom,
  uploadTypeAtom,
  useResetAddCandidateToCampaignAtomState,
} from "components/dover/AddCandidate/atoms";
import InitialCampaignMessageEditor from "components/dover/AddCandidate/CampaignMessageEditor";
import FooterActions from "components/dover/AddCandidate/Footer";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetCampaignQuery } from "services/doverapi/endpoints/campaign/endpoints";
import {
  useAddCandidateInitialOutreachBulkMutation,
  useAddCandidateInitialOutreachMutation,
} from "services/doverapi/endpoints/candidate";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

const PreviewAndSendEmailStep: React.FC<React.PropsWithChildren<{
  handleNextStep: () => void;
  handlePrevStep: () => void;
}>> = ({ handleNextStep, handlePrevStep }) => {
  // Pull in job ID from URL
  const [jobId] = useJobId();

  // Pull in candidate form data from step 1
  const uploadType = useAtomValue(uploadTypeAtom);
  const singleAddCandidateData = useAtomValue(singleAddCandidateToCampaignAtom);
  const bulkAddCandidateData = useAtomValue(bulkUploadCandidatesToCampaignAtom);

  // Track campaign message state
  const campaignMessageSubject = useAtomValue(campaignMessageSubjectAtom);
  const campaignMessageBody = useAtomValue(campaignMessageBodyAtom);
  const campaignMessageCcEmails = useAtomValue(campaignMessageCcEmailsAtom);
  const campaignMessageValid = useAtomValue(campaignMessageValidAtom);

  const resetAtomState = useResetAddCandidateToCampaignAtomState();

  const { context } = useModal(addCandidateToCampaignModalAtom);
  const campaignId = context?.campaignId;

  const { data: campaign, isFetching: isLoadingCampaign } = useGetCampaignQuery(campaignId);

  const initialMessage = useMemo(() => {
    return campaign?.threadMessages?.[0];
  }, [campaign]);

  const [
    addSingleCandidateToCampaign,
    { isLoading: isAddingSingleCandidate },
  ] = useAddCandidateInitialOutreachMutation();
  const [
    addBulkCandidatesToCampaign,
    { isLoading: isAddingBulkCandidates },
  ] = useAddCandidateInitialOutreachBulkMutation();

  const handleSingleAddCandidate = async (): Promise<void> => {
    if (!jobId) {
      console.error("No job ID found");
      return;
    }

    if (!singleAddCandidateData) {
      console.error("No single candidate data found");
      return;
    }

    const { firstName, lastName, email, linkedIn } = singleAddCandidateData;
    try {
      const toastId = toast.loading("Adding candidate to campaign...", toastOptions);
      const addSingleResponse = await addSingleCandidateToCampaign({
        job: jobId,
        firstName: firstName,
        lastName: lastName,
        campaign: campaignId,
        email: email ?? "",
        emailId: undefined,
        linkedinProfileUrl: linkedIn,
        firstMessageBody: campaignMessageBody,
        firstMessageSubject: campaignMessageSubject,
        ccEmails: campaignMessageCcEmails,
      }).unwrap();

      if (addSingleResponse.success) {
        const candidateId = addSingleResponse.candidate;
        const toastContent = (
          <Stack spacing={0.5}>
            <BodySmall>
              Added {firstName} {lastName} to campaign
            </BodySmall>
            {candidateId && (
              <Link to={APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, candidateId)}>
                <BodySmall color="inherit">View candidate</BodySmall>
              </Link>
            )}
          </Stack>
        );

        toast.update(toastId, {
          ...toastOptions,
          isLoading: false,
          type: "success",
          render: toastContent,
        });
      }

      if (!addSingleResponse.success) {
        toast.update(toastId, {
          ...toastOptions,
          isLoading: false,
          render: addSingleResponse.message,
          type: "error",
        });
        return;
      }
    } catch (e) {
      toast.error("Failed to add candidate to campaign", toastOptions);
      console.error(e);
      return;
    }
  };

  const handleBulkAddCandidates = async (): Promise<void> => {
    if (!jobId) {
      console.error("No job ID found");
      return;
    }

    if (!bulkAddCandidateData) {
      console.error("No bulk candidate data found");
      return;
    }

    const { manualSourcingItems } = bulkAddCandidateData;
    if (!manualSourcingItems || manualSourcingItems.length === 0) {
      console.error("No parsed bulk candidates data found");
      return;
    }

    try {
      const toastId = toast.loading("Adding candidates to campaign...", toastOptions);

      const addBulkResponse = await addBulkCandidatesToCampaign({
        data: {
          job: jobId,
          candidates: manualSourcingItems,
          campaign: campaignId,
          firstMessageBody: campaignMessageBody,
          firstMessageSubject: campaignMessageSubject,
          ccEmails: campaignMessageCcEmails,
        },
      }).unwrap();

      if (addBulkResponse.success) {
        toast.update(toastId, {
          render: "Successfully added candidates to campaign",
          type: "success",
          isLoading: false,
          ...toastOptions,
        });
      } else {
        toast.update(toastId, { render: addBulkResponse.message, type: "error", isLoading: false, ...toastOptions });
      }
    } catch (e) {
      toast.error("Failed to add candidates to campaign", toastOptions);
      console.error(e);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    // Default html form submission reloads the page
    e.preventDefault();

    if (!campaignId) {
      console.error("No campaign ID found");
      return;
    }

    // Single Add Candidate to Campaign
    if (uploadType === "single") {
      try {
        await handleSingleAddCandidate();
      } catch (e) {
        console.error(e);
        return;
      }
    }

    // Else Bulk Add Candidates to Campaign
    else {
      try {
        await handleBulkAddCandidates();
      } catch (e) {
        console.error(e);
        return;
      }
    }

    resetAtomState();
    handleNextStep();
  };

  if (isLoadingCampaign) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%" color={colors.grayscale.gray600}>
        <CircularProgress color="inherit" />
      </Stack>
    );
  }

  if (!isLoadingCampaign && (!campaign || !initialMessage)) {
    return <div>No campaign found</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <InitialCampaignMessageEditor
          fromEmail={campaign?.emailAliasInfo?.email}
          toName={uploadType === "single" ? singleAddCandidateData?.firstName : undefined}
          message={initialMessage!}
        />
        <FooterActions
          onBack={handlePrevStep}
          disableNext={!campaignMessageValid || isAddingSingleCandidate || isAddingBulkCandidates}
          nextLabel="Send"
        />
      </Stack>
    </form>
  );
};

export default PreviewAndSendEmailStep;
