/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { TextField, Radios } from "mui-rff";
import queryString from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Confetti from "react-confetti";
import { Form, useFormState } from "react-final-form";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import { BooleanParam, useQueryParams } from "use-query-params";

import { ReactComponent as BriefcaseSVG } from "assets/icons/briefcase.svg";
import { ReactComponent as CompleteCheckSVG } from "assets/icons/check-green-filled.svg";
import { ReactComponent as MapPinSVG } from "assets/icons/map-pin.svg";
import { ReactComponent as UploadCloudSVG } from "assets/icons/upload-cloud.svg";
import { ExternalFooter } from "components/ExternalFooter";
import HotLoading from "components/HotLoading";
import FileUploadField from "components/inputs/FileUpload";
import { Button as DoverButton, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Heading, TitleLarge } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { selectError } from "domains/files/selectors";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { getOpenApiClients, OpenApiClients } from "services/api";
import { useGetCareersPageClientQuery } from "services/doverapi/endpoints/careersPageClient";
import { useListCareersPageJobQuery } from "services/doverapi/endpoints/careersPageJob";
import { useGetApplicationPortalJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetJobDescriptionQuery } from "services/doverapi/endpoints/job-description/endpoints";
import { getEntityById } from "services/doverapi/entityAdapterUtils";
import { ApiApi } from "services/openapi";
import {
  ApplicationQuestionApplicationPortalJob,
  ApplicationQuestionApplicationPortalJobInputTypeEnum,
  ApplicationQuestionApplicationPortalJobQuestionTypeEnum,
  CareersPageJob,
} from "services/openapi/models";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, screenSizes } from "styles/theme";
import { InternalLink } from "styles/typography";
import { displayJobCompensation } from "utils/displayJobCompensation";
import { displayJobLocations } from "utils/displayJobLocations";
import { getHtmlFromUnknownContent } from "utils/draftJS";
import { isValidEmail } from "utils/email";
import { generateRandomizedImageUrl } from "utils/image";
import { isLinkedInURL } from "utils/linkedin";
import { showErrorToast } from "utils/showToast";
import {
  LEFT_PANEL_WIDTH,
  MIDDLE_SPACER_WIDTH,
  RIGHT_PANEL_WIDTH,
  X_GUTTER,
  X_GUTTER_MOBILE,
} from "views/inboundExternal/constants";
import {
  getGoogleJobPostingStructuredData,
  getInboundFormFields,
} from "views/inboundExternal/InboundApplication/helpers";
import {
  FormLabel,
  Header,
  HeaderContent,
  JobInfoBox,
  SectionHeader,
  StyledHtmlWrapper,
  StyledLogo,
} from "views/inboundExternal/styles";

interface ContainerProps {
  height?: string;
}
const ApplicationPortalContainer = styled.div<ContainerProps>`
  width: 100%;
  height: ${(props): string | undefined => props.height ?? "fit-content"};
  font-family: Inter, sans-serif;
  background-color: white;
  padding-top: 24px;
`;

const ContentContainer = styled.div`
  height: 100%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;

  @media (max-width: ${screenSizes.laptop}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentContainerWrapper = styled.div`
  padding: 32px ${X_GUTTER};

  @media (max-width: ${screenSizes.tablet}) {
    padding: 32px ${X_GUTTER_MOBILE};
  }
`;

const JobDescriptionWrapper = styled.div`
  max-width: 50%;
  width: ${LEFT_PANEL_WIDTH};

  @media (max-width: ${screenSizes.laptop}) {
    max-width: 100%;
  }
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 24px;
  border: 1px solid ${colors.grayscale.gray300};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  height: fit-content;
  position: sticky;
  top: 24px;
  width: ${RIGHT_PANEL_WIDTH};
  max-width: 50%;
  margin-left: ${MIDDLE_SPACER_WIDTH};
  margin-bottom: 24px;

  @media (max-width: ${screenSizes.laptop}) {
    position: relative;
    max-width: 100%;
    margin-top: 24px;
    margin-left: 0;
  }
`;

const REFERRER_SOURCE_QUERY_PARAM = "referrerSource";
const OBSCURED_REFERRER_SOURCE_QUERY_PARAM = "rs";
const INITIAL_FORM_VALUES: { files: File[] } = {
  files: [],
};

interface InboundApplicationPortalProps {
  apiClient: ApiApi;
  searchOrJobId: string;
  showGeneratedDescription?: boolean;
}

const InboundApplicationPortal: React.FC<React.PropsWithChildren<InboundApplicationPortalProps>> = ({
  searchOrJobId,
  apiClient,
  showGeneratedDescription,
}) => {
  const location = useLocation();
  const [query] = useQueryParams({
    // Used to control whether we show the form + job description, or just the form
    form: BooleanParam,
  });
  const { data: jobInfo, isFetching: isFetchingJobInfo } = useGetApplicationPortalJobQuery(searchOrJobId, {
    skip: !searchOrJobId,
  });
  const { data: jobDescription, isFetching: isFetchingJobDescription } = useGetJobDescriptionQuery(
    jobInfo?.id ? { jobId: jobInfo?.id, requiresAuthentication: false } : skipToken
  );

  const [submitted, setSubmitted] = useState(false);
  const [referrerSource, setReferrerSource] = useState<string | null>(null);
  const { width, height } = useWindowSize();

  const showFormOnly: boolean = query.form || false;
  const showApplyButton = width < 1024;
  const formRef = useRef<HTMLDivElement>(null);

  const showJobDescription =
    (jobDescription?.isPublished && jobDescription?.userProvidedDescription) || showGeneratedDescription;
  const showComp = !!jobInfo?.compensation?.openToSharingComp;

  const { data: client, isLoading: isClientLoading } = useGetCareersPageClientQuery(
    jobInfo?.clientId ? { clientId: jobInfo.clientId } : skipToken
  );

  const { data: jobsOnCareersPage } = useListCareersPageJobQuery(
    jobInfo?.clientId ? { clientId: jobInfo.clientId } : skipToken
  );
  const job: CareersPageJob | undefined = getEntityById(jobsOnCareersPage, searchOrJobId);
  // tracks whether this job has the Dover Careers Page source enabled to determine whether
  // to link back to All Jobs (i.e. the Careers Page)
  const isPostedToCareersPage = !!job;
  const customFields = useMemo(
    () =>
      jobInfo?.applicationQuestions.filter(
        question => question.questionType === ApplicationQuestionApplicationPortalJobQuestionTypeEnum.Custom
      ),
    [jobInfo?.applicationQuestions]
  );

  const linkedinUrlApplicationQuestion = useMemo(
    () =>
      jobInfo?.applicationQuestions.find(
        question => question.questionType === ApplicationQuestionApplicationPortalJobQuestionTypeEnum.LinkedinUrl
      ),
    [jobInfo?.applicationQuestions]
  );

  const linkedInUrlRequired = useMemo(
    () => jobInfo?.requireLinkedinProfileUrl || linkedinUrlApplicationQuestion?.required,
    [jobInfo?.applicationQuestions, jobInfo?.requireLinkedinProfileUrl]
  );

  const resumeApplicationQuestion = useMemo(
    () =>
      jobInfo?.applicationQuestions.find(
        question => question.questionType === ApplicationQuestionApplicationPortalJobQuestionTypeEnum.Resume
      ),
    [jobInfo?.applicationQuestions]
  );

  const phoneNumberApplicationQuestion = useMemo(
    () =>
      jobInfo?.applicationQuestions.find(
        question => question.questionType === ApplicationQuestionApplicationPortalJobQuestionTypeEnum.PhoneNumber
      ),
    [jobInfo?.applicationQuestions]
  );

  const compensation = React.useMemo(() => {
    const showCompensation = jobInfo?.compensation?.openToSharingComp;

    if (!showCompensation) {
      return undefined;
    }

    if (jobInfo?.compensation?.lowerBound && jobInfo?.compensation?.upperBound) {
      return displayJobCompensation(jobInfo.compensation);
    }

    return undefined;
  }, [jobInfo, jobDescription]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const rawReferrerSourceFromUrl =
      queryParams[REFERRER_SOURCE_QUERY_PARAM] || queryParams[OBSCURED_REFERRER_SOURCE_QUERY_PARAM] || null;

    let referrerSourceFromUrl: string | null;
    if (Array.isArray(rawReferrerSourceFromUrl)) {
      referrerSourceFromUrl = rawReferrerSourceFromUrl[0];
    } else {
      referrerSourceFromUrl = rawReferrerSourceFromUrl as string | null;
    }
    setReferrerSource(() => referrerSourceFromUrl);
  }, [location]);

  const onSubmit = async (values: any): Promise<void> => {
    const jobId = jobInfo?.id;
    if (!jobId) {
      return;
    }

    // reset top of form box in view
    if (formRef) {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const missingLinkedinUrl = !values.linkedinUrl && linkedInUrlRequired;
    const missingResume = values.files.length === 0 && resumeApplicationQuestion?.required;
    if (missingLinkedinUrl || missingResume) {
      showErrorToast(`Either a LinkedIn Profile URL or resume is required.`);
      return;
    }

    const fieldNames = customFields?.map(field => field.id);
    const defaultValues: { [key: string]: any } = {};
    Object.keys(values).forEach(key => {
      if (!fieldNames?.includes(key)) {
        defaultValues[key] = values[key];
      }
    });

    const applicationQuestions = customFields?.map(field => {
      return { question: field.question, answer: values[field.id], id: field.id };
    });

    try {
      // @ts-ignore
      await apiClient.createApplicationPortalInboundApplication({
        ...defaultValues,
        // only pass in a value here if client has enabled talent network at time of submission
        // NOTE: We leaving this blank because we hide DTN from UI:
        // https://app.shortcut.com/dover/story/196833/hide-dover-talent-network-from-ui
        optedInToTalentNetwork: null,
        jobId,
        referrerSource,
        resume: values.files?.[0],
        applicationQuestions: JSON.stringify(applicationQuestions),
      });
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      let errorMessageToDisplay = "Failed to submit. Please try again later.";
      try {
        const errorMsg = await e.json();
        if (errorMsg?.[0]) {
          errorMessageToDisplay = errorMsg?.[0];
        }
      } catch (e) {
        "pass";
      }
      showErrorToast(errorMessageToDisplay);
    }
  };

  const getCustomField = (field: ApplicationQuestionApplicationPortalJob): JSX.Element => {
    if (field.inputType === ApplicationQuestionApplicationPortalJobInputTypeEnum.MultipleChoice) {
      if (!field.multipleChoiceOptions?.length) {
        return <></>;
      }
      return (
        <>
          <FormLabel>{`${field.question}${field.required ? " *" : ""}`}</FormLabel>
          <Radios
            color="primary"
            label=""
            name={field.id}
            required={field.required}
            formControlLabelProps={{ style: { marginBottom: 0 } }}
            data={
              field.multipleChoiceOptions?.map(option => ({
                label: option,
                value: option,
              })) ?? []
            }
          />
        </>
      );
    }
    return (
      <>
        <FormLabel>{`${field.question}${field.required ? " *" : ""}`}</FormLabel>
        <TextField
          size="small"
          variant="outlined"
          multiline={field.inputType === ApplicationQuestionApplicationPortalJobInputTypeEnum.LongAnswer}
          name={field.id}
          margin="none"
          required={field.required}
        />
      </>
    );
  };

  const validate = (values: any): any => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (linkedInUrlRequired && !values.linkedinUrl) {
      errors.linkedinUrl = "Required";
    }
    if (values.linkedinUrl && !isLinkedInURL(values.linkedinUrl)) {
      errors.linkedinUrl = "Invalid LinkedIn Profile URL (example: https://www.linkedin.com/in/yourname)";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!isValidEmail(values.email)) {
      errors.email = "Invalid email";
    }
    if (values.phoneNumber && !values.phoneNumber.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g)) {
      errors.phoneNumber = "Invalid phone number";
    }
    if (resumeApplicationQuestion?.required && values.files.length === 0) {
      errors.files = "Required";
    }
    if (phoneNumberApplicationQuestion?.required && !values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    customFields
      ?.filter(field => field.required)
      .forEach(field => {
        if (!values[field.id]) {
          errors[field.id] = "Required";
        }
      });

    return errors;
  };

  const pageTitle = `Apply for ${jobInfo?.title} at ${jobInfo?.clientName}`;

  const useNewMetaTags = jobInfo?.clientName === "Trover";

  const getLinkedinDescription = (): string | undefined => {
    if (!jobInfo) {
      return undefined;
    }
    if (jobInfo.clientName && jobInfo.title) {
      return `${jobInfo.clientName} at ${jobInfo.title}`;
    }
    return jobInfo.clientName || jobInfo.title || undefined;
  };

  const companyLogo = useMemo((): React.ReactElement => {
    return <StyledLogo src={generateRandomizedImageUrl(jobInfo?.clientLogo)} alt=" url" />;
  }, [jobInfo]);

  const headerLogo = useMemo((): React.ReactElement => {
    return (
      <>
        {client?.careersPageLogoRedirectUrl ? (
          <a href={client.careersPageLogoRedirectUrl}>{companyLogo}</a>
        ) : (
          <>
            {isPostedToCareersPage ? (
              // TODO: "dover" should not be hardcoded in this link -- should be client name
              <InternalLink to={`/dover/careers/${jobInfo?.clientId}`}>{companyLogo}</InternalLink>
            ) : (
              companyLogo
            )}
          </>
        )}
      </>
    );
  }, [client, companyLogo, isPostedToCareersPage, jobInfo]);

  const formFields: { field: React.ReactElement; isNameField?: boolean }[] = useMemo(() => {
    if (!jobInfo?.id) {
      return [];
    }
    const inboundFormFields: { field: React.ReactElement; isNameField?: boolean }[] = getInboundFormFields(
      !!linkedInUrlRequired,
      jobInfo.id,
      linkedinUrlApplicationQuestion
        ? {
            ...linkedinUrlApplicationQuestion,
            required: linkedinUrlApplicationQuestion.required,
          }
        : undefined,
      phoneNumberApplicationQuestion
        ? {
            ...phoneNumberApplicationQuestion,
            required: phoneNumberApplicationQuestion.required,
          }
        : undefined
    );

    customFields?.forEach(field => {
      inboundFormFields.push({
        field: getCustomField(field),
      });
    });
    inboundFormFields.push({
      field: (
        <>
          <FormLabel>Anything else we should know?</FormLabel>
          <TextField size="small" variant="outlined" multiline name="additionalInfo" margin="none" required={false} />
        </>
      ),
    });

    return inboundFormFields;
  }, [jobInfo, linkedInUrlRequired, linkedinUrlApplicationQuestion, phoneNumberApplicationQuestion]);

  const nameFields = useMemo(() => formFields.filter(field => !!field.isNameField), [formFields]);
  const otherFields = useMemo(() => formFields.filter(field => !field.isNameField), [formFields]);

  if (isFetchingJobInfo || isClientLoading) {
    return <HotLoading />;
  }

  if (!jobInfo) {
    return (
      <ApplicationPortalContainer height="100%">
        <Stack justifyContent={"space-between"} height={"100%"}>
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"}>
            <TitleLarge color={colors.grayscale.gray600}>
              {"Hmm... we couldn't find the job posting you're looking for."}
            </TitleLarge>
            <SectionHeader style={{ fontWeight: 400 }} color={colors.grayscale.gray600}>
              It may have been filled or deactivated.
            </SectionHeader>
          </Stack>
          <ExternalFooter client={client} />
        </Stack>
      </ApplicationPortalContainer>
    );
  }

  const showLocation = jobInfo?.locations && jobInfo.locations.length > 0;
  const locationText = displayJobLocations(jobInfo.locations);

  return (
    <ApplicationPortalContainer>
      <PageHelmet
        title={pageTitle}
        description={useNewMetaTags ? getLinkedinDescription() : undefined}
        image={useNewMetaTags ? jobInfo.clientLogo : undefined}
        jobPostingStructuredData={getGoogleJobPostingStructuredData(jobInfo, jobDescription)}
      />

      <Header center>
        <HeaderContent>
          <Box mb={3}>{jobInfo.clientLogo !== undefined && headerLogo}</Box>
          {isFetchingJobInfo && (
            <Box mb={1}>
              <Skeleton width="100%" height="32px" />
            </Box>
          )}
          {jobInfo.title && jobInfo.clientName && (
            <Box mb={1}>
              <TitleLarge>
                {jobInfo.title} at {jobInfo.clientName}
              </TitleLarge>
            </Box>
          )}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            width="100%"
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={2}
          >
            <Stack
              direction={{ md: "column", lg: "row" }}
              spacing={{ xs: 1, lg: 2 }}
              alignItems={{ md: "flex-start", lg: "center" }}
            >
              {showLocation && (
                <Box>
                  <JobLocationInfo jobInfo={locationText} />
                </Box>
              )}
              {showComp && !!compensation && (
                <Box>
                  <JobInfoBox>
                    <BriefcaseSVG style={{ marginRight: "8px" }} />
                    {compensation}
                  </JobInfoBox>
                </Box>
              )}
            </Stack>
            {isPostedToCareersPage && (jobsOnCareersPage?.ids?.length ?? 0) > 1 && (
              <Box>
                <InternalLink to={`/${jobInfo.clientName}/careers/${jobInfo.clientId}`}>
                  <BodySmall color={colors.grayscale.gray600}>View all open roles</BodySmall>
                </InternalLink>
              </Box>
            )}
          </Stack>
        </HeaderContent>
      </Header>
      {submitted && <Confetti width={width} height={height} numberOfPieces={1000} recycle={false} />}
      <ContentContainerWrapper>
        <ContentContainer>
          {!showFormOnly && showJobDescription && (
            <JobDescriptionWrapper>
              {showApplyButton && (
                <Box mb={3}>
                  <DoverButton
                    variant={ButtonVariant.Secondary}
                    onClick={(): void => {
                      if (formRef) {
                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    Apply
                  </DoverButton>
                </Box>
              )}
              {isFetchingJobDescription && <Skeleton width="100%" height="64px" />}
              {showJobDescription && !isFetchingJobDescription && (
                <Stack spacing={2}>
                  <StyledHtmlWrapper>
                    {ReactHtmlParser(getHtmlFromUnknownContent(jobDescription?.userProvidedDescription ?? ""))}
                  </StyledHtmlWrapper>
                </Stack>
              )}
            </JobDescriptionWrapper>
          )}
          <FormWrapper ref={formRef}>
            {!showJobDescription && (
              <Box mb={2}>{showLocation && <JobLocationInfo jobInfo={locationText ?? ""} />}</Box>
            )}
            {submitted && (
              <>
                <Stack direction="column" alignItems="center" spacing={2}>
                  <CompleteCheckSVG />
                  <SectionHeader>Thanks for applying!</SectionHeader>
                  <Body style={{ textAlign: "center" }}>
                    {"We'll reach out if we're interested in moving forward with your application."}
                  </Body>
                </Stack>
              </>
            )}
            {!submitted && (
              <Stack spacing={2}>
                <Heading>Apply</Heading>
                <Form
                  onSubmit={onSubmit}
                  initialValues={INITIAL_FORM_VALUES}
                  validate={validate}
                  render={({ handleSubmit, submitting, errors, submitFailed }): React.ReactNode => (
                    <form onSubmit={handleSubmit} noValidate>
                      {/* Render First and Last name fields on same line on large screens */}
                      <Stack direction={{ xs: "column", sm: "row" }} spacing={1} mb={2}>
                        {nameFields.map((item, idx) => (
                          <Box key={idx} flexGrow="1">
                            {item.field}
                          </Box>
                        ))}
                      </Stack>
                      {otherFields.map((item, idx) => (
                        <Box mb={2} key={idx}>
                          {item.field}
                        </Box>
                      ))}
                      <Box mb={2}>
                        <FormLabel>Resume {resumeApplicationQuestion?.required ? "*" : "(optional)"}</FormLabel>
                        <ResumeField submissionError={submitFailed && !!errors?.files} />
                      </Box>
                      <DoverButton width="100%" variant={ButtonVariant.Primary} disabled={submitting} type="submit">
                        Apply
                      </DoverButton>
                    </form>
                  )}
                />
              </Stack>
            )}
          </FormWrapper>
        </ContentContainer>
      </ContentContainerWrapper>
      <ExternalFooter client={client} />
    </ApplicationPortalContainer>
  );
};

export const JobLocationInfo = ({ jobInfo }: { jobInfo: string }): React.ReactElement => {
  return (
    <JobInfoBox>
      <MapPinSVG style={{ marginRight: "8px" }} />
      {jobInfo}
    </JobInfoBox>
  );
};

const ResumeField = ({ submissionError }: { submissionError: boolean }): React.ReactElement => {
  const formState = useFormState();

  const errorKey = "uploadResume";
  const errorMessage = useSelector(selectError(errorKey));

  return (
    <>
      <FileUploadField
        name="files"
        acceptFileTypes=".pdf"
        dropzoneOptions={{
          maxFiles: 1,
          minSize: 1, // minSize of 1 byte exclude empty files
          maxSize: 5 * 10 ** 6, // maxSize of 5 MB
        }}
        errorKey={errorKey}
        placeholder={
          errorMessage ? (
            <Stack spacing={1} alignItems="center" justifyContent="center">
              <Body color={colors.critical.base}>Error: {errorMessage}</Body>
              <BodySmall color={colors.critical.base}>(Must be a PDF smaller than 5 MB)</BodySmall>
            </Stack>
          ) : formState.values.files[0] ? (
            <Stack>
              <Body>{formState.values.files[0].name}</Body>
            </Stack>
          ) : (
            <Stack spacing={1} alignItems="center" justifyContent="center">
              <UploadCloudSVG className="svg-fill" color={colors.grayscale.gray500} />
              <Body color={colors.grayscale.gray500} centered>
                Drag and drop files or{" "}
                <Box sx={{ cursor: "pointer", color: colors.link, display: "inline" }}>browse computer</Box>
              </Body>
              <BodySmall color={colors.grayscale.gray500} centered>
                (PDF only, 5 MB maximum.)
              </BodySmall>
            </Stack>
          )
        }
      ></FileUploadField>
      {submissionError && <Body color={colors.critical.base}>Please upload a resume</Body>}
    </>
  );
};

const InboundApplicationPortalWrapper = ({
  showGeneratedDescription,
}: {
  showGeneratedDescription?: boolean;
}): React.ReactElement => {
  const { searchOrJobId } = useParams<{ searchOrJobId: string }>();
  const jobIdFromUrl = useJobIdFromUrl();

  const { isAuthenticated } = useAuth0();
  const [client, setClient] = useState<ApiApi>();

  useEffect(() => {
    const setClientState = async (): Promise<void> => {
      let clients: OpenApiClients;
      if (isAuthenticated) {
        clients = await getOpenApiClients({ includeToken: true });
      } else {
        clients = await getOpenApiClients({ includeToken: false });
      }
      setClient(clients.apiApi);
    };
    setClientState();
  }, []);

  if (!client || (!searchOrJobId && !jobIdFromUrl)) {
    return <></>;
  }

  return (
    <InboundApplicationPortal
      apiClient={client}
      searchOrJobId={searchOrJobId ?? jobIdFromUrl!}
      showGeneratedDescription={showGeneratedDescription}
    />
  );
};

export default InboundApplicationPortalWrapper;
